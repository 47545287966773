import produce from 'immer';
import { SUBMIT_FBC_ApprovalRequest_FAIL, SUBMIT_FBC_ApprovalRequest_SUCCESS }
    from '../request/constants';

export const initialState = { error: false };

const fbcApprovalErrorsReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUBMIT_FBC_ApprovalRequest_FAIL:
                draft.error = action.error;
                break;
            case SUBMIT_FBC_ApprovalRequest_SUCCESS:
                draft.error = false;
                break;
        }
    });

export default fbcApprovalErrorsReducer;
