
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import collapsableSection from '../../../../common/components/collapsableSection';
import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import ReallocationDetailsTable from '../../components/reallocationDetailsTable';
import MultiTenantSearchAndAdd from '../multiTenantSearchAndAdd';
import reducer from './reducer';
import messages from './messages';


function ReallocationDetails({
    isReadOnly
}) {

    useInjectReducer({ key: 'RellocationDetails', reducer });

    return (
        <div>
            <div className="row spacer-16-top">
                <MultiTenantSearchAndAdd isReadOnly={isReadOnly} />

                <div className="col-lg-24" style={{ paddingTop: "10px" }}>
                    <ReallocationDetailsTable isReadOnly={isReadOnly} />
                </div>
            </div>
        </div >
    );
}

ReallocationDetails.propTypes = {
    isReadOnly: PropTypes.bool

};

const mapStateToProps = createStructuredSelector({

});

function mapDispatchToProps(dispatch) {
    return {

    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose
    (withConnect)
    (collapsableSection(messages.reallocationDetailsSectionHeader, true)
        (ReallocationDetails));