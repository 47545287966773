//#region  import section 
import React, { memo } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { Switch, Route } from "react-router-dom";
import CancelFutureBilligOpsHome from "../../../submit/operations/containers/cancelFutureBillingHome";
import PrivacyStatement from "../privacy/index";
import CancelFutureBillingSearch from "../../../search/cancelFutureBilling/components/index";
import CancelFutureBillingSearchHome from "../../../approval/approver/fbcApprover/home";
import { isFbcEnabled } from "../../internals/utils/featureFlag";
import CancelFutureBillingMenu from "./CancelFutureBillingMenu";
import MultiTenantHome from '../../../submit/operations/containers/multiTenantHome';
import MultiTenantMenu from './MultiTenantMenu';
import multiTenant from '../../../search/multiTenant/components/index';
import { isMtEnabledForUser } from "./MultiTenantMenu";
import MultiTenantSearchHome from '../../../submit/operations/containers/multiTenantSearchHome';
import UnAuthorised from './../unauthroised';
import { Redirect } from 'react-router';
import '../../app/accessibility-style.css';
import { RetirementMessage } from "../RetirementMessage/RetirementMessage";

//#endregion

//#region declare function component, this gets called when the user is active vl and doesn't have any roles assigned
// FBC feature accessible for all vl active users who doesnt have any specific role and PCNs assigned.

const VLActiveUserMenu = (props) => {
  const isFbcEnabledFlag = isFbcEnabled();
  const isMTEnabled = isMtEnabledForUser(props.userInfo);

  console.log("Fbc feature flag status:" + isFbcEnabledFlag);
  return (
    <div className="row remove-all-margin">
      <div className="col-md-5 col-lg-3 side-nav-wrap">
        {isFbcEnabledFlag === "True" ? (
          <div
            className="onedash-container"
            data-bi-area="OneDashboardSide"
            role="navigation"
          >
            <nav
              id="onedash-side-navigation"
              className="onedash-side-navigation mobileHide is-open"
              aria-label="menubar"
            >
              <div
                id="onedash-navigation-list"
                className="onedash-navigation-list"
              >
                <div id="onedash-top-menus">
                  <div>
                    <CancelFutureBillingMenu />
                  </div>

                  <div>
                    {isMTEnabled && <MultiTenantMenu />}
                  </div>
                </div>
              </div>
              <div>
                <PrivacyStatement />
              </div>
            </nav>
          </div>
        ) : null}
      </div>

      <div className="col-sm-24 col-md-19 col-lg-21 rightview">
        {RetirementMessage(props?.userInfo?.roles)}
        <Switch>
          <Route
            path="/fbc/request:docId?/:requestId?/:requestStatus?"
            component={CancelFutureBillingSearchHome}
          />
          <Route path="/fbc/search" component={CancelFutureBillingSearch} />
          <Route
            path="/fbc/create/:enrollmentNumber?/:systemPONumber?/:requestId?/:requestStatus?"
            render={() => <CancelFutureBilligOpsHome />}
          />
          {isMTEnabled ?
            <>
              <Route path="/multitenant/create/:leadEnrollmentNumber?" render={() => <MultiTenantHome />} />
              <Route path="/multitenant/search" component={multiTenant} />
              <Route path="/multitenant/request/:enrollmentNumber?/:requestId?/:requestStatus?" component={MultiTenantSearchHome} />
            </> :
            <>
              <Route path="/multitenant/create/:leadEnrollmentNumber?" render={() => <UnAuthorised />} />
              <Route path="/multitenant/search" component={() => <UnAuthorised />} />
              <Route path="/multitenant/request/:enrollmentNumber?/:requestId?/:requestStatus?" component={() => <UnAuthorised />} />
            </>

          }

          <Route path="/" render={() => (<Redirect to="/fbc/search" push={true} />)} />
        </Switch>
      </div>
    </div>
  );
};

//#endregion

const withConnect = connect(null, null);

export default compose(withConnect, memo)(injectIntl(VLActiveUserMenu));
