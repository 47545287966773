import { defineMessages } from 'react-intl';

export const scope = 'fbcApprovalErrors';

export default defineMessages({
  approvalSearchError: {
    id: `${scope}.approvalSearchError`,
    defaultMessage: 'Get Document Error ',
  },
  approvalRequestError: {
    id: `${scope}.approvalRequestError`,
    defaultMessage: 'Request Submit Error ',
  },
});
