import { ON_PO_SELECTION_MODAL_CLOSE, ON_ADD_SELECTED_PO } from './constants';

export function onPOSelectionModalClose() {
    return {
        type: ON_PO_SELECTION_MODAL_CLOSE
    };
}

export function onAddSelectedPOs() {
    return {
        type: ON_ADD_SELECTED_PO
    };
}