import React, { useEffect, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Link } from "react-router-dom";
import { createStructuredSelector } from 'reselect';
import { ActionControl } from '../../../common/components/ActionControl/ActionDropdown';
import FilterCriteria from '../../../common/search/components/filters/FilterCriteria';
import { FilterCredits, changeDateRange } from '../../../common/search/components/filters/actions';
import { makeSelectLoading, makeSelectError, makeSelectCredits, makeSelectDateRange } from '../../../common/search/components/filters/selectors';

import GridControl from '../../../common/components/grid/GridControl';
import SuccessMessage from '../../../common/components/controls/SuccessMessage';
import LoadingIndicator from '../../../common/components/LoadingIndicator/index';
import { makeSelectDomainData } from '../../../common/app/selectors';
import ErrorMessage from '../../../common/components/ErrorMessage/ErrorMessage';
import * as Helpers from '../../../common/internals/utils/helpers';
import DomainDataContext from '../../../common/context/DomainDataContext';
import exportColumns from '../../../common/internals/utils/exportExcelColumnsList';
import DateRangeFilter from '../../../common/components/controls/DateRangeFilter';
import errorMessages from '../../../common/internals/api/errorMessages';
import { isSearchEnhancementEnabled } from '../../../common/internals/utils/featureFlag';
import uuidv4 from 'uuid/v4';
let creditsList,exportCreditsList = [];
var dateRangeElement = "";

export function SearchCreditsOps(props) {

    const myDomainData = useContext(DomainDataContext);
    const { formatMessage } = props.intl;
    const isSearchEnhancementEnabledFlag = isSearchEnhancementEnabled();

    const linkFormatter = (cell, row) => {
        var url = "";
        if (row) {
            if (props.location.pathname.indexOf('/partner/') !== -1) {
                url = `../partner/create?invoice=${row.invoice_number}&requestId=${row.requestid}&status=${row.request_status_code}`;
            }
            if (props.location.pathname.indexOf('/submitter/') !== -1) {
                url = row.hasMultiple ? `../submitter/multiCreate?multiInvoiceNumber=${row.multiInvoiceNumber}&requestId=${row.requestid}&status=${row.request_status_code}&isEpt=true` : `../submitter/create?invoice=${row.invoice_number}&requestId=${row.requestid}&status=${row.request_status_code}`;
            }
            if (props.location.pathname.indexOf('/field/') !== -1) {
                url = `../field/create?invoice=${row.invoice_number}&requestId=${row.requestid}&status=${row.request_status_code}`;
            }
            if (props.location.pathname.indexOf('/field/subcfo/search') !== -1) {
                url = `../field/subcfo/create?invoice=${row.invoice_number}&requestId=${row.requestid}&status=${row.request_status_code}`;
            }
            if (props.location.pathname.indexOf('/ops/') !== -1) {
                url = row.hasMultiple ? `../ops/multiCreate?multiInvoiceNumber=${row.multiInvoiceNumber}&requestId=${row.requestid}&status=${row.request_status_code}&isEpt=true` : `../ops/create?invoice=${row.invoice_number}&requestId=${row.requestid}&status=${row.request_status_code}`;
            }
            if (row.hasMultiple) {
                console.log(row);
            }
            return (
                <Link to={url}> {row.requestid}</Link>
            );
        } 
    };

    function onDateRangeSelection(e, value) {
        dateRangeElement = PrepareFilterButtons(value);
    }

    function handleApplyEvent(value) {       
        dateRangeElement = PrepareFilterButtons(value);
    }
    
    function PrepareFilterButtons(lifetimeDays) {
        props.onChangeDateRange(lifetimeDays);
        props.onSearchCredits();
        return <DateRangeFilter key={uuidv4()} lifetimeDays={lifetimeDays} handleApplyEvent={handleApplyEvent} onSearchChange={props.onSearchChange} onDateRangeSelection={onDateRangeSelection} />;
    }

    function getCosmicTickets(cosmicCaseList) {
        if (Helpers.isEmpty(cosmicCaseList)) {
            return null;
        }
        const _strCosmicCases = cosmicCaseList && cosmicCaseList.reduce((_strCosmicCases, data) => {
            if (!Helpers.isEmpty(data) && !Helpers.isEmpty(data.ticketId)) {
                _strCosmicCases.push(
                    data.ticketId
                );
            }
            return _strCosmicCases;
        }, []);
        return _strCosmicCases.join(',\r\n');
    }

    const columns = [
        {
            dataField: 'requestid',
            text: formatMessage({ id: "lblRequestId" }),
            title: true,
            headerTitle: true,
            formatter: linkFormatter
        }, {
            dataField: 'invoice_number',
            text: formatMessage({ id: "lblInvoiceNumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
            style: { whiteSpace:"pre-wrap", wordWrap:"break-word"}
        }, {
            dataField: 'request_date',
            text: formatMessage({ id: "lblRequestDate" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'request_status',
            text: formatMessage({ id: "lblRequestStatues" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'customer_name',
            text: formatMessage({ id: "lblCustomerName" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'enrollment_number',
            text: formatMessage({ id: "lblEnrolmentNumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'po_number',
            text: formatMessage({ id: "lblPONumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }];

    if (isSearchEnhancementEnabledFlag == "True") {
        columns.push(
            {
                dataField: 'cosmic_cases',
                text: formatMessage({ id: "lblCosmicNumber" }),
                title: (cell, row, rowIndex, colIndex) => `${cell}`,
                headerTitle: true
            })
    }

    useEffect(() => {
        document.title = formatMessage({ id: "lblApplicationName" }) + " - " + formatMessage({ id: "lblSeachPageName" });
        dateRangeElement = PrepareFilterButtons("1D");
    }, []);
        
    let statusMessage = "";
    if (props && props.error && 'errorMessages' in props.error) {
        const titleMessage = { id: "titleErrorMessage", defaultMessage: "Search Error" };
        statusMessage = <ErrorMessage key="searcherrorMessages" messageBody={props.error.errorMessages} messageTitle={titleMessage} defaultMessage={errorMessages.tokenExpiredMsg} />
    } 
  
    creditsList = [];
    if (props.response && props.response.length > 0) {
        creditsList = [];
        props.response.map(data => (
            
            creditsList.push({
                uniqueId: uuidv4(),
                requestid: data.returnsIdentifier,
                hasMultiple: data.hasMultipleInvoice,
                invoice_number: data.hasMultipleInvoice ? Helpers.getMultiPleInvoices(data.multipleInvoiceList, "invoiceNumber") : data.billingDocumentNbr,
                request_date: Helpers.DateFormatForDisplay(new Date(data.requestSubmitDate)),
                request_status: (props.location.pathname.indexOf('/partner/') !== -1) ? Helpers.GetPartnerStatusByCode(data.requestStatusCode) : Helpers.GetStatusByCode(data.requestStatusCode),
                request_status_code: data.requestStatusCode,
                customer_name: data.endCustomerName,
                customer_number: data.endCustomerPurchaseOrderNumber,
                enrollment_number: data.agreementNumber,
                po_number: data.purchaseOrderNumber,
                multiInvoiceNumber: data.billingDocumentNbr,
                cosmic_cases: getCosmicTickets(data.cosmicCases)
            })
        ));        
    }

    exportCreditsList = [];
    if (props.response && props.response.length > 0) {
        exportCreditsList = [];
        props.response.map(data => (           
            exportCreditsList.push({
                uniqueId: uuidv4(),
                requestid: data.returnsIdentifier,
                request_status: (props.location.pathname.indexOf('/partner/') !== -1) ? Helpers.GetPartnerStatusByCode(data.requestStatusCode) : Helpers.GetStatusByCode(data.requestStatusCode),

                invoice_number: data.hasMultipleInvoice ? Helpers.getMultiPleInvoices(data.multipleInvoiceList,"invoiceNumber") : data.billingDocumentNbr,
                handling_fee: data.hasMultipleInvoice ? Helpers.getMultiPleInvoices(data.multipleInvoiceList, "restockFee") : data.handlingFee,
                invoice_date: data.hasMultipleInvoice ? Helpers.getMultiPleInvoices(data.multipleInvoiceList, "invoiceDate") : Helpers.DateFormatForDisplay(new Date(data.invoiceDate)),
                invoice_amount: data.hasMultipleInvoice ? Helpers.getMultiPleInvoices(data.multipleInvoiceList, "invoiceTotalAmount") : data.invoiceAmount,
                currency_code: data.currencyCode,
                po_number: data.purchaseOrderNumber,
                order_type: data.orderTypeCode,
                enrollment_number: data.agreementNumber,
                return_reason_code: data.returnReasonCode,
                return_reason: Helpers.GetReturnReasonsByCodeNew(data.returnReasonCode, myDomainData),
                rebill_po_number: Helpers.GetReBillPOAsString(data.rebillPOList),
                request_submit_date: data.requestSubmitDate ? Helpers.DateFormatForDisplay(new Date(data.requestSubmitDate)) : "",
                request_approval_date: data.requestApprovalDate ? Helpers.DateFormatForDisplay(new Date(data.requestApprovalDate)) : "",
                request_approval_status: data.requestApprovalStatus ? data.requestApprovalStatus : "",
                approver_contact: data.approverContact ? data.approverContact : "",
                approval_or_rejection_comments: data.approverComments ? data.approverComments : "",
                customer_pcn: data.endCustomerPurchaseOrderNumber,
                customer_name: data.endCustomerName,
                partner_pcn: data.partnerPCN,
                partner_name: data.partnerName,
                reseller_pcn: data.resellerPCN ? data.resellerPCN : "",
                reseller_name: data.resellerName ? data.resellerName : "",
                program_code: data.programCode,
                country_code: data.countryCode,
                cosmic_case: Helpers.GetCosmicCaseTicketsAsString(data.cosmicCases),
                cosmic_cases: getCosmicTickets(data.cosmicCases),   
            })
        ));
    }

    return (
        <div className="form-group">
            <div className="col-lg-24">
                <div className="row spacer-xs-bottom" >
                    <div className="col-sm-24 col-md-24 col-lg-24">
                        {statusMessage}
                    </div>
                </div>
                <div className="row spacer-ms-bottom">
                    <div className="col-sm-12 col-md-12 col-lg-12" >
                        <h1 className="section-title">                           
                            {formatMessage({ id: "lblCreateRequestSearch" })}
                        </h1>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12" >
                        {dateRangeElement}
                    </div>
                </div>
                <FilterCriteria key="filtercriteria" intl={props.intl} domainData={myDomainData} location={props && props.location} />
                <div className="row" >
                    <div className="col-sm-10 col-md-10 col-lg-10">
                        <div className={!props.loading ? 'hidden' : ''}>
                            <LoadingIndicator />
                        </div>
                    </div>
                </div>
                <div className="row spacer-xs-bottom">
                    <GridControl key="gridcontrolfordatadisplay" columns={columns} data={creditsList} intl={props.intl} />
                </div>
                <div className="row spacer-xs-bottom pull-right" >
                    <div className="col-sm-24 col-md-24 col-lg-24">
                        <ActionControl id="CreditListActions" fileName="CreditsList.csv" List={exportCreditsList} columns={exportColumns} intl={props.intl} />
                    </div>
                </div>
            </div>
        </div >
    );

}

SearchCreditsOps.propTypes = {
    dateRange: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    loading: PropTypes.bool,
    onSearchCredits: PropTypes.func,
    onChangeDateRange: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    error: makeSelectError(),
    response: makeSelectCredits(),
    domainData: makeSelectDomainData(),
    dateRange: makeSelectDateRange(),
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSearchCredits: () => dispatch(FilterCredits()),
        onChangeDateRange: (data) => dispatch(changeDateRange(data))
    };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, memo)(injectIntl(SearchCreditsOps));
