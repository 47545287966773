import React from 'react';
import reducer from './reducer';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import LocalisedLabel from '../../../../common/components/controls/LocalisedLabel';
import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import { makeSelectMTApproverComment, makeSelectMTApproverCallout, makeSelectMTRejectionReasonCode, makeSelectMTRejectionReasonDescripiton, makeSelectIsMTOtherRejectionReason } from './selectors';
import { changeApproverComments, changeApproverCallout, changeRejectionReason, addOtherRejectionReason, setOtherRejectionReason } from './actions';
import { userRoleType } from '../../../../common/internals/utils/helpers';
import DropDownBox from '../../../../common/components/controls/DropDown';
import * as Helpers from '../../../../common/internals/utils/helpers';
import { FormattedMessage } from 'react-intl';
import { COMMENTS_LENGTH } from '../../../../common/internals/utils/constants';
import messages from './messages';
function MultiTenantApproverDetails({
    approverDetailsProps,
    approverComment,
    onApproverComments,
    approverCallout,
    onApproverCallout,
    isApprovalReadonly,
    rejectionReasonCode,
    rejectionReasonDescripiton,
    onAddOtherRejectionReason,
    onRejectionReasonChange,
    isOtherRejectionReason,
    populateRejectionReason

}) {

    useInjectReducer({ key: 'multiTenantApproverDetails', reducer });
    approverComment = approverComment ? approverComment : approverDetailsProps && approverDetailsProps.approverComment;

    if (window && window.vlcredits.envData.Services.isRejectionReason.toLowerCase() == "true") {

        approverCallout = approverCallout ? approverCallout : approverDetailsProps && approverDetailsProps.approverCallout;
        rejectionReasonCode = rejectionReasonCode ? rejectionReasonCode : (approverDetailsProps && approverDetailsProps.rejectionReason && approverDetailsProps.rejectionReason.rejectReasonCode);
        if (approverDetailsProps && approverDetailsProps.rejectionReason && approverDetailsProps.rejectionReason.rejectReasonCode) {
            approverComment = (approverDetailsProps && approverDetailsProps.rejectionReason && approverDetailsProps.rejectionReason.rejectReason)
            if (rejectionReasonCode === "Others") {
                isOtherRejectionReason = true;
                rejectionReasonDescripiton = approverComment;
            }
        }

        var userRole = window && window.userRole;
        var isSubCFO = (userRoleType[userRole] === "SubsidiaryCFO");
        var rejectionReasonDropdownOptions = Helpers.getMTRejectionReasons();
    }
    if (window && window.vlcredits.envData.Services.isRejectionReason.toLowerCase() == "true") {
        return (
            <div className="row">
                <div className="col-lg-24">
                    <div className="form-group">
                        <div className={isSubCFO ? 'row hidden' : 'row'}>
                            <div className="col-xs-11 col-sm-12 col-md-12 col-lg-12">

                            <LocalisedLabel id="lblRejectionReasonsMT" htmlFor="ddlRejectionReasons" className="form-label" ariaLabel="Rejection Reasons" value="Rejection Reasons" />
                                <DropDownBox key="ddlRejectionReasonsNew" id="ddlRejectionReasons" disabled={isApprovalReadonly} ariaLabel="Rejection Reasons" inputList={rejectionReasonDropdownOptions} includeAll="false" value={rejectionReasonCode || ''} selectedValue={rejectionReasonCode || ''} onChange={onRejectionReasonChange} />
                                <div id="dvRejectionReasonsErrorMessage" className="alert alert-error" style={{ "display": "none" }} >
                                    <FormattedMessage {...messages.selectRejectionReason} />
                                </div>
                                <div id="dvSubmitErrorMessage" className="alert alert-error" style={{ "display": "none" }}><FormattedMessage {...messages.dvSubmitErrorMessage} /></div>
                            </div>
                            <div className="col-xs-11 col-sm-12 col-md-12 col-lg-12">
                                <div className={isOtherRejectionReason ? '' : 'hidden'}>
                                    <LocalisedLabel className="form-label"
                                        htmlFor="txtOtherRejectionReason" id="lblOtherRejectionReasons"
                                        ariaLabel="Other"
                                        value="Specify a Rejection Reason (minimum 50 characters required)." />
                                    <input type="text" className="form-control rounded-0" id="txtOtherRejectionReason" minLength="50" maxLength="2000"
                                        onChange={onAddOtherRejectionReason} value={rejectionReasonDescripiton || ''} readOnly={isApprovalReadonly} onBlur={populateRejectionReason} />
                                    <div id="dvOtherRejectionReasonsErrorMessage" className="alert alert-error" style={{ "display": "none" }} >
                                        <FormattedMessage {...messages.selectOtherRejectionReason} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row spacer-32-top"}>

                            <div className="col-xs-11 col-sm-12 col-md-12 col-lg-12">
                                <LocalisedLabel htmlFor="txtApproverComments"
                                    className="form-label" ariaLabel="Approver Comments"
                                    id="lblApproverComments"
                                    value="Approver Comment" />

                                <textarea className="form-control rounded-0" maxLength={COMMENTS_LENGTH}
                                    id="txtApproverComments" rows="5" required value={approverComment || ""}
                                    onChange={onApproverComments} readOnly={isApprovalReadonly} />

                                <div id="dvtxtApproverCommentsErrorMessage" className="alert alert-error" style={{ "display": "none" }} >
                                    <FormattedMessage {...messages.selectTxtApproverComments} />
                                </div>
                            </div>
                            <div className={'hidden'}>
                                <div className="col-xs-11 col-sm-12 col-md-12 col-lg-12">
                                    <LocalisedLabel className="form-label"
                                        htmlFor="txtCalloutforApprovers" id="lblCalloutforApprovers"
                                        ariaLabel="Callout for Approvers"
                                        value="Approver Callout" />
                                    <textarea className="form-control rounded-0"
                                        id="txtCalloutforApprovers" rows="5" maxLength="999"
                                        onChange={onApproverCallout} value={approverCallout} readOnly={isApprovalReadonly} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        );
    } else {

        return (
            <div className={isApprovalReadonly === true ? "hidden" : "row"}>
                <div className="col-lg-24">
                    <div className="form-group">
                        <div className="row spacer-32-top">
                            <div className="col-xs-11 col-sm-12 col-md-12 col-lg-24">
                                <LocalisedLabel
                                    htmlFor="txtApproverComments"
                                    className="form-label"
                                    ariaLabel="Reviewer Comments"
                                    id="lblFbcReviewerComments"
                                    value="Reviewer Comments"
                                />

                                <textarea
                                    className="form-control rounded-0"
                                    maxLength={COMMENTS_LENGTH}
                                    id="txtApproverComments"
                                    rows="5"
                                    value={approverComment || ""}
                                    onChange={onApproverComments}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

MultiTenantApproverDetails.propTypes = {
    isOtherRejectionReason: PropTypes.bool,
    onAddOtherRejectionReason: PropTypes.func,
    onApproverCallout: PropTypes.func,
    onApproverComments: PropTypes.func,
    onRejectionReasonChange: PropTypes.func,
    populateRejectionReason: PropTypes.func,
    rejectionReasonCode: PropTypes.string,
    rejectionReasonDescripiton: PropTypes.string,
    setOtherRejectionReason: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
    approverComment: makeSelectMTApproverComment(),
    approverCallout: makeSelectMTApproverCallout(),
    rejectionReasonCode: makeSelectMTRejectionReasonCode(),
    rejectionReasonDescripiton: makeSelectMTRejectionReasonDescripiton(),
    isOtherRejectionReason: makeSelectIsMTOtherRejectionReason()
});

function mapDispatchToProps(dispatch) {
    return {

        onApproverComments: evt => dispatch(changeApproverComments(evt.target.value)),
        onApproverCallout: evt => dispatch(changeApproverCallout(evt.target.value)),
        onRejectionReasonChange: evt => {
            var _dvErrorMessage = document.getElementById("dvRejectionReasonsErrorMessage");
            var _dvSubmitErrorMessage = document.getElementById("dvSubmitErrorMessage");
            if (_dvErrorMessage) {
                _dvErrorMessage.style.display = "none";
            }
            if (_dvSubmitErrorMessage) {
                _dvSubmitErrorMessage.style.display = "none";
            }
            dispatch(changeRejectionReason(evt.target.value));
            if (evt.target.value === "Others") {
                dispatch(setOtherRejectionReason(true));
                dispatch(changeApproverComments(""));
            } else {
                dispatch(setOtherRejectionReason(false));
                dispatch(addOtherRejectionReason(""));
                var _reason = Helpers.getMTRejectionReasonByCode(evt.target.value);
                dispatch(changeApproverComments(_reason && _reason.emailContent ? _reason.emailContent : ""));
            }
        },

        onAddOtherRejectionReason: evt => {
            if (evt.target.value.trim() !== "") {
                var _dvOtherErrorMessage = document.getElementById("dvOtherRejectionReasonsErrorMessage");
                var _txtOtherRejectionReason = document.getElementById("txtOtherRejectionReason");
                if (_dvOtherErrorMessage) {
                    _dvOtherErrorMessage.style.display = "none";
                }
                if (evt.target.value.trim().length >= 50) {
                    _txtOtherRejectionReason.style.backgroundColor = "";
                    _txtOtherRejectionReason.focus();
                }
            }
            dispatch(addOtherRejectionReason(evt.target.value));
        },
        populateRejectionReason: evt => dispatch(changeApproverComments(evt.target.value))
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
);

export default compose(withConnect)(injectIntl(MultiTenantApproverDetails));




