import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import reducer from './reducer';
import messages from './messages';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import SubmitInfo from '../../components/submitInfo';
import './Submit.css';
import ReturnRequest from '../returnRequest/Loadable';
import SubmitErrors from '../../components/SubmitErrors';
import Modal from '../../../../common/components/modal/modal';
import MultiModal from '../../../../common/components/MultiModal/MultiModal';
import LoadingIndicator from '../../../../common/components/LoadingIndicator';
import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import { makeSelectInvoiceNumberCreate } from '../../../common/components/createRequest/selectors';
import { makeSelectInvoiceNumberCreate as makeSelectMultiInvoiceNumberCreate } from '../../../common/components/createMultiInvoiceRequest/selectors';
import { submitReturnForm, closeRebillConfirmation, closeRestockFeeConfirmation } from '../returnForm/actions';
import { closeMultiInvoiceRestockFeeConfirmation, submitMultiInvoiceReturnForm, closeMultiInvoiceShowRebillConfirmation } from '../multiInvoiceReturnForm/actions';
import { cancelAPIInvoke, closeCancelConfirmation } from '../../../common/components/cancelrequest/actions';
import { closePOConfirmationModal } from '../../../operations/components/rebillPOTable/actions';
import { makeSelectShowCancelConfirmation, makeSelectCancelSuccess } from '../../../common/components/cancelrequest/selectors';
import MultiInvoiceReturnRequest from "../multiInvoiceReturnRequest/Loadable";
import { closerocvaluechangeconfimation, updaterocvaluechangeconfimation } from '../../../operations/components/returnDetails/actions';
import {
    makeSelectReturnFormSuccess,
    makeSelectReturnFormLoading,
    makeSelectShowRebillConfirmation,
    makeSelectReturnFormEReceiptStatus,
    makeSelectReturnFormSaveSuccessResponse,
    makeSelectReturnFormDeleteSuccessResponse,
    makeSelectRestockFeeConfirmation
} from '../returnForm/selectors';
import {
    makeSelectMultiInvoiceReturnFormSuccess,
    makeSelectMultiInvoiceReturnFormLoading,
    makeSelectMultiInvoiceShowRebillConfirmation,
    makeSelectMultiInvoiceReturnFormEReceiptStatus,
    makeSelectMultiInvoiceReturnFormSaveSuccessResponse,
    makeSelectMultiInvoiceReturnFormDeleteSuccessResponse,
    makeSelectMultiInvoiceRestockFeeConfirmation
} from '../multiInvoiceReturnForm/selectors';
import { makeSelectrocValueChanged } from '../../../operations/components/returnDetails/selectors';

import {
    makeSelectShowPOConfirmation
}
from '../../../operations/components/rebillPOTable/selectors';

const navigateAfterSubmit = () => {
    var uri = window.location.href.toString();
    const baseUri = uri.indexOf("?") > 0 ?
        uri.substring(0, uri.indexOf("?")) : uri;
    window.location.href = baseUri + '?submitSuccess=true';
};

function SubmitHome({
    multiInvoiceSubmitSuccess,
    invoiceNumber,
    multiInvoiceNumber,
    submitSuccess,
    saveDraftSuccess,
    multiInvoiceSaveDraftSuccess,
    deleteDraftSuccess,
    multiInvoiceDeleteDraftSuccess,
    cancelSuccess,
    loading,
    multiInvoiceLoading,
    submit,
    submitMultiInvoice,
    closeDialog,
    cancel,
    closeCancelDialog,
    eReceiptStatus,
    multiInvoiceeReceiptStatus,
    showRebillConfirmation,
    multiInvoiceShowRebillConfirmation,
    showCancelConfirmation,
    isMultiInvoice,
    rocOverriddenConfirmation,
    changeROCValue,
    closeROCConfirmation,
    showPOConfirmation,
    closePOConfirmation,
    restockFeeConfirmation,
    multiInvoiceRestockFeeConfirmation
}) {
    useInjectReducer({ key: 'submitHome', reducer });

    const submitSuccessMsg
        = (eReceiptStatus
            ? messages.eReceiptSuccessMessage
            : messages.submitSuccess) || (multiInvoiceeReceiptStatus
                ? messages.eReceiptSuccessMessage
            : messages.submitSuccess);

    var submitMessageConfirmation = [];

    ( showRebillConfirmation || multiInvoiceShowRebillConfirmation ) && submitMessageConfirmation.push(messages.rebillConfirmation);
    ( restockFeeConfirmation || multiInvoiceRestockFeeConfirmation ) && submitMessageConfirmation.push(messages.restockFeeConfirmation);

    return (
        <div id="submitHome" className="spacer-32-top">
            <div className="row">
                <div className="col-md-24 spacer-32-top" >
                    <SubmitErrors />
                    <SubmitInfo />
                    <Modal id="submitConfirmationModal"
                        isOpen={(submitSuccess && submitSuccess.success) || (multiInvoiceSubmitSuccess && multiInvoiceSubmitSuccess.success)}
                        headerMessage={submitSuccessMsg}
                        closeMessage={messages.closeAction}
                        close={navigateAfterSubmit}
                    />

                    <Modal id="deleteDraftConfirmationModal"
                        isOpen={(deleteDraftSuccess && deleteDraftSuccess.success) || (multiInvoiceDeleteDraftSuccess && multiInvoiceDeleteDraftSuccess.success)}
                        headerMessage={messages.deleteDraftSuccess}
                        closeMessage={messages.closeAction}
                        close={() => { window.location.href = `/` }}
                    />
                    <Modal id="saveConfirmationModal"
                        isOpen={saveDraftSuccess && saveDraftSuccess.success}
                        headerMessage={messages.saveDraftSuccess}
                        closeMessage={messages.closeAction}
                        close={() => { window.location.href += `?invoice=${invoiceNumber}&status=DFT` }}
                    />

                    <Modal id="multiInvoiceSaveConfirmationModal"
                        isOpen={multiInvoiceSaveDraftSuccess && multiInvoiceSaveDraftSuccess.success}
                        headerMessage={messages.saveDraftSuccess}
                        closeMessage={messages.closeAction}
                        close={() => { window.location.href += `?multiInvoiceNumber=${multiInvoiceNumber}&status=DFT` }}
                    />

                    <Modal id="rebillStatusConfirmationModal"
                        isOpen={showPOConfirmation}
                        headerMessage={messages.rebillStatusConfirmation}
                        closeMessage={messages.continueAction}
                        close={closePOConfirmation}
                    
                    />

                    <Modal id="cancelConfirmationModal"
                        isOpen={showCancelConfirmation}
                        headerMessage={messages.cancelConfirmation}
                        actionMessage={messages.continueAction}
                        action={cancel}
                        closeMessage={messages.goBackAction}
                        close={closeCancelDialog}
                    />

                    <Modal id="cancelSuccessModal"
                        isOpen={(cancelSuccess && cancelSuccess.success)}
                        headerMessage={messages.cancelSuccess}
                        closeMessage={messages.closeAction}
                        close={() => { window.location.href = `/` }}
                    />

                    <Modal id="rocOverriddenModal"
                        isOpen={rocOverriddenConfirmation}
                        headerMessage={messages.rocOverridenMessage}
                        actionMessage={messages.continueAction}
                        action={changeROCValue}
                        closeMessage={messages.goBackAction}
                        close={closeROCConfirmation}
                    />

                    <MultiModal id="submitConfirmationModal"
                        isOpen={restockFeeConfirmation || showRebillConfirmation }
                        headerMessage={messages.submitConfirmation}
                        messageArray={submitMessageConfirmation}
                        actionMessage={messages.continueAction}
                        action={submit}
                        closeMessage={messages.goBackAction}
                        close={closeDialog}
                    />

                    <MultiModal id="submitMultiInvoiceConfirmationModal"
                        isOpen={multiInvoiceRestockFeeConfirmation || multiInvoiceShowRebillConfirmation }
                        headerMessage={messages.submitConfirmation}
                        messageArray={submitMessageConfirmation}
                        actionMessage={messages.continueAction}
                        action={submitMultiInvoice}
                        closeMessage={messages.goBackAction}
                        close={closeDialog}
                    />      


                </div>
            </div>
            <div className="row">
                <div className="col-md-24 spacer-32-top" >
                    <h1 className="section-title">
                        {!isMultiInvoice ? (
                            <FormattedMessage {...messages.createCreditRequest} />
                        ) : (
                                <FormattedMessage {...messages.createMultiInvoiceCreditCreditRequest} />
                            )}
                    </h1>
                    <div className="col-sm-8 col-md-8 col-lg-5">
                        <div className={!(loading || multiInvoiceLoading) ? 'hidden' : ''}>
                            <LoadingIndicator />
                        </div>
                    </div>
                </div>
            </div>
            {!isMultiInvoice ? <ReturnRequest /> : <MultiInvoiceReturnRequest />}
        </div>
    );
}

SubmitHome.propTypes = {
    submitSuccess: PropTypes.bool,
    multiInvoiceSubmitSuccess: PropTypes.bool,
    saveDraftSuccess: PropTypes.bool,
    deleteDraftSuccess: PropTypes.bool,
    cancelSuccess: PropTypes.bool,
    submit: PropTypes.func,
    submitMultiInvoice: PropTypes.func,
    closeDialog: PropTypes.func,
    showRebillConfirmation: PropTypes.bool,
    multiInvoiceShowRebillConfirmation: PropTypes.bool,
    showCancelConfirmation: PropTypes.bool,
    loading: PropTypes.bool,
    eReceiptStatus: PropTypes.array,
    invoiceNumber: PropTypes.string,
    multiInvoiceNumber: PropTypes.string,
    rocOverriddenConfirmation: PropTypes.bool,
    changeROCValue: PropTypes.func,
    closeROCConfirmation: PropTypes.func,
    restockFeeConfirmation: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
    submitSuccess: makeSelectReturnFormSuccess(),
    multiInvoiceSubmitSuccess: makeSelectMultiInvoiceReturnFormSuccess(),
    saveDraftSuccess: makeSelectReturnFormSaveSuccessResponse(),
    multiInvoiceSaveDraftSuccess: makeSelectMultiInvoiceReturnFormSaveSuccessResponse(),
    deleteDraftSuccess: makeSelectReturnFormDeleteSuccessResponse(),
    multiInvoiceDeleteDraftSuccess: makeSelectMultiInvoiceReturnFormDeleteSuccessResponse(),
    cancelSuccess: makeSelectCancelSuccess(),
    showRebillConfirmation: makeSelectShowRebillConfirmation(),
    multiInvoiceShowRebillConfirmation: makeSelectMultiInvoiceShowRebillConfirmation(),
    showCancelConfirmation: makeSelectShowCancelConfirmation(),
    invoiceNumber: makeSelectInvoiceNumberCreate(),
    multiInvoiceNumber: makeSelectMultiInvoiceNumberCreate(),
    loading: makeSelectReturnFormLoading(),
    multiInvoiceLoading: makeSelectMultiInvoiceReturnFormLoading(),
    eReceiptStatus: makeSelectReturnFormEReceiptStatus(),
    multiInvoiceeReceiptStatus: makeSelectMultiInvoiceReturnFormEReceiptStatus(),
    rocOverriddenConfirmation: makeSelectrocValueChanged(),
    showPOConfirmation: makeSelectShowPOConfirmation(), 
    restockFeeConfirmation: makeSelectRestockFeeConfirmation(),
    multiInvoiceRestockFeeConfirmation: makeSelectMultiInvoiceRestockFeeConfirmation()
});

function mapDispatchToProps(dispatch) {
    return {
        submitMultiInvoice: () => dispatch(submitMultiInvoiceReturnForm()),
        submit: () => dispatch(submitReturnForm()),
        cancel: () => dispatch(cancelAPIInvoke()),
        closeDialog: evt => {
            dispatch(closeRebillConfirmation());
            dispatch(closeRestockFeeConfirmation());
            dispatch(closeMultiInvoiceRestockFeeConfirmation());
            dispatch(closeMultiInvoiceShowRebillConfirmation());
        },
        closeCancelDialog: evt => {
            dispatch(closeCancelConfirmation());
        },

        closeROCConfirmation: evt => {
            dispatch(closerocvaluechangeconfimation());
            focusRocOverrideDropdown();
        },
        changeROCValue: () => {
            dispatch(updaterocvaluechangeconfimation());
            focusRocOverrideDropdown();
        },
        closePOConfirmation: evt => {
            dispatch(closePOConfirmationModal());
        }
    }
}
function focusRocOverrideDropdown() {
    if (document.getElementById('rocOveride'))
        document.getElementById('rocOveride').focus();
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(SubmitHome);
