export const CHANGE_ENROLLMENT_NUMBER =
  "createMultiInvoiceRequest/CHANGE_ENROLLMENT_NUMBER";
export const CHANGE_SYSTEM_PO_NUMBER =
  "createMultiInvoiceRequest/CHANGE_SYSTEM_PO_NUMBER";
export const SEARCH_MULTI_INVOICE = "createMultiInvoiceRequest/SEARCH_MULTI_INVOICE";
export const CLEAR_ENROLLMENT_NUMBER = "createMultiInvoiceRequest/CLEAR_ENROLLMENT_NUMBER";
export const CLEAR_SYSTEM_PO_NUMBER = "createMultiInvoiceRequest/CLEAR_SYSTEM_PO_NUMBER";
export const SEARCH_MULTI_INVOICE_SUCCESS =
  "createMultiInvoiceRequest/SEARCH_MULTI_INVOICE_SUCCESS";
export const SEARCH_MULTI_INVOICE_ERROR =
  "createMultiInvoiceRequest/SEARCH_MULTI_INVOICE_ERROR";
export const SEARCH_INVOICE = "createMultiInvoiceRequest/SEARCH_INVOICE";
export const SEARCH_INVOICE_SUCCESS =
  "SEARCH_INVOICE_SUCCESS";
export const CHANGE_COSMIC_CASENUMBER = "createMultiInvoiceRequest/CHANGE_COSMIC_CASENUMBER";
export const CHANGE_BILLING_DOCUMENT_NUMBER = "createMultiInvoiceRequest/CHANGE_BILLING_DOCUMENT_NUMBER";