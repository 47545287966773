import produce from 'immer';
import {
    FILTER_CANCELLATION_REQUESTS,
    FILTER_CANCELLATION_ERROR,
    FILTER_CANCELLATION_SUCCESS,
    CHANGE_REQUEST_IDENTIFIER,
    CHANGE_REQUEST_STATUS,
    CHANGE_PO_NUMBER,
    CHANGE_SYSTEM_PO_NUMBER,
    CHANGE_ENROLLMENT_NUMBER,
    CHANGE_COSMIC_NUMBER,
    CHANGE_CUSTOMER_NAME,
    CHANGE_DATE_RANGE
} from './constants';

export const initialState = {};

const FilterCancellationRequestListReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FILTER_CANCELLATION_REQUESTS:
                draft.error = false;
                draft.loading = true;
                draft.isSubCFORequest = action.isSubCFORequest;
                break;

            case FILTER_CANCELLATION_SUCCESS:
                draft.cancellationListResponse = action.response;
                draft.loading = false;
                break;

            case FILTER_CANCELLATION_ERROR:
                draft.error = action.error;
                draft.loading = false;
                break;

            case CHANGE_REQUEST_IDENTIFIER:
                draft.requestIdentifier = action.requestIdentifier;
                draft.error = false;
                break;

            case CHANGE_REQUEST_STATUS:
                draft.requestStatus = action.requestStatus;
                draft.error = false;
                break;

            case CHANGE_PO_NUMBER:
                draft.poNumber = action.poNumber;
                draft.error = false;
                break;

            case CHANGE_SYSTEM_PO_NUMBER:
                draft.systemPONumber = action.systemPONumber;
                draft.error = false;
                break;

            case CHANGE_ENROLLMENT_NUMBER:
                draft.enrollmentNumber = action.enrollmentNumber;
                draft.error = false;
                break;

            case CHANGE_COSMIC_NUMBER:
                draft.cosmicNumber = action.cosmicNumber;
                draft.error = false;
                break;

            case CHANGE_CUSTOMER_NAME:
                draft.endCustomerName = action.endCustomerName;
                draft.error = false;
                break;

            case CHANGE_DATE_RANGE:
                draft.dateRange = action.dateRange;
                draft.error = false;
                break;
        }
    });

    export default FilterCancellationRequestListReducer;
