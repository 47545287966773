import produce from "immer";
import { FBC_SEARCH_WITH_POAA_SUCCESS, FBC_SEARCH_WITH_POAA } from "../../../common/components/cancelFutureBillingCreateRequest/constants";
// Need to Change
import { FBC_GET_RETURN_SUCCESS } from "../../../common/components/cancelFutureBillingFetchRequest/constants";
import {
    FBC_SUBMIT_RETURN,
    FBC_SUBMIT_RETURN_SUCCESS,
    FBC_SUBMIT_RETURN_FAIL,
    FBC_SHOW_REBILL_CONFIRMATION,
    FBC_CLOSE_REBILL_CONFIRMATION,
    FBC_TOGGLE_ACTIONS,
    FBC_SHOW_LINEITEM_CONFIRMATION,
    FBC_CLOSE_LINEITEM_CONFIRMATION
} from "./constants";

export const initialState = { isActionsOpen: false };

const mulltiInvoiceReturnFormReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case FBC_SEARCH_WITH_POAA_SUCCESS:
            case FBC_GET_RETURN_SUCCESS:
                draft.requestStatusCode = action.invoices.requestStatusCode;
                break;
            case FBC_SEARCH_WITH_POAA:
                draft.error = false;
                break;
            
            case FBC_SUBMIT_RETURN:
                draft.showLineItemConfirmation = false;
                draft.showRebillConfirmation = false;
                draft.restockFeeConfirmation = false;
                draft.loading = true;
                draft.deleteError = false;
                draft.error = false;
                draft.saveError = false;
                draft.successResponse = false;
                break;
            case FBC_SUBMIT_RETURN_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.successResponse = action.response;
                break;
            case FBC_SUBMIT_RETURN_FAIL:
                console.log(action.error);
                draft.error = action.error;
                draft.loading = false;
                draft.successResponse = false;
                break;
            case FBC_TOGGLE_ACTIONS:
                draft.isActionsOpen = !draft.isActionsOpen;
                break;
            case FBC_SHOW_REBILL_CONFIRMATION:
                draft.showRebillConfirmation = true;
                break;
            case FBC_CLOSE_REBILL_CONFIRMATION:
                draft.showRebillConfirmation = false;
                break;
                case FBC_SHOW_LINEITEM_CONFIRMATION:
                    draft.showLineItemConfirmation = true;
                    break;
                case FBC_CLOSE_LINEITEM_CONFIRMATION:
                    draft.showLineItemConfirmation = false;
                    break;

        }
    });

export default mulltiInvoiceReturnFormReducer;
