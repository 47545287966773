import React from 'react'
import PropTypes from 'prop-types'
import wrapperLabel from '../WrapperLabel/wrapperLabel';
import './input.css'
const Input = ({
    disabled,
    id,
    name,
    placeholder,
    type,
    onAnimationStart,
    onFocus,
    onBlur,
    onChange,
    value,
    className,
    editable,
    required,
    ...rest
}) => (
        <input
            {...rest}
            id={id || name}
            name={name}
            className={className}
            type={type}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disabled}
            readOnly={!editable}
            required={required}
            aria-labelledby={id + "Label"}
            aria-readonly={!editable}
            aria-required={required}
            tabIndex={editable ? 0 : -1}
            placeholder={placeholder}
        />
    )

Input.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    label: PropTypes.object,
    placeholder: PropTypes.string,
    type: PropTypes.oneOf([
        'text',
        'color',
        'email',
        'hidden',
        'image',
        'number',
        'password',
        'range',
        'search',
        'tel',
        'url',
    ]),
    disabled: PropTypes.bool,
    hint: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
}

Input.defaultProps = {
    className: 'form-control',
    disabled: false,
    id: null,
    label: null,
    type: 'text',
    hint: null,
    required: false,
    editable: true
}

export default wrapperLabel('input')(Input)