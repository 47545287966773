import { MaxFileSizeForUploadInBytes, MaxFileSizeForUploadInMB, FileUploadTranslationsMap,MaxFileUploadCount } from './constants'

export default class FileValidator {
    Errors = [];

    GetFileExtension = (filePath) => {
        // pop the last array element which will be the file extension.
        return filePath.split('.').pop();
    }

    IsAValidExtension = (file, acceptedFiles) => {
        const fileExtension = this.GetFileExtension(file.name);
        var isAValidExtension = acceptedFiles.includes(fileExtension.toLowerCase());

        if (isAValidExtension === false) {
            this.Errors.push('[' + fileExtension + '] ' + FileUploadTranslationsMap.get('invalidFileExtension'));
        }

        return isAValidExtension;
    }

    IsAValidFileName = (file) => {
        var filename = file.name.replace("."+this.GetFileExtension(file.name));
        var isAValidFileName = true;
        if (!filename.match('^[^*&%<>{}.|~]+$'))
        {
            isAValidFileName = false;
            this.Errors.push(FileUploadTranslationsMap.get('invalidFileName'));
        }

        return isAValidFileName;

    }

    IsOfAValidSize = (file) => {
        let FileSize = MaxFileSizeForUploadInBytes;
        var isOfAValidSize = file.size < FileSize;

        if (isOfAValidSize === false) {
            this.Errors.push(FileUploadTranslationsMap.get('fileUploadSizeExceeded') + MaxFileSizeForUploadInMB + " MB.");
        }

        return isOfAValidSize;
    }

    IsMaxFileCountReached = (files) => {
        let IsMaxFileCountReached = !(files && files.length >= MaxFileUploadCount);

        if (IsMaxFileCountReached === false) {
                this.Errors.push(FileUploadTranslationsMap.get('fileUploadMaxFileCountExceeded') + MaxFileUploadCount + ".");
        }

        return IsMaxFileCountReached;
    }

    IsAValidFile = (evt, props) => {
        var isAValidFile = false;

        let files = evt.target.files,
            value = evt.target.value;

        if (files.length > 0) {
            var file = files[0];

            isAValidFile = this.IsAValidExtension(file, props.acceptedFiles) &&
                this.IsOfAValidSize(file) && this.IsMaxFileCountReached(props.attachment) && this.IsAValidFileName(file);
        }
        else {
            this.Errors.push(FileUploadTranslationsMap.get('fileNotSelectedError'));
        }

        return isAValidFile;
    }

    
}