import produce from 'immer';
import {
    DOCUMENT_SELECTED,
    FILE_SELECTED,
    FILE_VALIDATION_ERRORS,
    FILE_READ_ERROR,
    FILE_SIGNATURE_ERROR,
    FILE_UPLOAD_ERROR,
    FILE_READ,
    UPLOAD_FILE,
    FILE_UPLOAD_SUCCESS,
    FILE_UPLOAD_RESET,
    FileUploadTranslationsMap,
    FILE_REMOVED,
    MODAL_OPEN,
    MODAL_CLOSED,
    DOWNLOAD_FILE,

    FILE_REMOVED_SUCCESS,
    FILE_REMOVED_ERROR,
    DOWNLOAF_FILE_SUCCESS,
    DOWNLOAF_FILE_ERROR
} from './constants';
import { SEARCH_INVOICE_SUCCESS } from '../createRequest/constants';
import { SEARCH_MULTI_INVOICE_SUCCESS } from '../createMultiInvoiceRequest/constants';
import { GET_RETURN_SUCCESS } from '../fetchReturnRequest/constants';
import { GET_MULTI_INVOICE_RETURN_SUCCESS } from '../fetchMultiInvoiceReturnRequest/constants';
import { MT_GET_RETURN_SUCCESS } from "../multiTenantFetchRequest/constants";
export const initialState = {};

const ResetErrors = (draft) => {
    draft.fileSelectionError = '';
    draft.fileInfo = null;
    draft.fileUploadSuccessOrError = '';
    draft.document = '';
    draft.isError = false;
}

function arrayRemove(arr, value) {

    return arr.filter(function (ele) {
        return ele.identifier != value;
    });

}

const FileUploadReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case SEARCH_MULTI_INVOICE_SUCCESS:
            case GET_MULTI_INVOICE_RETURN_SUCCESS:
                {
                    console.log("GET_MULTI_INVOICE_RETURN_SUCCESS");
                    draft.uploadedFiles = action.invoices.attachment;
                    draft.isReadOnly = (action.invoices.isReadOnly || action.invoices.isUserReadOnly) ? true : false;
                    break;
                }

            case SEARCH_INVOICE_SUCCESS:
            case GET_RETURN_SUCCESS:
                {
                    console.log("GET_RETURN_SUCCESS");
                    draft.uploadedFiles = action.invoice.attachment;
                    draft.isReadOnly = (action.invoice.isReadOnly || action.invoice.isUserReadOnly) ? true : false;
                    break;
                }

            case MT_GET_RETURN_SUCCESS:
                {
                    draft.isReadOnly = action.requestData.isReadOnly;
                    draft.uploadedFiles = action.requestData.attachment;
                    break;
                }

            case DOCUMENT_SELECTED:
                {
                    console.log('FileUploadReducer -> DOCUMENT_SELECTED');
                    draft.loading = false;
                    console.log(action.document);
                    draft.document = action.document;
                    break;
                }
            case FILE_SELECTED:
                {
                    console.log('FileUploadReducer -> FILE_SELECTED');
                    draft.loading = true;
                    draft.disableUI = true;
                    draft.fileSelectionError = "";
                    draft.fileUploadSuccessOrError = "";
                    break;
                }
            case FILE_VALIDATION_ERRORS:
                {
                    console.log('FileUploadReducer -> FILE_VALIDATION_ERRORS');
                    draft.loading = false;
                    draft.disableUI = false;

                    //console.log(action.errors);
                    if (Array.isArray(action.errors)) {
                        draft.fileSelectionError = action.errors.join('<br />');
                    }
                    break;
                }
            case FILE_READ_ERROR:
                {
                    console.log('FileUploadReducer -> FILE_READ_ERROR');
                    draft.loading = false;
                    draft.disableUI = false;

                    console.log(action.error);
                    draft.fileSelectionError = action.error;
                    break;
                }
            case FILE_SIGNATURE_ERROR:
                {
                    console.log('FileUploadReducer -> FILE_SIGNATURE_ERROR');
                    draft.loading = false;
                    draft.disableUI = false;

                    console.log(action.error);
                    draft.fileSelectionError = action.error;
                    break;
                }
            case FILE_READ:
                {
                    console.log('FileUploadReducer -> FILE_READ');
                    draft.loading = false;
                    draft.disableUI = false;

                    console.log(action.fileInfo);
                    draft.fileInfo = action.fileInfo;
                    break;
                }
            case UPLOAD_FILE:
                {
                    console.log('FileUploadReducer -> UPLOAD_FILE');
                    draft.loading = true;
                    draft.disableUI = true;
                    console.log(draft.fileInfo);
                    break;
                }
            case FILE_UPLOAD_ERROR:
                {
                    console.log('FileUploadReducer -> FILE_UPLOAD_ERROR');
                    draft.loading = false;
                    console.log(action.error);
                    draft.fileUploadSuccessOrError = action.error;
                    draft.disableUI = false;
                    draft.isError = true;
                    break;
                }
            case FILE_UPLOAD_SUCCESS:
                {
                    console.log('FileUploadReducer -> FILE_UPLOAD_SUCCESS');

                    if (!draft.uploadedFiles) {
                        draft.uploadedFiles = new Array();
                    }

                    var uploadedFile = {
                        "name": action.success.fileUploadResponse[0].fileName,
                        "identifier": action.success.fileUploadResponse[0].fileId,
                        "type": draft.document
                    };

                    draft.uploadedFiles.push(uploadedFile);

                    draft.fileUploadSuccessOrError = FileUploadTranslationsMap.get('fileUploadSuccess');
                    draft.disableUI = false;
                    draft.loading = false;
                    draft.isError = false;
                    draft.fileInfo = null;
                    break;
                }
            case FILE_UPLOAD_RESET:
                {
                    console.log('FileUploadReducer -> FILE_UPLOAD_RESET');
                    draft.loading = false;
                    draft.disableUI = false;
                    ResetErrors(draft);
                    break;
                }
            case FILE_REMOVED:
                {
                    console.log('FileUploadReducer -> FILE_REMOVED');
                    draft.loading = false;
                    draft.disableUI = false;
                    break;
                }
            case MODAL_OPEN:
                {
                    console.log('FileUploadReducer -> MODAL_OPEN');
                    console.log(action.IsModalOpen);
                    draft.IsModalOpen = true;
                    break;
                }
            case MODAL_CLOSED:
                {
                    console.log('FileUploadReducer -> MODAL_CLOSED');
                    console.log(action.IsModalOpen);
                    ResetErrors(draft);
                    draft.IsModalOpen = false;
                    break;
                }
            case DOWNLOAD_FILE:
                {
                    console.log('FileUploadReducer -> DOWNLOAD_FILE');
                    draft.loading = false;
                    draft.disableUI = false;
                    draft.Isdownloading = true;
                    //console.log(draft.identifier);
                    break;
                }
            case DOWNLOAF_FILE_SUCCESS:
                {
                    console.log('FileUploadReducer -> DOWNLOAF_FILE_SUCCESS');
                    draft.loading = false;
                    draft.disableUI = false;
                    draft.Isdownloading = false;
                    draft.isDownloadError = false;
                    //console.log(draft.identifier);
                    break;
                }
            case DOWNLOAF_FILE_ERROR:
                {
                    console.log('FileUploadReducer -> DOWNLOAF_FILE_ERROR');
                    draft.loading = false;
                    draft.disableUI = false;
                    draft.Isdownloading = false;
                    draft.isDownloadError = true;
                    //console.log(draft.identifier);
                    break;
                }
            case FILE_REMOVED_SUCCESS:
                {
                    console.log('FileUploadReducer -> FILE_REMOVED_SUCCESS');
                    draft.loading = false;
                    draft.disableUI = false;
                    draft.uploadedFiles = arrayRemove(draft.uploadedFiles, action.fileIdentifier);
                }
            case FILE_REMOVED_ERROR:
                {
                    console.log('FileUploadReducer -> FILE_REMOVED_ERROR');
                    draft.loading = false;
                    draft.disableUI = false;
                    //draft.uploadedFiles = arrayRemove(draft.uploadedFiles, action.fileIdentifier);
                }
            default:
                {
                    draft.loading = false;
                    draft.disableUI = false;
                    //ResetErrors(draft);
                    break;
                }
        }
    });

export default FileUploadReducer;