import { defineMessages } from 'react-intl';

export const scope = 'submitHome';

export default defineMessages({
    createRequestSubCFOApproval: {
        id: `${scope}.createRequestSubCFOApproval`,
        defaultMessage: 'Sub CFO Approval',
    },
});
