//#region import section

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";

import FbcApprovalRequest from "../request/index";
import makeSelectCancelFutureBillingSearchHome from "./selectors";
import messages from "./messages";
import reducer from "./reducer";
import saga from "./saga";
import { useInjectSaga } from "../../../../common/internals/utils/injectSaga";
import { useInjectReducer } from "../../../../common/internals/utils/injectReducer";
import FbcApprovalErrors from "../approvalErrors";
import FBCApprovalInfo from "../../../common/fbcApprovalInfo/index";
//#endregion

//#region functional component
const CancelFutureBillingSearchHome = (props) => {
  sessionStorage.removeItem("VLReturnsRedirectUri");
  useInjectReducer({ key: "CancelFutureBillingSearchHome", reducer });
  useInjectSaga({ key: "CancelFutureBillingSearchHome", saga });
  var searchParams = new URLSearchParams(props.location.search);
  window.vlcredits.FeatureName = "FBC";
  console.log(window.vlcredits.FeatureName);

  return (
    <div>
      <div className="row">
        <div className="col-md-24">
          <FBCApprovalInfo />
          <FbcApprovalErrors />
        </div>
      </div>
      <div className="row">
        <div className="col-md-24 spacer-18-top">
          <h1 className="section-title">
            {window.userRole === "VPU" ? (
              <FormattedMessage {...messages.fbcPartnerRequestTitle} />
            ) : (
              <FormattedMessage {...messages.fbcOpsRequestTitle} />
            )}
          </h1>
        </div>
      </div>
      <FbcApprovalRequest props={props} />
    </div>
  );
};

//#endregion

//#region property type check
CancelFutureBillingSearchHome.propTypes = {
  dispatch: PropTypes.func.isRequired,
};
//#endregion

//#region mapState, dispatch

const mapStateToProps = createStructuredSelector({
  cancelFutureBillingSearchHome: makeSelectCancelFutureBillingSearchHome(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

//#endregion

//#region  Connect to store

const withConnect = connect(mapStateToProps, mapDispatchToProps);

//#endregion

export default compose(withConnect)(injectIntl(CancelFutureBillingSearchHome));
