import { takeLatest, select, put, all, call } from "redux-saga/effects";
import { INIT_APPINSIGHTS } from "./constants";
import { makeSelectUserInfo } from "../../app/selectors";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
} from "@microsoft/applicationinsights-react-js";
import { setAppInsights } from "./actions";
import history from "../../internals/utils/history";

export function* appinsightsSaga(action) {
  try {
    const userInfo = yield select(makeSelectUserInfo());
    let appinsights_InstrumentationKey = action.instrumentation_key;
    const reactPlugin = new ReactPlugin();
    const ai = new ApplicationInsights({
      config: {
        instrumentationKey: appinsights_InstrumentationKey,
        extensions: [reactPlugin],
        //Use enableAutoRouteTracking: true only if you are not using the React plugin.
        //Both are capable of sending new PageViews when the route changes.
        //If both are enabled, duplicate PageViews may be sent.
        // enableAutoRouteTracking: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensionConfig: {
          [reactPlugin.identifier]: { history: history },
        },
      },
    });

    ai.loadAppInsights();
    let appInsights = ai.appInsights;
    
    yield put(setAppInsights(appInsights));
  } catch (error) {
    console.log("Appinsight initialization: " + error);
  }
}

export default function* initAppSaga() {
  yield takeLatest(INIT_APPINSIGHTS, appinsightsSaga);
}
