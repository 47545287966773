import { createSelector } from "reselect";
import { initialState } from "./reducer";
import * as Helpers from '../../../../common/internals/utils/helpers';
const selectRoot = state => state;
const selectMultiTenantRequestDomain = state => state.multiTenantRequest || initialState;
const makeSelectMultiTenantRequest = () => createSelector(selectMultiTenantRequestDomain, substate => substate);
const makeSelectMultiTenantSubmitInitiated = () =>
    createSelector(selectMultiTenantRequestDomain,
        substate => substate.multiTenantSubmitInitiated);

const makeSelectMultiTenantSubmitSuccess = () =>
    createSelector(selectMultiTenantRequestDomain,
        substate => substate.multiTenantSubmitSuccess);

const makeSelectloading = () =>
    createSelector(selectMultiTenantRequestDomain,
        substate => substate.loading);

const makeSelectMultiTenantFormData = () =>
    createSelector(
        selectRoot,
        globalState => {

            const cosmicCaseNumber = globalState.multiTenantCreateRequest.cosmicCaseNumber;
            const activeParticipants = globalState.multiTenantCreateRequest.activeParticipants;
            const leadAgreementNumber = globalState.multiTenantCreateRequest.leadEnrollmentNumber;
            const tenantAgreements = globalState.multiTenantCreateRequest.tenantAgreements;
            const sourceTenant = globalState.multiTenantSearchAndAdd.selectedTenant.value;
            const programCode = globalState.multiTenantRequest.enrollmentDetails.programCode;
            const masterAgmNumber = globalState.multiTenantRequest.enrollmentDetails.masterAgmNumber;
            const billToCountryCode = globalState.reallocationDetailsTable.billToCountryCode;
            const salesLocationCode = globalState.multiTenantRequest.enrollmentDetails.salesLocationCode;
            const pricingCurrency = globalState.multiTenantRequest.enrollmentDetails.pricingCurrency;
            const orderDetails = globalState.reallocationDetailsTable.orderDetails;
            const trackingId = globalState.multiTenantCreateRequest.trackingId;
            const attach = globalState.fileUploadModal?.uploadedFiles;

            let leadAgreementDetails = tenantAgreements.filter(tenant => tenant.agreementNumber === leadAgreementNumber);
            let leadAgreementName = "";
            let multiTenantPODetails = [];

            let tenantDetails = [];

            if (leadAgreementDetails && leadAgreementDetails.length > 0) {
                leadAgreementName = leadAgreementDetails[0].businessName;
                tenantDetails.push({
                    tenantId: leadAgreementDetails[0].agreementNumber,
                    tenantName: leadAgreementDetails[0].businessName,
                    contractTypecode: leadAgreementDetails[0].contractTypeCode,
                    countryName: leadAgreementDetails[0].countryName,
                    publicCustomerNumber: leadAgreementDetails[0].publicCustomerNumber
                });
            }

            let sourceAgreementDetails = tenantAgreements.filter(tenant => tenant.agreementNumber === sourceTenant.agreementNumber);

            if (sourceAgreementDetails && sourceAgreementDetails.length > 0) {
                tenantDetails.push({
                    tenantId: sourceAgreementDetails[0].agreementNumber,
                    tenantName: sourceAgreementDetails[0].businessName,
                    contractTypecode: sourceAgreementDetails[0].contractTypeCode,
                    countryName: sourceAgreementDetails[0].countryName,
                    publicCustomerNumber: sourceAgreementDetails[0].publicCustomerNumber
                });
            }

            globalState.multiTenantSearchAndAdd.selectedPurchaseOrdersData.map((purchaseOrder) => {
                let poDetails = {};
                poDetails["sourceTenant"] = sourceTenant.agreementNumber;
                poDetails["purchaseOrderNumber"] = orderDetails[purchaseOrder.externalIdentifier].PONumber;
                poDetails["externalIdentifier"] = purchaseOrder.externalIdentifier;
                poDetails["purchaseOrderId"] = purchaseOrder.purchaseOrderID;
                poDetails["purchaseOrderTypeCode"] = purchaseOrder.purchaseOrderTypeCode;
                poDetails["createdDate"] = purchaseOrder.createdDate;
                poDetails["systemPONumber"] = purchaseOrder.systemPONumber;
                poDetails["poliDetails"] = [];

                let partNumberGroups = Helpers.GroupBy(["partNumber"], orderDetails[purchaseOrder.externalIdentifier].lineItemDetails);

                Object.keys(purchaseOrder.selectedTenants).map((key) => {
                    let polidetails = {};
                    let details = (purchaseOrder.selectedTenants)[key];
                    polidetails["quantity"] = details.originalQuantity ? details.originalQuantity : 0;
                    polidetails["partNumber"] = details.selectedPartNumber.label;
                    polidetails["coveragePeriodStartDate"] = details.coveragePeriod.coverageStartDate;
                    polidetails["coveragePeriodEndDate"] = details.coveragePeriod.coverageEndDate;
                    polidetails["destinationTenant"] = [];
                    polidetails["externalIdentifier"] = key;
                    polidetails["productDescription"] = details.productDescription;
                    polidetails["salesOrderLineItemId"] = [];

                    let soliID = details.coveragePeriod.salesOrderLineItemId;
                    let partNumberDetails = partNumberGroups[details.selectedPartNumber.label];
                    let selectedSoliStartDate = new Date(details.coveragePeriod.coverageStartDate);
                    let selectedSoliEndDate = new Date(details.coveragePeriod.coverageEndDate);

                    partNumberDetails.map(pnDetail => {

                        let soliStartDate = new Date(pnDetail.coverageStartDate);
                        let soliEndDate = new Date(pnDetail.coverageEndDate)

                        if (pnDetail.salesOrderLineItemId === soliID || (
                            selectedSoliStartDate <= soliStartDate &&
                            selectedSoliEndDate <= soliEndDate)) {

                            polidetails["salesOrderLineItemId"].push(pnDetail.salesOrderLineItemId);
                        }
                    });

                    details.reallocationdetails.map((realloc) => {
                        let reallocDetails = {};
                        const destTenantAgreement = realloc.enrolmentNumAndTenantName.value.agreementNumber;
                        reallocDetails["tenantId"] = destTenantAgreement;
                        reallocDetails["tenantName"] = realloc.enrolmentNumAndTenantName.value.businessName;
                        reallocDetails["newPONumber"] = realloc.poNumber;
                        reallocDetails["quantity"] = realloc.reallocateQty
                        polidetails["destinationTenant"].push(reallocDetails);

                        let destTenantDetails = tenantDetails.filter(tenant => tenant.agreementNumber === destTenantAgreement)

                        if (!destTenantDetails || destTenantDetails.length === 0) {
                            destTenantDetails = tenantAgreements.filter(tenant => tenant.tenantId === destTenantAgreement);
                            if (destTenantDetails && destTenantDetails.length > 0) {
                                tenantDetails.push({
                                    tenantId: destTenantDetails[0].agreementNumber,
                                    tenantName: destTenantDetails[0].businessName,
                                    contractTypecode: destTenantDetails[0].contractTypeCode,
                                    countryName: destTenantDetails[0].countryName,
                                    publicCustomerNumber: destTenantDetails[0].publicCustomerNumber
                                });
                            }
                        }
                    })
                    poDetails["poliDetails"].push(polidetails);
                })
                multiTenantPODetails.push(poDetails);
            });

            const cosmicCaseNumberList = cosmicCaseNumber?.split(',');
            const trackingIdList = trackingId?.split(',');
            let tickets = [];
            for (let i = 0; cosmicCaseNumber && i < cosmicCaseNumberList.length; i++) {
                const ticketId = cosmicCaseNumberList[i];
                const trackId = trackingIdList[i] ?? "";
                if (ticketId) {
                    tickets.push({
                        "ticketId": ticketId.trim(),
                        "type": "submit",
                        "crmId": trackId.trim()
                    })
                }
            }

            const reallocateRequestPayload = {
                "vlmtOrder": {
                    "leadAgreementNumber": leadAgreementNumber,
                    "leadAgreementName": leadAgreementName.toUpperCase(),
                    "programCode": programCode,
                    "masterAgreementNumber": masterAgmNumber,
                    "billToCountryCode": billToCountryCode,
                    "salesLocationCode": salesLocationCode,
                    "pricingCurrency": pricingCurrency,
                    "attachment": attach,
                    "ticket": cosmicCaseNumber ? tickets : null,
                    "requestStatusCode": null,
                    "MultiTenantPODetails": multiTenantPODetails,
                    "tenants": tenantDetails,
                    "organizationPCNs": activeParticipants
                }
            };
            return reallocateRequestPayload;
        }
    );

const makeSelectEnrollmentDetails = () =>
    createSelector(selectMultiTenantRequestDomain,
        substate => substate.enrollmentDetails);

const makeSelectIsReadOnly = () =>
    createSelector(selectMultiTenantRequestDomain,
        substate => substate.isReadOnly);

const makeSelectSubmitError = () =>
    createSelector(selectMultiTenantRequestDomain,
        substate => substate.error);


const makeSelectIsTenantSelected = () =>
    createSelector(selectRoot,
        globalState => {
            if (globalState && globalState.multiTenantSearchAndAdd)
                return globalState.multiTenantSearchAndAdd.selectedTenant;
        });



const makeSelectRequestId = () =>
    createSelector(selectMultiTenantRequestDomain,
        substate => substate.submitResponseId);


const makeSelectMTAction = () =>
    createSelector(selectMultiTenantRequestDomain,
        substate => substate.action);



const makeSelectApprovalSuccess = () =>
    createSelector(selectMultiTenantRequestDomain,
        substate => substate.successResponse);

const makeSelectApprovalError = () =>
    createSelector(selectMultiTenantRequestDomain,
        substate => substate.approvalError);

const makeSelectRequiredCosmicCaseNumber = () =>
    createSelector(selectMultiTenantRequestDomain,
        substate => substate.cosmicCaseNumberValue);

const makeSelectRequiredTrackingId = () =>
    createSelector(selectMultiTenantRequestDomain,
        substate => substate.crmId);


const makeSelectTicket = () =>
    createSelector(selectMultiTenantRequestDomain,
        substate => substate.ticket);

export default makeSelectMultiTenantRequest;

export {
    makeSelectApprovalSuccess,
    makeSelectMTAction,
    makeSelectMultiTenantSubmitInitiated,
    makeSelectMultiTenantFormData,
    makeSelectRequestId,
    makeSelectMultiTenantSubmitSuccess,
    makeSelectEnrollmentDetails,
    makeSelectIsReadOnly,
    makeSelectloading,
    makeSelectIsTenantSelected,
    makeSelectSubmitError,
    makeSelectApprovalError,
    makeSelectRequiredCosmicCaseNumber,
    makeSelectRequiredTrackingId,
    makeSelectTicket
};
