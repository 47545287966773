import moment from 'moment';
const isSystemErrorStatusCodes = (code) => ['Y01', 'Y35', 'Y57', 'Y61', 'Y86', 'Y87', 'Y89', 'Y91', 'Y93', 'Y94', 'Y95', 'X31', 'X32', 'X33', 'YAB'].indexOf(code) > -1;
//const isSystemErrorStatusCodes = (code) => ['Y01', 'Y35', 'Y57', 'Y61', 'Y86', 'Y87', 'Y89', 'Y91', 'Y93', 'Y94', 'Y95'].indexOf(code) > -1;
const isRecentInvoice = (age) => age <= 60;
export const isLRDFullCreditCountryCode = (code) => code === 'BR';
export const isLRDSFullCreditCountryCodeAndSubCheck = (code, isSub) => code === 'BR' && isSub;
export const isLRDeReceiptCountryCode = (code) => code === 'MX';
export const isNotaFiscalNumberCountryCode = (code) => code === 'MX' || code === 'BR';
export const isLRDCountryCode = (code) => code === 'MX' || code === 'BR' || code === 'IN';
export const isCPS = (isProposalId, programCode) => isProposalId && (programCode === 'E6' || programCode === 'EU' || programCode === 'USG');

export const isFBCFullCancellationOnReturnReason = (returnReason) => ['Y97', 'Y91'].indexOf(returnReason) > -1;

export const getHandlingFeeFromRules =
    (CountryCode, licenseAge, reasonCode, isDirectAgreement, isProposalId, programCode) => {
        return isDirectAgreement
            || isLRDCountryCode(CountryCode)
            || isRecentInvoice(licenseAge)
            || isSystemErrorStatusCodes(reasonCode)
            || isCPS(isProposalId,programCode)?
            0 : 5;
    }

function updateMultiInvoiceHandlingFees(invoiceDetails, handlingFeePercentage) {
    var invoices = invoiceDetails;
    invoices.map(row => {
        var utcMoment = moment.utc();
        var diff = utcMoment.diff(row.invoiceDate,'days');
        if (handlingFeePercentage === 0 || isRecentInvoice(diff)) {
            row.restockFee = 0;
        }
        else {
            row.restockFee = 5;
        }
    });
    return invoices;
}

export { updateMultiInvoiceHandlingFees };