import React, { useEffect, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { injectIntl } from 'react-intl';
import { Link } from "react-router-dom";
import { createStructuredSelector } from 'reselect';
import { ActionControl } from '../../../common/components/ActionControl/ActionDropdown';
import FilterCriteria from '../../../common/search/components/filters/FilterCriteria';
import { FilterCredits, changeDateRange } from '../../../common/search/components/filters/actions';
import { makeSelectLoading, makeSelectError, makeSelectCredits, makeSelectDateRange } from '../../../common/search/components/filters/selectors';

import GridControl from '../../../common/components/grid/GridControl';
import SuccessMessage from '../../../common/components/controls/SuccessMessage';
import LoadingIndicator from '../../../common/components/LoadingIndicator/index';
import { makeSelectDomainData } from '../../../common/app/selectors';
import ErrorMessage from '../../../common/components/ErrorMessage/ErrorMessage';
import * as Helpers from '../../../common/internals/utils/helpers';
import DomainDataContext from '../../../common/context/DomainDataContext';
import DateRangeFilter from '../../../common/components/controls/DateRangeFilter';
import uuidv4 from 'uuid/v4';
import errorMessages from '../../../common/internals/api/errorMessages';
let creditsList = [];
var dateRangeElement = "";

export function SearchCreditsSubCFO(props) {

    const myDomainData = useContext(DomainDataContext);
    const { formatMessage } = props.intl;
    const linkFormatter = (cell, row) => {
        if (row) {

            var url =  row.hasMultiple ? `../subcfo/approve?multiInvoiceNumber=${row.multiInvoiceNumber}&requestId=${row.requestid}&cfostatus=${row.cfo_status_code}&status=${row.status_code}` :
                    `../subcfo/approve?invoice=${row.invoice_number}&requestId=${row.requestid}&cfostatus=${row.cfo_status_code}&status=${row.status_code}`;
                     
            if (props.location.pathname.indexOf('/field/subcfo/search') !== -1) {
                url = `../subcfo/create?invoice=${row.invoice_number}&requestId=${row.requestid}&cfostatus=${row.cfo_status_code}&status=${row.status_code}`;
            }
            return (
                <Link to={url}> {row.requestid}</Link>
            );
        }
    };

    function onDateRangeSelection(e, value) {
        dateRangeElement = PrepareFilterButtons(value);
    }

    function getMultiPleInvoices(multipleInvoiceList) {
        const invoiceList = multipleInvoiceList.reduce((invoiceList, data) => {
            if (data.isSelected) {
                invoiceList.push(
                    data.invoiceNumber
                );
            }
            return invoiceList;
        }, []);
        return invoiceList.join('\r\n');
    }

    function handleApplyEvent(value) {
        dateRangeElement = PrepareFilterButtons(value);
    }

    function PrepareFilterButtons(lifetimeDays) {
        props.onChangeDateRange(lifetimeDays);
        props.onSearchCredits();
        return <DateRangeFilter key={uuidv4()} lifetimeDays={lifetimeDays} handleApplyEvent={handleApplyEvent} onSearchChange={props.onSearchChange} onDateRangeSelection={onDateRangeSelection} />;
    }

    const columns = [
        {
            dataField: 'requestid',
            text: formatMessage({ id: "lblUniqueId" }),
            title: true,
            headerTitle: true,
            formatter: linkFormatter
        }, {
            dataField: 'invoice_number',
            text: formatMessage({ id: "lblInvoiceNumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
            style: { whiteSpace: "pre-wrap", wordWrap: "break-word" }
        }, {
            dataField: 'request_date',
            text: formatMessage({ id: "lblRequestDate" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'request_status',
            text: formatMessage({ id: "lblRequestStatues" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'countryCode',
            text: formatMessage({ id: "lblCountry" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'customer_name',
            text: formatMessage({ id: "lblCustomerName" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'enrollment_number',
            text: formatMessage({ id: "lblEnrolmentNumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'po_number',
            text: formatMessage({ id: "lblPONumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'invoiceAmount',
            text: formatMessage({ id: "lblInvoiceRevenue" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'currencyCode',
            text: formatMessage({ id: "lblCurrency" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'aging',
            text: formatMessage({ id: "lblAging" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'cfoApproverContact',
            text: formatMessage({ id: "lblSubCFO" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'approverComments',
            text: formatMessage({ id: "lblComments" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }];

    useEffect(() => {
        document.title = formatMessage({ id: "lblApplicationName" }) + " - " + formatMessage({ id: "lblSeachPageName" });
        dateRangeElement = PrepareFilterButtons("1D");
    }, []);

    let statusMessage = "";
    if (props && props.error && 'errorMessages' in props.error) {
        const titleMessage = { id: "titleErrorMessage", defaultMessage: "Search Error" };
        statusMessage = <ErrorMessage key="searcherrorMessages" messageBody={props.error.errorMessages} messageTitle={titleMessage} defaultMessage={errorMessages.tokenExpiredMsg} />
    } 

    creditsList = [];
    if (props.response && props.response.length > 0) {
        creditsList = [];
        props.response.map(data => (
            creditsList.push({
                uniqueId: uuidv4(),
                requestid: data.returnsIdentifier,
                hasMultiple: data.hasMultipleInvoice,
                invoice_number: data.hasMultipleInvoice ? getMultiPleInvoices(data.multipleInvoiceList) : data.billingDocumentNbr,
                request_date: Helpers.DateFormatForDisplay(new Date(data.requestSubmitDate)),
                request_status: Helpers.GetSubCFOStatusByCode(data.cfoRequestStatusCode),
                cfo_status_code: data.cfoRequestStatusCode,
                status_code: data.requestStatusCode,
                customer_name: data.endCustomerName,
                customer_number: data.endCustomerPurchaseOrderNumber,
                enrollment_number: data.agreementNumber,
                po_number: data.purchaseOrderNumber,
                currencyCode: data.currencyCode,
                invoiceAmount: data.invoiceAmount,
                multiInvoiceNumber: data.billingDocumentNbr,
                approverComments: data.cfoApproverComments ? data.cfoApproverComments : "",
                countryCode: Helpers.GetCountryByCode(data.countryCode, myDomainData),
                cfoApproverContact: data.cfoApproverContact ? data.cfoApproverContact : "" ,
                aging: Helpers.GetBusinessDayCount(new Date(data.requestSubmitDate), new Date())
            })
        ));
    }

    var relativeUrl = null;
    const url = sessionStorage.getItem('VLReturnsRedirectUri');
    console.log('Saved URL: ' + url);
    if (url && url.includes('invoice') && url.includes('approve')) {
        console.log('Clearing URL: ' + url);
        sessionStorage.removeItem('VLReturnsRedirectUri');
        try {
            const newUrl = new URL(url);
            relativeUrl = newUrl.pathname + newUrl.search;
        } catch (error) {
            console.log('URL: ' + url + ' is not a valid url. Error: ' + JSON.stringify(error));
        }
    }
    console.log('Parsed URL: ' + relativeUrl);

    return (
        <div>
            <div>
                {relativeUrl && <Redirect to={relativeUrl} push={true} />}
            </div>
            <div>
                {
                    !relativeUrl &&
                    <div className="form-group">
                        <div className="col-lg-24">
                            <div className="row spacer-xs-bottom" >
                                <div className="col-sm-24 col-md-24 col-lg-24">
                                    {statusMessage}
                                </div>
                            </div>
                            <div className="row spacer-ms-bottom">
                                <div className="col-sm-12 col-md-12 col-lg-12" >
                                    <h1 className="section-title">
                                        {formatMessage({ id: "lblCFOCreateRequestSearch" })}
                                    </h1>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12" >
                                    {dateRangeElement}
                                </div>
                            </div>
                            <FilterCriteria key="filtercriteria" intl={props.intl} domainData={myDomainData} />
                            <div className="row" >
                                <div className="col-sm-10 col-md-10 col-lg-10">
                                    <div className={!props.loading ? 'hidden' : ''}>
                                        <LoadingIndicator />
                                    </div>
                                </div>
                            </div>
                            <div className="row spacer-xs-bottom">
                                <GridControl key="gridcontrolfordatadisplay" columns={columns} data={creditsList} intl={props.intl} />
                            </div>
                            <div className="row spacer-xs-bottom pull-right" >
                                <div className="col-sm-24 col-md-24 col-lg-24">
                                    <ActionControl id="CreditListActions" fileName="CreditsList.csv" List={creditsList} columns={columns} intl={props.intl} />
                                </div>
                            </div>
                        </div>
                    </div >
                }
            </div>
        </div>
    );

}

SearchCreditsSubCFO.propTypes = {
    dateRange: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    loading: PropTypes.bool,
    onSearchCredits: PropTypes.func,
    onChangeDateRange: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    error: makeSelectError(),
    response: makeSelectCredits(),
    domainData: makeSelectDomainData(),
    dateRange: makeSelectDateRange(),
});

const mapDispatchToProps = (dispatch) => {
    return {
        onSearchCredits: () => dispatch(FilterCredits(true)),
        onChangeDateRange: (data) => dispatch(changeDateRange(data))
    };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, memo)(injectIntl(SearchCreditsSubCFO));
