import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectMultiTenantFetchRequestDomain = state => state.multiTenantFetchRequest || initialState;

const makeSelectRequestData = () => createSelector(selectMultiTenantFetchRequestDomain, substate => substate.requestData);
const makeLoading = () => createSelector(selectMultiTenantFetchRequestDomain, substate => substate.loading);
const makeSelectSubmitterEmail = () => createSelector(selectMultiTenantFetchRequestDomain, substate => {
    return substate.requestData ? substate.requestData.submitUserEmail : '';
});
const makeSelectBillingDocumentNumber = () => createSelector(selectMultiTenantFetchRequestDomain, substate => {
        return substate.requestData ? substate.requestData.leadEnrollmentNumber : '';
}
);
const makeSelectFetchError = () => createSelector(selectMultiTenantFetchRequestDomain, substate => substate.error);

export { makeSelectRequestData, makeLoading, makeSelectSubmitterEmail, makeSelectBillingDocumentNumber, makeSelectFetchError};
