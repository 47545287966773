import uuidv4 from 'uuid/v4';

function urlRFS() {
    return window.vlcredits.envData.Services.ReturnsFetchService.baseUrl;
}

function urlRPS() {
    return window.vlcredits.envData.Services.ReturnsProcessingService.baseUrl;
}

function urlReturnsUser() {
    return window.vlcredits.envData.Services.ReturnsUserProfileService.baseUrl;
}

function urlFileUpload() {
    return window.vlcredits.envData.Services.FileUploadService.baseUrl;
}

function gatewaySubscriptionKeyRFS() {
    return window.vlcredits.envData.Services.ReturnsFetchService.SubscriptionKey;
}

function gatewaySubscriptionKeyRPS() {
    return window.vlcredits.envData.Services.ReturnsProcessingService.SubscriptionKey;
}

function gatewaySubscriptionKeyReturnsUser() {
    return window.vlcredits.envData.Services.ReturnsUserProfileService.SubscriptionKey;
}

function gatewaySubscriptionKeyFileUpload() {
    return window.vlcredits.envData.Services.FileUploadService.SubscriptionKey;
}

function getInternalTenantId() {
    if (window.vlcredits.FeatureName === "MT") {
        console.log("TenantId :" + window.vlcredits.envData.Services.VlMTTenantId);
        return window.vlcredits.envData.Services.VlMTTenantId;
    } 
    else if (window.vlcredits.FeatureName === "FBC") {
        console.log("TenantId :" + window.vlcredits.envData.Services.VlFBCTenantId);
        return window.vlcredits.envData.Services.VlFBCTenantId;
    } else {
        console.log("TenantId :" + window.vlcredits.envData.Services.VlTenantId);
        return window.vlcredits.envData.Services.VlTenantId;
    }
}

function getOptions(verb) {
    return {
        method: verb,
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "x-ms-correlation-id": window && window.corelationId ? window.corelationId : uuidv4(),
            "x-ms-tenant-id": getInternalTenantId(),
            "Ocp-Apim-Subscription-Key": null,
            "Ocp-Apim-Trace": true
        },
        redirect: "follow",
        referrer: "no-referrer"
    };
}

export function domainDataRequestCreate() {
    var result = {
        baseUrl: urlRFS(),
        options: getOptions('GET')
    }
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRFS();
    return result;
};

export function newReturnRequestCreate() {
    var result = {
        baseUrl: urlRFS(),
        url: "api/invoice",
        options: getOptions('GET')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRFS();
    return result;
}

export function newMTRequestCreate() {
    var result = {
        baseUrl: urlRFS(),
        url: "api/agreement",
        options: getOptions('GET')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRFS();
    return result;
}

export function getReturnRequestCreate() {
    var result = {
        baseUrl: urlRFS(),
        url: "api/return",
        options: getOptions('GET')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRFS();
    return result;
}

export function rebillRequestCreate() {
    var result = {
        baseUrl: urlRFS(),
        url: "api/rebillpo",
        options: getOptions('GET')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRFS();
    return result;
}

export function getOrdersRequestCreate() {
    var result = {
        baseUrl: urlRFS(),
        url: "api/Order/getOrders",
        options: getOptions('GET')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRFS();
    return result;
}

export function getFullOrderRequestCreate() {
    var result = {
        baseUrl: urlRFS(),
        url: "api/Order/getOrders",
        options: getOptions('GET')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRFS();
    return result;
}

export function submitReturnRequestCreate() {
    var result = {
        baseUrl: urlRPS(),
        url: "api/rebillpo",
        options: getOptions('POST')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRPS();
    return result;
}

export function submitMTRequestCreate() {
    var result = {
        baseUrl: urlRPS(),
        url: "api/return",
        options: getOptions('POST')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRPS();
    return result;
}

export function cancellationListRequestCreate() {
    var result = {
        baseUrl: urlRFS(),
        url: "api/Search",
        options: getOptions('POST')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRFS();
    return result;
}

export function reallocationListRequestCreate() {
    var result = {
        baseUrl: urlRFS(),
        url: "api/Search",
        options: getOptions('POST')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRFS();
    return result;
}

export function creditListRequestCreate() {
    var result = {
        baseUrl: urlRFS(),
        url: "api/Search",
        options: getOptions('POST')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRFS();
    return result;
}

export function deleteDraftRequestCreate() {
    var result = {
        baseUrl: urlRPS(),
        url: "api/return",
        options: getOptions('DELETE')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRPS();
    return result;
}

export function CancelPendingRequest() {
    var result = {
        baseUrl: urlRPS(),
        options: getOptions('DELETE')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRPS();
    return result;
}

export function eReceiptRequest() {
    var result = {
        baseUrl: urlRFS(),
        url: "api/sap/invoicestatus",
        options: getOptions('POST')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRFS();
    return result;
}

export function fileUploadRequestCreate() {
    var result = {
        baseUrl: urlFileUpload(),
        url: "api/files",
        options: getOptions('POST')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyFileUpload();
    return result;
}

export function fileGetRequestCreate() {
    var result = {
        baseUrl: urlFileUpload(),
        url: "api/files/file",
        options: getOptions('GET')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyFileUpload();
    return result;
}

export function fileDeleteRequestCreate() {
    var result = {
        baseUrl: urlFileUpload(),
        url: "api/files/file",
        options: getOptions('DELETE')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyFileUpload();
    return result;
}

export function getReturnForApprovalCreate() {
    var result = {
        baseUrl: urlRFS(),
        options: getOptions('GET')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRFS();
    return result;
}

export function sumbitApprovalRequestCreate() {
    var result = {
        baseUrl: urlRPS(),
        options: getOptions('POST')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyRPS();
    return result;
}

export function userRolesRequestCreate() {
    var result = {
        baseUrl: urlReturnsUser(),
        url: "api/user/claims",
        options: getOptions('GET')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyReturnsUser();
    return result;
}

export function userPreferencesRequestCreate() {
    var result = {
        baseUrl: urlReturnsUser(),
        url: "api/user/preferences",
        options: getOptions('GET')
    };
    result.options.headers["Ocp-Apim-Subscription-Key"] = gatewaySubscriptionKeyReturnsUser();
    return result;
}



export default {
    newReturnRequestCreate, domainDataRequestCreate, creditListRequestCreate,
    submitReturnRequestCreate, fileUploadRequestCreate, fileGetRequestCreate, fileDeleteRequestCreate,
    getReturnForApprovalCreate, userRolesRequestCreate, userPreferencesRequestCreate, CancelPendingRequest
}