import { defineMessages } from 'react-intl';

export const scope = 'partNumberMenu';

export default defineMessages({

   saveButton: {
        id: `${scope}.saveButton`,
        defaultMessage: 'Save',
    },

    addMoreButton: {
        id: `${scope}.addMoreButton`,
        defaultMessage: 'Add More ',
    },

    selectPartNumber: {
        id: `${scope}.selectPartNumber`,
        defaultMessage: 'Select Part Number',
    },

    addPartNumbers: {
        id: `${scope}.addPartNumbers`,
        defaultMessage: 'Add Part Numbers',
    },



    
});
