import produce from 'immer';
import {
    CHANGE_INVOICE_NUMBER,
    SEARCH_INVOICE,
    CLEAR_INVOICE,
    SEARCH_INVOICE_ERROR,
    SEARCH_INVOICE_SUCCESS,
    CHANGE_COSMIC_CASENUMBER
} from './constants';

export const initialState = {};

const createRequestReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case CHANGE_INVOICE_NUMBER:
                draft.invoiceNumber = action.invoiceNumber;
                break;
            case CHANGE_COSMIC_CASENUMBER:
                draft.invoice.cosmicCaseNumber = action.value;
                break;
            case SEARCH_INVOICE:
                draft.invoice = {};
                draft.loading = true;
                draft.error = false;
                break;
            case CLEAR_INVOICE:
                draft.invoiceNumber = '';                
                draft.loading = false;
                draft.error = false;
                break;
            case SEARCH_INVOICE_SUCCESS:
                draft.invoiceNumber = action.invoiceNumber;
                draft.invoice = action.invoice;
                draft.loading = false;
                draft.error = false;
                break;
            case SEARCH_INVOICE_ERROR:
                draft.error = action.error;
                draft.loading = false;
                break;
        }
    });

export default createRequestReducer;
