import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import messages from './messages';
import InfoMessage from '../../../common/components/InfoMessage/InfoMessage';
import {Status_List} from '../../../../src/approvalSearch/components/constants';
import { makeSelectFormData } from './selectors'


export function ApprovalInfo({ formData }) {
   
    const readonly = formData && formData.isReadOnly;
    const isUserReadOnly = formData && formData.isUserReadOnly;    
    const isReadonlyApproval = (readonly || isUserReadOnly) ? true : false;
    const statusCode = formData && (formData.statusCode || formData.requestStatusCode);
    const operationsCenterCode = formData && formData.operationsCenterCode;
    var alternateROC = formData && formData.alternateROC;
    alternateROC = (alternateROC) ? alternateROC : operationsCenterCode;
    const rocOverriden = (operationsCenterCode == alternateROC) ? false : true; 
    var rocmessage = "";
    if (rocOverriden) {
        rocmessage = "Submitter has changed ROC from " + alternateROC + " to " + operationsCenterCode + "";
    }
    var  message = isUserReadOnly   ? "This user has readonly permissions" :Status_List[statusCode];
    if (statusCode === "REJ") {
        if (formData && formData.rejectionReason && formData.rejectionReason.rejectReasonCode) {
            message = Status_List[statusCode] + ":" + formData.rejectionReason.rejectReason;
        } 
    }

    if (statusCode === "CAN") {
        if (formData && formData.cancellationReason && formData.cancellationReason.cancelReasonCode) {
            message = Status_List[statusCode] + ":" + formData.cancellationReason.cancelReason;
        }
    }
     
    if (isReadonlyApproval)
    {
        if (rocOverriden) {
            return (<div>
                <InfoMessage messageBody={message} messageTitle={messages.approvalInfoMessage} />
                <InfoMessage messageBody={rocmessage} messageTitle={messages.rocInfoMessage} /></div>
            );
        }
        return (
            <InfoMessage messageBody={message} messageTitle={messages.approvalInfoMessage} />
        );
    }
    if (rocOverriden) {
        return (
            <InfoMessage messageBody={rocmessage} messageTitle={messages.rocInfoMessage} />
        );
    }
   return null;
}

ApprovalInfo.propTypes = {
    formData:PropTypes.object
};

const mapStateToProps = createStructuredSelector({
    formData: makeSelectFormData()
});

const withConnect = connect(
    mapStateToProps
);

export default compose(withConnect)(ApprovalInfo);
