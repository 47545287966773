import produce from 'immer';
import { SEARCH_INVOICE_ERROR, SEARCH_INVOICE_SUCCESS }
    from '../../../approval/approver/containers/createRequest/constants';

import { SEARCH_MULTI_INVOICE_ERROR, SEARCH_MULTI_INVOICE_SUCCESS }
    from '../../../approval/approver/containers/createMultiInvoiceRequest/constants';

export const initialState = { error: false };

const approvalErrorsReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SEARCH_MULTI_INVOICE_ERROR:
            case SEARCH_INVOICE_ERROR:
                draft.error = action.error;
                break;
            case SEARCH_MULTI_INVOICE_SUCCESS:
            case SEARCH_INVOICE_SUCCESS:
                draft.error = false;
                break;
        }
    });

export default approvalErrorsReducer;
