import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectMultiTenantHome = state => state.MultiTenantHome || initialState;
const selectRoot = state => state;

const makeSelectMultiTenantHome = () =>
    createSelector(
        selectMultiTenantHome,
        substate => substate,
    );


const makeSelectisValidRequestToSubmit= () =>
    createSelector(
        selectMultiTenantHome,
        substate => substate.isValidRequestToSubmit,
    );

const makeSelectGlobalState = () =>
    createSelector(
        selectRoot,
        globalState => globalState,
    );

export { makeSelectMultiTenantHome, makeSelectGlobalState, makeSelectisValidRequestToSubmit };
