import { cloneDeep } from 'lodash';
import { put, select, takeLatest } from 'redux-saga/effects';
import { makeSelectAccessToken, makeSelectAuthType } from '../../../../common/app/selectors';
import { getOrdersRequestCreate } from '../../../../common/internals/api/requests';
import { sagaFetchHandler } from '../../../../common/internals/api/sagaFetchHandler';
import { transformGetOrdersResponse } from "../../../../common/internals/api/transformReturnResponse";
import { ON_ADD_SELECTED_PO } from '../poSelectionModal/constants';
import { makeSelectPOList } from '../poSelectionModal/selectors';
import { getTenantCompleted, getTenantSearchError, updateDropDownOptions, updatePOList, updateSelectedPOs } from './actions';
import { SEARCH_TENANT, UPDATE_SELECTED_POS } from './constants';
import { makeSelectPurchaseOrderList, makeSelectSelectedPurchaseOrdersData } from './selectors';

export function* getTenant(action) {

    var tenant = action.tenant;
    var getTenantRequest = getOrdersRequestCreate();
    yield* setAuthTokens(getTenantRequest);

    getTenantRequest.url = `api/Order/getOrders?agreementNumber=${encodeURIComponent(tenant.value.agreementNumber)}`;

    yield* sagaFetchHandler(getTenantRequest, getTenantCompleted, getTenantSearchError, transformGetOrdersResponse, { "tenant": tenant.value });

}

export function* addSelectedPO() {
    const poList = yield select(makeSelectPOList());
    let selectedPurchaseOrdersData = cloneDeep(yield select(makeSelectSelectedPurchaseOrdersData()));

    poList.filter(x => x.isSelected === true).map(
        data =>
            selectedPurchaseOrdersData.filter(x => x.externalIdentifier === data.externalIdentifier).length === 0 && selectedPurchaseOrdersData.unshift({
                purchaseOrderNumber: data.purchaseOrderNumber,
                purchaseOrderID: data.purchaseOrderID,
                createdDate: data.createdDate,
                purchaseOrderTypeCode: data.purchaseOrderTypeCode,
                externalIdentifier: data.externalIdentifier,
                systemPONumber: data.systemPONumber
            })
    )
    if (poList.length === 1) {
        yield put(updateDropDownOptions(poList[0].purchaseOrderNumber));
    }

    yield put(updateSelectedPOs(selectedPurchaseOrdersData));

}

export function* filterPO() {
    const purchaseOrderList = yield select(makeSelectPurchaseOrderList());
    const selectedPurchaseOrdersData = yield select(makeSelectSelectedPurchaseOrdersData());
    const unchecked = [];

    for (let i = 0; i < purchaseOrderList.length; i++) {
        let j;

        for (j = 0; j < selectedPurchaseOrdersData.length; j++)
            if (purchaseOrderList[i].externalIdentifier === selectedPurchaseOrdersData[j].externalIdentifier)
                break;

        if (j == selectedPurchaseOrdersData.length) {
            unchecked.push(purchaseOrderList[i]);
        }
    }
    yield put(updatePOList(unchecked));


}


function validateResponse(response) {
    if (!response || !response.identifier)
        throw new Error('Empty Rebill Search Response');
    return response;
}

function* setAuthTokens(request) {
    const accessToken = yield select(makeSelectAccessToken());
    const authType = yield select(makeSelectAuthType());
    request.options.headers["Authorization"] = "Bearer " + accessToken;
    request.options.headers["x-ms-AuthType"] = authType;
}

export default function* createRequestSaga() {
    yield takeLatest(SEARCH_TENANT, getTenant);
    yield takeLatest(ON_ADD_SELECTED_PO, addSelectedPO);
    yield takeLatest(UPDATE_SELECTED_POS, filterPO);
}

