import { put, select, takeLatest } from 'redux-saga/effects';
import { ADD_REALLOCATE } from '../../components/reallocationDetailsTable/constants';
import { resetToInitialState, updateSelectedPOsReallocationDetails } from '../../containers/multiTenantSearchAndAdd/actions';
import { saveModalDetailsWithExtID } from '../../containers/partNumberMenu/actions';
import { SAVE_MODAL_DETAILS } from '../../containers/partNumberMenu/constants';
import { ON_REALLOCATION_MODAL_CLOSE } from '../../containers/reallocateModal/constants';
import { makeGlobalState, makeSelectExternalIdentifier } from '../../containers/reallocateModal/selectors';
import { makeSelectSelectedPurchaseOrdersData } from '../multiTenantSearchAndAdd/selectors';
import { updateReallocationBody } from './actions';



export function* saveModalDetailsWithExtId() {
    const data = yield select(makeSelectExternalIdentifier());
    const globalState = yield select(makeGlobalState());
    yield put(saveModalDetailsWithExtID(data));

    const orderDetils = globalState.reallocationDetailsTable.orderDetails;
    const systemPONumber = orderDetils[data].SystemPONumber ? orderDetils[data].SystemPONumber : "";

    if (globalState && globalState.reallocationBody && globalState.reallocationBody.reallocationBody) {
        let reallocationBody = globalState.reallocationBody.reallocationBody;

        let keys = Object.keys(reallocationBody);
        let validSelectedTenants = {};
        for (let key in keys) {
            let currentTenant = reallocationBody[keys[key]];
            if (currentTenant['selectedPartNumber'] != null && currentTenant['coveragePeriod'] != null && currentTenant['reallocationdetails'] != null) {
                for (let [i, currentReallocationDetail] of currentTenant.reallocationdetails.entries()) {
                    if (currentReallocationDetail.enrolmentNumAndTenantName == null ||
                        currentReallocationDetail.enrolmentNumAndTenantName == '' ||
                        currentReallocationDetail.reallocateQty == null ||
                        currentReallocationDetail.reallocateQty <= 0 ||
                        currentReallocationDetail.reallocateQty == '' ||
                        currentReallocationDetail.reallocateQty == '0'

                    ) {
                        currentTenant.reallocationdetails.splice(i, 1);
                    }
                }
                if (currentTenant['reallocationdetails'].length > 0) {
                    validSelectedTenants[keys[key]] = currentTenant;
                }
            }
        }
        let reallocationData = { "extId": data, "systemPONumber": systemPONumber, "reallocationBody": validSelectedTenants };
        yield put(updateSelectedPOsReallocationDetails(reallocationData));
        yield put(resetToInitialState());
    }

}

export function* resetTOInitialState() {
    yield put(resetToInitialState());
}

export function* setReallocationBody(action) {
    const selectedPurchaseOrdersData = yield select(makeSelectSelectedPurchaseOrdersData());

    const filteredPO = selectedPurchaseOrdersData.filter(x => x.externalIdentifier === action.data.externalIdentifier);

    if (filteredPO && filteredPO.length > 0) {
        let selectedTenants = filteredPO[0].selectedTenants;
        if (Object.keys(selectedTenants).length > 0) {
            yield put(updateReallocationBody(selectedTenants));
        }
    }
}

export default function* createRequestSaga() {
    yield takeLatest(SAVE_MODAL_DETAILS, saveModalDetailsWithExtId);
    yield takeLatest(ON_REALLOCATION_MODAL_CLOSE, resetTOInitialState);
    yield takeLatest(ADD_REALLOCATE, setReallocationBody);
}
