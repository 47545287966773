import { defineMessages } from 'react-intl';

export const scope = 'fbcPartnerSubmitHome';

export default defineMessages({
    createCreditRequest: {
        id: `${scope}.createCreditRequest`,
        defaultMessage: 'License Reduction Request',
    },
    createFutureBillingCancellationRequest: {
        id: `${scope}.createFutureBillingCancellationRequest`,
        defaultMessage: 'License Reduction Request',
    },
    submitSuccess: {
        id: `${scope}.submitSuccess`,
        defaultMessage: 'License Reduction Request submitted successfully',
    },    
    rebillConfirmation: {
        id: `${scope}.rebillConfirmation`,
        defaultMessage: 'No rebill invoices/purchase order are added for full Cancellation request. Do you want to continue ?',
    },
    rebillStatusConfirmation: {
        id: `${scope}.rebillStatusConfirmation`,
        defaultMessage: 'This PO is already in Accepted status , please use rebill "Invoice number" to ensure rebill is eligible and linked with this credit request. If you continue to use System PO, PO will be not eligible as Rebill PO. However, it will be linked with this credit request for reference.',
    },
    closeAction: {
        id: `${scope}.closeAction`,
        defaultMessage: 'close',
    },
    continueAction: {
        id: `${scope}.continueAction`,
        defaultMessage: 'Continue',
    },
    goBackAction: {
        id: `${scope}.goBackAction`,
        defaultMessage: 'Go Back',
    },
    submitConfirmation: {
        id: `${scope}.submitConfirmation`,
        defaultMessage: 'Submit Confirmation',
    },
    listItemsStatusConfirmation: {
        id: `${scope}.listItemsStatusConfirmation`,
        defaultMessage: 'Change Line Items',
    }


});
