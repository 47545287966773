import {
    FILTER_CANCELLATION_REQUESTS,
    FILTER_CANCELLATION_SUCCESS,
    FILTER_CANCELLATION_ERROR,
    CHANGE_REQUEST_IDENTIFIER,
    CHANGE_REQUEST_STATUS,
    CHANGE_PO_NUMBER,
    CHANGE_ENROLLMENT_NUMBER,
    CHANGE_DATE_RANGE,
    CHANGE_COSMIC_NUMBER,
    CHANGE_CUSTOMER_NAME
 } from './constants';

export function FilterCancellationRequests() {   
    return {
        type: FILTER_CANCELLATION_REQUESTS
    };
}

export function FilterCancellationRequestsList(cancellationListResponse) {
    return {
        type: FILTER_CANCELLATION_SUCCESS,
        response: cancellationListResponse
    };
}

export function FilterCancellationRequestsListError(error) {
    return {
        type: FILTER_CANCELLATION_ERROR,
        error
    };
}

export function changeRequestIdentifier(requestIdentifier) {
    return {
        type: CHANGE_REQUEST_IDENTIFIER,
        requestIdentifier
    };
}

export function changeRequestStatusCode(requestStatus) {
    return {
        type: CHANGE_REQUEST_STATUS,
        requestStatus
    };
}

export function changePurchaseOrderNumber(poNumber) {
    return {
        type: CHANGE_PO_NUMBER,
        poNumber
    };
}

export function changeAgreementNumber(enrollmentNumber) {
    return {
        type: CHANGE_ENROLLMENT_NUMBER,
        enrollmentNumber
    };
}

export function changeCosmicNumber(cosmicNumber) {
    return {
        type: CHANGE_COSMIC_NUMBER,
        cosmicNumber
    };
}

export function changeCustomerName(endCustomerName) {
    return {
        type: CHANGE_CUSTOMER_NAME,
        endCustomerName
    };
}

export function changeDateRange(dateRange) {
    return {
        type: CHANGE_DATE_RANGE,
        dateRange
    };
}

export function search(dateRange) {
    return {
        type: CHANGE_DATE_RANGE,
        dateRange
    };
}


