import { defineMessages } from "react-intl";

export const scope = "MultiTenantCreateRequest";

export default defineMessages({
    enterLeadEnrollmentNumber: {
        id: `${scope}.enterLeadEnrollmentNumber`,
        defaultMessage: 'Lead Enrollment Number',
    },

    enterCosmicCaseNumber: {
        id: `${scope}.enterCosmicCaseNumber`,
        defaultMessage: 'Cosmic Case Number',
    },

    enterTrackingId:{
        id:`${scope}.enterTrackingId`,
        defaultMessage:"CRM ID"
    }

});