import React ,{ useEffect }from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import * as Helpers from '../../../common/internals/utils/helpers';
import * as FocusTrap from 'focus-trap-react';

export function Modal({
    id,
    isOpen,
    headerMessage,
    bodyMessage,
    actionMessage,
    action,
    closeMessage,
    close,
    lineItems,
    customValues,
    isBothButtonsPrimary
}) {
    useEffect(() => {
        console.log('useEffect');
        Helpers.KeepFocusInOnedashPopUp(id);

    });
var actualLineItem = lineItems && lineItems.length > 0 ? [...new Set(lineItems)] : null;
//console.log("SEt_data:",actualLineItem);
var solinumberArray=[];
var soliNumbers="";
if(actualLineItem){
actualLineItem.forEach(element => {
    solinumberArray.push(element.salesOrderLineItemId);
});
}
if(solinumberArray && solinumberArray.length > 0){
    soliNumbers = solinumberArray.toString();
}
    if (isOpen) {

        const secondaryButtonClassName = "btn " + (isBothButtonsPrimary ? "btn-primary" : "f-secondary") + " c-button";

        window.scrollTo(0, 0);
        return (
            <div>
                <FocusTrap>
                    <div id={id}
                        style={{ display: 'block', opacity: '1' }}
                        className="modal"
                        aria-live="polite">
                        <div className="modal-dialog" aria-live="polite">
                            <div className="modal-content" aria-live="polite">
                                <div className="onedash-modal-content" aria-live="polite">
                                    <h4 aria-live="alert" className="spacer-32-top">
                                        <FormattedMessage {...headerMessage} values={customValues} />
                                    </h4>
                                    {bodyMessage  ?
                                        <h4 className="spacer-32-top">
                                            <FormattedMessage {...bodyMessage} />
                                        </h4>: '' }
                                        {
                                            actualLineItem && actualLineItem.length >0 ? 
                                            <div style={{marginTop:"8px",marginBottom:"8px"}}>
                                                <h4>Line Items SOLI Number:</h4>
                                                {soliNumbers}
                                            </div>
                                             : null
                                        }
                                    <div className="onedash-modal-buttons btn-group spacer-32-top">
                                        {close && closeMessage ?
                                            <button id={id + "modalbuttonClose"}
                                                className={`${secondaryButtonClassName} blackFocusOffset`}
                                                type="button" onClick={close}>
                                                <FormattedMessage {...closeMessage} />
                                            </button> : ''}
                                        {action && actionMessage ?
                                            <button id={id + "modalbuttonAction"}
                                                className="btn c-button f-primary btn-primary blackFocusOffset"
                                                type="button" onClick={action}>
                                                <FormattedMessage {...actionMessage} />
                                            </button> : ''}
                                    </div>
                                </div>
                            </div>

                        </div>
                        </div>
                </FocusTrap>
            </div >
        )
    }
    return null;
}

Modal.propTypes = {
    id: PropTypes.string.isRequired,
    headerMessage: PropTypes.object,
    bodyMessage: PropTypes.object,
    actionMessage: PropTypes.object,
    closeMessage: PropTypes.object,
    isOpen: PropTypes.bool,
    action: PropTypes.func,
    close: PropTypes.func,
    lineItems: PropTypes.array,
}

export default Modal;