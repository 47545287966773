import React from 'react';

export function RetirementMessage(roles) {
  if (!roles || roles.length === 0) {
    return RetirementMessageForRoc();
  }
  if (roles.includes('VPU')) {
    return RetirementMessageForPartner();
  }
  return RetirementMessageForRoc();
}

const partnerLaunchCalender = 'https://nam06.safelinks.protection.outlook.com/?url=https%3A%2F%2Fpartner.microsoft.com%2Fen-us%2Fresources%2Fdetail%2Fpartner-launch-calendar-vl-pdf&data=05%7C01%7CMichelle.Rowe%40microsoft.com%7C20c81fc030d34967b24d08dbaee3ba45%7C72f988bf86f141af91ab2d7cd011db47%7C1%7C0%7C638296065342203502%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=2GB2GJcDiPDcoRnbT%2FTa2BKEu72umYZFzNClEoLzGs0%3D&reserved=0'
const creditsAndTransferLearn = 'https://learn.microsoft.com/en-us/volume-licensing-central/orderworkspace/creditstransfers'
const vlCentral = 'https://vlcentral.microsoft.com/#home';

function RetirementMessageForPartner() {
  const partnerPreGA = new Date('2024-07-12');
  const parnterPostGA = new Date('2024-07-19');
  const currentDate = new Date();
  if (currentDate >= partnerPreGA && currentDate < parnterPostGA) {
    return <div className='RetirementBackGround'>
      <div>On <b>July 19, 2024</b>, Volume Licensing credit functionality will be added to VL Central. Review the <a href={partnerLaunchCalender}>VL Partner Launch Calendar</a> and how-to guidance on <a href={creditsAndTransferLearn}>Credits and Transfers | Microsoft Learn</a> to find out more. </div>
      <br />
      <div>Partners will have read-only access for the Volume Licensing Credit Tool (VLCT) from <b>2nd September 2024</b> and will be redirected to VL Central for all credit and transfers functionality beyond this date. VLCT will be retired on <b>October 5, 2024</b>.</div>
    </div>
  } else if (currentDate >= new Date(parnterPostGA)) {
    return <div className='RetirementBackGround'>
      <div>Volume Licensing credit functionality has been added to <a href={vlCentral}>Volume Licensing Central</a> in the Orders workspace. Review the <a href={partnerLaunchCalender}>VL Partner Launch Calendar</a> and how-to guidance on <a href={creditsAndTransferLearn}>Credits and Transfers | Microsoft Learn</a> to find out more. </div>
      <br />
      <div>Partners will have read-only access for the Volume Licensing Credit Tool (VLCT) from <b>2nd September 2024</b> and will be redirected to VL Central for all credit and transfers functionality beyond this date. VLCT will be retired on <b>October 5, 2024</b>.</div>
    </div>
  }
  return null;
}

function RetirementMessageForRoc() {
  const rocPreGA = new Date('2024-07-22');
  const rocPostGA = new Date('2024-08-02');
  const currentDate = new Date();
  if (currentDate >= rocPreGA && currentDate < rocPostGA) {
    return <div className='RetirementBackGround'>
      <div>On <b>August 2nd, 2024</b>, Volume Licensing credit functionality will be added to VL Central.</div>
      <br />
      <div>Users will have read-only access for the Volume Licensing Credit Tool (VLCT) from <b>2nd September 2024</b> and will be redirected to VL Central for all credit and transfers functionality beyond this date. VLCT will be retired on <b>October 5, 2024</b>.</div>
    </div>
  } else if (currentDate >= new Date(rocPostGA)) {
    return <div className='RetirementBackGround'>
      <div>Volume Licensing credit functionality has been added to <a href={vlCentral}>Volume Licensing Central</a> in the Orders workspace. </div>
      <br />
      <div>Users will have read-only access for the Volume Licensing Credit Tool (VLCT) from <b>2nd September 2024</b> and will be redirected to VL Central for all credit and transfers functionality beyond this date. VLCT will be retired on <b>October 5, 2024</b>.</div>
    </div>
  }
  return null;
}
