import { defineMessages } from 'react-intl';

export const scope = 'ReallocationTable';

export default defineMessages({
   
    noRecordsFound: {
        id: `${scope}.noRecordsFound`,
        defaultMessage: 'No records found.',
    },
    addMoreBtn: {
        id: `${scope}.addMoreBtn`,
        defaultMessage: " + Add More",
    },
});
