import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useInjectSaga } from '../../../../../common/internals/utils/injectSaga';
import { useInjectReducer } from '../../../../../common/internals/utils/injectReducer';
import ApprovalRequest from '../request/Loadable';
import makeSelectApprovalHome from './selectors';
import reducer from './reducer';
import saga from './saga';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { injectIntl } from 'react-intl';
import ApprovalErrors from '../../../../common/approvalErrors';
import ApprovalInfo from '../../../../common/approvalInfo';
import ApprovalMultiInvoiceRequest from "../multiInvoiceRequest/Loadable";


function ApprovalHome(props) {
   
    sessionStorage.removeItem('VLReturnsRedirectUri');
    useInjectReducer({ key: 'approvalHome', reducer });
    useInjectSaga({ key: 'approvalHome', saga });
    console.log("IsMultiInvoice Request ");

    var searchParams = new URLSearchParams(props.location.search);
    
    var isMultiInvoice = false;
    if (!searchParams.get("invoice")) {
        isMultiInvoice = true;
    }

    return (

        <div className="spacer-32-top">
            <div className="row">
                <div className="col-md-24 spacer-32-top" >
                    <ApprovalInfo />
                    <ApprovalErrors />
                </div>
            </div>
            <div className="row">
                <div className="col-md-24 spacer-32-top" >
                    <h1 className="section-title">
                        <FormattedMessage {...messages.createRequestApproval} />
                    </h1>
                </div>
            </div>

            {
                isMultiInvoice ? (
                    <ApprovalMultiInvoiceRequest props={props} />
                    
                ) : (
                        <ApprovalRequest props={props} />
                )               
            }

        </div>

    );
}

ApprovalHome.propTypes = {
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
    approvalHome: makeSelectApprovalHome()
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(injectIntl(ApprovalHome));
