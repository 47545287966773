import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import ErrorMessage from '../../../../common/components/ErrorMessage/ErrorMessage';
import InfoMessage from '../../../../common/components/InfoMessage/InfoMessage';
import messages from "./messages";
import * as Helpers from "../../../../common/internals/utils/helpers";
import { makeSelectMTSubmitInfoData } from './selectors';
import errorMessages from '../../../../common/internals/api/errorMessages';
import { userRoleType } from '../../../../common/internals/utils/helpers';
import { makeSelectRequestData } from '../../../common/components/multiTenantFetchRequest/selectors';

function MTSubmitInfo({ formData, requestData }) {
    const readonly = requestData && requestData.isReadOnly;
    const adjustedPOStatus = formData && formData.enrollmentDetails && formData.enrollmentDetails.adjustedPOStatus;
    const isUserReadOnly = requestData && requestData.isUserReadOnly;
    const userRole = window && window.userRole;
    const isPartner = (userRoleType[userRole] === "Partner");
    const readOnlyFlag = (readonly || isUserReadOnly) ? true : false;
    const operationsCenterCode = formData && formData.operationsCenterCode;
    const errors = formData && formData.enrollmentDetails && formData.enrollmentDetails.errors;
    const requestStatus = formData && formData.enrollmentDetails && formData.enrollmentDetails.requestStatusCode;
    var alternateROC = formData && formData.alternateROC;
    alternateROC = (alternateROC) ? alternateROC : operationsCenterCode;
    const rocOverriden = (operationsCenterCode == alternateROC) ? false : true;
    
    let request_status = '';
    let readOnlyMessageCustomValues = '';
    if (formData && formData.enrollmentDetails) {
          request_status = userRole === 'VPU'
              ? Helpers.GetMTPartnerRequestStatusByCode(requestStatus)
            : Helpers.GetMTOpsRequestStatusByCode(requestStatus);
     readOnlyMessageCustomValues = {
        requestStatus: request_status
        };
    }
    const readOnlyMessage = <FormattedMessage {...messages.readOnlyMessage} values={readOnlyMessageCustomValues} />; 
    var rocMessage = "";
    if (rocOverriden) {
        rocMessage = "Submitter has changed ROC from " + alternateROC + " to " + operationsCenterCode + "";
    }
   
    if(errors) {

        const defaultMessage = (userRole === 'VPU') ? errorMessages.partnerMsg : errorMessages.opsMsg;

        let displayErrors = [];

        for (let key in errors) {
            let poStatus = adjustedPOStatus.filter(poStatus => poStatus.TransactionID === key);
            let errorCodes = [];
            let errorMessages = {errors: []};

            for(let error of errors[key]) {
                if(errorCodes.includes(error)) {
                    continue;
                }
                errorCodes.push(error);
                if(error.includes(":")){

                    let errorDetails = error.split(":");
                    errorMessages.errors.push({
                        defaultMessage: "{dynamicMsg}",
                        dynamic: true,
                        id: `errorMessages.${errorDetails[0].trim()}`,
                        dynamicMsg: isPartner ? 'Order cannot be adjusted because it belongs to a base product SKU for which a step-up SKU was purchased. Please submit the reduction manually by CLT'
                            : `Order cannot be adjusted because it belongs to a base product SKU for which a step-up SKU was purchased. Please request full credit of following Step-up SKU order first: ${errorDetails[1].trim()}`
                    });
                }
                
                errorMessages.errors.push({
                        id: `errorMessages.${error}`,
                        defaultMessage: "Error Occurred during processing of adjustment order"
                    });
            }

            if(poStatus && poStatus.length !== 0) {
                displayErrors.push({title: poStatus[0].purchaseOrderNumber, errorMessages});
            } else {
                displayErrors.push({title: key, errorMessages})
            }
        }

        return (<>
            <InfoMessage messageBody={readOnlyMessage} messageTitle={messages.readOnlyInfoMessage} />
            {displayErrors.map((error, i) => 
                    <ErrorMessage messageBody={error.errorMessages} key={i}
                                  messageTitle={{... messages.errorMessage, 
                                                    defaultMessage: `${error.title}: ${messages.errorMessage.defaultMessage}`}}
                                  defaultMessage={defaultMessage} />)
            }
        </>);

    } else if (requestData && requestData.requestStatusCode && requestData.requestStatusCode === "REJ" && requestData.workflowList && requestData.workflowList[0].approverList && requestData.workflowList[0].approverList[0].comments) {
        var _rejectionReason = requestData.workflowList[0].approverList[0].comments;
        if (rocOverriden) {
            return (<div><InfoMessage messageBody={"Rejected: " + _rejectionReason} messageTitle={messages.readOnlyInfoMessage} />
                <InfoMessage messageBody={rocMessage}
                    messageTitle={messages.rocInfoMessage} /></div>)
        }
        return (<InfoMessage messageBody={"Rejected: " + _rejectionReason} messageTitle={messages.readOnlyInfoMessage} />);

    }
    else if (requestStatus === "REJ" && !errors) {
        
        const partialRequestMsg = <FormattedMessage {...messages.partialRequestSucess} values={''} />

        if (rocOverriden) {
            return (
                <>
                    <InfoMessage messageBody={readOnlyMessage} messageTitle={messages.readOnlyInfoMessage} />
                    <InfoMessage messageBody={rocMessage} messageTitle={messages.rocInfoMessage} />
                    <InfoMessage messageBody={partialRequestMsg} messageTitle={messages.partialRequestSucessTitle} />
                </>);
        }
         return (
        <>
            <InfoMessage messageBody={readOnlyMessage} messageTitle={messages.readOnlyInfoMessage} />
            <InfoMessage messageBody={partialRequestMsg} messageTitle={messages.partialRequestSucessTitle} />
        </>);

    } else if (readOnlyFlag) {
        
       if (rocOverriden) {
            return (<div><InfoMessage messageBody={readOnlyMessage}
                messageTitle={messages.readOnlyInfoMessage} />
                <InfoMessage messageBody={rocMessage}
                    messageTitle={messages.rocInfoMessage} /></div>)
        }
        return (<InfoMessage messageBody={readOnlyMessage}
            messageTitle={messages.readOnlyInfoMessage} />)
    }

    return null;
}

const mapStateToProps = createStructuredSelector({
    formData: makeSelectMTSubmitInfoData(),
    requestData: makeSelectRequestData()
});

const withConnect = connect(
    mapStateToProps
);

export default compose(withConnect)(MTSubmitInfo);