import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectRoot = state => state;
const selectGlobal = state => state.global || initialState;

const makeSelectRolesLoading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.rolesloading,
    );

const makeSelectLoading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.loading,
    );


const makeSelectError = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.error,
    );

const makeSelectDomainData = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.domainData,
    );


const makeSelectUserInfo = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.userInfo,
    );

const makeSelectAuthType = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.userInfo.authType,
    );

const makeSelectAccessToken = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.userInfo.userAccessToken,
    );

const makeSelectAccessorGuid = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.userInfo.accessorGuid,
    );
const makeSelectUserPref = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.userInfo.userPref,
    );

export {
    makeSelectLoading,
    makeSelectError,
    makeSelectDomainData,
    makeSelectUserInfo,
    makeSelectAuthType,
    makeSelectAccessToken,
    makeSelectAccessorGuid,
    makeSelectUserPref,
    makeSelectRolesLoading
};
