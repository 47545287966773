import { createSelector } from "reselect";

const selectRoot = state => state;

const makeSelectIsReturnFormReadOnly = () =>
    createSelector(
        selectRoot,
        globalState => {
            var readOnlyFlag = null;
            if (globalState.fetchReturnRequest && globalState.fetchReturnRequest.invoice)
                readOnlyFlag = globalState.fetchReturnRequest.invoice.isReadOnly
                    || globalState.fetchReturnRequest.invoice.isUserReadOnly;

            if (globalState.createRequest && globalState.createRequest.invoice)
                readOnlyFlag = globalState.createRequest.invoice.isReadOnly
                    || globalState.createRequest.invoice.isUserReadOnly;

            if (globalState.fetchMultiInvoiceReturnRequest && globalState.fetchMultiInvoiceReturnRequest.invoices)
                readOnlyFlag = globalState.fetchMultiInvoiceReturnRequest.invoices.isReadOnly
                    || globalState.fetchMultiInvoiceReturnRequest.invoices.isUserReadOnly;

            if (globalState.createMultiInvoiceRequest && globalState.createMultiInvoiceRequest.invoices)
                readOnlyFlag = globalState.createMultiInvoiceRequest.invoices.isReadOnly
                    || globalState.createMultiInvoiceRequest.invoices.isUserReadOnly;

            return readOnlyFlag;
        },
    );

const makeSelectFormData = () =>
    createSelector(
        selectRoot,
        globalState => {
            var formData = null;
            if (globalState.createRequest && globalState.createRequest.invoice)
                formData = globalState.createRequest.invoice;
            if (globalState.createMultiInvoiceRequest && globalState.createMultiInvoiceRequest.invoices)
                formData = globalState.createMultiInvoiceRequest.invoices;

            return formData;
        },
    );


export {
    makeSelectIsReturnFormReadOnly,
    makeSelectFormData
};
