import React, { useEffect, memo } from "react";
import saga from "./saga";
import reducer from "./reducer";
import { compose } from "redux";
import messages from "./messages";
import PropTypes from "prop-types";
import { getReturn } from './actions';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useInjectSaga } from "../../../../common/internals/utils/injectSaga";
import { useInjectReducer } from "../../../../common/internals/utils/injectReducer";
import Input from '../../../../common/components/Input';
import {
    makeSelectEnrollmentNumberFetch,
    makeSelectSystemPONumberFetch
} from './selectors';

import { makeCancelFutureBillingReturnFormReadOnly } from '../../../operations/containers/cancelFutureBillingReturnForm/selectors';

function CancelFutureBillingFetchRequest({
    isReadOnly,
    enrollmentNumber,
    systemPONumber,
    getReturnOnLoad,
    }) {
    useInjectReducer({ key: "CancelFutureBillingFetechRequest", reducer });
    useInjectSaga({ key: "CancelFutureBillingFetechRequest", saga });

    var searchParams = new URLSearchParams(window.location.search);
    var returnlicensenumber = searchParams.get("licensenumber");

    useEffect(() => {
        if (
            (returnlicensenumber) &&
            (returnlicensenumber.trim().length > 0)
        ) {
            var payload = {
                returnlicensenumber,
                requestId: searchParams.get("requestId"),
                status: searchParams.get("status"),
                cfostatus: searchParams.get("cfostatus")
            };
            getReturnOnLoad(payload);
        }
    }, []);

    return (
        <div>
            <div className="row spacer-32-top">
                <div className="col-md-24 form-group">
                    <div className="row">
                        <div className="col-sm-8 col-md-8 col-lg-6 col-xl-4">
                            <Input
                                value={enrollmentNumber || ""}
                                label={messages.enrollmentNumber}
                                id="enrollmentNumber"
                                editable={false}
                            />
                        </div>
                        <div className="col-sm-8 col-md-8 col-lg-6 col-xl-4">
                            <Input
                                value={systemPONumber || ""}
                                label={messages.systemPONumber}
                                id="systemPONumber"
                                editable={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

CancelFutureBillingFetchRequest.propTypes = {
    invoiceNumber: PropTypes.string,
    getReturnOnLoad: PropTypes.func    
};

const mapStateToProps = createStructuredSelector({
    enrollmentNumber: makeSelectEnrollmentNumberFetch(),
    systemPONumber: makeSelectSystemPONumberFetch(),
    isReadOnly: makeCancelFutureBillingReturnFormReadOnly()
});

function mapDispatchToProps(dispatch) {
    return {
        getReturnOnLoad: payload => dispatch(getReturn(payload))        
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(CancelFutureBillingFetchRequest);
