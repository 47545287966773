import produce from 'immer';
import { SEARCH_INVOICE_SUCCESS } from '../../../common/components/createRequest/constants'
import { SEARCH_MULTI_INVOICE_SUCCESS } from '../../../common/components/createMultiInvoiceRequest/constants'
import { SEARCH_REBILLPO_SUCCESS } from '../../containers/rebillSearch/constants'
import { REMOVE_REBILLPO_NUMBER, CLOSE_PO_CONFIRMATION, SELECT_REBILL_INVOICE, CHANGE_PO_EXPAND } from './constants'
import { GET_RETURN_SUCCESS } from '../../../common/components/fetchReturnRequest/constants'
import { GET_MULTI_INVOICE_RETURN_SUCCESS } from '../../../common/components/fetchMultiInvoiceReturnRequest/constants';
import * as Helpers from '../../../../common/internals/utils/helpers';

export const initialState = { rebillInvoicepo: [] };

let totalRebillPOAmount = 0;
let totalRebillPOAmountUSD = 0;
let rebillPOList = null;
let isRebillCurrencyDiff = false;

const rebillpoTableReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SEARCH_MULTI_INVOICE_SUCCESS:
            case GET_MULTI_INVOICE_RETURN_SUCCESS:

                rebillPOList = action.invoices.rebillInvoicePO;
                draft.totalRebillPOAmount = 0;
                draft.totalRebillPOAmountUSD = 0;

                if (rebillPOList) {
                    totalRebillPOAmount = 0;
                    totalRebillPOAmountUSD = 0;
                    [rebillPOList, totalRebillPOAmount, totalRebillPOAmountUSD, isRebillCurrencyDiff] = Helpers.performRebillCalculation(rebillPOList);
                    draft.totalRebillPOAmount = totalRebillPOAmount;
                    draft.totalRebillPOAmountUSD = totalRebillPOAmountUSD;
                    draft.isRebillCurrencyDiff = isRebillCurrencyDiff;
                }
                draft.rebillInvoicepo = rebillPOList;
                break;

            case SEARCH_INVOICE_SUCCESS:
            case GET_RETURN_SUCCESS:

                rebillPOList = action.invoice.rebillInvoicePO;
                draft.totalRebillPOAmount = 0;
                draft.totalRebillPOAmountUSD = 0;
                if (rebillPOList) {
                    totalRebillPOAmount = 0;
                    totalRebillPOAmountUSD = 0;
                    [rebillPOList, totalRebillPOAmount, totalRebillPOAmountUSD, isRebillCurrencyDiff] = Helpers.performRebillCalculation(rebillPOList);
                    draft.totalRebillPOAmount = totalRebillPOAmount;
                    draft.totalRebillPOAmountUSD = totalRebillPOAmountUSD;
                    draft.isRebillCurrencyDiff = isRebillCurrencyDiff;
                }
                draft.rebillInvoicepo = rebillPOList;

                break;

            case SEARCH_REBILLPO_SUCCESS:
                action.rebillpo.expanded = true;
                
                if (draft.rebillInvoicepo)
                    draft.rebillInvoicepo.push(action.rebillpo);
                else
                    draft.rebillInvoicepo = new Array(action.rebillpo);

                rebillPOList = draft.rebillInvoicepo;
                if (rebillPOList) {
                    totalRebillPOAmount = 0;
                    totalRebillPOAmountUSD = 0;
                    [rebillPOList, totalRebillPOAmount, totalRebillPOAmountUSD, isRebillCurrencyDiff] = Helpers.performRebillCalculation(rebillPOList, action);
                    draft.totalRebillPOAmount = totalRebillPOAmount;
                    draft.totalRebillPOAmountUSD = totalRebillPOAmountUSD;
                    draft.isRebillCurrencyDiff = isRebillCurrencyDiff;

                }
                draft.rebillInvoicepo = rebillPOList;

                draft.showPOConfirmation = action.rebillpo.type === 1 && action.rebillpo.poStatusCode === 'HAD' ? true : false;
                break;
            case REMOVE_REBILLPO_NUMBER:

                draft.rebillInvoicepo =
                    draft.rebillInvoicepo.filter(r => r.identifier !== action.payload);
            case SELECT_REBILL_INVOICE:

                rebillPOList = draft.rebillInvoicepo;
                totalRebillPOAmount = 0;
                totalRebillPOAmountUSD = 0;
                [rebillPOList, totalRebillPOAmount, totalRebillPOAmountUSD, isRebillCurrencyDiff] = Helpers.performRebillCalculation(rebillPOList, action);
                draft.totalRebillPOAmount = totalRebillPOAmount;
                draft.totalRebillPOAmountUSD = totalRebillPOAmountUSD;
                draft.isRebillCurrencyDiff = isRebillCurrencyDiff;
                draft.rebillInvoicepo = rebillPOList;


                break;
            case CLOSE_PO_CONFIRMATION:

                draft.showPOConfirmation = false;
                break;
            case CHANGE_PO_EXPAND:

                let poList = draft.rebillInvoicepo;
                poList.map(row => {
                    if (row.identifier === action.id) {
                        row.expanded = typeof (row.expanded) !== 'undefined' ? !row.expanded : true;
                    }
                    console.log(row);
                });

                draft.rebillInvoicepo = poList;
                break;
        }
    });

export default rebillpoTableReducer;