import { select, takeLatest } from "redux-saga/effects";
import {
    makeSelectAccessToken,
    makeSelectAuthType
} from "../../../../common/app/selectors";
import { submitMTRequestCreate } from "../../../../common/internals/api/requests";
import { sagaFetchHandler } from "../../../../common/internals/api/sagaFetchHandler";
import { makeSelectLeadEnrollmentNumberCreate } from "../../../common/components/multiTenantCreateRequest/selectors";
import { makeSelectBillingDocumentNumber } from "../../../common/components/multiTenantFetchRequest/selectors";
import { submitMTRequestError, submitMTRequestSuccess } from "./actions";
import { SUBMIT_MULTI_TENANT_REQUEST, SUBMIT_MT_ApprovalRequest } from "./constants";
import { makeSelectMultiTenantFormData, makeSelectMTAction, makeSelectRequiredCosmicCaseNumber, makeSelectRequiredTrackingId, makeSelectTicket } from "./selectors";
import {
    submitMTApprovalRequestSuccess,
    submitMTApprovalRequestFailure,
} from "./actions";
import { sumbitApprovalRequestCreate } from "../../../../common/internals/api/requests";

import { makeSelectMTApproverComment, makeSelectMTApproverCallout, makeSelectMTRejectionReasonCode, makeSelectMTRejectionReasonDescripiton, makeSelectMTWorkflow } from "../../../common/components/multiTenantApproverDetails/selectors";

export function* submitRequest() {
    const leadEnrollmentNumber = yield select(makeSelectLeadEnrollmentNumberCreate());
    const multiTenantFormData = yield select(makeSelectMultiTenantFormData());
    console.log(multiTenantFormData);

    var submitMTRequest = submitMTRequestCreate();
    yield* setAuthTokens(submitMTRequest);
    submitMTRequest.url = `api/return/${leadEnrollmentNumber}`;
    submitMTRequest.options.body = JSON.stringify(multiTenantFormData);
    yield* sagaFetchHandler(submitMTRequest, submitMTRequestSuccess, submitMTRequestError);
}


export function* submitMTRequest() {
    var submitMTApprovalRequest = sumbitApprovalRequestCreate();
    yield* setAuthTokens(submitMTApprovalRequest);
    var billingDocNumber = yield select(makeSelectBillingDocumentNumber());
    var approverComment = yield select(makeSelectMTApproverComment());
    var approverCallout = yield select(makeSelectMTApproverCallout());
    var workflow = 0;
    var action = yield select(makeSelectMTAction());
    var rejectionReasonCode = yield select(makeSelectMTRejectionReasonCode());
    var otherRejectionReason = yield select(makeSelectMTRejectionReasonDescripiton());
    var cosmicCaseNumber = yield select(makeSelectRequiredCosmicCaseNumber());
    var trackingId = yield select(makeSelectRequiredTrackingId());
    const savedTickets = yield select(makeSelectTicket());
    submitMTApprovalRequest.url = `api/return/${billingDocNumber}/Approval`;
    submitMTApprovalRequest.options.body = JSON.stringify(
        TransformRequest(
            billingDocNumber,
            approverComment,
            approverCallout,
            rejectionReasonCode,
            otherRejectionReason,
            workflow,
            cosmicCaseNumber,
            trackingId,
            savedTickets,
            action,
        )
    );

    yield* sagaFetchHandler(
        submitMTApprovalRequest,
        submitMTApprovalRequestSuccess,
        submitMTApprovalRequestFailure
    );
}

//#endregion

function TransformRequest(
    billingDocNumber,
    approverComment,
    approverCallout,
    rejectionReasonCode,
    otherRejectionReason,
    workflow,
    cosmicCaseNumber,
    trackingId,
    savedTickets,
    action
) {

    const cosmicCaseNumberList = cosmicCaseNumber ? cosmicCaseNumber?.split(',') : savedTickets?.map(x => x.ticketId);
    const trackingIdList = trackingId?.split(',');
    let tickets = [];
    for (let i = 0; cosmicCaseNumberList && i < cosmicCaseNumberList.length; i++) {
        const ticketId = cosmicCaseNumberList[i];
        const trackId = trackingIdList[i] ?? "";
        if (ticketId) {
            tickets.push({
                "ticketId": ticketId.trim(),
                "type": "approval",
                "crmId": trackId.trim()
            })
        }
    }

    if (window && window.vlcredits.envData.Services.isRejectionReason == "true") {
        var approvalSub = {
            mtrApprover: {
                workflow: workflow,
                approverComment: approverComment,
                approverCallout: approverCallout,
                rejectionReasonCode: rejectionReasonCode,
                otherRejectionReason: otherRejectionReason,
                billingDocumentNbr: billingDocNumber,
                tickets: tickets.length > 0 ? tickets : null,
                RejectionReason: {
                    RejectReason: approverComment,
                    RejectReasonCode: rejectionReasonCode
                }
            },
            action: action
        };
    } else {
        var approvalSub = {
            mtrApprover: {
                workflow: workflow,
                approverComment: approverComment,
                billingDocumentNbr: billingDocNumber,
                tickets: tickets.length > 0 ? tickets : null
            },
            action: action
        };
    }
    return approvalSub;
}

function* setAuthTokens(request) {
    const accessToken = yield select(makeSelectAccessToken());
    const authType = yield select(makeSelectAuthType());
    request.options.headers["Authorization"] = "Bearer " + accessToken;
    request.options.headers["x-ms-AuthType"] = authType;
}

export default function* multiTenantRequest() {
    yield takeLatest(SUBMIT_MULTI_TENANT_REQUEST, submitRequest);
    yield takeLatest(SUBMIT_MT_ApprovalRequest, submitMTRequest);

}

