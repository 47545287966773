import React, { useEffect,memo, useContext } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import reducer from './reducer';
import saga from './saga';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import DropDownBox from '../../../../common/components/controls/DropDown';
import LocalisedLabel from '../../../../common/components/controls/LocalisedLabel';
import TextBox from '../../../../common/components/controls/TextBox';

import { useInjectSaga } from '../../../../common/internals/utils/injectSaga';
import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import DomainDataContext from '../../../../common/context/DomainDataContext';
import {
    FilterCredits, changeRequestIdentifier, changeRequestStatusCode, changeSalesLocationCode, changeOperationsCenterCode, changeBillingDocumentNbr, changePurchaseOrderNumber, changeAgreementNumber, changeEndCustomerNumber, changeCosmicNumber,changeCustomerName, changeProgramCode
} from './actions';
import { makeSelectLoading, makeSelectError, makeSelectRequestId, makeSelectRequestStatus, makeSelectSalesLocation, makeSelectOperationCenter, makeSelectInvoiceNumber, makeSelectPoNumber, makeSelectEnrollmentNumber, makeSelectCustomerNumber, makeSelectCosmicNumber, makeSelectCustomerName ,makeSelectProgramCode, makeSelectDateRange } from './selectors';
import { makeSelectUserInfo } from '../../../../common/app/selectors';
import { makeSelectDomainData } from '../../../../common/app/selectors';
import { requestStatus_Other, requestStatus_VPU, OperationCentersList, ApprovarRequestStatusList, SubCFORequestStatus } from '../../../../common/internals/utils/requestStatusRoleBased';
import * as Helpers from '../../../../common/internals/utils/helpers';
import { isSearchEnhancementEnabled } from '../../../../common/internals/utils/featureFlag';

export function FilterCriteria({ requestId, requestStatus, salesLocation, operationCenter, invoiceNumber, poNumber, enrollmentNumber, customerNumber, cosmicNumber, programCode, dateRange, endCustomerName,
    onChangeRequestIdentifier,
    onChangeRequestStatusCode,
    onChangeSalesLocationCode,
    onChangeOperationsCenterCode,
    onChangeBillingDocumentNbr,
    onChangePurchaseOrderNumber,
    onChangeAgreementNumber,
    onChangeEndCustomerNumber,
    onChangeCosmicNumber,
    onChangeProgramCode,
    onChangeCustomerName,
    onSearchCreditRequestList, onLoadData, userInformation, domainDataInfo, loading,intl }) {
    const myDomainData = useContext(DomainDataContext);
    const { formatMessage } = intl;
    useInjectReducer({ key: 'creditList', reducer });
    useInjectSaga({ key: 'creditList', saga });

    useEffect(() => {
        onLoadData();
    }, []);

    var userRole = "";
    if (userInformation) {
        if (userInformation.roles.length > 0) {
            userRole = userInformation.roles[0];
        }
    }

    const isSearchEnhancementEnabledFlag = isSearchEnhancementEnabled();

    var isApprovar = false, isSubCFO = false;
    window.isApprovar = false;
    window.isSubCFO = false;

    var StatusList = requestStatus_Other;
    if (userRole === "VPU") {
        StatusList = requestStatus_VPU;
    }
    if (userRole === "VSC") { //VSC - SUB CFO
        StatusList = SubCFORequestStatus;
        isSubCFO = true;
        window.isSubCFO = isSubCFO;
    }
    if (userRole === "VFU") {
        window.isFieldUser = true;
    }

    if (window && window.location && window.location.pathname && window.location.pathname.indexOf('/subcfo/search') !== -1) {
        isSubCFO = true;
        StatusList = SubCFORequestStatus;
        window.isSubCFO = isSubCFO;
    }


    if (window && window.location && window.location.pathname && window.location.pathname.indexOf('/approver/') !== -1) {
        isApprovar = true;
        window.isApprovar = true;
        StatusList = ApprovarRequestStatusList;
    }
    var lblSearchText = formatMessage({ id: "lblSearchText" });
    return (
        <div className="row spacer-xs-bottom" >
            <div className="col-sm-24 col-md-24 col-lg-24">
                <div className="row spacer-xs-bottom" >
                    {!isApprovar &&
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <LocalisedLabel htmlFor="txtRequestId" className="form-label" ariaLabel="Request ID" id="lblRequestId" value="Request ID" />
                            <TextBox type="text" className="form-control mainElement" id="txtRequestId" value={requestId} onChange={onChangeRequestIdentifier} />
                        </div>
                    }
                    {isApprovar &&
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <LocalisedLabel htmlFor="txtApprovalId" className="form-label" ariaLabel="Approval ID" id="lblApprovalId" value="Approval ID" />
                            <TextBox type="text" className="form-control mainElement" id="txtApprovalId" value={requestId} onChange={onChangeRequestIdentifier} />
                        </div>
                    }
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <LocalisedLabel className="form-label" htmlFor="ddlRequestStatues" id="lblRequestStatues" ariaLabel="Request Status" value="Request Status" />
                        <DropDownBox id="ddlRequestStatues" ariaLabel="Request Status" inputList={StatusList} includeAll="true" value={requestStatus} onChange={onChangeRequestStatusCode} />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <LocalisedLabel htmlFor="txtInvoiceNumber" className="form-label" id="lblInvoiceNumber" />
                        <TextBox type="text" className="form-control" id="txtInvoiceNumber" value={invoiceNumber} onChange={onChangeBillingDocumentNbr} />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <LocalisedLabel htmlFor="txtEnrolmentNumber" className="form-label" id="lblEnrolmentNumber" />
                        <TextBox type="text" className="form-control" id="txtEnrolmentNumber" value={enrollmentNumber} onChange={onChangeAgreementNumber} />
                    </div>

                </div>
                <div className="row spacer-xs-bottom" >

                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <LocalisedLabel className="form-label" htmlFor="ddlSalesLocations" id="lblCountry" ariaLabel="Country" value="Country" />
                        <DropDownBox id="ddlSalesLocations" ariaLabel="Country" inputList={Helpers.GetCountryListForDropDown(myDomainData)} includeAll="true" value={Helpers.GetCountryListForDropDown(myDomainData)} onChange={onChangeSalesLocationCode} />
                    </div>

                    {!isSubCFO &&
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <LocalisedLabel className="form-label" htmlFor="ddlOperationCenters" id="lblOperationCenters" ariaLabel="Operation Center" value="Operation Center" />
                            <DropDownBox id="ddlOperationCenters" ariaLabel="Operation Center" inputList={OperationCentersList} includeAll="true" value={operationCenter} onChange={onChangeOperationsCenterCode}  />
                        </div>
                    }
                    {!isSubCFO &&
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <LocalisedLabel htmlFor="txtPONumber" className="form-label" id="lblPONumber" />
                            <TextBox type="text" className="form-control" id="txtPONumber" value={poNumber} onChange={onChangePurchaseOrderNumber} />
                        </div>
                    }
                </div>

                {isSearchEnhancementEnabledFlag === "True" ?
                    <div className="row spacer-xs-bottom" >
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <LocalisedLabel htmlFor="txtProgramCode" className="form-label" id="lblProgramCode" />
                            <DropDownBox id="ddlProgramCode" ariaLabel="Program Code" inputList={Helpers.GetProgramCodesForDropDown(myDomainData)} includeAll="true" value={Helpers.GetProgramCodesForDropDown(myDomainData)} onChange={onChangeProgramCode} />
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <LocalisedLabel htmlFor="txtCosmicNumber" className="form-label" id="lblCosmicNumber" />
                            <TextBox type="text" className="form-control" id="txtCosmicNumber" value={cosmicNumber} onChange={onChangeCosmicNumber} />
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <LocalisedLabel htmlFor="txtCustomerName" className="form-label" id="lblCustomerName" />
                            <TextBox type="text" className="form-control" id="txtCustomerName" value={endCustomerName} onChange={onChangeCustomerName} />
                        </div>
                    </div>
                    :
                    ""
                }
                <div className="row spacer-xs-bottom pull-right" >
                    <div className="col-sm-24 col-md-24 col-lg-24 ">
                        <div className="btn-group">
                            <button id="btnSearch" className="btn btn-default active" type="submit" aria-label={lblSearchText} onClick={onSearchCreditRequestList}>{lblSearchText}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

FilterCriteria.propTypes = {
    customerNumber: PropTypes.string,
    cosmicNumber: PropTypes.string,
    endCustomerName: PropTypes.string,
    programCode: PropTypes.string,
    dateRange: PropTypes.string,
    enrollmentNumber: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    invoiceNumber: PropTypes.string,
    loading: PropTypes.bool,
    operationCenter: PropTypes.string,
    poNumber: PropTypes.string,
    requestId: PropTypes.string,
    requestStatus: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
    salesLocation: PropTypes.string,
    userInformation: PropTypes.object,
    domainDataInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChangeAgreementNumber: PropTypes.func,
    onChangeBillingDocumentNbr: PropTypes.func,
    onChangeEndCustomerNumber: PropTypes.func,
    onChangeCosmicNumber: PropTypes.func,
    onChangeCustomerName: PropTypes.func,
    onChangeProgramCode: PropTypes.func,
    onChangeOperationsCenterCode: PropTypes.func,
    onChangePurchaseOrderNumber: PropTypes.func,
    onChangeRequestIdentifier: PropTypes.func,
    onChangeRequestStatusCode: PropTypes.func,
    onChangeSalesLocationCode: PropTypes.func,
    onSearchCreditRequestList: PropTypes.func,
    onLoadData: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    error: makeSelectError(),
    requestId: makeSelectRequestId(),
    requestStatus: makeSelectRequestStatus(),
    salesLocation: makeSelectSalesLocation(),
    operationCenter: makeSelectOperationCenter(),
    invoiceNumber: makeSelectInvoiceNumber(),
    poNumber: makeSelectPoNumber(),
    enrollmentNumber: makeSelectEnrollmentNumber(),
    customerNumber: makeSelectCustomerNumber(),
    cosmicNumber: makeSelectCosmicNumber(),
    endCustomerName: makeSelectCustomerName(),
    programCode: makeSelectProgramCode(),
    userInformation: makeSelectUserInfo(),
    domainDataInfo: makeSelectDomainData()
});

function mapDispatchToProps(dispatch) {
    return {
        onLoadData: evt => {
            if (window.isSubCFO) {
                var statusList = [];
                Object.keys(SubCFORequestStatus).forEach(e => {
                    if (SubCFORequestStatus[e] && SubCFORequestStatus[e].key) {
                        var _split = SubCFORequestStatus[e].key.split(",");
                        if (_split && _split.length > 1) {
                            _split.forEach(x => {
                                statusList.push(x);
                            });
                        } else {
                            statusList.push(SubCFORequestStatus[e].key);
                        }
                    }

                });
                dispatch(changeRequestStatusCode(statusList));
            }
        },
        onChangeRequestIdentifier: evt => dispatch(changeRequestIdentifier(evt.target.value)),
        onChangeRequestStatusCode: evt => {
            if (window.isSubCFO) {
                if (evt.target.value === "-1") {
                    var statusList = [];
                    Object.keys(SubCFORequestStatus).forEach(e => {
                        if (SubCFORequestStatus[e] && SubCFORequestStatus[e].key) {
                            var _split = SubCFORequestStatus[e].key.split(",");
                            if (_split && _split.length > 1) {
                                _split.forEach(x => {
                                    statusList.push(x);
                                });
                            } else {
                                statusList.push(SubCFORequestStatus[e].key);
                            }
                        }

                    });
                    dispatch(changeRequestStatusCode(statusList));
                } else {
                    dispatch(changeRequestStatusCode(evt.target.value.split(',')));
                }
            } else {
                dispatch(changeRequestStatusCode(evt.target.value.split(',')));
            }
        },
        onChangeSalesLocationCode: evt => dispatch(changeSalesLocationCode(evt.target.value)),
        onChangeOperationsCenterCode: evt => dispatch(changeOperationsCenterCode(evt.target.value)),
        onChangeBillingDocumentNbr: evt => dispatch(changeBillingDocumentNbr(evt.target.value)),
        onChangePurchaseOrderNumber: evt => dispatch(changePurchaseOrderNumber(evt.target.value)),
        onChangeAgreementNumber: evt => dispatch(changeAgreementNumber(evt.target.value)),
        onChangeEndCustomerNumber: evt => dispatch(changeEndCustomerNumber(evt.target.value)),
        onChangeCosmicNumber: evt => dispatch(changeCosmicNumber(evt.target.value)),
        onChangeCustomerName: evt => dispatch(changeCustomerName(evt.target.value)),
        onChangeProgramCode: evt => dispatch(changeProgramCode(evt.target.value)),
        onSearchCreditRequestList: evt => {
            dispatch(FilterCredits(window.isSubCFO));
        }
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, memo)(injectIntl(FilterCriteria));



