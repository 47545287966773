import React, { memo } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { makeSelectUserInfo } from "../../app/selectors";
import '../../app/accessibility-style.css';
import { isReadonlyVersion } from "../../internals/utils/helpers";


export function isMtEnabledForUser(userInfo) {

    const userRole = window && window.userRole;

    if (userRole === "VPU") {

        let isMTEnabledForAllPartners = window.vlcredits.envData.Services.isMTEnabledForAllPartners;

        isMTEnabledForAllPartners = isMTEnabledForAllPartners ? isMTEnabledForAllPartners : "True";

        if (isMTEnabledForAllPartners === "True") {
            return true;
        }

        var mtWhiteListedPartnerEmail = window.vlcredits.envData.Services.MTWhitelistedPartnerAccounts.split(",");

        return mtWhiteListedPartnerEmail.length > 0
            && mtWhiteListedPartnerEmail.find(email => email === userInfo.email);
    } else {

        let isMTEnabledForAllOPs = window.vlcredits.envData.Services.isMTEnabledForAllOPs;

        isMTEnabledForAllOPs = isMTEnabledForAllOPs ? isMTEnabledForAllOPs : "True";

        if (isMTEnabledForAllOPs === "True") {
            return true;
        }

        var mtWhiteListedOpsEmail = window.vlcredits.envData.Services.MTWhitelistedOPsAccounts.split(",");

        return mtWhiteListedOpsEmail.length > 0
            && mtWhiteListedOpsEmail.find(email => email === userInfo.email);
    }
};

function MultiTenantMenu(props) {
    //constants
    const { formatMessage } = props.intl;
    var lblMultiTenantReallocation = formatMessage({
        id: "lblMultiTenantReallocation",
    });
    var lblReallocateLicenses = formatMessage({ id: "lblReallocateLicenses" });
    var lblSearchText = formatMessage({ id: "lblSearchText" })
    const userRole = window && window.userRole;

    var _createLink = userRole === "VPU" ? "partner/multitenant/create" : "/multitenant/create";
    var _searchLink = userRole === "VPU" ? "partner/multitenant/search" : "/multitenant/search";

    //return elements
    return (
        <div>
            <div>
                <div
                    id="tenant-reallocation-name"
                    className="onedash-accountpicker-account is-visible"
                >
                    <span
                        className="activeAccount activeAccountTitle"
                        aria-label={lblMultiTenantReallocation}
                    >
                        {lblMultiTenantReallocation}
                    </span>
                </div>
                <div
                    id="onedash-internaltools-menu-title-2"
                    className="onedash-navigation-menu-title is-active"
                >
                    <ul role="menu">
                        {!isReadonlyVersion() && <li className="onedash-navigation-menu-item" role="none">
                            <a href={_createLink} id="linkMultiTenantCreate" role="menuitem">
                                <div
                                    className="onedash-navigation-category"
                                    aria-label={lblMultiTenantReallocation + lblReallocateLicenses}
                                >
                                    {lblReallocateLicenses}
                                </div>
                            </a>
                        </li>}
                        <li className="onedash-navigation-menu-item" role="none">
                            <a href={_searchLink} id="linkMultiTenantSearch" role="menuitem">
                                <div
                                    className="onedash-navigation-category"
                                    aria-label={lblMultiTenantReallocation + lblSearchText}
                                >
                                    {lblSearchText}
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = createStructuredSelector(
    { userInfo: makeSelectUserInfo() }
);

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect, memo)(injectIntl(MultiTenantMenu));
