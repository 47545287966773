import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectReturnDetailsDomain = state => state.returnDetails || initialState;
const selectInvoicesDetailsDomain = state => state.invoicesDetails || initialState;

const makeSelectIsBDApproved = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.isBdApproved,
    );

const makeSelectIsFullCredit = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.isFullCredit,
    );

const makeSelectReturnReason = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.returnReason,
    );

const makeSelectBillToCountryCode = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.billToCountryCode,
    );

const makeSelectHandlingFeePercentage = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.handlingFeePercentage,
    );

const makeSelectinvoicesDetails = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.invoicesDetails,
    );

const makeSelectReturnDetails = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate,
    );

const makeSelectNetCreditAmount = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.netCreditAmount,
    );

const makeSelectNetCreditAmountLocal = () =>
    
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.netCreditAmountLocal,
    );

const makeSelectGrossCreditAmount = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.grossCredit,
    );

const makeSelectGrossCreditAmountLocal = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.grossCreditLocal,
    );

const makeSelectGrossCreditAmountFromInvoices = () =>
    createSelector(
        selectInvoicesDetailsDomain,
        substate => substate.grossCredit,
    );

const makeSelectGrossCreditAmountLocalFromInvoices = () =>
    createSelector(
        selectInvoicesDetailsDomain,
        substate => substate.grossCreditLocal,
    );

const makeSelectoperationsCenterCode = () =>
    
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.alternateROC,
    );

const makeSelectOldoperationsCenterCode = () =>

    createSelector(
        selectReturnDetailsDomain,
        substate => substate.operationsCenterCode,
    );

const makeSelectrocValueChanged = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.rocValueChanged,
    );

const makeSelectProgramCode = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.programCode,
    );

const makeSelectIsCurrencyDiff = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.isCurrencyDiff,
    );

const makeSelectProposalId = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.proposalId,
    );


const makeSelectIsOptOut = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.isOptOut,
    );

export default makeSelectReturnDetails;
export {
    selectReturnDetailsDomain,
    makeSelectIsBDApproved,
    makeSelectIsFullCredit,
    makeSelectHandlingFeePercentage,
    makeSelectReturnReason,
    makeSelectNetCreditAmount,
    makeSelectNetCreditAmountLocal,
    makeSelectBillToCountryCode,
    makeSelectGrossCreditAmount,
    makeSelectGrossCreditAmountLocal,
    makeSelectGrossCreditAmountFromInvoices,
    makeSelectGrossCreditAmountLocalFromInvoices,
    makeSelectinvoicesDetails,
    makeSelectoperationsCenterCode,
    makeSelectrocValueChanged,
    makeSelectOldoperationsCenterCode,
    makeSelectProgramCode,
    makeSelectIsCurrencyDiff,
    makeSelectProposalId,
    makeSelectIsOptOut
};