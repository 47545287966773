import { defineMessages } from 'react-intl';

export const scope = 'approvalInfo';

export default defineMessages({
  approvalInfoMessage: {
    id: `${scope}.approvalInfoMessage`,
    defaultMessage: 'Reason for Readonly Screen',
    },
    rocInfoMessage: {
        id: `${scope}.rocInfoMessage`,
        defaultMessage: 'ROC Override Update',
    }
});
