import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { injectIntl } from 'react-intl';
import Announcer from 'react-a11y-announcer';
import $ from 'jquery';
export class GridControl extends Component {

    constructor(props) {

        super(props);
        this.readOnlyPfams = this.readOnlyPfams.bind(this);
        this.insertPfamsRow = this.insertPfamsRow.bind(this);
    }
    componentDidMount() {               
        $('#tblCreditsList').find('thead > tr > th').each(function (index, item) {
            $(item).attr("tabindex", "-1");
        });
        this.handleDataChange = this.handleDataChange.bind(this);
        if(window && this.props && window.vlcredits.FeatureName == "FBC" && this.props.isPfamEnable == true){
            window.addEventListener('load', this.readOnlyPfams());
        }
    }
    componentDidUpdate(prevProps){
        if(window && this.props && window.vlcredits.FeatureName == "FBC" && this.props.isPfamEnable == true){
        window.addEventListener('load', this.readOnlyPfams());
        }
    }
    handleDataChange = ({ dataSize }) => {
        $('#tblCreditsList').attr("aria-rowcount", dataSize);
    }

    readOnlyPfams() {
        var tableElement = document.getElementById('tblCreditsList');
        var data = this.props.data ? this.props.data : [];
        var  userRole = window && window.userRole;
        if(tableElement && data){
            var existingPfamsRow = document.getElementsByClassName("lineItem_readonly_pfams_");
            while(existingPfamsRow.length > 0){
                tableElement.deleteRow(existingPfamsRow[0].rowIndex);
            }
            data.forEach(item => {
                if(item.isMatchedPFAM == true){
                    var currentTableRow = document.querySelector(".line_item"+item.uniqueId);
            if(currentTableRow){
                var errorIndex = parseInt(currentTableRow.rowIndex) + 1;
                var message = (userRole == 'VPU') ? " This SKU will be reviewed against Product Term and/or PSF" : "As per product terms and/or PSF, this PFAM  SKU has restrictions to be reviewed before the reduction is allowed in Product Term and/or PSF.";
                this.insertPfamsRow(item,message,currentTableRow.cells.length,errorIndex);
            }
                }
            });
        }
           
        };

        insertPfamsRow(row,message, colspan, index) {
                var tableElement = document.getElementById('tblCreditsList');
                var tableNewRow = document.getElementById("lineItem_readonly_pfams_"+row.uniqueId);
                if (!tableNewRow) {
                    tableNewRow = tableElement.insertRow(index);
                    tableNewRow.setAttribute('id', "lineItem_readonly_pfams_"+row.uniqueId);
                    tableNewRow.setAttribute('class', "lineItem_readonly_pfams_");
                    tableNewRow.setAttribute('style', 'border-top: none');
                    tableNewRow.setAttribute('aria-live', "assertive");
                }
    
                var tableNewCell = document.getElementById("lineItemPfamReadonlyCell"+row.uniqueId);
                if (!tableNewCell) {
                    tableNewCell = document.createElement('td');
                    tableNewCell.setAttribute('id', "lineItemPfamReadonlyCell"+row.uniqueId);
                    tableNewCell.setAttribute('colspan', colspan);
                }
    
                var divElement = document.getElementById("dvPfamReadonlyMessage"+row.uniqueId);
                if (!divElement) {
                    divElement = document.createElement('div');
                    divElement.setAttribute('id', "dvPfamReadonlyMessage"+row.uniqueId);
                    //divElement.setAttribute('class', "alert alert-error");
                    divElement.setAttribute('style', 'color: #006cd8;font-weight: bold');
                    divElement.setAttribute('aria-live', "assertive");
                    divElement.innerHTML = message;
                    tableNewCell.appendChild(divElement);
                    tableNewRow.appendChild(tableNewCell);
                } else {
                    divElement.innerHTML = message;
                }
        }

    render() {

        const { formatMessage } = this.props.intl;
        var _msg = formatMessage({ id: "grdNoRecords" });
        const NoRecordsFound = <div aria-label={_msg} aria-live="polite">{_msg}</div>;

        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            //alwaysShowAllBtns: true, // Always show next and previous button            
            hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: formatMessage({ id: "grdPaginationFirstText" }),
            prePageText: formatMessage({ id: 'grdPaginationBackText' }),
            nextPageText: formatMessage({ id: 'grdPaginationNextText' }),
            lastPageText: formatMessage({ id: 'grdPaginationLastText' }),
            nextPageTitle: formatMessage({ id: 'grdPaginationNextPageText' }),
            prePageTitle: formatMessage({ id: 'grdPaginationPrevPageText' }),
            firstPageTitle: formatMessage({ id: 'grdPaginationFirstPageText' }),
            lastPageTitle: formatMessage({ id: 'grdPaginationLastPageText' }),
            showTotal: false,
            sizePerPageList: [{
                text: '25', value: 25
            }, {
                text: '50', value: 50
            }, {
                text: formatMessage({ id: 'grdPaginationAllText' }), value: this.props.data.length
            }]
        };

        const tableCaption = <div style={{ "display": "none" }}>{formatMessage({ id: 'tblTitle' })}</div>;
        const tableTitle = this.props.title ? this.props.title:  formatMessage({ id: 'tblTitle' });
        const announerMsg = this.props.data.length > 0 ? "Search Result found" : _msg;
        $('#tblCreditsList').attr("aria-label", tableTitle);
        //$("#tblCreditsList").attr("style", "overflow-x: auto; display: block");
        //$(".table").attr("style", "overflow-x: auto; display: block");
        $(".react-bootstrap-table").attr("style", "overflow-x: auto");
        const { SearchBar } = Search;
        const rowClasses = (row, rowIndex) => {
            let classes = null;
              classes = 'line_item'+row.uniqueId;
          
            return classes;
          };
        const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div aria-label={tableTitle}>
                <ToolkitProvider
                    keyField="uniqueId"
                    columns={this.props.columns}
                    data={this.props.data}                   
                    search={{
                        searchFormatted: true
                    }}
                    aria-label={tableTitle}
                >
                    {
                        toolkitprops => (
                            <div arial-label="Search results found">
                               <BootstrapTable
                                    id="tblCreditsList"
                                    onDataSizeChange={this.handleDataChange}
                                    caption={tableCaption}
                                    role="table"
                                    loading="Loading"
                                    aria-label={tableTitle}
                                    aria-live="polite"
                                    hover
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    condensed
                                    noDataIndication={NoRecordsFound}
                                    rowClasses= {rowClasses}
                                />
                                <div className="pull-right">
                                    <PaginationListStandalone {...paginationProps} />
                                </div>
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
        );


        return (
            <div className="col-sm-24 col-md-24 col-lg-24" aria-live="polite">
                <Announcer text={announerMsg}/>
                <div aria-label='Search Results found' aria-live="assertive">
                    <PaginationProvider pagination={paginationFactory(options)}>
                        {contentTable}
                    </PaginationProvider>
                </div>
            </div>
        );

    }
}
export default injectIntl(GridControl);
