import { defineMessages } from 'react-intl';

export const scope = 'fileUpload';

export default defineMessages({
    attachmentsSectionHeader: {
        id: `${scope}.attachmentsSectionHeader`,
        defaultMessage: 'Attachments',
    },
    attachmentsFileName: {
        id: `${scope}.attachmentsFileName`,
        defaultMessage: 'FileName',
    },
    attachmentsDocumentType: {
        id: `${scope}.attachmentsDocumentType`,
        defaultMessage: 'DocumentType',
    },
    attachmentsAction: {
        id: `${scope}.attachmentsAction`,
        defaultMessage: 'Action',
    }
});
