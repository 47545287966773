import produce from 'immer';
import { ON_CHECKBOX_ACTION } from '../../components/poSelectionTable/constants';
import { ON_PO_REMOVAL } from '../../components/reallocationDetailsTable/constants';
import { SEARCH_TENANT_SUCCESS, SELECT_PO, UPDATE_PO_LIST, UPDATE_SELECTED_POS } from '../multiTenantSearchAndAdd/constants';
import { ON_ADD_SELECTED_PO, ON_PO_SELECTION_MODAL_CLOSE } from './constants';

export const initialState = { isPOSelectionModal: false, poList: [] };

const poSelectionModalReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case ON_PO_SELECTION_MODAL_CLOSE:
                draft.poList = [];
                draft.poNumber = null;
                draft.isPOSelectionModal = false;
                break;
            case ON_ADD_SELECTED_PO:
                draft.isPOSelectionModal = false;
                break;
            case UPDATE_SELECTED_POS:
                draft.poList = [];
                draft.poNumber = null;
                break;
            case UPDATE_PO_LIST:

                draft.purchaseOrdersList = [];
                for (let i = 0; i < action.unchecked.length; i++) {
                    draft.purchaseOrdersList.push(action.unchecked[i]);
                }
                break;

            case SELECT_PO:
                draft.isPOSelectionModal = true;
                draft.poNumber = action.selectedPO.value;
                draft.purchaseOrdersList.filter(x => x.purchaseOrderNumber === action.selectedPO.value).map(
                    data =>
                        draft.poList.push({
                            purchaseOrderNumber: data.purchaseOrderNumber,
                            purchaseOrderID: data.purchaseOrderID,
                            createdDate: data.createdDate,
                            purchaseOrderTypeCode: data.purchaseOrderTypeCode,
                            externalIdentifier: data.externalIdentifier,
                            systemPONumber: data.systemPONumber,
                            isSelected: false
                        })
                )
                break;
            case ON_CHECKBOX_ACTION:
                let poListUpdated = [];
                draft.poList.map(
                    data =>
                        poListUpdated.push({
                            purchaseOrderNumber: data.purchaseOrderNumber,
                            purchaseOrderID: data.purchaseOrderID,
                            createdDate: data.createdDate,
                            purchaseOrderTypeCode: data.purchaseOrderTypeCode,
                            externalIdentifier: data.externalIdentifier,
                            isSelected: (data.externalIdentifier === action.data) ? action.checked : data.isSelected,
                            systemPONumber: data.systemPONumber
                        })
                )
                draft.poList = poListUpdated;
                break;

            case SEARCH_TENANT_SUCCESS:
                draft.purchaseOrdersList = action.purchaseOrdersList;
                draft.purchaseOrdersListCopy = draft.purchaseOrdersList;
                break;

            case ON_PO_REMOVAL:
                draft.purchaseOrdersListCopy.map(item => {
                    if (item.externalIdentifier === action.data)
                        draft.purchaseOrdersList.push(item);

                });
                break;

        }
    });

export default poSelectionModalReducer;
