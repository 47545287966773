import produce from "immer";
import { FBC_COLLAPSE_ALL_SECTIONS, FBC_EXPAND_ALL_SECTIONS, FBC_GET_PO_DETAILS, FBC_GET_PO_SUCCESS, FBC_GET_PO_ERROR } from "./constants";
import { FBC_SEARCH_WITH_POAA_SUCCESS } from '../../../common/components/cancelFutureBillingCreateRequest/constants';

export const initialState = {};

const CancelFutureBillingRequestReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FBC_COLLAPSE_ALL_SECTIONS:
        break;
        case FBC_EXPAND_ALL_SECTIONS:
        break;
        case FBC_GET_PO_DETAILS:
                draft.podetails = {};
                draft.loading = true;
                draft.error = false;
                break;
        case FBC_GET_PO_SUCCESS:
                draft.podetails = action.invoices;
                draft.loading = false;
                draft.error = false;
                break;
            case FBC_GET_PO_ERROR:
                draft.podetails = {};
                draft.error = action.error;
                draft.loading = false;
                break;
                case FBC_SEARCH_WITH_POAA_SUCCESS:
                draft.error = false;
                break;
    }
  });

export default CancelFutureBillingRequestReducer;
