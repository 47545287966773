import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the submitErrors state domain
 */
const selectapprovalErrorsDomain = state => state.approvalErrors || initialState;

const makeSelectCreateRequestError = () =>
    createSelector(selectapprovalErrorsDomain, substate => substate.error);

const makeSelectApprovalErrors = () =>
  createSelector(
    selectapprovalErrorsDomain,
    substate => substate,
  );

export default makeSelectApprovalErrors;
export { selectapprovalErrorsDomain, makeSelectCreateRequestError };
