import produce from 'immer';
import { RESET_TO_INITIAL_STATE } from '../multiTenantSearchAndAdd/constants';
import { CURRENT_REALLOCATION_MODAL_DETAILS_CHANGED } from '../reallocationBody/constants';
import { DISABLE_ADD_MORE, ENABLE_ADD_MORE, PART_NUMBER_DELETED, PART_NUMBER_FOCUSED, UPDATE_PART_NUMBER_DETAILS } from './constants';

export const initialState = { partNumberList: [], isAddMoreEnabled: true, partNumberFocused: "null", previousPartNumber: "null", isAddMoreDisabled: false, announcerText: "" };

const partNumberMenuReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case CURRENT_REALLOCATION_MODAL_DETAILS_CHANGED:
                if (draft.partNumberList && draft.partNumberList.length > 0) {
                    if (draft.previousPartNumber == "null") {
                        draft.partNumberList.push(action.value)
                    } else {
                        for (let partNumber in draft.partNumberList) {
                            if (draft.partNumberList[partNumber].label == draft.previousPartNumber.label) {
                                draft.partNumberList[partNumber] = action.value;
                            }
                        }
                    }
                } else {
                    draft.partNumberList = new Array(action.value);
                }
                draft.isAddMoreEnabled = false;
                draft.previousPartNumber = action.value;
                break;
            case PART_NUMBER_FOCUSED:
                draft.partNumberFocused = action.value;
                draft.previousPartNumber = action.value;
                draft.isAddMoreEnabled = false;
                break;
            case ENABLE_ADD_MORE:
                draft.isAddMoreEnabled = true;
                draft.partNumberFocused = null;
                draft.previousPartNumber = "null";
                break;
            case PART_NUMBER_DELETED:
                let updatedPartNumberList = draft.partNumberList.filter(item => item.value !== action.value.value)
                draft.partNumberList = updatedPartNumberList;
                draft.partNumberFocused = "null";
                draft.announcerText = `Part Number ${action.value.label} deleted`
                break;
            case RESET_TO_INITIAL_STATE:
                draft.partNumberList = [];
                draft.isAddMoreEnabled = true;
                draft.partNumberFocused = "null";
                draft.previousPartNumber = "null";
                draft.announcerText = "";
                break;
            case UPDATE_PART_NUMBER_DETAILS:
                draft.partNumberList = action.partNumberList;
                draft.isAddMoreEnabled = false;
                break;
            case DISABLE_ADD_MORE:
                draft.isAddMoreDisabled = action.value.disableAddMore;

                if (action.value.fromDetails && !draft.invalidPOs.includes(action.value.invalidPO)) {
                    draft.invalidPOs.push(action.value.invalidPO);
                } else if (!action.value.fromDetails) {
                    draft.invalidPOs = action.value.invalidPOs;
                }
                break;
        }
    });

export default partNumberMenuReducer;
