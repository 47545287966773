import produce from "immer";
import { USER_ROLES_FETCHED } from "./../../../../common/app/constants";
import {
    MULTI_TENANT_CHANGE_COSMIC_CASE_NUMBER, 
    MULTI_TENANT_CHANGE_LEAD_ENROLLMENT_NUMBER,
    MULTI_TENANT_CHANGE_TRACKING_ID, 
    MULTI_TENANT_CLEAR_LEAD_ENROLLMENT_NUMBER, 
    MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER, 
    MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER_ERROR, 
    MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER_SUCCESS, 
    RESET_TO_DEFAULT
} from "./constants";

export const initialState = { isEnrollmentDetailsLoaded: false };

const MultiTenantCreateRequestReducer = (state = initialState, action) =>
    produce(state, draft => {
       
        switch (action.type) {

            case MULTI_TENANT_CHANGE_LEAD_ENROLLMENT_NUMBER:
                draft.leadEnrollmentNumber = action.leadEnrollmentNumber;
                break;
            case MULTI_TENANT_CHANGE_COSMIC_CASE_NUMBER:
                draft.cosmicCaseNumber = action.cosmicCaseNumber;
                break;
            case MULTI_TENANT_CHANGE_TRACKING_ID:
                draft.trackingId = action.trackingId;
                break;
            case MULTI_TENANT_CLEAR_LEAD_ENROLLMENT_NUMBER:
                draft.leadEnrollmentNumber = "";
                draft.loading = false;
                draft.error = false;
                break;
            case MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER_SUCCESS:
                draft.loading = false;
                if(!action.agreementDetails.tenantAgreements ||
                    action.agreementDetails.tenantAgreements.length < 2) {
                    draft.error = {
                        errorMessages: {
                            errors: [{
                                defaultMessage: "Lead enrollment not found. Please enter valid Lead agreement.",
                                id: "errorMessages.21068"
                            }]
                        }
                    }
                    break;
                }
                draft.activeParticipants = action.agreementDetails.activeParticipants;
                draft.tenantAgreements = action.agreementDetails.tenantAgreements;
                draft.isEnrollmentDetailsLoaded = true;
                draft.error = false;

                break;
            case MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER_ERROR:
                draft.error = action.error;
                draft.loading = false;
                break;

            case MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER:
                draft.isEnrollmentDetailsLoaded = false;
                draft.loading = true;
                draft.error = false;
                break;
            case MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER:
                draft.isEnrollmentDetailsLoaded = false;
                draft.loading = true;
                draft.error = false;
                break;
            case USER_ROLES_FETCHED:
                draft.role = action.userInfo.roles;
                break;
            case RESET_TO_DEFAULT:
                draft.cosmicCaseNumber = '';
                draft.trackingId = '';
           
        }
    });

export default MultiTenantCreateRequestReducer;
