import { defineMessages } from 'react-intl';

export const scope = 'MultiTenantHome';

export default defineMessages({
    submitSuccess: {
        id: `${scope}.submitSuccess`,
        defaultMessage: 'Request Submitted Successfully! Request Id {reqId}',
    },
    closeAction: {
        id: `${scope}.closeAction`,
        defaultMessage: 'Close',
    },
    continueAction: {
        id: `${scope}.continueAction`,
        defaultMessage: 'Continue',
    },
    goBackAction: {
        id: `${scope}.goBackAction`,
        defaultMessage: 'Go Back',
    },
    submitConfirmation: {
        id: `${scope}.submitConfirmation`,
        defaultMessage: 'Submit Confirmation',
    },
    reallocateLicenses: {
        id: `${scope}.reallocateLicenses`,
        defaultMessage: 'Reallocate Licenses',
    },
    submitModalConfirmationROC: {
        id: `${scope}.submitModalConfirmationROC`,
        defaultMessage: 'Please confirm to submit the reallocation request',
    },
    submitModalConfirmationPartner: {
        id: `${scope}.submitModalConfirmationPartner`,
        defaultMessage: 'Please note SEZ exempt details are required to be uploaded for India orders to get the tax benefit (if applicable).Lack of sufficient documents will lead to invoicing as per existing tax without exemption.Please confirm to submit the reallocation request else "Close" to upload the SEZ details.',
    },
    submitModalConfirmation: {
        id: `${scope}.submitModalConfirmation`,
        defaultMessage: 'Licenses Reallocated will be Reduced from the Source Enrollment through a credit and new POs will be created for Target Tenant Enrolments with reallocated quantity.',
    },
    noMessage: {
        id: `${scope}.noMessage`,
        defaultMessage: ' '

    },
    submitModalMessage: {
        id: `${scope}.submitModalMessage`,
        defaultMessage: 'No reallocation data to process the submit request',
    },


});
