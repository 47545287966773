import { ON_REALLOCATION_MODAL_ERROR, ON_REALLOCATION_MODAL_CLOSE } from './constants';

export function onReallocationModalClose() {
    return {
        type: ON_REALLOCATION_MODAL_CLOSE
    };
}

export function onReallocationModalError(value) {
    return {
        type: ON_REALLOCATION_MODAL_ERROR,
        value
    };
}
