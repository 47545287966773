import {
    GET_DOMAIN_DATA, DOMAIN_DATA_FETCHED, DOMAIN_DATA_ERROR,
    USER_ROLES, USER_ROLES_FETCHED, USER_ROLES_ERROR,
    ACCESSTOKEN, AUTHTYPE, FETCH_ACCESSTOKEN, FETCH_AUTHTYPE,
    USER_PREFS, USER_PREFS_FETCHED, USER_PREFS_ERROR
} from './constants';

export function getDomainData(domainDataResources) {
    return {
        type: GET_DOMAIN_DATA,
        payload: domainDataResources
    }
}

export function domainDataFetched(domainData) {
    return {
        type: DOMAIN_DATA_FETCHED,
        domainData
    };
}

export function domainDataError(error) {
    return {
        type: DOMAIN_DATA_ERROR,
        error
    };
}


export function getUserRoles() {
    return {
        type: USER_ROLES        
    }
}

export function userRolesFetched(userInfo) {
    
    return {
        type: USER_ROLES_FETCHED,
        userInfo
    };
}

export function userRolesError(error) {   
    return {
        type: USER_ROLES_ERROR,
        error
    };
}

export function getUserPref(accessorGuid) {
    return {
        type: USER_PREFS,
        payload: accessorGuid
    }
}

export function userPrefsFetched(userPrefs) {   
    
    return {
        type: USER_PREFS_FETCHED,
        userPrefs
    };
}

export function userPrefError(error) {
    return {
        type: USER_PREFS_ERROR,
        error
    };
}

export function setAccessToken(accessToken) {
    return {
        type: ACCESSTOKEN,
        accessToken: accessToken
    }
}

export function setAuthType(authType) {
    return {
        type: AUTHTYPE,
        authType: authType
    }
}

export function getAccessToken(accessToken) {    
    return {
        type: FETCH_ACCESSTOKEN,
        accessToken
    }
}

export function getAuthType(authType) {
    return {
        type: FETCH_AUTHTYPE,
        authType
    }
}
