import React from 'react';
import { injectIntl } from 'react-intl';
export function ResourceNotFound(props) {  
    
    return <div className="container" style={{ marginTop: '300px', marginBottom: '300px' }}>
        <div className="row">
            <div className="text-center">
                <i className="win-icon win-icon-Warning" style={{ fontSize: '60px',
    color: '#ffd400'}}></i>
                <h2> The resource you are looking for has been moved or not available now. </h2>    
            </div>
        </div>
    </div>;
}
export default injectIntl(ResourceNotFound);
