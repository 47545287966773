import produce from 'immer';
import { RESET_TO_INITIAL_STATE } from '../../containers/multiTenantSearchAndAdd/constants';
import { ENABLE_ADD_MORE, PART_NUMBER_DELETED } from '../../containers/partNumberMenu/constants';
import { CURRENT_REALLOCATION_MODAL_DETAILS_CHANGED } from '../../containers/reallocationBody/constants';
import { ADD_FORMFIELD, CHANGE_MODAL_TABLE_ONPARTNUMBERFOCUS, CHANGE_REMAINING_QTY, REALLOCATION_MODAL_DETAILS_CHANGED } from './constants.js';

export const initialState = { mtModalTableDetails: [{ enrolmentNumAndTenantName: "", reallocateQty: 0, poNumber: "" }], remainingQty: "" }


const mtModalTableDetailsReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case REALLOCATION_MODAL_DETAILS_CHANGED:
                draft.mtModalTableDetails && draft.mtModalTableDetails.length >= 1 ? draft.mtModalTableDetails = [...action.data] : draft.mtModalTableDetails = [...initialState.mtModalTableDetails];
                break;
            case ADD_FORMFIELD:
                draft.mtModalTableDetails = [...draft.mtModalTableDetails, { enrolmentNumAndTenantName: "", reallocateQty: 0, poNumber: draft.mtModalTableDetails && draft.mtModalTableDetails.length > 0 ? draft.mtModalTableDetails[draft.mtModalTableDetails.length - 1].poNumber : "" }];
                break;
            case CURRENT_REALLOCATION_MODAL_DETAILS_CHANGED:
                draft.mtModalTableDetails = [...draft.mtModalTableDetails];
                break;
            case CHANGE_MODAL_TABLE_ONPARTNUMBERFOCUS:
                draft.mtModalTableDetails = [...action.data];
                break;
            case ENABLE_ADD_MORE:
                draft.mtModalTableDetails = new Array({ enrolmentNumAndTenantName: "", reallocateQty: 0, poNumber: "" });
                break;
            case PART_NUMBER_DELETED:
                draft.mtModalTableDetails = new Array({ enrolmentNumAndTenantName: "", reallocateQty: 0, poNumber: "" });
                break;
            case CHANGE_REMAINING_QTY:
                draft.remainingQty = action.data;
                break;
            case RESET_TO_INITIAL_STATE:
                draft.mtModalTableDetails = [{ enrolmentNumAndTenantName: "", reallocateQty: 0, poNumber: "" }];
                draft.remainingQty = "";
                break;
        }
    });

export default mtModalTableDetailsReducer;