import produce from 'immer';
import { 
  SUBMIT_ApprovalRequest ,
  Reject_ApprovalRequest,SUBMIT_ApprovalRequest_SUCCESS,SUBMIT_ApprovalRequest_FAIL,
  Procced_ApprovalRequest,Close_Dialog} from './constants';

export const initialState = {workflow:"0"};

const approvalRequestReducer = (state = initialState, action) =>
  produce(state, ( draft ) => {
    switch (action.type) {
      case SUBMIT_ApprovalRequest:
        draft.action = action.value;
        draft.isOverride= false;
        draft.loading = true;
        draft.error = false;
        draft.successResponse = false;
      break;
      case Procced_ApprovalRequest:
        draft.isOverride = action.value;
        draft.loading = false;
        draft.error = false;
        draft.successResponse = false;
      break;
      case Close_Dialog:
        draft.isOverride = action.value;
        draft.loading = false;
        draft.error = false;
        draft.successResponse = false;
      break;
      case Reject_ApprovalRequest:
          draft.action = action.value;
          draft.loading = true;
          draft.error = false;
          draft.successResponse = false;
          break;
      case SUBMIT_ApprovalRequest_SUCCESS:
          draft.loading = false;
          draft.error = false;                
          draft.successResponse = action.response;
          break;
      case SUBMIT_ApprovalRequest_FAIL:
          draft.error = action.error;
          draft.loading = false;
          draft.successResponse = false;
          break;

    }
  });

export default approvalRequestReducer;