import produce from 'immer';
import {
    FBC_GET_RETURN,
    FBC_GET_RETURN_ERROR,
    FBC_GET_RETURN_SUCCESS,
    CHANGE_FBC_COSMIC_CASENUMBER
} from './constants';
import { LOCATION_CHANGE } from "react-router-redux";
export const initialState = {};

const CancelFutureBillingFetechRequestReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case CHANGE_FBC_COSMIC_CASENUMBER:
                draft.invoices.cosmicCaseNumber = action.value;
                break;
            case FBC_GET_RETURN:
                draft.invoices = {};
                draft.loading = true;
                draft.error = false;
                break;
            case FBC_GET_RETURN_SUCCESS:
                draft.multiInvoiceNumber = action.multiInvoiceNumber;
                draft.invoices = action.invoices;
                draft.loading = false;
                draft.error = false;
                break;
            case FBC_GET_RETURN_ERROR:
                draft.error = action.error;
                draft.loading = false;
                break;
            case LOCATION_CHANGE:
                draft.multiInvoiceNumber = "";
                draft.invoices = {};
                draft.loading = false;
                draft.error = false;
                break;
        }
    });

export default CancelFutureBillingFetechRequestReducer;
