import React from 'react';
import { injectIntl, intlShape, defineMessages } from 'react-intl';

const DynamicErrorMessage = ({ intl, message }) => {

    let isEligibleBasesBeingCalled = false;

    let messageid = "";
    if (message.id.includes('5680714')) messageid = "5680714";
    else if (message.id.includes('5680715')) messageid = "5680715";

    let errorMessage = []
    if (!(messageid === "")) {
        let data = JSON.parse(message.dynamicMsg)
        isEligibleBasesBeingCalled = data.IsEligibleBasesBeingCalled ? data.IsEligibleBasesBeingCalled : false;
        data.StepUpValidationResponses.forEach((item, index) => {
            if (errorMessage.some(elem => elem.BasePartNumber == item.BasePartNumber)) {
                errorMessage.filter(elem => elem.BasePartNumber == item.BasePartNumber)[0].StepUpPolis.push(...item.StepUpPolis)
            } else {
                errorMessage.push(item)
            }
        })
    }
    return (

        (messageid === "5680714" || messageid === "5680715") ?
            <div>
                {
                    messageid == "5680714" ?
                        isEligibleBasesBeingCalled ?
                            <div>
                                 {messageid} : The order cannot be adjusted because it belongs to a base product SKU and there are no enough quantity of base SKU to cover the StepUp SKU. Credit Step-Up SKU or order more eligible Base SKU.
                            </div>
                            :
                            <div>
                                {messageid} : Order cannot be adjusted because it belongs to a base product SKU for which a step-up SKU was purchased. Please request full credit of following Step-up SKU order first:
                            </div>
                        :
                        <div>
                            {messageid} : The order cannot be cancelled because it belongs to Step Up product(s) listed below. Cancel the Step-Up SKU(s) first and then cancel targeted Base SKU(s) with the same coverage. Step-Up Rebill order can be placed via POET.
                        </div>
                }
                <div className="table-responsive" style={{ width: '50%', padding: '10px', paddingLeft: '70px' }}>
                    {errorMessage.map((row, i) => {
                        return (
                            <table className="table table-responsive">
                                <tHead>
                                    <div>Base Part Number: {row.BasePartNumber}</div>
                                    <tr>
                                        <th>StepUp PO Number</th>
                                        <th>StepUp Part Number</th>
                                        <th>Quantity</th>
                                    </tr>
                                </tHead>
                                <tbody >
                                    {row.StepUpPolis.map(cell => {
                                        return (
                                            <tr>
                                                <td style={{ width: '50%', padding: '10px' }}>{cell.StepUpPoNumber}</td>
                                                <td style={{ width: '50%', padding: '10px' }}>{cell.StepUpPartNumber}</td>
                                                <td style={{ width: '50%', padding: '10px' }}>{cell.Quantity}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        )
                    })}
                </div>
            </div>
            :
            <li>
                {
                    (message && message.id.split("errorMessages.").length > 1 && !isNaN(message.id.split("errorMessages.")[1])) ?
                        (
                            <div aria-live="assertive" aria-label={message.id.split("errorMessages.")[1] + ":" + intl.formatMessage(message, { dynamicMsg: message.dynamicMsg })}> {message.id.split("errorMessages.")[1]} : {intl.formatMessage(message, { dynamicMsg: message.dynamicMsg })}</div>
                        ) :
                        (
                            <div aria-live="assertive" aria-label={intl.formatMessage(message, { dynamicMsg: message.dynamicMsg })}> {intl.formatMessage(message, { dynamicMsg: message.dynamicMsg })}</div>
                        )
                }
            </li>

    );
};

DynamicErrorMessage.propTypes = {
    intl: intlShape.isRequired
};

export default injectIntl(DynamicErrorMessage);
