export const GET_DOMAIN_DATA = 'common/GET_DOMAIN_DATA';
export const DOMAIN_DATA_FETCHED = 'common/DOMAIN_DATA_FETCHED';
export const DOMAIN_DATA_ERROR = 'common/DOMAIN_DATA_ERROR';

export const USER_ROLES = 'common/USER_ROLES';
export const USER_ROLES_FETCHED = 'common/USER_ROLES_FETCHED';
export const USER_ROLES_ERROR = 'common/USER_ROLES_ERROR';

export const ACCESSTOKEN = 'common/ACCESSTOKEN';
export const AUTHTYPE = 'common/AUTHTYPE';
export const FETCH_ACCESSTOKEN = 'common/FETCH_ACCESSTOKEN';
export const FETCH_AUTHTYPE = 'common/FETCH_AUTHTYPE';

export const USER_PREFS = 'common/USER_PREFS';
export const USER_PREFS_FETCHED = 'common/USER_PREFS_FETCHED';
export const USER_PREFS_ERROR = 'common/USER_PREFS_ERROR';
