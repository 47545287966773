import { defineMessages } from 'react-intl';

export const scope = 'submitHome';

export default defineMessages({
    createCreditRequest: {
        id: `${scope}.createCreditRequest`,
        defaultMessage: 'Create Credit Request',
    },
    createMultiInvoiceCreditCreditRequest: {
        id: `${scope}.createMultiInvoiceCreditCreditRequest`,
        defaultMessage: 'Multi-Invoice Credit Request',
    },
    submitSuccess: {
        id: `${scope}.submitSuccess`,
        defaultMessage: 'Credit Request submitted successfully',
    },
    eReceiptSuccessMessage: {
        id: `${scope}.eReceiptSuccessMessage`,
        defaultMessage: 'Credit Request submitted successfully. The invoice in submitted request was previously paid. We are currently reversing your payment and your credit will be processed in 3 business days.',
    },
    saveDraftSuccess: {
        id: `${scope}.saveDraftSuccess`,
        defaultMessage: 'Credit request draft saved successfully',
    },
    deleteDraftSuccess: {
        id: `${scope}.deleteDraftSuccess`,
        defaultMessage: 'Credit request draft deleted successfully',
    },
    cancelSuccess: {
        id: `${scope}.cancelSuccess`,
        defaultMessage: 'Credit request cancelled successfully',
    },
    rebillConfirmation: {
        id: `${scope}.rebillConfirmation`,
        defaultMessage: 'No rebill invoices/purchase order are added for full credit request. Do you want to continue ?',
    },
    rebillStatusConfirmation: {
        id: `${scope}.rebillStatusConfirmation`,
        defaultMessage: 'This PO is already in Accepted status , please use rebill "Invoice number" to ensure rebill is eligible and linked with this credit request. If you continue to use System PO, PO will be not eligible as Rebill PO. However, it will be linked with this credit request for reference.',
    },
    cancelConfirmation: {
        id: `${scope}.cancelConfirmation`,
        defaultMessage: 'Are you sure you want to cancel this request ?',
    },
    closeAction: {
        id: `${scope}.closeAction`,
        defaultMessage: 'close',
    },
    continueAction: {
        id: `${scope}.continueAction`,
        defaultMessage: 'Continue',
    },
    goBackAction: {
        id: `${scope}.goBackAction`,
        defaultMessage: 'Go Back',
    },
    rocOverridenMessage: {
        id: `${scope}.rocOverridenMessage`,
        defaultMessage: 'Are you sure you want to change the ROC? Please note that the ROC selected should match the ROC where the order was originally placed as it determines the Operations Centre where this credit request will be processed.If the selected ROC is incorrect this credit request will be rejected.',
    },
    restockFeeConfirmation: {
        id: `${scope}.restockFeeConfirmation`,
        defaultMessage: 'Are you sure you want to submit this credit with 5% restock fee?',
    },
    submitConfirmation: {
        id: `${scope}.submitConfirmation`,
        defaultMessage: 'Submit Confirmation',
    }


});
