import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

export class LocalisedLabel extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { formatMessage } = this.props.intl;

        return (
            <label htmlFor={this.props && this.props.htmlFor ? this.props.htmlFor : ''}
                className={this.props && this.props.className ? this.props.className : ''}
                title={formatMessage({ id: this.props.id })} aria-label={formatMessage({ id: this.props.id })}>
                <FormattedMessage id={this.props.id}
                    defaultMessage={this.props && this.props.value ? this.props.value : ''}
                    description= {this.props.description} />
            </label>
        );
    }
}
export default injectIntl(LocalisedLabel);
