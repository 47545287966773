import { defineMessages } from 'react-intl';

export const scope = 'fbcApprovalRequest';

export default defineMessages({
    btnApprove: {
        id: `${scope}.btnApproveSubmit`,
        defaultMessage: 'Submit',
    },
    btnReject: {
        id: `${scope}.btnReject`,
        defaultMessage: 'Reject',
    },
    btnReset: {
        id: `${scope}.btnReset`,
        defaultMessage: 'Reset',
    },
    modalApproveMessgae: {
        id: `${scope}.modalApproveMessgae`,
        defaultMessage: 'Request Processed Successfully',
    },
    modalRejectMessgae: {
        id: `${scope}.modalRejectMessgae`,
        defaultMessage: 'Request Rejected. Please communicate the partner accordingly.',
    },
    modalClose: {
        id: `${scope}.modalClose`,
        defaultMessage: 'Close',
    },
    
    proceedAction: {
        id: `${scope}.proceedAction`,
        defaultMessage: 'Proceed',
    },
    cancelAction: {
        id: `${scope}.cancelAction`,
        defaultMessage: 'Cancel',
    },
    reviewCommentsErrorMessage:
    {
        id: `${scope}.reviewComments`,
        defaultMessage: 'Please enter review comments',
    }
});