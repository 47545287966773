
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from '../common/internals/utils/history';
import globalReducer from '../common/app/reducer';

export default function createReducer(injectedReducers = {}) {
    const rootReducer = combineReducers({
        global: globalReducer,
        router: connectRouter(history),
        ...injectedReducers,
    });

    return rootReducer;
}
