import {
    FILTER_REALLOCATION_REQUESTS,
    FILTER_REALLOCATION_SUCCESS,   
    FILTER_REALLOCATION_ERROR,
    CHANGE_REQUEST_IDENTIFIER,
    CHANGE_REQUEST_STATUS,
    CHANGE_ENROLLMENT_NUMBER,
    CHANGE_DATE_RANGE,
    CHANGE_COSMIC_NUMBER,
    CHANGE_ENROLLMENT_NAME,
    CHANGE_TENANT_NUMBER
 } from './constants';

export function FilterReallocationRequests() {    
    return {
        type: FILTER_REALLOCATION_REQUESTS
    };
}

export function FilterReallocationRequestsList(reallocationListResponse) {
    return {
        type: FILTER_REALLOCATION_SUCCESS,
        response: reallocationListResponse
    };
}

export function FilterReallocationRequestsListError(error) {
    return {
        type: FILTER_REALLOCATION_ERROR,
        error
    };
}

export function changeRequestIdentifier(requestIdentifier) {
    return {
        type: CHANGE_REQUEST_IDENTIFIER,
        requestIdentifier
    };
}

export function changeRequestStatusCode(requestStatus) {
    return {
        type: CHANGE_REQUEST_STATUS,
        requestStatus
    };
}

export function changeAgreementNumber(enrollmentNumber) {
    return {
        type: CHANGE_ENROLLMENT_NUMBER,                                 
        enrollmentNumber
    };
}


export function changeTenantNumber(tenantNumber) {
    return {
        type: CHANGE_TENANT_NUMBER,
        tenantNumber
    };

}

export function changeEnrollmentName(enrollmentName) {
    return {
        type: CHANGE_ENROLLMENT_NAME,
        enrollmentName
    };
}

export function changeCosmicNumber(cosmicNumber) {
    return {
        type: CHANGE_COSMIC_NUMBER,
        cosmicNumber
    };
}

export function changeDateRange(dateRange) {
    return {
        type: CHANGE_DATE_RANGE,
        dateRange
    };
}

export function search(dateRange) {
    return {
        type: CHANGE_DATE_RANGE,
        dateRange
    };
}


