export const SUBMIT_FBC_ApprovalRequest =
  "FbcApprovalRequest/SUBMIT_FBC_ApprovalRequest";

export const SUBMIT_FBC_ApprovalRequest_SUCCESS =
  "FbcApprovalRequest/SUBMIT_FBC_ApprovalRequest_SUCCESS";

export const SUBMIT_FBC_ApprovalRequest_FAIL =
  "FbcApprovalRequest/SUBMIT_FBC_ApprovalRequest_FAIL";

export const Proceed_FBC_ApprovalRequest =
  "FbcApprovalRequest/Proceed_FBC_ApprovalRequest";

export const Close_Dialog = "FbcApprovalRequest/Close_Dialog";

export const DEFAULT_ACTION = "FbcApprovalRequest/DEFAULT_ACTION";
