import { call, put, select, takeLatest } from "redux-saga/effects";
import { FBC_SEARCH_WITH_POAA } from "./constants";
import { searchCompleted, searchError } from "./actions";
import { newReturnRequestCreate } from "../../../../common/internals/api/requests";
import { makeSelectEnrollmentNumberCreate, makeSelectSystemPONumberCreate } from "./selectors";
import { transformReturnResponse } from "../../../../common/internals/api/transformReturnResponse";
import {
  makeSelectAccessToken,
  makeSelectAuthType
} from "../../../../common/app/selectors";
import { sagaFetchHandler } from "../../../../common/internals/api/sagaFetchHandler";


export function* getInvoice() {
    const enrollmentNumber = yield select(makeSelectEnrollmentNumberCreate());
    const systemPONumber = yield select(makeSelectSystemPONumberCreate());
    //console.log("EnrollmentNum " + enrollmentNumber + " systemPONumber " + systemPONumber);
    var newReturnRequest = newReturnRequestCreate();
    if(window.vlcredits.envData.Services.isDuplicatePoApi == "true"){
      newReturnRequest.url = `api/purchaseorder/SearchOrder/${encodeURIComponent(systemPONumber.trim())}?POID=0&agreementNumber=${enrollmentNumber}`;
    }else{
      newReturnRequest.url = `api/purchaseorder/${encodeURIComponent(systemPONumber.trim())}?agreementNumber=${enrollmentNumber}`;
    }
    yield* setAuthTokens(newReturnRequest);
    yield* sagaFetchHandler(
     newReturnRequest,
     searchCompleted,
     searchError,
     transformReturnResponse,
        { "enrollmentNumber": enrollmentNumber, "systemPONumber":systemPONumber }
   );
}

function* setAuthTokens(request) {
  const accessToken = yield select(makeSelectAccessToken());
  const authType = yield select(makeSelectAuthType());
  request.options.headers["Authorization"] = "Bearer " + accessToken;
  request.options.headers["x-ms-AuthType"] = authType;
}

export default function* createRequestSaga() {
    yield takeLatest(FBC_SEARCH_WITH_POAA, getInvoice);
}
