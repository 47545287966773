import { defineMessages } from "react-intl";

export const scope = "CancelFutureBillingCreateRequest";

export default defineMessages({
    enterEnrollmentNumber: {
        id: `${scope}.enterEnrollmentNumber`,
        defaultMessage: 'Enrollment Number',
    },
    enterSystemPONumber: {
        id: `${scope}.enterSystemPONumber`,
        defaultMessage: 'System Purchase Order Number',
    },
    enterPONumber: {
        id: `${scope}.enterPONumber`,
        defaultMessage: 'Purchase Order Number*',
    },
    cosmicCaseNumber: {
        id: `${scope}.cosmicCaseNumber`,
        defaultMessage: "Cosmic Case Number"
    }
});
