import { call, put, select  } from 'redux-saga/effects';
import fetchWrapper from './fetchWrapper';
import messages from './errorMessages';
import { makeSelectAppinsight } from '../../components/appInsights/selectors';
import { userRoleType } from '../utils/helpers';

export function* sagaFetchHandler(
    request,
    successAction,
    failureAction,
    transformResponse,
    successParam
) {

    //to log in appinsights
    let appInsights = yield select(makeSelectAppinsight());
    var requestData = JSON.stringify(request);
    var properties = { requestData };

    var requestUrl = "Request-" + request.baseUrl + "/" + request.url;
    appInsights && appInsights.trackTrace({ message: requestUrl, properties: properties });
    try {

          const successResponse = yield call(fetchWrapper, request);

        //do not log response for search calls.
        (!request.url.includes('api/Search')) &&
            appInsights && appInsights.trackTrace({ message: "Response-" + JSON.stringify(successResponse), properties: properties });

        if (successResponse.errorDictionary && !successResponse.success) {
            const errorMessages = getErrorMessages(successResponse.errorDictionary);
            yield put(failureAction({ 'errorMessages': errorMessages }));
        }
        else {
            const finalResponse
                = (transformResponse) ?
                    transformResponse(successResponse) :
                    successResponse;
                yield put(successAction(finalResponse, successParam));
        }
    }
    catch (err) {

        //log error
        appInsights && appInsights.trackException({ exception: err, properties: properties });

        console.log('Failed Fetch from Saga: ' + err);
        if (err.message === "401") {
            const errorMessages = {};
            errorMessages["errors"] = [messages.tokenExpiredMsg];
            yield put(failureAction({ 'errorMessages': errorMessages }));
        }else if (err.message === "404"){
            const errorMessages = {};
            errorMessages["errors"] = [messages.notFoundMsg];
            yield put(failureAction({ 'errorMessages': errorMessages }));
        }
        else {
            yield put(failureAction({ 'errorMessages': '' }));
        }
    }
}

function getErrorMessages(errorDictionary) {
    const errorMessages = {};
    for (const error in errorDictionary) {
        if (errorDictionary.hasOwnProperty(error)) {
            for (var code in errorDictionary[error]) {
                var errorCode = errorDictionary[error][code];
                var strToken = errorCode.split(":");

                if (messages.hasOwnProperty(strToken[0])) {
                    const errorObject = JSON.parse(JSON.stringify(messages[strToken[0]]));
                    if (Object.keys(errorObject).length > 0) {
                        let errorMsg = errorObject;
                        if (strToken.length > 1) {
                            if (strToken[0] == '5680714' || strToken[0] == '5680715') {
                                errorMsg.dynamicMsg = errorCode.slice(8);
                            }
                            else {
                                const userRole = window && window.userRole;
                                const isPartner = (userRoleType[userRole] === "Partner");
                                errorMsg.dynamicMsg = isPartner ? 'Order cannot be adjusted because it belongs to a base product SKU for which a step-up SKU was purchased. Please submit the reduction manually by CLT' : 'Order cannot be adjusted because it belongs to a base product SKU for which a step-up SKU was purchased. Please request full credit of following Step-up SKU order first:'+strToken[1];
                            }
                        }
                        if (errorMessages[error]) {
                            errorMessages[error].push(errorMsg);
                        }
                        else {
                            errorMessages[error] = [];
                            errorMessages[error].push(errorMsg);
                        }
                    }
                }
            }

        }
    }
    return errorMessages;
}
