export const EXPORT_PDF = "MultiInvoiceReturnForm/EXPORT_PDF";
export const TOGGLE_ACTIONS = "MultiInvoiceReturnForm/TOGGLE_ACTIONS";
export const SUBMIT_RETURN = "MultiInvoiceReturnForm/SUBMIT_RETURN";
export const SUBMIT_RETURN_SUCCESS =
  "MultiInvoiceReturnForm/SUBMIT_RETURN_SUCCESS";
export const SUBMIT_RETURN_FAIL = "MultiInvoiceReturnForm/SUBMIT_RETURN_FAIL";
export const DELETE_DRAFT = "MultiInvoiceReturnForm/DELETE_DRAFT";
export const DELETE_DRAFT_SUCCESS =
  "MultiInvoiceReturnForm/DELETE_DRAFT_SUCCESS";
export const DELETE_DRAFT_FAIL = "MultiInvoiceReturnForm/DELETE_DRAFT_FAIL";
export const SAVE_RETURN = "MultiInvoiceReturnForm/SAVE_RETURN";
export const SAVE_RETURN_SUCCESS = "MultiInvoiceReturnForm/SAVE_RETURN_SUCCESS";
export const SAVE_RETURN_FAIL = "MultiInvoiceReturnForm/SAVE_RETURN_FAIL";
export const E_RECEIPT_FAIL = "MultiInvoiceReturnForm/E_RECEIPT_FAIL";
export const E_RECEIPT_SUCCESS = "MultiInvoiceReturnForm/E_RECEIPT_SUCCESS";
export const SHOW_REBILL_CONFIRMATION =
  "MultiInvoiceReturnForm/SHOW_REBILL_CONFIRMATION";
export const CLOSE_REBILL_CONFIRMATION =
  "MultiInvoiceReturnForm/CLOSE_REBILL_CONFIRMATION";
export const SHOW_RESTOCK_FEE_CONFIRMATION = 'MultiInvoiceReturnForm/SHOW_RESTOCK_FEE_CONFIRMATION';
export const CLOSE_RESTOCK_FEE_CONFIRMATION = 'MultiInvoiceReturnForm/CLOSE_RESTOCK_FEE_CONFIRMATION';
export const VALIDATE_SUBMIT_FORM = 'MultiInvoiceReturnForm/VALIDATE_SUBMIT_FORM'