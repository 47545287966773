import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import PartNumberMenu from '../partNumberMenu';
import ReallocationBody from '../reallocationBody';
import { onReallocationModalClose } from './actions';
import $ from 'jquery';
import reducer from './reducer';
import {
    makeSelectIsOpen,
    makeSelectPONumber
} from './selectors';
import * as FocusTrap from 'focus-trap-react';
import '../../../../common/app/accessibility-style.css';
function ReallocateModal({
    isOpen,
    poNumber,
    onReallocationModalCloseClick
}) {

    useInjectReducer({ key: 'reallocateModal', reducer });
    $(document).ready(function () {
        $("#partNumberMenuIcon").click(function () {

            var className = $('#onedash-side-navigation-partNumberMenu').attr('class');

            if ("onedash-side-navigation mobileHide is-open" === className) {
                open();
            }
            else {
                close();
            }
        });


        function open() {
            $("#partNumberMenuIcon").each(function () { $(this).attr({ "data-bi-id": "ExpandMenu-SetToClose", "data-bi-name": "ExpandMenuIcon-SetToClose", "aria-expanded": "true" }) });
            $("#onedash-side-navigation-partNumberMenu").removeClass("onedash-side-navigation mobileHide is-open");
            $("#onedash-side-navigation-partNumberMenu").addClass("onedash-side-navigation is-open");
            $("#onedash-hamburger-mobile-partNumberMenu").removeClass("is-selected");

        }

        function close() {
            $("#partNumberMenuIcon").each(function () { $(this).attr({ "data-bi-id": "ExpandMenu-SetToOpen", "data-bi-name": "ExpandMenuIcon-SetToOpen", "aria-expanded": "false" }) });
            $("#onedash-side-navigation-partNumberMenu").removeClass("onedash-side-navigation is-open");
            $("#onedash-side-navigation-partNumberMenu").addClass("onedash-side-navigation mobileHide is-open");
            $("#onedash-hamburger-mobile-partNumberMenu").addClass("is-selected");
        }
    });

    if (isOpen) {
        window.scrollTo(0, 0);
        return (
            <div className="row">
                <FocusTrap>

                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="modal right fade in" id="myModal2" style={{ display: 'block', opacity: '1', paddingTop: '3%', fontSize: 'smaller' }}>
                        <div className="modal-dialog" role="document">
                                <header id="onedash-header-wrapper" className="onedash-header-universal" data-bi-area="OneDashboardTop" role="none" style={{ backgroundColor: 'white', color: '#333333' }}>
                                    <div className="onedash-header-title pull-left">
                                        <div id="onedash-hamburger-mobile" className="is-selected">
                                            <button title="Toggle the navigation pane"
                                                className="onedash-hamburger-btn"
                                                id="partNumberMenuIcon"
                                                aria-expanded="false" aria-label="Toggle the navigation pane"
                                                data-bi-name="ExpandMenuIcon-SetToOpen" data-bi-id="ExpandMenu-SetToOpen"
                                                data-bi-slot="0" >
                                                <div className="onedash-hamburger-icon">
                                                    <div className="win-icon win-icon-GlobalNavButton"></div>
                                                </div>
                                            </button>

                                        </div>
                                    </div>
                                    <div className="onedash-header-title pull-left col-xs-18">
                                        <span className="onedash-header-title-text" aria-label="Reallocate Licenses | PO Number">Reallocate Licenses | {poNumber}</span>
                                    </div>
                                    <div class="onedash-header-actions pull-right" id="dvProfilePopUp">
                                        <button type="button" class="btn btn-primary close close-btn" data-dismiss="modal" aria-label="Close" onClick={onReallocationModalCloseClick}>
                                            <span aria-hidden="true" style={{ paddingRight: '50px' }}>&times;</span>
                                        </button>
                                    </div>
                                </header>
                            <div class="modal-content" style={{ padding: '0px' }}>
                                <div class="col-lg-5 col-md-5 col-sm-3" style={{ backgroundColor: 'none', color: '#333333', height: 'inherit' }}>
                                    {<PartNumberMenu />}
                                </div>
                                <div class="col-lg-18 col-sm-12 col-md-18 ">
                                    {<ReallocationBody />}
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </FocusTrap>

            </div >

        );
    }
    return null;
}

ReallocateModal.propTypes = {
    isOpen: PropTypes.bool,
    poNumber: PropTypes.string
};

const mapStateToProps = createStructuredSelector({
    isOpen: makeSelectIsOpen(),
    poNumber: makeSelectPONumber()
});

function mapDispatchToProps(dispatch) {
    return {
        onReallocationModalCloseClick: evt => {
            if (evt !== undefined) {
                return dispatch(onReallocationModalClose());
            }
        }

    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(ReallocateModal);