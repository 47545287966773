
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import { useInjectSaga } from '../../../../common/internals/utils/injectSaga';
import { deletePartNumber, enableAddMore, partNumberToFocus, saveModalDetails } from './actions';
import reducer from './reducer';
import saga from './saga';
import {
    makeSelectInvalidPOs, makeSelectIsAddMoreDisabled, makeSelectIsAddMoreEnabled,
    makeSelectPartNumberList, makeSelectAnnouncerText
} from './selectors';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import Announcer from 'react-a11y-announcer';
import '../../../../common/app/accessibility-style.css';

function PartNumberMenu({
    selectPartNumberList,
    selectIsAddMoreEnabled,
    enableAddMore, partNumberToFocus, deletePartNumber, saveModalDetails, selectIsAddMoreDisabled,
    invalidPOs,
    announerText
}) {

    useInjectReducer({ key: 'partNumberMenu', reducer });
    useInjectSaga({ key: 'partNumberMenu', saga });


    let handleChange = (e, strval) => {
        partNumberToFocus(e);
    }

    return (

        <div className="row remove-all-margin">
            <div className="col-md-5 col-lg-3  side-nav-wrap">
                <div className="onedash-container" data-bi-area="OneDashboardSide" role="navigation">
                    <nav id="onedash-side-navigation-partNumberMenu" className="onedash-side-navigation mobileHide is-open" aria-label="menubar" style={{ backgroundColor: '#eaeaea', color: 'black', height: '78%' }}>
                        <div id="onedash-navigation-list" className="onedash-navigation-list">
                            <div id="onedash-top-menus">
                                <div id="partner-company-name1"
                                    className="onedash-accountpicker-account is-visible">
                                    <span className="activeAccount">
                                        <FormattedMessage {...messages.addPartNumbers} />
                                    </span>
                                </div>
                                <Announcer text={announerText} />
                                <div id="onedash-internaltools-menu-title" className="onedash-navigation-menu-title is-active">
                                    <ul role="menu">
                                        {
                                            selectPartNumberList && selectPartNumberList.length > 0 ?
                                                selectPartNumberList.map((partNumber, i) =>
                                                    <li className="onedash-navigation-menu-item" role="none">
                                                        <div>

                                                            <button type="button" role="menuitem" className="pull-left blackFocus" id={partNumber.label} style={{ color: 'black', width: 'auto', text: 'center' }} value={partNumber.value} onClick={(val) => {
                                                                partNumberToFocus(partNumber)
                                                            }}>{partNumber.label}</button>

                                                            <div className="pull-left" style={{ color: "red", paddingRight: "3px" }}>
                                                                {invalidPOs.includes(partNumber.label) ?
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16"><path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" /></svg>
                                                                    : ""}
                                                            </div>

                                                            <button type="button" className="pull-right blackFocus" id={partNumber} style={{ color: '#3191E7', paddingRight: '10px', width: 'auto' }} value={partNumber.value} onClick={() => {
                                                                deletePartNumber(partNumber)
                                                            }} aria-label="delete part number">

                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </li>
                                                ) :
                                                ""
                                        }

                                        {/*This Part will be shown only when Add More is Pressed*/}
                                        {selectIsAddMoreEnabled ?
                                            <li className="onedash-navigation-menu-item" role="none">
                                                <a role="menuitem">
                                                    <div className="onedash-navigation-category blackFocus" id="Select Part Number" style={{ color: 'black' }} >

                                                        <FormattedMessage {...messages.selectPartNumber} />

                                                    </div>
                                                </a>

                                            </li>
                                            : ""
                                        }

                                        <li className="onedash-navigation-menu-item" role="none" >
                                            <button type="button" className="btn btn-link blackFocus" style={{
                                                padding: '10px 0 0 16px',
                                                color: 'black',
                                                float: 'left',
                                                text: 'center'
                                            }} onClick={enableAddMore}
                                                disabled={selectIsAddMoreDisabled}
                                            > + <FormattedMessage {...messages.addMoreButton} />
                                            </button>
                                        </li>

                                    </ul>

                                </div>

                            </div>

                        </div>
                        <div className={selectIsAddMoreDisabled ? "hidden" : "button-section"} style={{ paddingLeft: "30%", color: 'black', margin: 0, padding: "0", background: 'white' }}>
                            <div className="save-btn" style={{ background: '#333333' }}>
                                <button className="button add whiteFocus" type="button" style={{ width: '10 px', minWidth: '0px', background: '', paddingLeft: "30%", color: 'white', text: 'center' }} onClick={saveModalDetails} disabled={selectIsAddMoreDisabled}>
                                    <b>
                                        <FormattedMessage {...messages.saveButton} />
                                    </b>
                                </button>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>


    );
}

PartNumberMenu.propTypes = {
    selectPartNumberList: PropTypes.array,
    selectIsAddMoreEnabled: PropTypes.bool,
    selectIsAddMoreDisabled: PropTypes.bool,
    enableAddMore: PropTypes.func,
    saveModalDetails: PropTypes.func,
    partNumberToFocus: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
    selectPartNumberList: makeSelectPartNumberList(),
    selectIsAddMoreEnabled: makeSelectIsAddMoreEnabled(),
    selectIsAddMoreDisabled: makeSelectIsAddMoreDisabled(),
    invalidPOs: makeSelectInvalidPOs(),
    announerText: makeSelectAnnouncerText()

});

function mapDispatchToProps(dispatch) {
    return {
        enableAddMore: evt => {
            if (evt != undefined) {
                return dispatch(enableAddMore());
            }
        },
        deletePartNumber: (value) => {
            if (value != undefined) {
                return dispatch(deletePartNumber(value));
            }
        },

        partNumberToFocus: (value) => {
            if (value != undefined) {
                return dispatch(partNumberToFocus(value));
            }
        },
        saveModalDetails: evt => {
            if (evt != undefined) {
                return dispatch(saveModalDetails());
            }
        }
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(PartNumberMenu);