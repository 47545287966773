import React from "react";
import { FormattedMessage } from "react-intl";
import messages from "./messages";
import saga from "./saga";
import reducer from "./reducer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { useInjectSaga } from "../../../../common/internals/utils/injectSaga";
import { useInjectReducer } from "../../../../common/internals/utils/injectReducer";
import { validateSubmitForm } from "./actions";
import { makeCancelFutureBillingReturnFormReadOnly, makeCancelFutureBillingReturnFormLoading } from "./selectors";
import { search } from "../../../common/components/createMultiInvoiceRequest/actions";
import SubmitNotes from "../../../operations/components/submitNotes/Loadable";//"../../components/submitNotes/Loadable";
import { isFBCFullCancellationOnReturnReason } from '../../../../common/internals/utils/HandlingFeeHelper';
import * as Helpers from '../../../../common/internals/utils/helpers';

var _cosmicErrorMessage = <FormattedMessage {...messages.cosmicCaseErrorMessage} />;
var _returnReasonErrorMessage = <FormattedMessage {...messages.returnReasonErrorMessage} />;
var _rebillPOErrorMessage = <FormattedMessage {...messages.rebillPoMandatoryErrorMessage} />

function FBCPartnerSubmitHome({ invoices, onSubmitForm, isReadOnly, onResetClick, loading }) {
    useInjectReducer({ key: "CancelFutureBillingReturnForm", reducer });

    useInjectSaga({ key: "CancelFutureBillingReturnForm", saga });
    window.vlcredits.FeatureName = "FBC";
    return (
        <div className="row spacer-32-bottom">
            <form role="form" onSubmit={onSubmitForm}>
                <div className="col-lg-24">
                    <SubmitNotes />
                </div>

                <div className="col-lg-24">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-8 col-md-8 col-lg-13">
                                {isReadOnly ? (
                                    ""
                                ) : (
                                    <div className="btn-group">
                                        {/* TODO: Readonly */}
                                        {!Helpers.isReadonlyVersion() && <button
                                            type="submit"
                                            name="submitBtn"
                                            className="btn btn-default active"
                                            disabled={loading}
                                        >
                                            <FormattedMessage {...messages.submit} />
                                        </button>}
                                        <div className="hidden">
                                            <button
                                                type="button"
                                                className="btn btn-default"
                                                onClick={onResetClick}
                                            >
                                                <FormattedMessage {...messages.reset} />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

FBCPartnerSubmitHome.propTypes = {
    onSubmitForm: PropTypes.func,
    isReadOnly: PropTypes.bool,
    onResetClick: PropTypes.func,
    loading: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
    isReadOnly: makeCancelFutureBillingReturnFormReadOnly(),
    loading: makeCancelFutureBillingReturnFormLoading()
});

function mapDispatchToProps(dispatch) {
    return {

        onResetClick: evt => {
            if (evt) {
                if (evt.preventDefault) evt.preventDefault();
                var uri = window.location.href.toString();
                if (uri.indexOf("multiInvoice=") > 0) {
                    window.location.href = uri + "&reset=true";
                } else {
                    dispatch(search());
                }
            }
        },

        onSubmitForm: evt => {
            if (evt) {
                if (evt.preventDefault) evt.preventDefault();

                if (document.activeElement.getAttribute('name') === "submitBtn") {
                    var isValid = false;

                    var _cosmicError = _cosmicErrorMessage && _cosmicErrorMessage.props && _cosmicErrorMessage.props.defaultMessage;
                    var _returnReasonError = _returnReasonErrorMessage && _returnReasonErrorMessage.props && _returnReasonErrorMessage.props.defaultMessage;
                    var _rebillPOError = _rebillPOErrorMessage && _rebillPOErrorMessage.props && _rebillPOErrorMessage.props.defaultMessage;

                    var _returnReason = document.getElementById("FBCReturnReason");
                    var _txtRebillPONumber = document.getElementById("txtRebillPONumber");
                    var _chkFullCancellation = document.getElementById("fullCancellation");

                    var _businessRuleForRebillPo = ((_chkFullCancellation && _chkFullCancellation.checked) || (isFBCFullCancellationOnReturnReason(_returnReason.value)));

                    if (Helpers.isEmptyOrSpaces(_returnReason.value.trim())) {
                        _returnReason.setCustomValidity(_returnReasonError);
                        try {
                            _returnReason.reportValidity();
                        } catch (e) {
                            alert(_returnReasonError);
                        }
                        isValid = false;
                        return isValid;
                    } else if (window.userRole != "VPU" && _businessRuleForRebillPo) {
                        if (_txtRebillPONumber && Helpers.isEmptyOrSpaces(_txtRebillPONumber.value)) {
                            _txtRebillPONumber.setCustomValidity(_rebillPOError);
                            try {
                                _txtRebillPONumber.reportValidity();
                            } catch (e) {
                                alert(_rebillPOError);
                            }
                            isValid = false;
                            return isValid;
                        } else {
                            _txtRebillPONumber && _txtRebillPONumber.setCustomValidity("");
                            try {
                                _txtRebillPONumber && _txtRebillPONumber.reportValidity();
                            } catch (e) {
                                //no action required
                            }
                            isValid = true;
                        }
                    } else {
                        _returnReason && _returnReason.setCustomValidity("");
                        _txtRebillPONumber && _txtRebillPONumber.setCustomValidity("");
                        isValid = true;
                    }

                    if (isValid) {
                        dispatch(validateSubmitForm());
                        window.scrollTo(0, 0);
                    } else {
                        console.log("checking here");
                    }
                }
            }
        }

    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FBCPartnerSubmitHome);
