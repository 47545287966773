import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { isNotaFiscalNumberCountryCode } from '../../../../common/internals/utils/HandlingFeeHelper';

/* Direct selector to the FetchReturnRequest state domain */
const selectFetchReturnRequestDomain = state => state.fetchReturnRequest || initialState;

/* Default selector used by FetchReturnRequest */
const makeSelectFetchReturnRequest = () =>
    createSelector(
        selectFetchReturnRequestDomain,
        substate => substate,
    );

const makeSelectInvoiceNumberFetch = () =>
    createSelector(selectFetchReturnRequestDomain, substate => substate.invoiceNumber);

const makeSelectLocalInvoiceNumberFetch = () =>
    createSelector(
        selectFetchReturnRequestDomain,
        substate => substate.invoice ? substate.invoice.localInvoiceNumber : null);

const makeSelectFetchRequestIsLRD = () =>
    createSelector(selectFetchReturnRequestDomain,
        substate => substate.invoice ?
            isNotaFiscalNumberCountryCode(substate.invoice.billToCountryCode)
            : null);

const makeSelectInvoiceFetch = () =>
    createSelector(selectFetchReturnRequestDomain, substate => substate.invoice);

const makeSelectCosmicCaseNumber = () =>
    createSelector(selectFetchReturnRequestDomain,
        substate => substate.invoice ? substate.invoice.cosmicCaseNumber : null);

const makeSelectFetchReturnRequestError = () =>
    createSelector(selectFetchReturnRequestDomain, substate => substate.error);

const makeSelectLoading = () =>
    createSelector(selectFetchReturnRequestDomain, substate => substate.loading);

export default makeSelectFetchReturnRequest;
export {
    makeSelectLoading,
    makeSelectCosmicCaseNumber,
    makeSelectFetchReturnRequest,
    makeSelectInvoiceNumberFetch,
    makeSelectInvoiceFetch,
    makeSelectFetchRequestIsLRD,
    makeSelectLocalInvoiceNumberFetch,
    makeSelectFetchReturnRequestError
};
