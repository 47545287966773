import { call, put, select, take, all, fork } from 'redux-saga/effects';
import { map } from 'lodash';
import { GET_DOMAIN_DATA, USER_ROLES, USER_PREFS } from './constants';
import { domainDataFetched, domainDataError, userRolesFetched, userRolesError, userPrefsFetched, userPrefError } from './actions';
import { makeSelectAccessToken, makeSelectAuthType } from './selectors';

import { domainDataRequestCreate, userRolesRequestCreate, userPreferencesRequestCreate } from '../internals/api/requests';
import fetchWrapper from '../internals/api/fetchWrapper';
import { transformResponse } from '../internals/api/transformResponse';
import { makeSelectAppinsight } from '../components/appInsights/selectors';

export default function* fetchDomainData() {
    const task1 = yield fork(getDomainData);
    const task2 = yield fork(getUserRolesList);
    const task3 = yield fork(getUserPreferences);
}

function* getDomainData() {
    const action = yield take(GET_DOMAIN_DATA);
    const data = yield all(action.payload.map(resource => call(getDomainDataForResource, resource)));
    yield put(domainDataFetched(data));
}

function* getDomainDataForResource(resource) {
    let data = {};
    var accessToken = yield select(makeSelectAccessToken());
    var authType = yield select(makeSelectAuthType());
    var domainDataRequest = domainDataRequestCreate();
    domainDataRequest.options.headers["Authorization"] = "Bearer " + accessToken;
    domainDataRequest.options.headers["x-ms-AuthType"] = authType;
    domainDataRequest.url = "api/domain?actionName=" + resource;

    //to log in appinsights
    let appInsights = yield select(makeSelectAppinsight());
    var requestData = JSON.stringify(domainDataRequest);
    var properties = { requestData };
    var requestUrl = "Request-" + domainDataRequest.baseUrl + "/" + domainDataRequest.url;

    try {
        //log request
        appInsights && appInsights.trackTrace({ message: requestUrl, properties: properties });

        data = yield call(fetchWrapper, domainDataRequest, false);

        //log response
        appInsights && appInsights.trackTrace({ message: "Response-" + JSON.stringify(data), properties: properties  });
        //allDomainData = transformResponse(resource, data);        
    } catch (err) {
        //log error
        appInsights && appInsights.trackException({ exception: err, properties: properties });

        yield put(domainDataError(err));
    }    
    return { key: resource, value: data };
}

export function* getUserRolesList() {

    yield take(USER_ROLES);
    var accessToken = yield select(makeSelectAccessToken());
    var authType = yield select(makeSelectAuthType());
    var userRolesRequest = userRolesRequestCreate();
    userRolesRequest.options.headers["Authorization"] = "Bearer " + accessToken;;
    userRolesRequest.options.headers["x-ms-AuthType"] = authType;

    //to log appinsights
    let appInsights = yield select(makeSelectAppinsight());
    var requestData = JSON.stringify(userRolesRequest);
    var properties = { requestData };
    var requestUrl = "Request-" + userRolesRequest.baseUrl + "/" + userRolesRequest.url;

    try {
           //log request
       appInsights && appInsights.trackTrace({ message: requestUrl, properties: properties });
        
        const userRolesResponse = yield call(fetchWrapper, userRolesRequest, false);

        //log response
        appInsights && appInsights.trackTrace({ message: "Response-" + JSON.stringify(userRolesResponse), properties: properties  });

        yield put(userRolesFetched(userRolesResponse));
    } catch (err) {
         //log error
         appInsights && appInsights.trackException({ exception: err, properties: properties });

        yield put(userRolesError(err));
    }
}

export function* getUserPreferences() {

    const prefAction = yield take(USER_PREFS);
    var accessToken = yield select(makeSelectAccessToken());
    var authType = yield select(makeSelectAuthType());
    var userPreferencesRequest = userPreferencesRequestCreate();
    userPreferencesRequest.url = `api/user/preferences?accessorGuid=${prefAction.payload}`;
    userPreferencesRequest.options.headers["Authorization"] = "Bearer " + accessToken;;
    userPreferencesRequest.options.headers["x-ms-AuthType"] = authType;

    //to log in appinsights
    var requestData = JSON.stringify(userPreferencesRequest);
    var properties = { requestData };
    let appInsights = yield select(makeSelectAppinsight());
    var requestUrl = "Request-" + userPreferencesRequest.url;

    try {
        //log request
       appInsights && appInsights.trackTrace({ message: requestUrl, properties: properties });

        const userPrefsResponse = yield call(fetchWrapper, userPreferencesRequest, false);
         //log response
         appInsights && appInsights.trackTrace({ message: "Response-" + JSON.stringify(userPrefsResponse), properties: properties });

        yield put(userPrefsFetched(userPrefsResponse));
    } catch (err) {
         //log error
         appInsights && appInsights.trackException({ exception: err, properties: properties });

        yield put(userPrefError(err));
    }
}