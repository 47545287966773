import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectReallocationDetailsTableDomain = state => state.reallocationDetailsTable || initialState;
const selectRoot = state => state;

const makeSelectIsLoading = () =>
    createSelector(
        selectReallocationDetailsTableDomain,
        substate => substate.isLoading,
    );

const makeSelectOrderdetails = () =>
    createSelector(
        selectReallocationDetailsTableDomain,
        substate => substate.orderDetails,
    );


const makeSelectReallocationDetailsTable = () =>
    createSelector(
        selectReallocationDetailsTableDomain,
        substate => substate,
    );

const makeSelectReacllocationDetailsError = () =>
    createSelector(
        selectReallocationDetailsTableDomain,
        substate => substate.error
    );

const makeSelectBillToCountryCode = () =>
    createSelector(
        selectReallocationDetailsTableDomain,
        substate => substate.billToCountryCode
    );

export default makeSelectReallocationDetailsTable;
export { makeSelectIsLoading, makeSelectOrderdetails, makeSelectReacllocationDetailsError, makeSelectBillToCountryCode };
