import { FBC_COLLAPSE_ALL_SECTIONS, FBC_EXPAND_ALL_SECTIONS, FBC_GET_PO_DETAILS, FBC_GET_PO_SUCCESS, FBC_GET_PO_ERROR } from "./constants";

export function collapseAllSections() {
  return {
      type: FBC_COLLAPSE_ALL_SECTIONS
  };
}

export function expandAllSections() {
  return {
      type: FBC_EXPAND_ALL_SECTIONS
  };
}

export function getPoData(poId) {
  return {
      type: FBC_GET_PO_DETAILS,
      payload: poId
  };
}

export function getPoSuccess(invoices, searchObject) {
  return {
      type: FBC_GET_PO_SUCCESS,
      invoices,
      searchObject
  };
}

export function getPoError(error) {
  return {
      type: FBC_GET_PO_ERROR,
      error
  };
}
