import {
    CLOSE_SUBMIT_CONFIRMATION,
    SUBMIT_MULTI_TENANT_FORM,
    SUBMIT_MULTI_TENANT_REQUEST,
    SUBMIT_RETURN_FAIL,
    SUBMIT_RETURN_SUCCESS,
    SUBMIT_MT_ApprovalRequest,
    SUBMIT_MT_ApprovalRequest_SUCCESS,
    SUBMIT_MT_ApprovalRequest_FAIL,
    CHANGE_MT_TRACKING_ID,
    CHANGE_MT_COSMIC_CASENUMBER
} from "./constants";

export function submitMultiTenantForm() {
    return {
        type: SUBMIT_MULTI_TENANT_FORM
    };
}

export function closeSubmitConfirmation() {
    return {
        type: CLOSE_SUBMIT_CONFIRMATION
    };
}

export function submitMTReturnForm() {
    return {
        type: SUBMIT_MULTI_TENANT_REQUEST
    };
}

export function submitMTRequestSuccess(response) {
    return {
        type: SUBMIT_RETURN_SUCCESS,
        response
    };
}

export function submitMTRequestError(error) {
    return {
        type: SUBMIT_RETURN_FAIL,
        error
    };
}

export function submitMTApprovalRequest(value) {
    return {
        type: SUBMIT_MT_ApprovalRequest,
        value
    };
}

export function submitMTApprovalRequestSuccess(response) {
    return {
        type: SUBMIT_MT_ApprovalRequest_SUCCESS,
        response,
    };
}

export function submitMTApprovalRequestFailure(error) {
    return {
        type: SUBMIT_MT_ApprovalRequest_FAIL,
        error,
    };
}

export function changeRequiredCosmicCaseNumber(cosmicCaseNumberValue) {
    return {
        type: CHANGE_MT_COSMIC_CASENUMBER,
        cosmicCaseNumberValue
    };
}

export function changeRequiredTrackingId(crmId) {
    return {
        type: CHANGE_MT_TRACKING_ID,
        crmId
    };
}