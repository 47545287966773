import { FILTER_CREDITS } from './constants';
import { select, takeLatest } from 'redux-saga/effects';
import { FilterCreditsList, FilterCreditsListError } from './actions';
import { creditListRequestCreate } from '../../../../common/internals/api/requests';
import { sagaFetchHandler } from '../../../../common/internals/api/sagaFetchHandler';
import { formatSearchRequest } from '../../../../common/internals/utils/formatRequest';
import { makeSelectAccessToken, makeSelectAuthType } from '../../../../common/app/selectors';
import {
    makeSelectRequestId,
    makeSelectRequestStatus,
    makeSelectSalesLocation,
    makeSelectOperationCenter,
    makeSelectInvoiceNumber,
    makeSelectPoNumber,
    makeSelectEnrollmentNumber,
    makeSelectCustomerNumber,
    makeSelectCosmicNumber,
    makeSelectCustomerName,
    makeSelectProgramCode,
    makeSelectDateRange,
    makeSelectSubCFORequest
} from './selectors';

export function* getCreditList() {
    var creditListRequest = creditListRequestCreate();
    yield* setAuthTokens(creditListRequest);

    var requestParams = {
        "dateRange": yield select(makeSelectDateRange()),
        "returnsIdentifier": yield select(makeSelectRequestId()), // requestId
        "countryCode": yield select(makeSelectSalesLocation()),
        "operationsCenterCode": yield select(makeSelectOperationCenter()), //operationCenter
        "billingDocumentNbr": yield select(makeSelectInvoiceNumber()), //invoiceNumber
        "purchaseOrderNumber": yield select(makeSelectPoNumber()), //poNumber
        "agreementNumber": yield select(makeSelectEnrollmentNumber()), //enrollmentNumber
        "endCustomerPurchaseOrderNumber": yield select(makeSelectCustomerNumber()), //customerNumber    
        "cosmicNumber": yield select(makeSelectCosmicNumber()), //cosmicNumber
        "programCode": yield select(makeSelectProgramCode()), //programCode
        "endCustomerName": yield select(makeSelectCustomerName())  //customerName
    };
    
    if (window.isSubCFO) {
        requestParams["cfoRequestStatusCode"] = yield select(makeSelectRequestStatus());
    } else {
        requestParams["requestStatusCode"] = yield select(makeSelectRequestStatus());
        requestParams["isSubCFORequest"] = yield select(makeSelectSubCFORequest());
    }

    if (window.isFieldUser) {
        requestParams["hasMultipleInvoice"] = false;
    }
    var requestPayload = formatSearchRequest(requestParams);
    creditListRequest.options.body = requestPayload;
    yield* sagaFetchHandler(creditListRequest, FilterCreditsList, FilterCreditsListError);
}

function* setAuthTokens(request) {
    const accessToken = yield select(makeSelectAccessToken());
    const authType = yield select(makeSelectAuthType());
    request.options.headers["Authorization"] = "Bearer " + accessToken;
    request.options.headers["x-ms-AuthType"] = authType;
}

export default function* filterCreditListRequestSaga() {
    yield takeLatest(FILTER_CREDITS, getCreditList);
}