import { defineMessages } from 'react-intl';

export const scope = 'submitHome';

export default defineMessages({
    createRequestApproval: {
        id: `${scope}.createRequestApproval`,
        defaultMessage: 'Credit Request Approval',
    },
});
