import produce from 'immer';
import { SEARCH_TENANT } from './constants';

export const initialState = { selectedTenant : null};

const rebillDetailsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
      switch (action.type) {
          case SEARCH_TENANT:
              draft.selectedTenant = action.tenant;
              break;
    }
  });

export default rebillDetailsReducer;
