import produce from 'immer';
import { SEARCH_INVOICE_SUCCESS } from '../../../common/components/createRequest/constants'
import { GET_RETURN_SUCCESS } from '../../../common/components/fetchReturnRequest/constants';
import { GET_MULTI_INVOICE_RETURN_SUCCESS } from '../../../common/components/fetchMultiInvoiceReturnRequest/constants';
import { SEARCH_MULTI_INVOICE_SUCCESS } from "../../../common/components/createMultiInvoiceRequest/constants";
import {    CHANGE_FBC_FULL_CANCEL, CHANGE_FBC_RETURN_REASON,    CHANGE_FBC_COSMIC_CASENUMBER,    CHANGE_FBC_SYTEMPONUMBER, CHANGE_ADJUST_YEAR} from './constants'
import { isLRDSFullCreditCountryCodeAndSubCheck } from '../../../../common/internals/utils/HandlingFeeHelper';
import { FBC_GET_PO_SUCCESS } from '../../../operations/containers/cancelFutureBillingRequest/constants';
import { FBC_SEARCH_WITH_POAA_SUCCESS } from '../../../common/components/cancelFutureBillingCreateRequest/constants';


export const initialState = {};

const cfbReturnDetailsReducer = (state = initialState, action) =>
    produce(state, (draft) => {

       
        switch (action.type) {
            
            
            case SEARCH_MULTI_INVOICE_SUCCESS:
                draft.isDirectAgreement = action.invoices.isDirectAgreement;
                draft.proposalId = action.invoices.proposalId;
                draft.isProposalId = action.invoices.isProposalId;
                draft.isBdApproved = action.invoices.isBdApproved;
                draft.programCode = action.invoices.programCode;
                draft.billToCountryCode = action.invoices.billToCountryCode;
                draft.isFullCancellation = true;
                draft.netCreditAmount = action.invoices.netCreditUSD;
                draft.netCreditAmountLocal = action.invoices.netAmount;
                draft.returnReason = action.invoices.returnReason;
                draft.grossCredit = action.invoices.invoiceAmountUSD;
                draft.grossCreditLocal = action.invoices.invoiceAmount;
                // ToDo : Change Handling Fees to a Flag ( 5 is considered as False here)
                draft.handlingFeePercentage = 5;
                draft.operationsCenterCode = action.invoices.operationsCenterCode;
                draft.alternateROC = (action.invoices.alternateROC) ? action.invoices.alternateROC : action.invoices.operationsCenterCode;
                draft.programCode = action.invoices.programCode;
                draft.hasMultipleRequestCurrency = action.invoices.hasMultipleRequestCurrency;
                draft.hasMultipleRebillCurrency = action.invoices.hasMultipleRebillCurrency;
                draft.isCurrencyDiff = (draft.hasMultipleRequestCurrency || draft.hasMultipleRebillCurrency) ? true : false;
                break;
            case GET_MULTI_INVOICE_RETURN_SUCCESS:
                draft.proposalId = action.invoices.proposalId;
                draft.programCode = action.invoices.programCode;
                draft.billToCountryCode = action.invoices.billToCountryCode;
                draft.handlingFeePercentage = 5;
                draft.isBdApproved = action.invoices.isBdApproved;
                draft.returnReason = action.invoices.returnReason;
                draft.netCreditAmount = action.invoices.netCreditUSD;
                draft.netCreditAmountLocal = action.invoices.netAmount;
                draft.grossCredit = action.invoices.invoiceAmountUSD;
                draft.grossCreditLocal = action.invoices.invoiceAmount;
                //draft.grossCredit = action.inv
                draft.isDirectAgreement = action.invoices.isDirectAgreement;
                draft.isFullCredit = isLRDSFullCreditCountryCodeAndSubCheck(action.invoices.billToCountryCode, action.invoices.isSUB) ?
                    true : action.invoices.isFullCredit;
                draft.operationsCenterCode = action.invoices.operationsCenterCode;
                draft.alternateROC = (action.invoices.alternateROC) ? action.invoices.alternateROC : action.invoices.operationsCenterCode;
                draft.programCode = action.invoices.programCode;
                draft.hasMultipleRequestCurrency = action.invoices.hasMultipleRequestCurrency;
                draft.hasMultipleRebillCurrency = action.invoices.hasMultipleRebillCurrency;
                draft.isCurrencyDiff = (draft.hasMultipleRequestCurrency || draft.hasMultipleRebillCurrency) ? true : false;
                break;
            case GET_RETURN_SUCCESS:
                draft.billToCountryCode = action.invoice.billToCountryCode;
                draft.licenseAge = action.invoice.licenseAge;
                draft.isBdApproved = action.invoice.isBdApproved;
                draft.handlingFeePercentage = action.invoice.handlingFeePercentage;
                draft.returnReason = action.invoice.returnReason;
                draft.netCreditAmount = action.invoice.netCreditUSD;
                draft.netCreditAmountLocal = action.invoice.netAmount;
                draft.proposalId = action.invoice.proposalId;
                draft.isProposalId = action.invoice.isProposalId;
                draft.programCode = action.invoice.programCode;
                draft.isDirectAgreement = action.invoice.isDirectAgreement;
                draft.isFullCredit
                    = isLRDSFullCreditCountryCodeAndSubCheck(action.invoice.billToCountryCode, action.invoice.isSUB) ?
                        true : action.invoice.isFullCredit;
                draft.operationsCenterCode = action.invoice.operationsCenterCode;
                draft.alternateROC = (action.invoice.alternateROC) ? action.invoice.alternateROC : action.invoice.operationsCenterCode;
                draft.programCode = action.invoice.programCode;
                draft.hasMultipleRequestCurrency = action.invoice.hasMultipleRequestCurrency;
                draft.hasMultipleRebillCurrency = action.invoice.hasMultipleRebillCurrency;
                draft.isCurrencyDiff = (draft.hasMultipleRequestCurrency || draft.hasMultipleRebillCurrency) ? true : false;

                break;
            case SEARCH_INVOICE_SUCCESS:
                draft.billToCountryCode = action.invoice.billToCountryCode;
                draft.licenseAge = action.invoice.licenseAge;
                draft.proposalId = action.invoice.proposalId;
                draft.isProposalId = action.invoice.isProposalId;
                draft.programCode = action.invoice.programCode;
                draft.isBdApproved = action.invoice.isBdApproved;
                draft.handlingFeePercentage = action.invoice.handlingFeePercentage;
                draft.returnReason = action.invoice.returnReason;
                draft.isDirectAgreement = action.invoice.isDirectAgreement;
                draft.netCreditAmount = isLRDSFullCreditCountryCodeAndSubCheck(action.invoice.billToCountryCode, action.invoice.isSUB) ?
                    action.invoice.invoiceAmountUSD : action.invoice.netCreditUSD;
                draft.netCreditAmountLocal = isLRDSFullCreditCountryCodeAndSubCheck(action.invoice.billToCountryCode, action.invoice.isSUB) ?
                    action.invoice.invoiceAmount : action.invoice.netAmount;
                draft.isFullCredit
                    = isLRDSFullCreditCountryCodeAndSubCheck(action.invoice.billToCountryCode, action.invoice.isSUB) ?
                        true : action.invoice.isFullCredit;
                draft.operationsCenterCode = action.invoice.operationsCenterCode;
                draft.alternateROC = (action.invoice.alternateROC) ? action.invoice.alternateROC : action.invoice.operationsCenterCode;
                draft.programCode = action.invoice.programCode;
                draft.hasMultipleRequestCurrency = action.invoice.hasMultipleRequestCurrency;
                draft.hasMultipleRebillCurrency = action.invoice.hasMultipleRebillCurrency;
                draft.isCurrencyDiff = (draft.hasMultipleRequestCurrency || draft.hasMultipleRebillCurrency) ? true : false;
                break;

            case CHANGE_FBC_SYTEMPONUMBER:
                draft.rebillSystemPONumber = action.value;
                break;
            case CHANGE_FBC_COSMIC_CASENUMBER:
                draft.cosmicCaseNumber = action.value;
                break;
            case CHANGE_FBC_FULL_CANCEL:
                draft.isFullCancellation = action.value;
                draft.adjustedYear = null;
                break;
            case CHANGE_ADJUST_YEAR:
                draft.adjustedYear = action.value;
                break;
                case FBC_GET_PO_SUCCESS:
                    draft.isFullCancellation = false;
                    draft.returnReason = "";
                    draft.cosmicCaseNumber = "";
                    draft.rebillSystemPONumber = "";
                    draft.adjustedYear = null;
                    break;
                    case FBC_SEARCH_WITH_POAA_SUCCESS:
                    draft.isFullCancellation = false;
                    draft.returnReason = "";
                    draft.cosmicCaseNumber = "";
                    draft.rebillSystemPONumber = "";
                draft.adjustedYear = null;
                draft.billToCountryCode = action.invoices.billToCountryCode;
                draft.programCode = action.invoices.programCode;
                draft.proposalId = action.invoices.proposalId;
                    break;
            case CHANGE_FBC_RETURN_REASON:
                draft.returnReason = action.value;
                draft.adjustedYear = null;
                break;
        }
    });

export default cfbReturnDetailsReducer;
