import React, { Component } from "react";
import { injectIntl } from 'react-intl';
export class DropDown extends Component {
    render() { 
        const { formatMessage } = this.props.intl;
        let options;
        if (this.props.inputList && this.props.inputList.length > 0) {
            options = this.props.inputList.map(a => <option value={a.key} key={a.key} name={a.key} aria-label={a.value}>{a.value}</option>);            
        }
        var ariaLabel = formatMessage({ id: "lblSelect" });
        var defailtSelect = "--" + ariaLabel + "--";
        return (
            <div className="combobox">
                <select aria-invalid="false" value={this.props.selectedValue} required={this.props.required}  className={this.props && this.props.className ? this.props.className : 'form-control'} disabled={this.props.disabled} id={this.props.id} aria-label={this.props.ariaLabel} onChange={this.props.onChange}>
                    {this.props.includeAll && <option value="-1" key="-1Select" aria-label={ariaLabel}>{defailtSelect}</option>}
                    {options}
                </select>
            </div>
        );

    }
}
export default injectIntl(DropDown);
