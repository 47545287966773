import produce from 'immer';
import { ADD_REALLOCATE } from '../../components/reallocationDetailsTable/constants';
import { RESET_TO_INITIAL_STATE } from '../../containers/multiTenantSearchAndAdd/constants';
import { ON_REALLOCATION_MODAL_CLOSE, ON_REALLOCATION_MODAL_ERROR } from './constants';


export const initialState = { isReallocateModalOpen: false };

const reallocateModalReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case ADD_REALLOCATE:
                draft.isReallocateModalOpen = true;
                draft.poNumber = action.data.poNumber;
                draft.externalIdentifier = action.data.externalIdentifier;
                break;
            case ON_REALLOCATION_MODAL_CLOSE:
                draft.isReallocateModalOpen = false;
                draft.error = undefined;
                break;
            case RESET_TO_INITIAL_STATE:
                draft.isReallocateModalOpen = false;
                break;
            case ON_REALLOCATION_MODAL_ERROR:
                draft.error = action.value;
                break;
        }
    });

export default reallocateModalReducer;
