//#region import
import React, { memo, useContext } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { createStructuredSelector } from "reselect";

import reducer from "./reducer";
import saga from "./saga";
import DropDownBox from "../../../common/components/controls/DropDown";
import LocalisedLabel from "../../../common/components/controls/LocalisedLabel";
import TextBox from "../../../common/components/controls/TextBox";
import { useInjectSaga } from "../../../common/internals/utils/injectSaga";
import { useInjectReducer } from "../../../common/internals/utils/injectReducer";
import {
    FilterCancellationRequests,
    changeRequestIdentifier,
    changeRequestStatusCode,
    changePurchaseOrderNumber,
    changeAgreementNumber,
    changeCosmicNumber,
    changeCustomerName
} from "./actions";

import {
    makeSelectLoading,
    makeSelectError,
    makeSelectRequestId,
    makeSelectRequestStatus,
    makeSelectPoNumber,
    makeSelectSystemPoNumber,
    makeSelectEnrollmentNumber,
    makeSelectCosmicNumber,
    makeSelectCustomerName,
} from "./selectors";

import { makeSelectUserInfo } from "../../../common/app/selectors";

import {
    PartnerRequestStatus_FBC_Search,
    OpsRequestStatus_FBC,
} from "../../../common/internals/utils/requestStatusRoleBased";
//#endregion

//#region function component
const FilterCriteria = (props) => {

    const { formatMessage } = props.intl;

    useInjectReducer({ key: "cancellationList", reducer });
    useInjectSaga({ key: "cancellationList", saga });

    const userRole = window && window.userRole;
    console.log("loginUserRole: " + userRole);

    var lblSearchText = formatMessage({ id: "lblSearchText" });
    return (
        <div className="row spacer-xs-bottom">
            <div className="col-sm-24 col-md-24 col-lg-24">
                <div className="row spacer-xs-bottom">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <LocalisedLabel htmlFor="txtRequestId" className="form-label" ariaLabel="Request ID" id="lblRequestId" value="Request ID" />
                        <TextBox type="text" className="form-control mainElement" id="txtRequestId" value={props.requestId} onChange={props.onChangeRequestIdentifier} />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <LocalisedLabel
                            htmlFor="txtEnrolmentNumber"
                            className="form-label"
                            id="lblEnrolmentNumber"
                        />
                        <TextBox
                            type="text"
                            className="form-control"
                            id="txtEnrolmentNumber"
                            value={props.enrollmentNumber}
                            onChange={props.onChangeAgreementNumber}
                        />
                    </div>

                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <LocalisedLabel
                            htmlFor="txtPONumber"
                            className="form-label"
                            id="lblPONumber"
                        />
                        <TextBox
                            type="text"
                            className="form-control"
                            id="txtPONumber"
                            value={props.poNumber}
                            onChange={props.onChangePurchaseOrderNumber}
                        />
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <LocalisedLabel
                            className="form-label"
                            htmlFor="ddlRequestStatus"
                            id="lblRequestStatus"
                            ariaLabel="Request Status"
                            value="Request Status"
                        />
                        <DropDownBox
                            id="ddlRequestStatus"
                            ariaLabel="Request Status"
                            inputList={
                                userRole === "VPU"
                                    ? PartnerRequestStatus_FBC_Search
                                    : OpsRequestStatus_FBC
                            }
                            includeAll="true"
                            value={props.requestStatus}
                            onChange={props.onChangeRequestStatusCode}
                        />
                    </div>
                    
                    {userRole != "VPU" ? (
                        <div className="col-sm-6 col-md-6 col-lg-6">
                            <LocalisedLabel
                                htmlFor="txtCosmicNumber"
                                className="form-label"
                                id="lblCosmicNumber"
                            />
                            <TextBox
                                type="text"
                                className="form-control"
                                id="txtCosmicNumber"
                                value={props.cosmicNumber}
                                onChange={props.onChangeCosmicNumber}
                            />
                        </div>
                    ) : null}
                    <div className="col-sm-6 col-md-6 col-lg-6">
                        <LocalisedLabel
                            htmlFor="txtCustomerName"
                            className="form-label"
                            id="lblCustomerName"
                        />
                        <TextBox
                            type="text"
                            className="form-control"
                            id="txtCustomerName"
                            value={props.endCustomerName}
                            onChange={props.onChangeCustomerName}
                        />
                    </div>
                </div>

                <div className="row spacer-xs-bottom pull-right">
                    <div className="col-sm-24 col-md-24 col-lg-24 ">
                        <div className="btn-group">
                            <button
                                id="btnSearch"
                                className="btn btn-default active"
                                type="submit"
                                aria-label={lblSearchText}
                                onClick={props.onSearchLicenseRequestList}
                            >
                                {lblSearchText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

//#endregion

//#region Validating property types getting inside this component through prop param.

FilterCriteria.propTypes = {
    cosmicNumber: PropTypes.string,
    endCustomerName: PropTypes.string,
    dateRange: PropTypes.string,
    enrollmentNumber: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    loading: PropTypes.bool,
    poNumber: PropTypes.string,
    requestId: PropTypes.string,
    requestStatus: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
    ]),
    onChangeAgreementNumber: PropTypes.func,
    onChangeCosmicNumber: PropTypes.func,
    onChangeCustomerName: PropTypes.func,
    onChangePurchaseOrderNumber: PropTypes.func,
    onChangeRequestIdentifier: PropTypes.func,
    onChangeRequestStatusCode: PropTypes.func,
    onSearchLicenseRequestList: PropTypes.func
};

//#endregion

//#region map state and dispatch 

const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    error: makeSelectError(),
    requestId: makeSelectRequestId(),
    requestStatus: makeSelectRequestStatus(),
    poNumber: makeSelectPoNumber(),
    systemPONumber: makeSelectSystemPoNumber(),
    enrollmentNumber: makeSelectEnrollmentNumber(),
    cosmicNumber: makeSelectCosmicNumber(),
    endCustomerName: makeSelectCustomerName(),
    userInformation: makeSelectUserInfo()
});

const mapDispatchToProps = (dispatch) => {
    return {
        onChangeRequestIdentifier: (evt) =>
            dispatch(changeRequestIdentifier(evt.target.value)),
        onChangeRequestStatusCode: (evt) =>
            dispatch(changeRequestStatusCode(evt.target.value.split(","))),
        onChangePurchaseOrderNumber: (evt) =>
            dispatch(changePurchaseOrderNumber(evt.target.value)),
        onChangeAgreementNumber: (evt) =>
            dispatch(changeAgreementNumber(evt.target.value)),
        onChangeCosmicNumber: (evt) =>
            dispatch(changeCosmicNumber(evt.target.value)),
        onChangeCustomerName: (evt) =>
            dispatch(changeCustomerName(evt.target.value)),
        onSearchLicenseRequestList: evt => {
            dispatch(FilterCancellationRequests());
        }
    };
};

//#endregion

//#region  connect to store 

const withConnect = connect(mapStateToProps, mapDispatchToProps);

//#endregion

export default compose(withConnect, memo)(injectIntl(FilterCriteria));
