export const DOCUMENT_SELECTED = "DOCUMENT_SELECTED";
export const FILE_SELECTED = "FILE_SELECTED";
export const FILE_VALIDATION_ERRORS = "FILE_VALIDATION_ERRORS";
export const FILE_READ_ERROR = "FILE_READ_ERROR";
export const FILE_SIGNATURE_ERROR = 'FILE_SIGNATURE_ERROR';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';
export const FILE_READ = 'FILE_READ';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_RESET = 'FILE_UPLOAD_RESET';
export const FILE_REMOVED = "FILE_REMOVED";
export const FILE_REMOVED_SUCCESS = "FILE_REMOVED_SUCCESS";
export const FILE_REMOVED_ERROR = "FILE_REMOVED_ERROR";
export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_CLOSED = "MODAL_CLOSED";
export const DOWNLOAD_FILE = "DOWNLOAD_FILE";
export const DOWNLOAF_FILE_SUCCESS = "DOWNLOAF_FILE_SUCCESS";
export const DOWNLOAF_FILE_ERROR = "DOWNLOAF_FILE_ERROR";

export const FileSignatures = [
    ["pdf", [0x25, 0x50, 0x44, 0x46, 0x2d]],
    ["doc", [0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xB1, 0x1A, 0xE1]],
    ["docx", [0x50, 0x4B, 0x03, 0x04]],
    ["jpg", [0xFF, 0xD8, 0xFF, 0xE0]],
    ["png", [0x89, 0x50, 0x4E, 0x47, 0x0D, 0x0A, 0x1A, 0x0A]],
    ["msg", [0xD0, 0xCF, 0x11, 0xE0, 0xA1, 0xB1, 0x1A, 0xE1]],
    ["xlsx", [0x50, 0x4B, 0x03, 0x04, 0x14, 0X00, 0x60, 0x00]]									  															  															 															  
];

const FileUploadTranslations = [];

export var FileUploadTranslationsMap = new Map(FileUploadTranslations);

export const MaxFileSizeForUploadInBytes = 5242880;
export const MaxFileSizeForUploadInMB = 5;
export const MaxFileUploadCount = 5;

export const DocumentType = {
    0: 'Customer Acknowledgement Form',
    1: 'ROC Director Approval Form',    
    2: 'Others',
    4: 'Customer Acknowledgement Form',
    5: 'ROC Director Approval Form'

};
