import React from 'react';
import { compose } from 'redux';
import SubCFOApprovalRequest from '../request/Loadable';
import SubCFOApprovalMultiInvoiceRequest from '../multiInvoiceRequest/Loadable';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { injectIntl } from 'react-intl';
import ApprovalErrors from '../../../../common/approvalErrors';
import ApprovalInfo from '../../../../common/approvalInfo';

function SubCFOApprovalHome(props) {   
    sessionStorage.removeItem('VLReturnsRedirectUri');
    var searchParams = new URLSearchParams(props.location.search);

    var isMultiInvoice = false;
    if (!searchParams.get("invoice")) {
        isMultiInvoice = true;
    }

    return (
        <div className="spacer-32-top">
            <div className="row">
                <div className="col-md-24 spacer-32-top" >
                    <ApprovalInfo />
                    <ApprovalErrors />
                </div>
            </div>
            <div className="row">
                <div className="col-md-24 spacer-32-top" >
                    <h1 className="section-title">
                        <FormattedMessage {...messages.createRequestSubCFOApproval} />
                    </h1>
                </div>
            </div>

            {
                isMultiInvoice ? (
                    <SubCFOApprovalMultiInvoiceRequest props={props} />

                ) : (
                        <SubCFOApprovalRequest props={props} />
                    )
            }

        </div>

    );
}


export default compose(injectIntl(SubCFOApprovalHome));
