import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { isNotaFiscalNumberCountryCode } from '../../../../common/internals/utils/HandlingFeeHelper';
import { isLRDCountryCode, isLRDeReceiptCountryCode } from '../../../../common/internals/utils/HandlingFeeHelper';

/* Direct selector to the createRequest state domain */
const selectCreateRequestDomain = state => state.createRequest || initialState;

/* Default selector used by CreateRequest */
const makeSelectCreateRequest = () =>
    createSelector(
        selectCreateRequestDomain,
        substate => substate,
    );

const makeSelectInvoiceNumberCreate = () =>
    createSelector(selectCreateRequestDomain, substate => substate.invoiceNumber);

const makeSelectCosmicCaseNumber = () =>
    createSelector(selectCreateRequestDomain,
        substate => substate.invoice && substate.invoice.purchaseOrderId ?
            (substate.invoice.cosmicCaseNumber ? substate.invoice.cosmicCaseNumber : ' ') : null);

const makeSelectLocalInvoiceNumberCreate = () =>
    createSelector(
        selectCreateRequestDomain,
        substate => substate.invoice ? substate.invoice.localInvoiceNumber : null);

const makeSelectInvoiceCreate = () =>
    createSelector(selectCreateRequestDomain, substate => substate.invoice);

const makeSelectCreateRequestIsLRD = () =>
    createSelector(selectCreateRequestDomain,
        substate => substate.invoice ?
            isNotaFiscalNumberCountryCode(substate.invoice.billToCountryCode)
            : null);

const makeSelectCreateRequestIsLRDeReceipt = () =>
    createSelector(selectCreateRequestDomain,
        substate => substate.invoice ?
            isLRDeReceiptCountryCode(substate.invoice.billToCountryCode)
            : null);

const makeSelectCreateRequestError = () =>
    createSelector(selectCreateRequestDomain, substate => substate.error);

const makeSelectCreateRequestLoading = () =>
    createSelector(selectCreateRequestDomain, substate => substate.loading);

export default makeSelectCreateRequest;
export {
    makeSelectCreateRequestLoading,
    makeSelectCreateRequest,
    makeSelectInvoiceNumberCreate,
    makeSelectInvoiceCreate,
    makeSelectCreateRequestError,
    makeSelectCreateRequestIsLRD,
    makeSelectLocalInvoiceNumberCreate,
    makeSelectCosmicCaseNumber,
    makeSelectCreateRequestIsLRDeReceipt
};
