//#region import section
import React, { useEffect, memo } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { createStructuredSelector } from "reselect";
import { Link } from "react-router-dom";
import uuidv4 from "uuid/v4";

import GridControl from "../../../common/components/grid/GridControl";
import ErrorMessage from "../../../common/components/ErrorMessage/ErrorMessage";
import LoadingIndicator from "../../../common/components/LoadingIndicator/index";
import { makeSelectDomainData } from "../../../common/app/selectors";
import * as Helpers from "../../../common/internals/utils/helpers";
import DateRangeFilter from "../../../common/components/controls/DateRangeFilter";
import errorMessages from "../../../common/internals/api/errorMessages";

import FilterCriteria from "./FilterCriteria";
import {
    FilterCancellationRequests,
    changeDateRange,
} from "./actions";
import {
    makeSelectLoading,
    makeSelectError,
    makeSelectCancellation,
    makeSelectDateRange,
} from "./selectors";


//#endregion

var dateRangeElement = "";

//#region function component declaration
const Search = (props) => {
    const { formatMessage } = props.intl;

    window.vlcredits.FeatureName = "FBC";
    

    const userRole = window && window.userRole;

    const linkFormatter = (cell, row) => {
        if (row) {
            var url = `../fbc/request?docId=${row.invoice_number}&requestId=${row.requestId}&status=${row.request_status_code}`
            return <Link to={url}> {row.requestId}</Link>;
        }
    };

    function onDateRangeSelection(e, value) {
        dateRangeElement = PrepareFilterButtons(value);
    }

    function getCosmicTickets(cosmicCaseList) {
        if (Helpers.isEmpty(cosmicCaseList)) {
            return null;
        }
        const _strCosmicCases =
            cosmicCaseList &&
            cosmicCaseList.reduce((_strCosmicCases, data) => {
                if (!Helpers.isEmpty(data) && !Helpers.isEmpty(data.ticketId)) {
                    _strCosmicCases.push(data.ticketId);
                }
                return _strCosmicCases;
            }, []);

        return _strCosmicCases.join(",\r\n");
    }

    function handleApplyEvent(value) {
        dateRangeElement = PrepareFilterButtons(value);
    }

    function PrepareFilterButtons(lifetimeDays) {
        props.onChangeDateRange(lifetimeDays);
        props.onCancellationRequestsSearch();
        return (
            <DateRangeFilter
                key="lifetimebuttons"
                lifetimeDays={lifetimeDays}
                handleApplyEvent={handleApplyEvent}
                onSearchChange={props.onSearchChange}
                onDateRangeSelection={onDateRangeSelection}
            />
        );
    }

    useEffect(() => {
        document.title =
            formatMessage({ id: "lblApplicationName" }) +
            " - " +
            formatMessage({ id: "lblSeachPageName" });
        dateRangeElement = PrepareFilterButtons("1D");
    }, []);

    let columns = [
        {
            dataField: "requestId",
            text: formatMessage({ id: "lblRequestId" }),
            title: true,
            headerTitle: true,
            formatter: linkFormatter,
        },
        {
            dataField: "request_date",
            text: formatMessage({ id: "lblRequestDate" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        },
        {
            dataField: "request_status",
            text: formatMessage({ id: "lblRequestStatues" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        },
        {
            dataField: 'customer_name',
            text: formatMessage({ id: "lblCustomerName" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        },
        {
            dataField: "enrollment_number",
            text: formatMessage({ id: "lblEnrolmentNumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        },
        {
            dataField: "po_number",
            text: formatMessage({ id: "lblPONumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        },
    ];

    const additionalColumnsForROCUser = [
        {
            dataField: "system_po_number",
            text: formatMessage({ id: "lblSystemPONumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        },
        {
            dataField: "cosmic_cases",
            text: formatMessage({ id: "lblCosmicNumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        }
    ];

    //Display cosmic case only for ROC users.
    columns = (userRole != 'VPU') ? columns.concat(additionalColumnsForROCUser) : columns;


    let statusMessage = "";
    if (props && props.error && "errorMessages" in props.error) {
        const titleMessage = {
            id: "titleErrorMessage",
            defaultMessage: "Search Error",
        };
        statusMessage = (
            <ErrorMessage
                key="cancellationSearchErrorMessages"
                messageBody={props.error.errorMessages}
                messageTitle={titleMessage}
                defaultMessage={errorMessages.tokenExpiredMsg}
            />
        );
    }

    var licenseCancellationRequestList = [];
    if (props.response && props.response.length > 0) {
        licenseCancellationRequestList = [];

        props.response.map((data) =>
            licenseCancellationRequestList.push({
                uniqueId: uuidv4(),
                requestId: data.returnsIdentifier,
                hasMultiple: data.hasMultipleInvoice,
                invoice_number: data.hasMultipleInvoice
                    ? Helpers.getMultiPleInvoices(
                        data.multipleInvoiceList,
                        "invoiceNumber"
                    )
                    : data.billingDocumentNbr,
                request_date: Helpers.DateFormatForDisplay(data.requestSubmitDate),
                request_status: userRole === 'VPU'
                    ? Helpers.GetFbcPartnerStatusByCode(data.requestStatusCode)
                    : Helpers.GetFbcOpsStatusByCode(data.requestStatusCode),
                request_status_code: data.requestStatusCode,
                customer_name: data.endCustomerName,
                customer_number: data.endCustomerPurchaseOrderNumber,
                enrollment_number: data.agreementNumber,
                po_number: data.purchaseOrderNumber,
                system_po_number: data.systemPONumber,
                multiInvoiceNumber: data.billingDocumentNbr,
                cosmic_cases: getCosmicTickets(data.cosmicCases),
            })
        );
    }
    return (
        <div>
            <div>
                <div className="form-group">
                    <div className="col-lg-24">
                        <div className="row spacer-xs-bottom">
                            <div className="col-sm-24 col-md-24 col-lg-24">
                                {statusMessage}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h1 className="section-title">
                                    {formatMessage({ id: "lblSearchText" })}
                                </h1>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                {dateRangeElement}
                            </div>
                        </div>
                        <FilterCriteria
                            key="fbcFilterCriteria"
                            intl={props.intl}
                            domainData={props.domainData}
                            location={props && props.location}
                        />
                        <div className="row">
                            <div className="col-sm-10 col-md-10 col-lg-10">
                                <div className={!props.loading ? "hidden" : ""}>
                                    <LoadingIndicator />
                                </div>
                            </div>
                        </div>
                        <div className="row spacer-xs-bottom">
                            <GridControl
                                key="gridcontrolfordatadisplay"
                                columns={columns}
                                data={licenseCancellationRequestList}
                                intl={props.intl}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

//#endregion

//#region Validating properties coming inside this function component through props param
Search.propTypes = {
    dateRange: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    loading: PropTypes.bool,
    onCancellationRequestsSearch: PropTypes.func,
    onChangeDateRange: PropTypes.func,
};

//#endregion

const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    error: makeSelectError(),
    response: makeSelectCancellation(),
    domainData: makeSelectDomainData(),
    dateRange: makeSelectDateRange(),
});

const mapDispatchToProps = (dispatch) => {
    return {
        onCancellationRequestsSearch: () => dispatch(FilterCancellationRequests()),
        onChangeDateRange: (data) => dispatch(changeDateRange(data)),
    };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(injectIntl(Search));
