import { takeLatest, select } from 'redux-saga/effects';
import { FBC_GET_RETURN } from './constants';
import { getReturnCompleted, getReturnError } from './actions';
import { getReturnRequestCreate } from '../../../../common/internals/api/requests';
import { sagaFetchHandler } from '../../../../common/internals/api/sagaFetchHandler';
import { transformReturnResponse } from '../../../../common/internals/api/transformReturnResponse';
import { makeSelectAuthType, makeSelectAccessToken } from '../../../../common/app/selectors';

export function* getReturn(action) {
    var getReturnRequest = getReturnRequestCreate();
    yield* setAuthTokens(getReturnRequest);
    getReturnRequest.url
        = `api/return/${action.payload.returnMultiInvoiceNumber}?api-version=1.0&StatusCode=${action.payload.status}&returnsIdentifier=${action.payload.requestId}`;

    if (action.payload && action.payload.cfostatus)
        getReturnRequest.url += `&cfoStatusCode=${action.payload.cfostatus}`;

    yield* sagaFetchHandler(getReturnRequest, getReturnCompleted,
        getReturnError, transformReturnResponse, action.payload.returnInvoiceNumber);
}

function* setAuthTokens(request) {
    const accessToken = yield select(makeSelectAccessToken());
    const authType = yield select(makeSelectAuthType());
    request.options.headers["Authorization"] = "Bearer " + accessToken;
    request.options.headers["x-ms-AuthType"] = authType;
}

export default function* fetchRequestSaga() {
    yield takeLatest(FBC_GET_RETURN, getReturn);
}