import { throttle } from "redux-saga/effects";

function parseJSON(response) {
    console.log(response);
    if (response.status === 204 || response.status === 205) {
        return null;
    }
    const result
        = response.json().catch(err => {
            //console.error(`'${err}' caught empty response`);
            if (response.status === 200) {
                return {};
            } else if (response.status === 404) {
                throw new Error("404");
            }
            else
                throw new Error(response.statusText);
        });

    if (response.status === 401)
        throw new Error("401");

    return result;
}

function parseBlob(response) {
    if (response.status === 204 || response.status === 205) {
        return null;
    }
    return response.blob();
}

export default function fetchWrapper(request, parseErrors = true) {
    //console.log(request);
    function checkStatus(response) {
        console.log(response);
        if ((response.status < 200 || response.status >= 300) && !parseErrors) {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
        return response;
    }
    return fetch(`${request.baseUrl}/${request.url}`, request.options)
        .then(checkStatus)
        .then(parseJSON);
}

export function fetchWrapperBlob(request, parseErrors = true) {

    function checkStatus(response) {

        if (response.status === 404) {
            const error = new Error(response.statusText);
            error.message = response.status.toString();
            throw error;
        }

        if ((response.status < 200 || response.status >= 300) && !parseErrors) {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
        return response;
    }

    return fetch(`${request.baseUrl}/${request.url}`, request.options)
        .then(checkStatus)
        .then(parseBlob);
}
