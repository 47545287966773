import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { isNotaFiscalNumberCountryCode } from '../../../../common/internals/utils/HandlingFeeHelper';

/* Direct selector to the FetchReturnRequest state domain */
const selectFetchMultiInvoiceReturnRequestDomain = state => state.fetchMultiInvoiceReturnRequest || initialState;

/* Default selector used by FetchReturnRequest */
const makeSelectFetchMultiInvoiceReturnRequest = () =>
    createSelector(
        selectFetchMultiInvoiceReturnRequestDomain,
        substate => substate,
    );

const makeSelectMultiInvoiceNumberFetch = () =>
    createSelector(selectFetchMultiInvoiceReturnRequestDomain, substate => substate.invoiceNumber);

const makeSelectEnrollmentNumberFetch = () =>
    createSelector(selectFetchMultiInvoiceReturnRequestDomain, substate => substate.invoices ? substate.invoices.enrollmentNumber:"");

const makeSelectSystemPONumberFetch = () =>
    createSelector(selectFetchMultiInvoiceReturnRequestDomain, substate => substate.invoices ? substate.invoices.PONumber : "" );

const makeSelectLocalMultiInvoiceNumberFetch = () =>
    createSelector(
        selectFetchMultiInvoiceReturnRequestDomain,
        substate => substate.invoices ? substate.invoices.localMultiInvoiceNumber : null);

const makeSelectFetchMultiInvoiceRequestIsLRD = () =>
    createSelector(selectFetchMultiInvoiceReturnRequestDomain,
        substate => substate.invoices ?
            isNotaFiscalNumberCountryCode(substate.invoices.billToCountryCode)
            : null);

const makeSelectInvoicesFetch = () =>
    createSelector(selectFetchMultiInvoiceReturnRequestDomain, substate => substate.invoices);

const makeSelectCosmicCaseNumber = () =>
    createSelector(selectFetchMultiInvoiceReturnRequestDomain,
        substate => substate.invoices ? substate.invoices.cosmicCaseNumber : null);

const makeSelectFetchMultiInvoiceReturnRequestError = () =>
    createSelector(selectFetchMultiInvoiceReturnRequestDomain, substate => substate.error);

const makeSelectLoading = () =>
    createSelector(selectFetchMultiInvoiceReturnRequestDomain, substate => substate.loading);

const makeSelectInvoiceNumberFetch =() =>
    createSelector(selectFetchMultiInvoiceReturnRequestDomain, substate => substate.invoices ? substate.invoices.invoiceNumber : null);

export default makeSelectFetchMultiInvoiceReturnRequest;
export {
    makeSelectLoading,
    makeSelectCosmicCaseNumber,
    makeSelectFetchMultiInvoiceReturnRequest,
    makeSelectEnrollmentNumberFetch,
    makeSelectSystemPONumberFetch,
    makeSelectMultiInvoiceNumberFetch,
    makeSelectInvoicesFetch,
    makeSelectFetchMultiInvoiceRequestIsLRD,
    makeSelectLocalMultiInvoiceNumberFetch,
    makeSelectFetchMultiInvoiceReturnRequestError,
    makeSelectInvoiceNumberFetch
};
