import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import LoadingIndicator from '../../../../common/components/LoadingIndicator';
import Modal from '../../../../common/components/modal/modal';
import MtSubmitInfo from '../../components/mtSubmitInfo';
import SubmitErrors from '../../components/SubmitErrors';
import MultiTenantRequest from '../multiTenantRequest';
import { closeSubmitConfirmation, submitMTReturnForm } from '../multiTenantRequest/actions';
import { makeSelectMultiTenantSubmitInitiated, makeSelectMultiTenantSubmitSuccess, makeSelectRequestId, makeSelectEnrollmentDetails} from '../multiTenantRequest/selectors';
import POSelectionModal from '../poSelectionModal';
import ReallocateModal from '../reallocateModal';
import messages from './messages';
import { makeSelectSelectedPurchaseOrdersData } from '../multiTenantSearchAndAdd/selectors';
import { makeSelectIsTenantSelected } from '../multiTenantRequest/selectors';
import { makeSelectisValidRequestToSubmit } from './selectors';
import { useInjectReducer } from "../../../../common/internals/utils/injectReducer";
import { useInjectSaga } from "../../../../common/internals/utils/injectSaga";
import reducer from "./reducer";
import { injectIntl } from "react-intl";
import saga from "./saga";
import MTApprovalErrors from '../../components/mtApprovalErrors';
import '../../../../common/app/accessibility-style.css';

const navigateAfterSubmit = () => {
    var uri = window.location.href.toString();
    const baseUri = uri.indexOf("?") > 0 ?
        uri.substring(0, uri.indexOf("?")) : uri;
    window.location.href = baseUri + '?submitSuccess=true';
};

function MultiTenantHome({
    props,
    submitSuccess,
    loading,
    submit,
    poNumber,
    closeDialog,
    submitInitiated,
    selectedPurchaseOrdersData,
    selectedTenant,
    isValidRequestToSubmit,
    requestId,
    enrollmentDetails
}) {
    useInjectSaga({ key: "multiTenantHome", saga });
    useInjectReducer({ key: "MultiTenantHome", reducer });

    let submitSucessModalCustomValues = {
        reqId: requestId
    };
    window.vlcredits.FeatureName = "MT";
    const userRole = window && window.userRole;
    const countryCurrency = enrollmentDetails && enrollmentDetails.pricingCurrency;
   

    return (
        <div id="MultiTenantRequestHome" className="spacer-18-top">
            <div className="row">
                <div className="col-md-24 spacer-20-top" >
                    {<SubmitErrors />}
                    {<MTApprovalErrors/>}
                    {<MtSubmitInfo />}
                    <Modal id="submitConfirmationModal"
                        isOpen={submitInitiated && selectedPurchaseOrdersData && selectedPurchaseOrdersData.length > 0 && selectedTenant && isValidRequestToSubmit}
                        headerMessage={messages.submitModalConfirmation}
                        bodyMessage={userRole === 'VPU' && countryCurrency === 'INR' ? messages.submitModalConfirmationPartner : messages.submitModalConfirmationROC}
                        actionMessage={messages.submitConfirmation}
                        action={submit}
                        closeMessage={messages.closeAction}
                        close={closeDialog}
                        isBothButtonsPrimary={true}
                    />

                    <Modal id="submitConfirmationModal"
                        isOpen={submitInitiated && (!isValidRequestToSubmit)}
                        headerMessage={messages.submitModalMessage}
                        closeMessage={messages.closeAction}
                        close={closeDialog}
                    />

                    <Modal id="submitSucessModal"
                        isOpen={submitSuccess}
                        headerMessage={messages.submitSuccess}
                        closeMessage={messages.closeAction}
                        close={navigateAfterSubmit}
                        customValues={submitSucessModalCustomValues}
                    />

                    <ReallocateModal id="ReallocateModal"
                        poNumber={poNumber}
                    />

                    <POSelectionModal />
                </div>
            </div>
            <div className="row">
                <div className="col-md-24 spacer-32-top" >
                    <h1 className="section-title">
                        <FormattedMessage {...messages.reallocateLicenses} />
                    </h1>
                    <div className="col-sm-8 col-md-8 col-lg-5">
                        <div className={!loading ? 'hidden' : ''}>
                            <LoadingIndicator />
                        </div>
                    </div>
                </div>
            </div>
            {<MultiTenantRequest props={props} />}
        </div>
    );
}

MultiTenantHome.propTypes = {
    submitSuccess: PropTypes.bool,
    submit: PropTypes.func,
    closeDialog: PropTypes.func,
    loading: PropTypes.bool,
    invoiceNumber: PropTypes.string,
    multiInvoiceNumber: PropTypes.string,
    changeListItems: PropTypes.func,
    lineItemShowStatus: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
    submitInitiated: makeSelectMultiTenantSubmitInitiated(),
    submitSuccess: makeSelectMultiTenantSubmitSuccess(),
    selectedPurchaseOrdersData: makeSelectSelectedPurchaseOrdersData(),
    selectedTenant: makeSelectIsTenantSelected(),
    isValidRequestToSubmit: makeSelectisValidRequestToSubmit(),
    requestId: makeSelectRequestId(),
    enrollmentDetails : makeSelectEnrollmentDetails()
});

function mapDispatchToProps(dispatch) {
    return {
        submit: (evt) => {
            dispatch(submitMTReturnForm());
        },
        closeDialog: evt => {
            dispatch(closeSubmitConfirmation());
        }
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(injectIntl(MultiTenantHome));
