/*
 * setup the i18n language files and locale data 
 */
const addLocaleData = require('react-intl').addLocaleData;
const enLocaleData = require('react-intl/locale-data/en');
const deLocaleData = require('react-intl/locale-data/de');
const esLocaleData = require('react-intl/locale-data/es');
const frLocaleData = require('react-intl/locale-data/fr');
const jaLocaleData = require('react-intl/locale-data/ja');
const koLocaleData = require('react-intl/locale-data/ko');
const ptLocaleData = require('react-intl/locale-data/pt');
const ruLocaleData = require('react-intl/locale-data/ru');
const zhLocaleData = require('react-intl/locale-data/zh');


const enTranslationMessages = require('./translations/en.json');
const deTranslationMessages = require('./translations/en.de-DE.json');
const mxTranslationMessages = require('./translations/en.es-MX.json');
const frTranslationMessages = require('./translations/en.fr-FR.json');
const jpTranslationMessages = require('./translations/en.ja-JP.json');
const krTranslationMessages = require('./translations/en.ko-KR.json');
const brTranslationMessages = require('./translations/en.pt-BR.json');
const ruTranslationMessages = require('./translations/en.ru-RU.json');
const cnTranslationMessages = require('./translations/en.zh-CN.json');
const twTranslationMessages = require('./translations/en.zh-TW.json');

addLocaleData(enLocaleData);
addLocaleData(esLocaleData);
addLocaleData(frLocaleData);
addLocaleData(jaLocaleData);
addLocaleData(koLocaleData);
addLocaleData(ptLocaleData);
addLocaleData(ruLocaleData);
addLocaleData(zhLocaleData);
addLocaleData(deLocaleData);

const DEFAULT_LOCALE = 'en-US';

const appLocales = [
    'en-US',
    'de-DE',
    'es-MX',
    'fr-FR',
    'ja-JP',
    'ko-KR',
    'pt-BR',
    'ru-RU',
    'zh-CN',
    'zh-TW'
];

const formatTranslationMessages = (locale, messages) => {
    const defaultFormattedMessages =
        locale !== DEFAULT_LOCALE
            ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
            : {};
    const flattenFormattedMessages = (formattedMessages, key) => {
        const formattedMessage =
            !messages[key] && locale !== DEFAULT_LOCALE
                ? defaultFormattedMessages[key]
                : messages[key];
        return Object.assign(formattedMessages, { [key]: formattedMessage });
    };
    return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
    'en-US': formatTranslationMessages('en-US', enTranslationMessages),
    'de-DE': formatTranslationMessages('de-DE', deTranslationMessages),
    'es-MX': formatTranslationMessages('es-MX', mxTranslationMessages),
    'fr-FR': formatTranslationMessages('fr-FR', frTranslationMessages),
    'ja-JP': formatTranslationMessages('ja-JP', jpTranslationMessages),
    'ko-KR': formatTranslationMessages('ko-KR', krTranslationMessages),
    'pt-BR': formatTranslationMessages('pt-BR', brTranslationMessages),
    'ru-RU': formatTranslationMessages('ru-RU', ruTranslationMessages),
    'zh-CN': formatTranslationMessages('zh-CN', cnTranslationMessages),
    'zh-TW': formatTranslationMessages('zh-TW', twTranslationMessages),
};

export { appLocales, formatTranslationMessages, translationMessages, DEFAULT_LOCALE }
