import { DISABLE_ADD_MORE, ENABLE_ADD_MORE, PART_NUMBER_DELETED, PART_NUMBER_FOCUSED, SAVE_MODAL_DETAILS, SAVE_MODAL_DETAILS_WITH_EXTID, UPDATE_PART_NUMBER_DETAILS } from './constants';

export function enableAddMore() {
    return {
        type: ENABLE_ADD_MORE
    };
}

export function partNumberToFocus(value) {
    return {
        type: PART_NUMBER_FOCUSED,
        value
    };
}

export function saveModalDetails() {
    return {
        type: SAVE_MODAL_DETAILS
    };
}

export function saveModalDetailsWithExtID(value) {
    return {
        type: SAVE_MODAL_DETAILS_WITH_EXTID, value

    };
}

export function deletePartNumber(value) {
    return {
        type: PART_NUMBER_DELETED,
        value
    };
}

export function updatePartNumberdetails(partNumberList) {
    return {
        type: UPDATE_PART_NUMBER_DETAILS,
        partNumberList
    }
}

export function updateAddMore(value) {
    return {
        type: DISABLE_ADD_MORE,
        value
    }
}