import React, { Component } from 'react';
import $ from 'jquery';
import * as Helpers from '../../../common/internals/utils/helpers';
import '../../../common/components/DateRange.css';
import { injectIntl } from 'react-intl';
export class DateRangeActionControl extends Component {

    constructor(props) {
        super(props);       
        this.onSelfApply = this.onSelfApply.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onDateChange = this.onDateChange.bind(this);  
        this.onCustomClick = this.onCustomClick.bind(this);  
    }   

    onCustomClick() {
        if ($("#ddlDateRangeControl").attr("aria-expanded") === "true") { 
            Helpers.KeepFocusInPopUp("ddlDateRangeControlPanal");
        } else {
            console.log("not expanded");
        }        
    }

    onSelfApply() {       
        var startDay = Helpers.FormatInputDateFromDateRangeAction($("#txtFromDateRange").val());
        var endDay = Helpers.FormatInputDateFromDateRangeAction($("#txtToDateRange").val());     
        if (startDay === "Invalid date" || endDay === "Invalid date") {
            console.log("Invalid dates");
        } else {
            var value = startDay + ' to ' + endDay;
            setTimeout(function () {
                $('#ddlDateRangeControl').focus();
            }, 10);
            this.props.onApply(value);
        }
    }

    onCancel() {
        $("#ddlDateRangeControl").attr("aria-expanded", "false");     
        $("#dvDropDown").removeClass("open");   
        setTimeout(function () {
            $('#ddlDateRangeControl').focus();
        }, 10);
    }
    onDateChange(e) {        
        $("#dvDropDown").addClass("open");
        console.log(e.target);
    }

    render() {
        const { formatMessage } = this.props.intl;
        var lblFromDate = formatMessage({ id: "lblFromDate" });
        var lblToDate = formatMessage({ id: "lblToDate" });
        var lblApply = formatMessage({ id: "lblApply" });
        var lblCancel = formatMessage({ id: "lblCancel" });
        var lblCustomTobeDisplayed = this.props.value.indexOf(" to ") !== -1 ? this.props.value : formatMessage({ id: "lblCustom" }); 
        
        return (
            <div className="row spacer-12-bottom spacer-24-left">
                <div className="dropdown" id="dvDropDown">
                    <button type="radio" aria-pressed={this.props.value.indexOf(" to ") !== -1 ? "true" : "undefined"} className="btn-datefilter dropdown-toggle" id="ddlDateRangeControl" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.onCustomClick}>
                        {lblCustomTobeDisplayed}
                    </button>
                    <div className="dropdown-menu spacer-24-bottom" aria-label="ddlDateRangeControl" style={{ "paddingLeft": "25px" }} id="ddlDateRangeControlPanal">
                        <div className="row spacer-12-top spacer-12-left">
                            <div className="spacer-12-bottom col-sm-20 col-md-20 col-lg-20">
                                <label htmlFor="txtDtFromDate">{lblFromDate}</label><br />
                                <input type="date" id="txtFromDateRange" aria-label={lblFromDate} defaultValue={this.props.startDate} onChange={this.onDateChange} onBlur={this.onDateChange} onClick={this.onDateChange} data-date-format={window.dateFormat.toUpperCase()} />
                            </div>
                            <div className="spacer-12-bottom col-sm-20 col-md-20 col-lg-20">
                                <label htmlFor="txtDtToDate">{lblToDate}</label><br />
                                <input type="date" id="txtToDateRange" aria-label={lblToDate} defaultValue={this.props.endDate} onChange={this.onDateChange} onBlur={this.onDateChange} onClick={this.onDateChange} data-date-format={window.dateFormat.toUpperCase()}/>
                            </div>                           
                        </div>

                        <div className="btn-group">
                            <button type="button" name="btnApply" className="btn btn-default active" onClick={this.onSelfApply}><span>{lblApply}</span></button>
                            <button type="button" className="btn btn-default" onClick={this.onCancel}><span>{lblCancel}</span></button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
export default (injectIntl(DateRangeActionControl));