import React from 'react';
import { injectIntl } from 'react-intl';
import authentication from '../../../login/containers/msal-react';
export function WorkAccountMapped(props) {  
    
    return <div className="container" style={{ marginTop: '200px', marginBottom: '300px' }}>
        <div className="row">
            <div className="text-center">
                <h2> This login is already mapped with following Work or School Account(s) (WSA). Please sign out and login again using one of the below WSA(s).</h2>
            </div>
            <div style={{display: 'flex',justifyContent: 'center',marginTop: '20px',marginBottom:'20px'}}><ol>{props.mappedAccounts.map((account) => <li key={account}>{account}</li>)}</ol></div>
            <div className="text-center">
                <h2><div style={{display: 'flex',justifyContent: 'center'}}><span>Please</span><div onClick={authentication.signOut} style={{marginLeft: '5px',marginRight: '5px'}}><a>click here</a></div><span>to sign out.</span></div></h2>
            </div>
        </div>
    </div>;
}
export default injectIntl(WorkAccountMapped);
