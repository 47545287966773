import { REMOVE_REBILLPO_NUMBER, CLOSE_PO_CONFIRMATION, SELECT_REBILL_INVOICE, CHANGE_PO_EXPAND } from './constants';

export function removeRebillPo(data) {
    return {
        type: REMOVE_REBILLPO_NUMBER,
        payload: data
    };
}

export function closePOConfirmationModal() {
    return { type: CLOSE_PO_CONFIRMATION };
}

export function selectRebillInvoice(id, isSelected) {
    return {
        type: SELECT_REBILL_INVOICE,
        id,
        isSelected
    };
}

export function changePOExpand(id, expand) {
    return {
        type: CHANGE_PO_EXPAND,
        id        
    };
}