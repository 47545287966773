import { defineMessages } from 'react-intl';

export const scope = 'ErrorMessage';

export default defineMessages({
    closeErrorMessage: {
        id: `${scope}.closeErrorMessage`,
        defaultMessage: 'Close error message',
    },
});
