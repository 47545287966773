export const FBC_CHANGE_ENROLLMENT_NUMBER =  "CancelFutureBillingCreateRequest/FBC_CHANGE_ENROLLMENT_NUMBER";
export const FBC_CHANGE_SYSTEM_PO_NUMBER =  "CancelFutureBillingCreateRequest/FBC_CHANGE_SYSTEM_PO_NUMBER";
export const FBC_SEARCH_WITH_POAA = "CancelFutureBillingCreateRequest/FBC_SEARCH_WITH_POAA";
export const FBC_CLEAR_ENROLLMENT_NUMBER = "CancelFutureBillingCreateRequest/FBC_CLEAR_ENROLLMENT_NUMBER";
export const FBC_CLEAR_SYSTEM_PO_NUMBER = "CancelFutureBillingCreateRequest/FBC_CLEAR_SYSTEM_PO_NUMBER";
export const FBC_SEARCH_WITH_POAA_SUCCESS =  "CancelFutureBillingCreateRequest/FBC_SEARCH_WITH_POAA_SUCCESS";
export const FBC_SEARCH_WITH_POAA_ERROR =  "CancelFutureBillingCreateRequest/FBC_SEARCH_WITH_POAA_ERROR";
export const FBC_SEARCH_INVOICE = "CancelFutureBillingCreateRequest/FBC_SEARCH_INVOICE";
export const FBC_SEARCH_INVOICE_SUCCESS =  "CancelFutureBillingCreateRequest/SEARCH_INVOICE_SUCCESS";
export const FBC_CHANGE_COSMIC_CASENUMBER = "CancelFutureBillingCreateRequest/FBC_CHANGE_COSMIC_CASENUMBER";
export const FBC_CHANGE_BILLING_DOCUMENT_NUMBER = "CancelFutureBillingCreateRequest/FBC_CHANGE_BILLING_DOCUMENT_NUMBER";