import * as Msal from 'msal';
import React from 'react';
import { setAuthType, setAccessToken } from '../../common/app/actions';

const logger = new Msal.Logger(loggerCallback, {
    level: Msal.LogLevel.Verbose
});

const state = {
    launchApp: null,
    errorApp: null,
    idToken: null,
    accessToken: null,
    authType: null,
    email: '',
    name: ''
}

var appConfig = {
    instance: null,
    tenant: null,
    applicationId: null,
    cacheLocation: null,
    scopes: [],
    redirectUri: null,
    postLogoutRedirectUri: null,
    validateAuthority: null,
    accessTokenScope: null,
    liveLoginScope: null,
    msaTenantId: null,
    firstPartyEnabled: null,
    instrumentationKey: null
};

function loggerCallback(logLevel, message, piiLoggingEnabled) {
    console.log(message);
}

function authCallback(authErr, response) {
    if (authErr) {
        console.log('Login HOC: authCallback error: ' + JSON.stringify(authErr));
        // if (failureCallback) {
        //     failureCallback();
        // }
    }
    if (response && response.tokenType == "access_token" && response.accessToken != null) {
        console.log('Login HOC: acquireTokenRedirect succeeded');
    }
}

function getDomain(sId) {
    var domainDictionary = window.vlcredits.envData.Services.domainDictionary;
    var keys = domainDictionary.split(",");
    
    var sidArray = sId.split("-");
    var sidArrayLength = sidArray.length;
    var indexValue = sidArrayLength - 1;
    var actualarray = sidArray.slice(0,indexValue);
    var actualSid = actualarray.join('-');
    var isAvailabe = keys.includes(actualSid);
    console.log("Is_available:",isAvailabe,actualSid);
    if(isAvailabe == true){
    return actualSid;
    }
}

function getDomainUser(accountData) {
    var domainSid = "";
    var domain="";
    var alias="";
if(accountData && accountData.idToken && accountData.idToken.onprem_sid){
domainSid = accountData.idToken.onprem_sid;
}else{
    return domainSid;
}
domain = getDomain(domainSid);
var upnData = accountData && accountData.idToken && accountData.idToken.upn ? accountData.idToken.upn : "";
if(upnData){
    var upnArray = upnData && upnData.split("@");
    alias = upnArray[0];
}
if(domain && alias){
return domain + "@" + alias;
}else{
    return "";
}
}


function loginAndAcquireToken(successCallback, failureCallback) {
    const localMsalApp = window.msal;
    let account = localMsalApp.getAccount();
    let accessTokenRequest = {
        scopes: account && account.idToken && account.idToken.tid === appConfig.msaTenantId ?
            (appConfig.firstPartyEnabled ? [appConfig.applicationId] : [appConfig.liveLoginScope]) : [appConfig.accessTokenScope],
        extraQueryParameters: appConfig.firstPartyEnabled ? { 'msaoauth2': 'true' } : null
    }
    if (account && account.idToken && account.idToken.tid) {
        accessTokenRequest.authority = appConfig.instance + account.idToken.tid
    }
if(account && account.idToken && account.idToken.tid === appConfig.msaTenantId){
    state.authType = "msa";
}else{
   const domainUservalue = getDomainUser(account);
   console.log("domain_value:",domainUservalue);
   if(domainUservalue){
    state.authType = "aad";
   }else{
    state.authType = "aadupn";
   }
}
    //state.authType = account && account.idToken && account.idToken.tid === appConfig.msaTenantId ? "msa" : "aad";
console.log("msa_tenet:",appConfig.msaTenantId,account);
    if (!account) {
        // Try to get token from SSO session
        console.log('Login HOC: Trying a silent login and token acquisition');
        localMsalApp.acquireTokenSilent(accessTokenRequest)
            .then(accessTokenResponse => {
                console.log('Login HOC: Obtained accesstoken successfully');
                state.accessToken = accessTokenResponse.accessToken;
                account = localMsalApp.getAccount();
                state.idToken = account.idToken;
                state.email = account.userName;
                state.name = account.name;
                if (state.launchApp) {
                    state.launchApp();
                }
                if (successCallback) {
                    successCallback();
                }
            }, error => {
                if (error) {
                    console.log('Login HOC: acquireTokenSilent error: ' + JSON.stringify(error));
                    console.log('Login HOC: Trying loginRedirect');
                    localMsalApp.loginRedirect({
                        scopes: appConfig.scopes,
                        extraQueryParameters: appConfig.firstPartyEnabled ? { 'msaoauth2': 'true' } : null
                    });
                }
            });

    } else {
        // The user is already logged in
        state.idToken = account.idToken;
        state.email = account.userName;
        state.name = account.name;
        // Get access token
        console.log('Login HOC: User is logged in. Trying acquireTokenSilent');
        localMsalApp.acquireTokenSilent(accessTokenRequest).
            then(accessTokenResponse => {
                console.log('Login HOC: Obtained accesstoken successfully');
                state.accessToken = accessTokenResponse.accessToken;
                if (state.launchApp) {
                    state.launchApp();
                }
                if (successCallback) {
                    successCallback();
                }
            }, error => {
                if (error) {
                    console.log('Login HOC: Silent accesstoken failed');
                    console.log('Login HOC: acquireTokenSilent error: ' + JSON.stringify(error));
                    console.log('Login HOC: Trying acquireTokenRedirect');
                    localMsalApp.acquireTokenRedirect(accessTokenRequest);
                }
            });
    }
}

const authentication = {
    initialize: (config) => {
        appConfig = config;
        const authority = config.instance && config.tenant ? config.instance + config.tenant : 'https://login.microsoftonline.com/common';
        const validateAuthority = (config.validateAuthority != null) ? config.validateAuthority : false;
        let scopes = config.scopes;
        if (!scopes || scopes.length === 0) {
            console.log('To obtain access tokens you must specify one or more scopes.');
        }
        state.scopes = scopes;

        const msalConfig = {
            auth: {
                clientId: config.applicationId,
                redirectUri: config.redirectUri,
                postLogoutredirectUri: config.postLogoutRedirectUri,
                authority: authority,
                validateAuthority: validateAuthority
            },
            cache: {
                cacheLocation: config.cacheLocation,
                storeAuthStateInCookie: true
            },
            framework: {
                isAngular: false
            },
            logger: logger
        }

        new Msal.UserAgentApplication(msalConfig);
        window.msal.handleRedirectCallback(authCallback);
        if (!window.msal) {
            console.log('Login HOC: window.msal creation failed');
        }
    },
    run: (launchApp, errorApp) => {
        state.launchApp = launchApp
        if (errorApp)
            state.errorApp = errorApp;
        if (!window.msal.isCallback(window.location.hash) && window.parent === window && !window.opener) {
            loginAndAcquireToken();
        }
    },
    required: (WrappedComponent, renderLoading, ErrorComponent, store) => {

        return class EnhancedAppComponent extends React.Component {

            constructor(props) {
                super(props);
                this.state = {
                    signedIn: false,
                    error: false,
                };
            }

            componentWillMount() {
                loginAndAcquireToken(() => {
                    store && typeof store.dispatch === 'function' ? store.dispatch(setAuthType(state.authType)) : console.log("dispath is not a function");
                    store && typeof store.dispatch === 'function' ? store.dispatch(setAccessToken(state.accessToken)) : console.log("dispath is not a function");
                    this.setState({
                        ...this.state,
                        signedIn: true,
                        error: false
                    });
                }, () => {
                    this.setState({
                        ...this.state,
                        signedIn: false,
                        error: true
                    });
                });
            };
            
            render() {
                if (this.state.signedIn) {
                    return (<WrappedComponent key="appComponent" appinsightsKey={appConfig.instrumentationKey} {...this.props} />);
                };
                if (this.state.error) {
                    return (< ErrorComponent {
                        ...this.props
                    }
                    />);
                }
                return typeof renderLoading === 'function' ? renderLoading() : null;
            };
        }
    },
    signOut: () => {
        window.msal.logout()
    },
    getIdToken: () => {
        if (window.msal && window.msal.getAccount()) {
            return window.msal.getAccount().idToken;
        }
        return state.idToken;
    },
    getAccessToken: () => {
        return state.accessToken;
    },
    getAuthType: () => {
        return state.authType;
    },
    getEmail: () => {
        if (window.msal && window.msal.getAccount()) {
            return window.msal.getAccount().userName;
        }
        return state.email;
    },
    getName: () => {
        if (window.msal && window.msal.getAccount()) {
            return window.msal.getAccount().name;
        }
        return state.name;
    }
}

export default authentication;