import produce from 'immer';
import {
    FILTER_CREDITS_SUCCESS,
    FILTER_CREDITS_ERROR,
    FILTER_CREDITS,
    CHANGE_REQUEST_IDENTIFIER,
    CHANGE_REQUEST_STATUS,
    CHANGE_SALES_LOCATION,
    CHANGE_OPERATION_CENTER,
    CHANGE_BILLING_DOCUMENT,
    CHANGE_PO_NUMBER,
    CHANGE_ENROLLMENT_NUMBER,
    CHANGE_CUSTOMER_NUMBER,
    CHANGE_COSMIC_NUMBER,
    CHANGE_CUSTOMER_NAME,
    CHANGE_PROGRAM_CODE,
    CHANGE_DATE_RANGE
} from './constants';
import { LOCATION_CHANGE } from "react-router-redux";

export const initialState = {};

const FilterCreditListReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FILTER_CREDITS:
                draft.error = false;
                draft.loading = true;
                draft.isSubCFORequest = action.isSubCFORequest;
                break;

            case FILTER_CREDITS_SUCCESS:
                draft.creditsListResponse = action.response;
                draft.loading = false;
                break;

            case FILTER_CREDITS_ERROR:
                draft.error = action.error;
                draft.loading = false;
                break;

            case CHANGE_REQUEST_IDENTIFIER:
                draft.requestIdentifier = action.requestIdentifier;
                draft.error = false;
                break;

            case CHANGE_REQUEST_STATUS:
                draft.requestStatus = action.requestStatus;
                draft.error = false;
                break;

            case CHANGE_SALES_LOCATION:
                draft.salesLocation = action.salesLocation;
                draft.error = false;
                break;

            case CHANGE_OPERATION_CENTER:
                draft.operationCenterCode = action.operationCenterCode;
                draft.error = false;
                break;

            case CHANGE_BILLING_DOCUMENT:
                draft.billingdocumentNBR = action.billingdocumentNBR;
                draft.error = false;
                break;

            case CHANGE_PO_NUMBER:
                draft.poNumber = action.poNumber;
                draft.error = false;
                break;

            case CHANGE_ENROLLMENT_NUMBER:
                draft.enrollmentNumber = action.enrollmentNumber;
                draft.error = false;
                break;

            case CHANGE_CUSTOMER_NUMBER:
                draft.customerNumber = action.customerNumber;
                draft.error = false;
                break;

            case CHANGE_COSMIC_NUMBER:
                draft.cosmicNumber = action.cosmicNumber;
                draft.error = false;
                break;
            case CHANGE_CUSTOMER_NAME:
                draft.endCustomerName = action.endCustomerName;
                draft.error = false;
                break;

            case CHANGE_PROGRAM_CODE:
                draft.programCode = action.programCode;
                draft.error = false;
                break;

            case CHANGE_DATE_RANGE:
                draft.dateRange = action.dateRange;
                draft.error = false;
                break;

            case LOCATION_CHANGE:
                draft.requestIdentifier = null;
                draft.requestStatus = null;
                draft.salesLocation = null;
                draft.operationCenterCode = null;
                draft.billingdocumentNBR = null;
                draft.poNumber = null;
                draft.enrollmentNumber = null;
                draft.cosmicNumber = null;
                draft.endCustomerName = null;
                draft.programCode = null;
                draft.customerNumber = null;
                draft.creditsListResponse = null;
                draft.error = false;
                draft.isSubCFORequest = false
                break;

        }
    });

export default FilterCreditListReducer;
