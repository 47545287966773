import React from 'react';

export default function LoadingPage() {
    return <div className="container" style={{ marginTop: '300px', marginBottom: '300px' }}>
        <div className="row">
            <div className="text-center">
                <h2>Loading...</h2>
            </div>
        </div>
    </div>;
}
