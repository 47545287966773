import {
    FBC_GET_RETURN_SUCCESS,
    FBC_GET_RETURN_ERROR,
    FBC_GET_RETURN,
    CHANGE_FBC_COSMIC_CASENUMBER
} from './constants';

export function getReturn(payload) {
    return {
        type: FBC_GET_RETURN,
        payload
    };
}

export function getReturnCompleted(invoices, multiInvoiceNumber) {
    return {
        type: FBC_GET_RETURN_SUCCESS,
        invoices,
        multiInvoiceNumber,
    };
}

export function getReturnError(error) {
    return {
        type: FBC_GET_RETURN_ERROR,
        error,
    };
}

export function changeManualCosmicCaseNumber(value) {
    return {
        type: CHANGE_FBC_COSMIC_CASENUMBER,
        value
    };
}