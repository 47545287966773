import React from 'react';
import { injectIntl } from 'react-intl';
export function UnAuthorised(props) {  
    
    return <div className="container" style={{ marginTop: '300px', marginBottom: '300px' }}>
        <div className="row">
            <div className="text-center">
                <h2> You are not authorized to access this application. </h2>
            </div>
        </div>
    </div>;
}
export default injectIntl(UnAuthorised);
