import styled from 'styled-components';

const Wrapper = styled.div`
    margin: 2em auto;
    width: 35px;
    height: 35px;
    position: relative;
    margin-top:-40px;
    margin-right:-80px;
`;

export default Wrapper;
