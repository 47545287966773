import React from 'react';

export default function ErrorPage() {
    return <div className="container" style={{ marginTop: '300px', marginBottom: '300px' }}>
        <div className="row">
            <div className="text-center">
                <h2> An internal error has occurred. </h2>
            </div>
        </div>
    </div>;
}
