import produce from 'immer';
import {
    GET_RETURN,
    GET_RETURN_ERROR,
    GET_RETURN_SUCCESS,
    CHANGE_COSMIC_CASENUMBER
} from './constants';
import { LOCATION_CHANGE } from "react-router-redux";
export const initialState = {};

const fetchRequestReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case CHANGE_COSMIC_CASENUMBER:
                draft.invoice.cosmicCaseNumber = action.value;
                break;
            case GET_RETURN:
                draft.invoice = {};
                draft.loading = true;
                draft.error = false;
                break;
            case GET_RETURN_SUCCESS:
                draft.invoiceNumber = action.invoiceNumber;
                draft.invoice = action.invoice;
                draft.loading = false;
                draft.error = false;
                break;
            case GET_RETURN_ERROR:
                draft.error = action.error;
                draft.loading = false;
                break;
            case LOCATION_CHANGE:
                draft.invoiceNumber = "";
                draft.invoice = {};
                draft.loading = false;
                draft.error = false;
                break;
        }
    });

export default fetchRequestReducer;
