import { call, put, select, take, fork, takeLatest } from 'redux-saga/effects';
import { UPLOAD_FILE, DOWNLOAD_FILE, FileUploadTranslationsMap, FILE_REMOVED_ERROR, FILE_REMOVED_SUCCESS, FILE_REMOVED } from './constants';
import { FileUploadError, FileUploadSuccess, DeleteFileSuccess, DeleteFileError, DOwnloadFileError, DOwnloadFileSuccess } from './actions';
import fileDownload from 'js-file-download';

import { fileUploadRequestCreate, fileGetRequestCreate, fileDeleteRequestCreate } from '../../../../common/internals/api/requests';
import fetchWrapper, { fetchWrapperBlob } from '../../../../common/internals/api/fetchWrapper';
//import { transformResponse } from '../../../../common/internals/api/transformResponse';
import { makeSelectFileUploadPayload, makeSelectAccessToken, makeSelectAuthType, makeSelectBlobFile } from './selectors';

export function* uploadFile() {

    //yield take(UPLOAD_FILE);  
    const fileInfo = yield select(makeSelectFileUploadPayload());

    if (fileInfo) {
        var accessToken = yield select(makeSelectAccessToken());
        var authType = yield select(makeSelectAuthType());
        var fileUploadRequest = fileUploadRequestCreate();
        yield* setAuthTokens(fileUploadRequest);
        //fileUploadRequest.options.headers["Authorization"] = "Bearer " + accessToken;
        //fileUploadRequest.options.headers["x-ms-AuthType"] = authType;

        // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/Sending_and_Receiving_Binary_Data
        const requestPayload = {
            "fileName": fileInfo.fileName,
            "fileContent": _arrayBufferToBase64(fileInfo.fileContent),
            "fileLengthInBytes": fileInfo.fileLengthInBytes,
            "contentType": fileInfo.contentType,
            "correlationId": ""
        };
        var requestPayloadarr = new Array();
        requestPayloadarr.push(requestPayload);
        fileUploadRequest.options.body = JSON.stringify(requestPayloadarr);

        try {
            const fileUploadResponse = yield call(fetchWrapper, fileUploadRequest, true);

            //const fileUploadResponse = [{ "fileName": fileInfo.fileName, "createdBy": "Regatte", "status": "Active", "contentType": "application/pdf", "partitionKey": fileInfo.fileName+".pdf6b01792a-8c36-4d94-87e8-3608c0ca7f01", "rowKey": "2", "timestamp": "0001-01-01T00:00:00+00:00", "eTag": "W/\"datetime'2019-07-09T10%3A16%3A28.6540803Z'\"" }];

            const success = {
                "fileInfo": fileInfo,
                "fileUploadResponse": fileUploadResponse
            };

            yield put(FileUploadSuccess(success));
        } catch (err) {
            if (err.message === "401") {
                yield put(FileUploadError(FileUploadTranslationsMap.get('TokenExpired')));
            }

            yield put(FileUploadError(FileUploadTranslationsMap.get('fileUploadError') + (err.message ? ' ' + err.message : '')));
        }
    }
    else {
        yield put(FileUploadError(FileUploadTranslationsMap.get('fileNotSelectedError')));
    }
}



export function* getUploadedFile(action) {
    //const downloadfile = yield take(DOWNLOAD_FILE);
    //console.log('getUploadedFile');
    var fileGetRequest = fileGetRequestCreate();
    yield* setAuthTokens(fileGetRequest);
    fileGetRequest.url = `api/files/file/${action.payload.identifer}`;
    try {
        const response = yield call(fetchWrapperBlob, fileGetRequest);
        fileDownload(response, action.payload.fileName);
        yield put(DOwnloadFileSuccess());
    }
    catch (err) {
        if (err.message === "401") {
            yield put(FileUploadError(FileUploadTranslationsMap.get('TokenExpired')));
        }
        yield put(DOwnloadFileError());
    }
    //yield put(FileUploadSuccess("success"));
}

export function* deleteUploadedFile(action) {
    var fileDeleteRequest = fileDeleteRequestCreate();
    yield* setAuthTokens(fileDeleteRequest);
    fileDeleteRequest.url = `api/files/file/${action.fileIdentifier}`;
    try {
        yield call(fetchWrapper, fileDeleteRequest);
        yield put(DeleteFileSuccess(action.fileIdentifier));
    }
    catch (err) {
        if (err.message === "401") {
            yield put(FileUploadError(FileUploadTranslationsMap.get('TokenExpired')));
        }
        yield put(DeleteFileError(''));
    }
}

function* setAuthTokens(request) {
    const accessToken = yield select(makeSelectAccessToken());
    const authType = yield select(makeSelectAuthType());
    request.options.headers["Authorization"] = "Bearer " + accessToken;
    request.options.headers["x-ms-AuthType"] = authType;
}

function _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}


export default function* fileUploadRequestSaga() {
    yield takeLatest(UPLOAD_FILE, uploadFile);
    yield takeLatest(DOWNLOAD_FILE, getUploadedFile);
    yield takeLatest(FILE_REMOVED, deleteUploadedFile);
}