import {
    FILTER_CREDITS_SUCCESS,
    FILTER_CREDITS_ERROR,
    FILTER_CREDITS,
    CHANGE_REQUEST_IDENTIFIER,
    CHANGE_REQUEST_STATUS,
    CHANGE_SALES_LOCATION,
    CHANGE_OPERATION_CENTER,
    CHANGE_BILLING_DOCUMENT,
    CHANGE_PO_NUMBER,
    CHANGE_ENROLLMENT_NUMBER,
    CHANGE_CUSTOMER_NUMBER,
    CHANGE_DATE_RANGE,
    CHANGE_COSMIC_NUMBER,
    CHANGE_CUSTOMER_NAME,
    CHANGE_PROGRAM_CODE,
 } from './constants';


export function FilterCredits(isSubCFORequest) {   
    isSubCFORequest = isSubCFORequest && isSubCFORequest ? isSubCFORequest : false;
    return {
        type: FILTER_CREDITS,
        isSubCFORequest
    };
}

export function FilterCreditsList(creditsListResponse) {
    return {
        type: FILTER_CREDITS_SUCCESS,
        response: creditsListResponse
    };
}

export function FilterCreditsListError(error) {
    return {
        type: FILTER_CREDITS_ERROR,
        error
    };
}

export function changeRequestIdentifier(requestIdentifier) {
    return {
        type: CHANGE_REQUEST_IDENTIFIER,
        requestIdentifier
    };
}

export function changeRequestStatusCode(requestStatus) {
    return {
        type: CHANGE_REQUEST_STATUS,
        requestStatus
    };
}

export function changeSalesLocationCode(salesLocation) {
    return {
        type: CHANGE_SALES_LOCATION,
        salesLocation
    };
}

export function changeOperationsCenterCode(operationCenterCode) {
    return {
        type: CHANGE_OPERATION_CENTER,
        operationCenterCode
    };
}

export function changeBillingDocumentNbr(billingdocumentNBR) {
    return {
        type: CHANGE_BILLING_DOCUMENT,
        billingdocumentNBR
    };
}
export function changePurchaseOrderNumber(poNumber) {
    return {
        type: CHANGE_PO_NUMBER,
        poNumber
    };
}

export function changeAgreementNumber(enrollmentNumber) {
    return {
        type: CHANGE_ENROLLMENT_NUMBER,
        enrollmentNumber
    };
}
export function changeEndCustomerNumber(customerNumber) {
    return {
        type: CHANGE_CUSTOMER_NUMBER,
        customerNumber
    };
}

export function changeCosmicNumber(cosmicNumber) {
    return {
        type: CHANGE_COSMIC_NUMBER,
        cosmicNumber
    };
}

export function changeCustomerName(endCustomerName) {
    return {
        type: CHANGE_CUSTOMER_NAME,
        endCustomerName
    };
}
export function changeProgramCode(programCode) {
    return {
        type: CHANGE_PROGRAM_CODE,
        programCode
    };
}

export function changeDateRange(dateRange) {
    return {
        type: CHANGE_DATE_RANGE,
        dateRange
    };
}

export function search(dateRange) {
    return {
        type: CHANGE_DATE_RANGE,
        dateRange
    };
}


