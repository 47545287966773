import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectReturnDetailsDomain = state => state.returnDetails || initialState;

const makeSelectIsBDApproved = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.isBdApproved,
    );

const makeSelectIsFullCredit = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.isFullCredit,
    );

const makeSelectInvoiceDate = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.invoiceDate,
    );

const makeSelectReturnReason = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.returnReason,
    );

const makeSelectBillToCountryCode = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.billToCountryCode,
    );

const makeSelectIsSUB = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.isSUB,
    );


const makeSelectHandlingFeePercentage = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.handlingFeePercentage,
    );

const makeSelectReturnDetails = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate,
    );

const makeSelectNetCreditAmount = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.netCreditAmount,
    );

const makeSelectNetCreditAmountLocal = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.netCreditAmountLocal,
    );
const makeSelectoperationsCenterCode = () =>

    createSelector(
        selectReturnDetailsDomain,
        substate => substate.alternateROC,
    );
const makeSelectOldoperationsCenterCode = () =>

    createSelector(
        selectReturnDetailsDomain,
        substate => substate.operationsCenterCode,
    );
const makeSelectrocValueChanged = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.rocValueChanged,
    );

const makeSelectprogramCode = () =>
    createSelector(
        selectReturnDetailsDomain,
        substate => substate.programCode,
    );



export default makeSelectReturnDetails;
export {
    selectReturnDetailsDomain,
    makeSelectIsBDApproved,
    makeSelectIsFullCredit,
    makeSelectHandlingFeePercentage,
    makeSelectReturnReason,
    makeSelectNetCreditAmount,
    makeSelectNetCreditAmountLocal,
    makeSelectBillToCountryCode,
    makeSelectIsSUB,
    makeSelectoperationsCenterCode,
    makeSelectrocValueChanged,
    makeSelectOldoperationsCenterCode,
    makeSelectprogramCode,
    makeSelectInvoiceDate
};