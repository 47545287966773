import { takeLatest, select, put } from "redux-saga/effects";
import uuidv4 from 'uuid/v4';
import { FBC_SUBMIT_RETURN, FBC_VALIDATE_SUBMIT_FORM } from "./constants";
import { makeSelectEnrollmentNumberCreate, makeSelectSystemPONumberCreate } from "../../../common/components/cancelFutureBillingCreateRequest/selectors";
import { makeSelectEnrollmentNumberFetch, makeSelectSystemPONumberFetch } from "../../../common/components/cancelFutureBillingFetchRequest/selectors";
import {
    makeListItemsDifference
} from '../../../common/components/cfblineItemDetails/selectors';
import { sagaFetchHandler } from '../../../../common/internals/api/sagaFetchHandler';

import { submitReturnRequestCreate } from '../../../../common/internals/api/requests';
import { makeCancelFutureBillingReturnFormData } from './selectors';
import { makeSelectAuthType, makeSelectAccessToken } from '../../../../common/app/selectors';
import { submitReturnFormSuccess, submitFBCReturnForm, submitReturnFormFailure, showLineItemConfirmation } from '../../../operations/containers/cancelFutureBillingReturnForm/actions';
import { makeSelectisFullCancellation } from '../../components/cfbReturnDetails/selectors';
import { isEmpty } from "lodash";



export function* submitRequest() {

    const uuid = uuidv4();
    const enrollmentNumber = yield* getEnrollmentNbr();
    const systemPONumber = yield* getSystemPONumber();
    const returnFormData = yield select(makeCancelFutureBillingReturnFormData());   
    const requestBillingDocumentNbr =  uuid;
   
    returnFormData.vlfbOrder.agreementNumber = enrollmentNumber;
    returnFormData.vlfbOrder.purchaseOrderNumber = systemPONumber.trim(); 
    returnFormData.vlfbOrder.billingDocumentNbr = requestBillingDocumentNbr;
    var submitReturnRequest = submitReturnRequestCreate();
    yield* setAuthTokens(submitReturnRequest);
    submitReturnRequest.url = `api/return/${requestBillingDocumentNbr}`;
    console.log("Submit_response:",JSON.stringify(returnFormData));
    submitReturnRequest.options.body = JSON.stringify(returnFormData);
    yield* sagaFetchHandler(submitReturnRequest, submitReturnFormSuccess, submitReturnFormFailure);
}

export function* validateSubmitForm() {

    //const rebillPoList = yield select(makeSelectRebillpoList());
    //var isRebillConfirmation = !(rebillPoList && rebillPoList.length > 0) ? true : false;
    /*const changeListItems = yield select(makeListItemsDifference());
    const isFullCancellation = yield select(makeSelectisFullCancellation());
    if(!isEmpty(changeListItems) && isFullCancellation != true){
        yield put(showLineItemConfirmation());
    }else{
        yield put(submitFBCReturnForm());
    }*/
    yield put(submitFBCReturnForm());
}

function* setAuthTokens(request) {
    const accessToken = yield select(makeSelectAccessToken());
    const authType = yield select(makeSelectAuthType());
    request.options.headers["Authorization"] = "Bearer " + accessToken;
    request.options.headers["x-ms-AuthType"] = authType;
}


//function* getBillingDocumentNbr() {
//    const billingDocumentNbrFromCreate = yield select(makeSelectInvoiceNumberCreate());
//    const billingDocumentNbrFromFetch = yield select(makeSelectInvoiceNumberFetch());
//    return billingDocumentNbrFromCreate ? billingDocumentNbrFromCreate : billingDocumentNbrFromFetch;

//}

function* getEnrollmentNbr() {
    const enrollmentNumberCreate = yield select(makeSelectEnrollmentNumberCreate());
    const enrollmentNumberFetch = yield select(makeSelectEnrollmentNumberFetch());
    return enrollmentNumberCreate ? enrollmentNumberCreate : enrollmentNumberFetch;
}

function* getSystemPONumber() {
    const systemPONumberCreate = yield select(makeSelectSystemPONumberCreate());
    const systemPONumberFetch = yield select(makeSelectSystemPONumberFetch());
    return systemPONumberCreate ? systemPONumberCreate : systemPONumberFetch;
}


export default function* multiInvoiceReturnFormSaga() {
    yield takeLatest(FBC_SUBMIT_RETURN, submitRequest);
    yield takeLatest(FBC_VALIDATE_SUBMIT_FORM, validateSubmitForm);
}
