import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl';
import * as Helpers  from '../../../common/internals/utils/helpers'

export function MultiModal({
    id,
    isOpen,
    headerMessage,
    messageArray,
    actionMessage,
    action,
    closeMessage,
    close
}) {
    useEffect(() => {
        console.log('useEffect');
        Helpers.KeepFocusInOnedashPopUp(id);
    });
    if (isOpen) {
        window.scrollTo(0, 0);
        return (
            <div>
                <div id={id}
                    style={{ display: 'block', opacity: '1' }}
                    className="modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="onedash-modal-content">

                                <p>
                                    <h4 className="spacer-32-top">
                                        <FormattedMessage {...headerMessage} />
                                </h4>
                                </p>

                                <ol>
                                    {
                                        messageArray.map((item) =>
                                            <li><h4 className="spacer-32-top"><FormattedMessage {...item} />
                                            </h4>
                                            </li>
                                        )
                                    }
                                </ol>

                                <div className="onedash-modal-buttons btn-group spacer-32-top">
                                    {close && closeMessage ?
                                        <button id={id + "modalbuttonClose"}
                                            className="btn c-button f-secondary"
                                            type="button" onClick={close}>
                                            <FormattedMessage {...closeMessage} />
                                        </button> : ''}
                                    {action && actionMessage ?
                                        <button id={id + "modalbuttonClose"}
                                            className="btn c-button f-primary btn-primary"
                                            type="button" onClick={action}>
                                            <FormattedMessage {...actionMessage} />
                                        </button> : ''}
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div >
        )
    }
    return null;
}

MultiModal.propTypes = {
    id: PropTypes.string.isRequired,
    headerMessage: PropTypes.object,
    messageArray: PropTypes.Array,
    actionMessage: PropTypes.object,
    closeMessage: PropTypes.object,
    isOpen: PropTypes.bool,
    action: PropTypes.func,
    close: PropTypes.func,
}

export default MultiModal;