import produce from 'immer';
import {
    FILTER_REALLOCATION_REQUESTS,
    FILTER_REALLOCATION_ERROR,
    FILTER_REALLOCATION_SUCCESS,
    CHANGE_REQUEST_IDENTIFIER,
    CHANGE_REQUEST_STATUS,
    CHANGE_ENROLLMENT_NAME,
    CHANGE_SYSTEM_PO_NUMBER,
    CHANGE_ENROLLMENT_NUMBER,
    CHANGE_COSMIC_NUMBER,
    CHANGE_DATE_RANGE,
    CHANGE_TENANT_NUMBER
} from './constants';

export const initialState = {};

const FilterReallocationRequestListReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FILTER_REALLOCATION_REQUESTS:
                draft.error = false;
                draft.loading = true;
                draft.isSubCFORequest = action.isSubCFORequest;
                break;

            case FILTER_REALLOCATION_SUCCESS:
                draft.reallocationListResponse = action.response;
                draft.loading = false;
                break;

            case FILTER_REALLOCATION_ERROR:
                draft.error = action.error;
                draft.loading = false;
                break;

            case CHANGE_REQUEST_IDENTIFIER:
                draft.requestIdentifier = action.requestIdentifier;
                draft.error = false;
                break;

            case CHANGE_REQUEST_STATUS:
                draft.requestStatus = action.requestStatus;
                draft.error = false;
                break;

            case CHANGE_ENROLLMENT_NAME:
                draft.enrollmentName = action.enrollmentName;
                draft.error = false;
                break;

            case CHANGE_SYSTEM_PO_NUMBER:
                draft.systemPONumber = action.systemPONumber;
                draft.error = false;
                break;

            case CHANGE_ENROLLMENT_NUMBER:
                draft.enrollmentNumber = action.enrollmentNumber;
                draft.error = false;
                break;

            case CHANGE_TENANT_NUMBER:
                draft.tenantNumber = action.tenantNumber;
                draft.error = false;
                break;

            case CHANGE_COSMIC_NUMBER:
                draft.cosmicNumber = action.cosmicNumber;
                draft.error = false;
                break;

            case CHANGE_DATE_RANGE:
                draft.dateRange = action.dateRange;
                draft.error = false;
                break;
        }
    });

export default FilterReallocationRequestListReducer;
