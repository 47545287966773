import { select, takeLatest } from "redux-saga/effects";
import { reallocationListRequestCreate } from "../../../common/internals/api/requests";
import { sagaFetchHandler } from "../../../common/internals/api/sagaFetchHandler";
import { formatSearchRequest } from "../../../common/internals/utils/formatRequest";
import {
    makeSelectAccessToken,
    makeSelectAuthType,
} from "../../../common/app/selectors";

import {
    FilterReallocationRequestsList,
    FilterReallocationRequestsListError,
} from "./actions";
import { FILTER_REALLOCATION_REQUESTS } from "./constants";
import {
    makeSelectRequestId,
    makeSelectRequestStatus,
    makeSelectEnrollmentName,
    makeSelectEnrollmentNumber,
    makeSelectCosmicNumber,
    makeSelectTenantNumber,
    makeSelectDateRange,
} from "./selectors";

export function* getReallocationList() {
    var reallocationListRequest = reallocationListRequestCreate();
    yield* setAuthTokens(reallocationListRequest);

    var requestParams = {
        dateRange: yield select(makeSelectDateRange()),
        returnsIdentifier: yield select(makeSelectRequestId()),
        agreementName: yield select(makeSelectEnrollmentName()),
        agreementNumber: yield select(makeSelectEnrollmentNumber()),
        cosmicNumber: yield select(makeSelectCosmicNumber()),
        requestStatusCode: yield select(makeSelectRequestStatus()),
    	sourceTenantAgreementNumber: yield select(makeSelectTenantNumber())
    };

    var requestPayload = formatSearchRequest(requestParams);
    reallocationListRequest.options.body = requestPayload;
    yield* sagaFetchHandler(
        reallocationListRequest,
        FilterReallocationRequestsList,
        FilterReallocationRequestsListError
    );
}

function* setAuthTokens(request) {
    const accessToken = yield select(makeSelectAccessToken());
    const authType = yield select(makeSelectAuthType());
    request.options.headers["Authorization"] = "Bearer " + accessToken;
    request.options.headers["x-ms-AuthType"] = authType;
}

export default function* filterReallocationListRequestSaga() {
    yield takeLatest(FILTER_REALLOCATION_REQUESTS, getReallocationList);
}


