import React from 'react';
import { compose } from 'redux';
import messages from './messages';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import InfoMessage from '../../../../common/components/InfoMessage/InfoMessage';
import { makeSelectFormData } from './selectors'

export function FBCSubmitInfo({formData}) {
    const readonly = formData && formData.isReadOnly;
    const isUserReadOnly = formData && formData.isUserReadOnly;
    const readOnlyFlag = (readonly || isUserReadOnly) ? true : false;
    const operationsCenterCode = formData && formData.operationsCenterCode;
    var alternateROC = formData && formData.alternateROC;
    alternateROC = (alternateROC) ? alternateROC : operationsCenterCode;
    const rocOverriden = (operationsCenterCode == alternateROC) ? false: true; 
    var rocMessage = "";
    if (rocOverriden) {
        rocMessage  = "Submitter has changed ROC from " + alternateROC + " to " + operationsCenterCode + "";
    }
    if (formData && formData.requestStatusCode && formData.requestStatusCode === "REJ" && formData.rejectionReason && formData.rejectionReason.rejectReason) {
        var _rejectionReason = formData && formData.rejectionReason && formData.rejectionReason.rejectReason;
        if (rocOverriden) {
            return (<div><InfoMessage messageBody={"Rejected: " + _rejectionReason} messageTitle={messages.readOnlyInfoMessage}  />
                <InfoMessage messageBody={rocMessage}
                    messageTitle={messages.rocInfoMessage} /></div>)
        }
        return (<InfoMessage messageBody={"Rejected: " + _rejectionReason} messageTitle={messages.readOnlyInfoMessage} />);

    } else if (formData && formData.requestStatusCode && formData.requestStatusCode === "CAN" && formData.cancellationReason && formData.cancellationReason.cancelReason) {
        var _cancelReason = formData && formData.cancellationReason && formData.cancellationReason.cancelReason;
        if (rocOverriden) {
            return (<div><InfoMessage messageBody={"Cancelled: " + _cancelReason} messageTitle={messages.readOnlyInfoMessage} />
                <InfoMessage messageBody={rocMessage}
                    messageTitle={messages.rocInfoMessage} /></div>)
        }
        return (<InfoMessage messageBody={"Cancelled: " + _cancelReason} messageTitle={messages.readOnlyInfoMessage} />);

    } else if (readOnlyFlag) {
        const message = <FormattedMessage {...messages.readOnlyMessage} />;
        if (rocOverriden) {
            return (<div><InfoMessage messageBody={message}
                messageTitle={messages.readOnlyInfoMessage} />
                <InfoMessage messageBody={rocMessage}
                    messageTitle={messages.rocInfoMessage} /></div>)
        }
        return (<InfoMessage messageBody={message}
            messageTitle={messages.readOnlyInfoMessage} />)
    }
    return null;
}

FBCSubmitInfo.propTypes = {
    formData: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
    formData: makeSelectFormData()
});

const withConnect = connect(
    mapStateToProps
);

export default compose(withConnect)(FBCSubmitInfo);
