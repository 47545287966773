import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectpoSelectionModalDomain = state => state.poSelectionModal || initialState;
const selectRoot = state => state || initialState;

const makeSelectPOSelectionModal = () =>
    createSelector(
        selectpoSelectionModalDomain,
        substate => substate,
    );

const makeSelectIsOpen = () =>
    createSelector(selectpoSelectionModalDomain, substate => substate.isPOSelectionModal);

const makeSelectPONumber = () =>
    createSelector(selectpoSelectionModalDomain, substate => substate.poNumber);

const makeSelectPOList = () =>
    createSelector(selectpoSelectionModalDomain, substate => substate.poList);


const makeSelectPurchaseOrderList = () =>
    createSelector(selectpoSelectionModalDomain, substate => substate.purchaseOrdersList);

export default makeSelectPOSelectionModal;

export { makeSelectIsOpen, makeSelectPONumber, makeSelectPOList, makeSelectPurchaseOrderList};