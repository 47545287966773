import { defineMessages } from 'react-intl';

export const scope = 'multiTenantSearchAndAdd';

export default defineMessages({
    sourceTenant: {
        id: `${scope}.sourceTenant`,
        defaultMessage: 'Source Enrollment Number',
    },

    noSourceTenantsFound: {  
        id: `${scope}.noSourceTenantsFound`,
        defaultMessage: 'No source enrollments found.',
    },

    noPOFound: {  
        id: `${scope}.noPOFound`,
        defaultMessage: 'No POs to reallocate.',
    },
});
