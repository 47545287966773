import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

export class SuccessMessage extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { formatMessage } = this.props.intl;
        return (
            <div className="alert-band">
                <div className="alert with-icon alert-success alert-dismissible fade in" role="alert" aria-live="assertive" aria-hidden="false">
                    <button type="button" className="close" data-dismiss="alert" aria-label={formatMessage({ id: "lblCloseText" })} onClick={this.props.onClick ? this.props.onClick : null}>
                        <span aria-hidden="true"><i className="win-icon win-icon-Cancel" /></span>
                    </button>
                    <span className="alert-icon"><i className="win-icon win-icon-Completed" /></span>
                    <div className="alert-title">
                        <FormattedMessage id={this.props.id} defaultMessage={this.props && this.props.value ? this.props.value : ''} description={this.props.title} />
                    </div>
                    <div className="row">
                        <div className="col-md-24">
                            <p>{this.props.message}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(SuccessMessage);
