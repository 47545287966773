//#region import section

import { takeLatest, select } from "redux-saga/effects";
import {
  SUBMIT_FBC_ApprovalRequest} from "./constants";
import {
  submitFbcApprovalRequestSuccess,
  submitFbcApprovalRequestFailure,
} from "./actions";
import {
  makeSelectFbcAction,
  makeSelectFbcWorkflow,
} from "./selectors";

import { makeSelectInvoiceNumber } from "../../../../approval/approver/containers/createRequest/selectors";
import {
  makeSelectAuthType,
  makeSelectAccessToken,
} from "../../../../common/app/selectors";

import {
  makeSelectFbcApproverComment,  makeSelectFbcApproverCallout, makeSelectFbcRejectionReasonCode, makeSelectFbcRejectionReasonDescripiton
} from "../approverDetails/selectors";


import { sumbitApprovalRequestCreate } from "../../../../common/internals/api/requests";
import * as Helpers from "../../../../common/internals/utils/helpers";
import { sagaFetchHandler } from "../../../../common/internals/api/sagaFetchHandler";

//#endregion

//#region functional component

export function* submitFbcRequest() {
  var submitFbcApprovalRequest = sumbitApprovalRequestCreate();
  yield* setAuthTokens(submitFbcApprovalRequest);
  var invoiceNumber = yield select(makeSelectInvoiceNumber());
  var approverComment = yield select(makeSelectFbcApproverComment());
  var approverCallout = yield select(makeSelectFbcApproverCallout());
  var workflow = yield select(makeSelectFbcWorkflow());
  var action = yield select(makeSelectFbcAction());

  var rejectionReasonCode = yield select(makeSelectFbcRejectionReasonCode());
    var otherRejectionReason = yield select(makeSelectFbcRejectionReasonDescripiton());

  //Approve api url
  submitFbcApprovalRequest.url = `api/return/${invoiceNumber}/Approval`;

  //convert request to json format
  submitFbcApprovalRequest.options.body = JSON.stringify(
    TransformRequest(
      invoiceNumber,
      approverComment,
      approverCallout,
      rejectionReasonCode,
      otherRejectionReason,
      workflow,
      action,
    )
  );
//console.log("submitFbcApprovalRequest:",submitFbcApprovalRequest,invoiceNumber,approverComment);
  yield* sagaFetchHandler(
    submitFbcApprovalRequest,
    submitFbcApprovalRequestSuccess,
    submitFbcApprovalRequestFailure
  );
}

//#endregion


function TransformRequest(
    invoiceNumber,
    approverComment,
    approverCallout,
    rejectionReasonCode,
    otherRejectionReason,
    workflow,
    action
) {
  if(window && window.vlcredits.envData.Services.isRejectionReason == "true"){
    var approvalSub = {
        vlFbApprover: {
            workflow: workflow,
            approverComment: approverComment,
            approverCallout: approverCallout,
            rejectionReasonCode: rejectionReasonCode,
            otherRejectionReason: otherRejectionReason,
            billingDocumentNbr: invoiceNumber,
            RejectionReason: {
              RejectReason: approverComment,
              RejectReasonCode: rejectionReasonCode
            }
        },
        action: action
    };
  }else {
    var approvalSub = {
      vlFbApprover: {
          workflow: workflow,
          approverComment: approverComment,
          billingDocumentNbr: invoiceNumber
      },
      action: action
  };
  }
    return approvalSub;
}

function* setAuthTokens(request) {
  const accessToken = yield select(makeSelectAccessToken());
  const authType = yield select(makeSelectAuthType());
  request.options.headers["Authorization"] = "Bearer " + accessToken;
  request.options.headers["x-ms-AuthType"] = authType;
}

//#endregion

export default function* fbcApprovalRequestSaga() {
  yield takeLatest(SUBMIT_FBC_ApprovalRequest, submitFbcRequest);
}
