export const responseFilter = {
    "Currency": [
        "CurrencyCode",
        "CurrencyName"
    ],
    "PurchaseOrderType": [
        "PurchaseOrderTypeCode",
        "PurchaseOrderTypeName"
    ],
    "Program": [
        "ProgramCode",
        "ProgramName"
    ],
    "Country": [
        "CountryCode",
        "CountryName"
    ],
    "BillingOption": [
        "BillingOptionCode",
        "BillingOptionName"
    ],
    "GetMTReturn": [
        "billingDocumentNbr",
        "ticket",
        "tenants",
        "submitUserEmail",
        "isSUB",
        "multiTenantPODetails",
        "adjustedPOStatus",
        "masterAgreementNumber",
        "leadAgreementName",
        "programCode",
        "masterAgmNumber",
        "billToCountryCode",
        "salesLocationCode",
        "pricingCurrency",
        "errors",
        "requestStatusCode",
        "cancellationReason",
        "isReadOnly",
        "attachment",
        "workflowList",
        "isUserReadOnly",
        "entitySourceSystem"
    ],
    "GetReturn": [
        "purchaseOrderId",
        "invoiceDate",
        "invoiceTotalAmount",
        "isOptOut",
        "pricingCurrencyCode",
        "invoiceTotalAmountUSD",
        "purchaseOrderNumber",
        "systemPONumber",
        "purchaseOrderTypeCode",
        "agreementNumber",
        "cosmicCaseNumber",
        "requestId",
        "programCode",
        "masterAgreementNumber",
        "salesLocationCode",
        "endCustomerPurchaseOrderNumber",
        "endCustomerName",
        "submitUserEmail",
        "billToPCN",
        "billToName",
        "softwareAdvisorPCN",
        "softwareAdvisorName",
        "api_partnerEmail",
        "indirectCustomerNumber",
        "resellerName",
        "api_resellerEmail",
        "purchaseOrderLineItemCollection",
        "multipleInvoiceList",
        "IsBdApproved",
        "isFullCredit",
        "handlingFee",
        "rebillPOList",
        "workflowList",
        "entitySourceSystem",
        "tickets",
        "attachement",
        "empowermentIds",
        "notes",
        "netAmountUSD",
        "netAmount",
        "returnReasonCode",
        "notaFiscal",
        "approverComment",
        "approverCallout",
        "invoiceNumber",
        "billingDocumentNbr",
        "returnsIdentifier",
        "isReadOnly",
        "isUserReadOnly",
        "billToCountryCode",
        "attachment",
        "requestStatusCode",
        "USDCurrencyValue",
        "isBdApproved",
        "licenseAge",
        "userRole",
        "isDirectAgreement",
        "isCFOReadOnly",
        "isSUB",
        "rejectionReason",
        "cancellationReason",
        "proposalId",
        "isAutoSubmit",
        "operationsCenterCode",
        "alternateROC",
        "hasMultipleRequestCurrency",
        "hasMultipleRebillCurrency",
        "multiTenantPODetails",
        "errors"
    ],
    "ApproverList": [
        "workflow",
        "approverLevel",
        "approvedBy",
        "approveDate",
        "status",
        "validUntill",
        "approverComments"
    ],
    "CreditList": [
        "tickets",
        "purchaseOrderId",
        "masterAgreementNumber",
        "isFullCredit",
        "invoiceTotalAmount",
        "invoiceTotalAmountUSD",
        "netAmount",
        "netAmountUSD",
        "billToPCN",
        "billToName",
        "endCustomerPurchaseOrderNumber",
        "endCustomerName",
        "indirectCustomerNumber",
        "resellerName",
        "directCustomerBillToNumber",
        "billOnTime",
        "externalIdentifier",
        "purchaseOrderNumber",
        "agreementNumber",
        "transactionSetPurposeCode",
        "statusCode",
        "agreementTypeCode",
        "usagePeriodDate",
        "programCode",
        "purchaseOrderTypeCode",
        "purchaseOrderLineItemCollection",
        "pricingCurrencyCode",
        "purchaseOrderDate",
        "source",
        "entitySourceSystem",
        "customerTypeCode",
        "salesLocationCode",
        "operationsCenterCode",
        "rebillPOList",
        "attachements",
        "requestStatusCode",
        "rmaId",
        "rmaNbr",
        "returnsIdentifier",
        "isReadOnly",
        "requestApprovalDate",
        "approvalStatusCode",
        "licenseAge",
        "returnReasonCode",
        "submitUserId",
        "submitUserLCID",
        "submitUserEmail",
        "requestEntryDate",
        "requestSubmitDate",
        "billingDocumentNbr",
        "pKey",
        "id",
        "_etag",
        "rejectionReason",
        "cancellationReason",
        "isAutoSubmit"
    ],
    "RebillPO": [
        "identifier",
        "amount",
        "amountUSD",
        "type",
        "poInvoiceDate",
        "currency",
        "isEligible",
        "lineItems",
        "poStatusCode",
        "programCode",
        "selectedAmount",
        "selectedAmountUSD"
    ],
    "lineItemDetails": [
        "externalIdentifier",
        "poliStatus",
        "productFamilyCode",
        "productFamilyName",
        "partNumber",
        "programOfferingCode",
        "programOfferingName",
        "usageCountryCode",
        "billingOptionCode",
        "productTypeCode",
        "salesOrderLineItemId",
        "salesOrderId",
        "poLineItemId",
        "revenueTransactionTypeCode",
        "soliStatus",
        "externalStatus",
        "quantity",
        "usedPrice",
        "billOnDate",
        "coverageStartDate",
        "coverageEndDate",
        "extendedAmount",
        "invoiceNumber",
        "invoiceTotalAmount",
        "billingMultiplierPrice",
        "usagePeriodDate",
        "billingTypeCode",
        "invoiceDate",
        "purchaseUnitCode",
        "originalPurchaseOrderNumber",
        "endCustomrPurchaseOrderNumber",
        "createdDate",
        "billingMultipler",
        "specialPricingAppliedFlag",
        "comments",
        "adjustedQuantity",
        "adjustedPrice",
        "productDescription",
        "isAdjusted",
        "isMatchedPFAM",
        "USDCurrencyValue"
    ],
    "multipleInvoiceList": [
        "exchangeRate",
        "externalIdentifier",
        "invoiceDate",
        "invoiceNumber",
        "invoiceTotalAmount",
        "invoiceTotalAmountUSD",
        "isSelected",
        "localInvoiceNumber"
    ],
    "multiTenantPODetails": [
        "createdData",
        "externalIdentifier",
        "poliDetails",
        "purchaseOrderId",
        "purchaseOrderNumber",
        "purchaseOrderTypeCode",
        "sourceTenant",
        "systemPONumber"
    ],
    "poliDetails": [
        "POLineItemID",
        "coveragePeriodEndDate",
        "coveragePeriodStartDate",
        "createdDate",
        "destinationTenant",
        "partNumber",
        "quantity"
    ],
    "destinationTenant": [
        "newPONumber",
        "quantity",
        "tenantId",
        "tenantName"
    ]
}
