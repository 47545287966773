import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectFbcApproverDetailsDomain = (state) =>
  state.fbcApproverDetails || initialState;

const makeSelectFbcApproverDetails = () =>
  createSelector(selectFbcApproverDetailsDomain, (substate) => substate);

const makeSelectFbcApproverComment = () =>
  createSelector(
    selectFbcApproverDetailsDomain,
    (substate) => substate.approverComment
  );

  const makeSelectFbcApproverCallout = () =>
  createSelector(
    selectFbcApproverDetailsDomain,
      substate => substate.approverCallout,
    );

const makeSelectFbcRejectionReasonCode = () =>
    createSelector(
      selectFbcApproverDetailsDomain,
        substate => substate.rejectionReasonCode,
    );

const makeSelectFbcRejectionReasonDescripiton = () =>
    createSelector(
      selectFbcApproverDetailsDomain,
        substate => substate.rejectionReasonDescripiton,
    );


const makeSelectIsFbcOtherRejectionReason = () =>
    createSelector(
      selectFbcApproverDetailsDomain,
        substate => substate.isOtherRejectionReason,
    );

export default makeSelectFbcApproverDetails;
export { selectFbcApproverDetailsDomain, makeSelectFbcApproverComment, makeSelectFbcApproverCallout, makeSelectFbcRejectionReasonCode, makeSelectFbcRejectionReasonDescripiton, makeSelectIsFbcOtherRejectionReason };
