import React from 'react';
import messages from './messages';
import { FormattedMessage } from 'react-intl';

function CloseButton({ onClick }) {
    if (onClick)
        return (
            <FormattedMessage {...messages.closeErrorMessage}>
                {(closeMessage) =>
                    <button type="button" className="close" data-dismiss="alert" aria-label={closeMessage} onClick={this.props.onClick} >
                        <span aria-hidden="true"><i className="win-icon win-icon-Cancel" /></span>
                    </button>}
            </FormattedMessage>
        );
    return null;
}

export function InfoMessage({ messageBody, messageTitle, onCloseClick }) {
    return (
        <div className="alert-band">
            <div className="alert with-icon alert-progress alert-dismissible fade in" role="alert" aria-live="assertive" aria-hidden="false">
                
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true"><i className="win-icon win-icon-Cancel"></i></span>
                </button>
                <span className="alert-icon"><i className="win-icon win-icon-Info"></i></span>
                <div className="alert-title"><FormattedMessage {...messageTitle} /></div>
                <div className="row">
                    <div className="col-xs-24" style={{"wordWrap" : "break-word"}}>
                        <p> {messageBody} </p>
                    </div>
                </div>
            </div>
        </div>



    );
}

export default InfoMessage;
