export const FILTER_REALLOCATION_REQUESTS = 'FILTER_REALLOCATION_REQUESTS';
export const FILTER_REALLOCATION_SUCCESS = 'FILTER_REALLOCATION_SUCCESS';
export const FILTER_REALLOCATION_ERROR = 'FILTER_REALLOCATION_ERROR';
export const CHANGE_REQUEST_IDENTIFIER = 'CHANGE_REQUEST_IDENTIFIER';
export const CHANGE_REQUEST_STATUS = 'CHANGE_REQUEST_STATUS';
export const CHANGE_SYSTEM_PO_NUMBER = 'CHANGE_SYSTEM_PO_NUMBER';
export const CHANGE_ENROLLMENT_NUMBER = 'CHANGE_ENROLLMENT_NUMBER';
export const CHANGE_CUSTOMER_NUMBER = 'CHANGE_CUSTOMER_NUMBER';
export const CHANGE_COSMIC_NUMBER = 'CHANGE_COSMIC_NUMBER';
export const CHANGE_DATE_RANGE = 'CHANGE_DATE_RANGE';
export const CHANGE_ENROLLMENT_NAME = 'CHANGE_ENROLLMENT_NAME';
export const CHANGE_TENANT_NUMBER = 'CHANGE_TENANT_NUMBER';


