import React from 'react';
import { compose } from 'redux';
import messages from './messages';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import InfoMessage from '../../../../common/components/InfoMessage/InfoMessage';
import { makeSelectIsReturnFormReadOnly, makeSelectInvoice } from '../../../partners/containers/returnForm/selectors';
import { makeSelectUserInfo } from '../../../../common/app/selectors';
import { isWamEnabled } from '../../../../common/internals/utils/featureFlag';


export function SubmitInfo({ isUserReadOnly, isReadOnly, invoice,userInfo}) {

    let isWamEnabledFlag = isWamEnabled();
    console.log("inside_isWamEnabledFlag: ", isWamEnabledFlag,userInfo);
    let authType = userInfo && userInfo.authType;
    let linkedAccounts = userInfo && userInfo.linkedAccounts;

    let userEmail = userInfo && userInfo.email;
    let isLinkedAccountOverride = userInfo && userInfo.isLinkedAccountOverride;

    var iswhitelistedaccounts = false;
    const whitelistedaccounts = window.vlcredits.envData.Services.wamWhitelistedaccounts;
if(whitelistedaccounts){
  var keys = whitelistedaccounts.split(",");
  iswhitelistedaccounts = keys.includes(userEmail);
}


    const operationsCenterCode = invoice && invoice.operationsCenterCode;
    var alternateROC = invoice && invoice.alternateROC;
    alternateROC = (alternateROC) ? alternateROC : operationsCenterCode;
    const rocOverriden = (operationsCenterCode == alternateROC) ? false : true;
    var rocMessage = "";
    if (rocOverriden) {
        rocMessage = "Submitter has changed ROC from " + alternateROC + " to " + operationsCenterCode + "";
    }
    if (invoice && invoice.requestStatusCode && invoice.requestStatusCode === "REJ" && invoice.rejectionReason && invoice.rejectionReason.rejectReason) {
        var _rejectionReason = invoice && invoice.rejectionReason && invoice.rejectionReason.rejectReason;
        if (rocOverriden) {
            return (<div><InfoMessage messageBody={"Rejected: " + _rejectionReason} messageTitle={messages.readOnlyInfoMessage} />
                <InfoMessage messageBody={rocMessage}
                    messageTitle={messages.rocInfoMessage} /></div>)
        }
        return (<InfoMessage messageBody={"Rejected: " + _rejectionReason} messageTitle={messages.readOnlyInfoMessage} />);
    }
    else if (invoice && invoice.requestStatusCode && invoice.requestStatusCode === "CAN" && invoice.cancellationReason && invoice.cancellationReason.cancelReason) {
        var _cancelReason = invoice && invoice.cancellationReason && invoice.cancellationReason.cancelReason;
        if (rocOverriden) {
            return (<div><InfoMessage messageBody={"Cancelled: " + _cancelReason} messageTitle={messages.readOnlyInfoMessage} />
                <InfoMessage messageBody={rocMessage}
                    messageTitle={messages.rocInfoMessage} /></div >)
        }
        return (<InfoMessage messageBody={"Cancelled: " + _cancelReason} messageTitle={messages.readOnlyInfoMessage} />);
    } else {
        if (isReadOnly) {
            const message = <FormattedMessage {...messages.readOnlyMessage} />;
            if (rocOverriden) {
                return (<div><InfoMessage messageBody={message}
                    messageTitle={messages.readOnlyInfoMessage} />
                    <InfoMessage messageBody={rocMessage}
                        messageTitle={messages.rocInfoMessage} /></div >)
            }
            return (<InfoMessage messageBody={message}
                messageTitle={messages.readOnlyInfoMessage} />);
        }
        if (isUserReadOnly) {
            const message = <FormattedMessage {...messages.userReadOnlyMessage} />;
            if (rocOverriden) {
                return (<div><InfoMessage messageBody={message}
                    messageTitle={messages.readOnlyInfoMessage} />
                    <InfoMessage messageBody={rocMessage}
                        messageTitle={messages.rocInfoMessage} /></div >)
            }
            return (<InfoMessage messageBody={message}
                messageTitle={messages.readOnlyInfoMessage} />);
        }
    }
    if((isWamEnabledFlag == "true" && authType == "msa") && ((!isLinkedAccountOverride || isLinkedAccountOverride == "False") && iswhitelistedaccounts == false)){
        if(linkedAccounts == null || linkedAccounts.length == 0){
            const message = <FormattedMessage {...messages.workAccountMapped} />;
            return (<InfoMessage messageBody={message}
                messageTitle={messages.workAccountTitle} />)
        }
}
    return null;
}

SubmitInfo.propTypes = {
    isUserReadOnly: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    invoice: PropTypes.object,
    userInfo: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    isUserReadOnly: makeSelectIsReturnFormReadOnly(),
    isReadOnly: makeSelectIsReturnFormReadOnly(),
    invoice: makeSelectInvoice(),
    userInfo: makeSelectUserInfo()
});

const withConnect = connect(
    mapStateToProps
);

export default compose(withConnect)(SubmitInfo);
