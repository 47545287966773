import { ADD_REALLOCATE, ON_PO_REMOVAL, GET_FULL_ORDER_SUCCESS, GET_FULL_ORDER_ERROR, PART_NUMBER_LOADING_SUCCESS } from './constants';

export function onAddReallocate(data) {
    return {
        type: ADD_REALLOCATE,
        data
    }
}

export function onPORemoval(data) {
    return {
        type: ON_PO_REMOVAL,
        data
    }

}

export function getFullOrderSuccess(purchaseorder, searchObject) {
    return {
        type: GET_FULL_ORDER_SUCCESS,
        purchaseorder,
        searchObject
    };
}

export function getFullOrderError(error) {
    return {
        type: GET_FULL_ORDER_ERROR,
        error
    };
}

export function partNumberLoadingSuccess() {
    return {
        type: PART_NUMBER_LOADING_SUCCESS
    }
}