import { select, takeLatest } from 'redux-saga/effects';
import { makeSelectAccessToken, makeSelectAuthType } from '../../../../common/app/selectors';
import { getReturnRequestCreate } from '../../../../common/internals/api/requests';
import { sagaFetchHandler } from '../../../../common/internals/api/sagaFetchHandler';
import { transformReturnResponse } from '../../../../common/internals/api/transformReturnResponse';
import { getMTRequestDataCompleted, getMTRequestDataError } from './actions';
import { MT_GET_RETURN } from './constants';

export function* getReturn(action) {
    var getReturnRequest = getReturnRequestCreate();
    yield* setAuthTokens(getReturnRequest);
    getReturnRequest.url
        = `api/return/${action.payload.leadEnrollmentNumber}?api-version=1.0&StatusCode=${action.payload.status}&returnsIdentifier=${action.payload.requestId}`;

    yield* sagaFetchHandler(getReturnRequest, getMTRequestDataCompleted,
        getMTRequestDataError, transformReturnResponse, action.payload.leadEnrollmentNumber);
}

function* setAuthTokens(request) {
    const accessToken = yield select(makeSelectAccessToken());
    const authType = yield select(makeSelectAuthType());
    request.options.headers["Authorization"] = "Bearer " + accessToken;
    request.options.headers["x-ms-AuthType"] = authType;
}

export default function* fetchRequestSaga() {
    yield takeLatest(MT_GET_RETURN, getReturn);
}