import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectRoot = state => state;
const selectMultiInvoiceReturnFormDomain = state =>
  state.multiInvoiceReturnForm || initialState;


const makeSelectMultiInvoiceReturnForm = () =>
    createSelector(selectMultiInvoiceReturnFormDomain, substate => substate);


const makeSelectIsMultiInvoiceReturnFormReadOnly = () =>
  createSelector(selectRoot, globalState => {
      if (globalState.fetchMultiInvoiceReturnRequest && globalState.fetchMultiInvoiceReturnRequest.invoices)
          return globalState.fetchMultiInvoiceReturnRequest.invoices.isReadOnly
              || globalState.fetchMultiInvoiceReturnRequest.invoices.isUserReadOnly;

      if (globalState.createMultiInvoiceRequest && globalState.createMultiInvoiceRequest.invoices)
          return globalState.createMultiInvoiceRequest.invoices.isReadOnly
              || globalState.createMultiInvoiceRequest.invoices.isUserReadOnly;

    return null;
  });


const makeSelectMultiInvoiceReturnFormError = () =>
    createSelector(selectMultiInvoiceReturnFormDomain, substate => substate.error);

const makeSelectMultiInvoiceReturnFormLoading = () =>
    createSelector(selectMultiInvoiceReturnFormDomain, substate => substate.loading);

const makeSelectMultiInvoiceReturnFormSuccess = () =>
    createSelector(selectMultiInvoiceReturnFormDomain, substate => substate.successResponse);

const makeSelectMultiInvoiceReturnFormDeleteSuccessResponse = () =>
    createSelector(selectMultiInvoiceReturnFormDomain, substate => substate.deleteSuccessResponse);

const makeSelectMultiInvoiceReturnFormDeleteError = () =>
    createSelector(selectMultiInvoiceReturnFormDomain, substate => substate.deleteError);

const makeSelectMultiInvoiceReturnFormSaveSuccessResponse = () =>
    createSelector(selectMultiInvoiceReturnFormDomain, substate => substate.saveSuccessResponse);

const makeSelectMultiInvoiceReturnFormSaveError = () =>
    createSelector(selectMultiInvoiceReturnFormDomain, substate => substate.saveError);

const makeSelectMultiInvoiceShowRebillConfirmation = () =>
    createSelector(selectMultiInvoiceReturnFormDomain, substate => substate.showRebillConfirmation);

const makeSelectMultiInvoiceReturnFormIsActionOpen = () =>
  createSelector(
    selectMultiInvoiceReturnFormDomain,
    substate => substate.isActionsOpen
  );

const makeSelectMultiInvoiceReturnStatus = () =>
  createSelector(
    selectMultiInvoiceReturnFormDomain,
    substate => substate.requestStatusCode
    );

const makeSelectMultiInvoiceReturnFormEReceiptStatus = () =>
    createSelector(selectMultiInvoiceReturnFormDomain, substate => substate.eReceiptStatus);

const makeSelectMultiInvoiceReturnFormData = () =>
    createSelector(
        selectRoot,
        globalState => {

            const cosmicCaseNumber =
                (globalState.fetchMultiInvoiceReturnRequest && globalState.fetchMultiInvoiceReturnRequest.invoices) ?
                    globalState.fetchMultiInvoiceReturnRequest.invoices.cosmicCaseNumber :
                    globalState.createMultiInvoiceRequest.invoices.cosmicCaseNumber;

            const {
                rebillpoTable: {
                    rebillInvoicepo: rebillPOList
                },
                returnDetails: {
                    returnReason: returnReasonCode,
                    isBdApproved: isBdApproved,
                    netCreditAmount: invoiceTotalAmountUSD,
                    netCreditAmountLocal: invoiceTotalAmount,
                    grossCreditAmount: grossTotalAmountUSD,
                    grossCreditAmountLocal: grossTotalAmount,
                    operationsCenterCode: operationsCenterCode
                },
                empowermentsDetails: {
                    empowermentIdCSV: empowermentIds,
                    notes: notes
                },
                invoicesDetails: {
                    invoicesDetails: multipleInvoiceList 
                },
                multiInvoiceReturnForm: {
                    requestStatusCode: requestStatusCode
                },
                fileUploadModal: modalData,

            } = globalState;
           
            const returnRequestPayload = {
                "openOrder": null,
                "vlOrder": {
                    
                    returnReasonCode,
                    "netAmountUSD": invoiceTotalAmountUSD,
                    "netAmount": invoiceTotalAmount,
                    "invoiceinvoiceTotalAmount": grossTotalAmount,
                    "invoiceTotalAmountUSD": grossTotalAmountUSD,
                    "rebillPOList":  rebillPOList,
                    "attachment": modalData ? modalData.uploadedFiles : [],
                    "multipleInvoiceList": multipleInvoiceList,
                    "tickets": cosmicCaseNumber ?
                        [{ "ticketId": cosmicCaseNumber.trim(), "type": "manual" }] : null,
                    empowermentIds,
                    isBdApproved,
                    notes,
                    requestStatusCode,
                    "alternateROC": operationsCenterCode
                }
            };
            return returnRequestPayload;
        }
    );

const makeSelectMultiInvoiceRestockFeeConfirmation = () =>
    createSelector(
        selectMultiInvoiceReturnFormDomain,
        substate => substate.restockFeeConfirmation,
    )

export default makeSelectMultiInvoiceReturnForm;

export {
    selectMultiInvoiceReturnFormDomain,
    makeSelectIsMultiInvoiceReturnFormReadOnly,
    makeSelectMultiInvoiceReturnFormIsActionOpen,
    makeSelectMultiInvoiceReturnStatus,
    makeSelectMultiInvoiceReturnFormError,
    makeSelectMultiInvoiceReturnFormLoading,
    makeSelectMultiInvoiceReturnFormSuccess,
    makeSelectMultiInvoiceShowRebillConfirmation,
    makeSelectMultiInvoiceReturnFormDeleteError,
    makeSelectMultiInvoiceReturnFormSaveError,
    makeSelectMultiInvoiceReturnFormDeleteSuccessResponse,
    makeSelectMultiInvoiceReturnFormSaveSuccessResponse,
    makeSelectMultiInvoiceReturnFormEReceiptStatus,
    makeSelectMultiInvoiceReturnFormData,
    makeSelectMultiInvoiceRestockFeeConfirmation
};
