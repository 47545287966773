import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectReallocationBodyDomain = state => state.reallocationBody || initialState;
const selectRoot = state => state || initialState;

export const makeSelectReallocationBodyModal = () =>
    createSelector(
        selectReallocationBodyDomain,
        substate => substate,
    );

const makeSelectSelectedPOLIValue = () =>
    createSelector(selectReallocationBodyDomain, substate => substate.currentFormSelectedValue);


const makePartNumberList = () =>
    createSelector(
        selectRoot, substate => substate.partNumberMenu ? substate.partNumberMenu.partNumberList : []

    );

const makeSelectAnnouncerText = () =>
        createSelector(
            selectRoot, substate => substate.announcerText
        );

export default makeSelectReallocationBodyModal;

export { makeSelectSelectedPOLIValue, makePartNumberList };