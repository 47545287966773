import React, { Component } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { injectIntl } from 'react-intl';
export class ExportToExcel extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { formatMessage } = this.props.intl;
        var exportToExcel = formatMessage({ id: "lblExportToExcel" });
        return (
            <ToolkitProvider
                keyField="uniqueId"
                data={this.props.List}
                columns={this.props.columns}
                exportCSV=
                {{
                    fileName: this.props.fileName
                }}>
                {
                    props => (
                        <div role="menu">
                            <div className='hidden' >
                                <BootstrapTable {...props.baseProps} />
                            </div>
                            <ExportCSV {...props.csvProps} exportToExcel={exportToExcel} />
                        </div>)
                }
            </ToolkitProvider>
        );
    }
}

export default (injectIntl(ExportToExcel));

const ExportCSV = (props) => {
    const handleClick = () => {
        props.onExport();
    };
    return (
        <a value={props.value} key={props.key} role="menuitem" onClick={handleClick} href="javascript:void(0);"
            aria-live="polite" aria-label={props.exportToExcel} aria-labelledby={props.exportToExcel}> {props.exportToExcel}  </a>
    );
};