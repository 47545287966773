import produce from 'immer';
import { CHANGE_LOCALE } from './constants';
import { USER_PREFS_FETCHED } from '../../app/constants';
import { appLocales } from '../../i18n';

export const initialState = {
    locale: 'en-US',
};

const languageProviderReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case CHANGE_LOCALE:
                //console.log(' current locale - ' + draft.locale + ' & from preferences - ' + action.locale);
                if (appLocales.includes(action.locale)
                    && draft.locale !== action.locale)
                    draft.locale = action.locale;
                break;
        }
    });

export default languageProviderReducer;
