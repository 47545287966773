import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectPartNumberMenuDomain = state => state.partNumberMenu || initialState;
const selectRoot = state => state || initialState;

export const makeSelectPartNumberMenu = () =>
    createSelector(
        selectPartNumberMenuDomain,
        substate => substate,
    );

const makeSelectIsAddMoreEnabled = () =>
    createSelector(
        selectPartNumberMenuDomain,
        substate => substate.isAddMoreEnabled
    );

const makeSelectIsAddMoreDisabled = () =>
    createSelector(
        selectPartNumberMenuDomain,
        substate => substate.isAddMoreDisabled
    );

const makeSelectInvalidPOs = () => 
    createSelector(
        selectPartNumberMenuDomain,
        substate => {
            return substate.invalidPOs ? substate.invalidPOs : [];        
    });

const makeFocusedPartNumber = () =>
    createSelector(
        selectPartNumberMenuDomain,
        substate => substate.makeFocusedPartNumber
    );

const makeSelectPartNumberList = () =>
    createSelector(selectPartNumberMenuDomain, substate => substate.partNumberList);

const makeSelectAnnouncerText = () =>
        createSelector(selectPartNumberMenuDomain, substate => substate.announcerText);

export default makeSelectPartNumberMenu;

export { makeSelectPartNumberList, makeSelectIsAddMoreEnabled, makeFocusedPartNumber, makeSelectIsAddMoreDisabled, makeSelectAnnouncerText, makeSelectInvalidPOs };