import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectFbcApprovalErrorsDomain = state => state.fbcApprovalErrors || initialState;

const makeSelectFbcRequestError = () =>
    createSelector(selectFbcApprovalErrorsDomain, substate => substate.error);

const makeSelectFbcApprovalErrors = () =>
  createSelector(
    selectFbcApprovalErrorsDomain,
    substate => substate,
  );

export default makeSelectFbcApprovalErrors;
export { selectFbcApprovalErrorsDomain, makeSelectFbcRequestError };
