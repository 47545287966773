import { defineMessages } from 'react-intl';

export const scope = 'partnerSubmitHome';

export default defineMessages({
    createCreditRequest: {
        id: `${scope}.createCreditRequest`,
        defaultMessage: 'Create Credit Request',
    },
    submitSuccess: {
        id: `${scope}.submitSuccess`,
        defaultMessage: 'Credit Request submitted successfully',
    },
    saveDraftSuccess: {
        id: `${scope}.saveDraftSuccess`,
        defaultMessage: 'Credit request draft saved successfully',
    },
    deleteDraftSuccess: {
        id: `${scope}.deleteDraftSuccess`,
        defaultMessage: 'Credit request draft deleted successfully',
    },
    rebillConfirmation: {
        id: `${scope}.rebillConfirmation`,
        defaultMessage: 'No rebill invoices added for full credit request. Do you want to continue ?',
    },
    closeAction: {
        id: `${scope}.closeAction`,
        defaultMessage: 'close',
    },
    continueAction: {
        id: `${scope}.continueAction`,
        defaultMessage: 'Continue',
    },
    goBackAction: {
        id: `${scope}.goBackAction`,
        defaultMessage: 'Go Back',
    },
    eReceiptSuccessMessage: {
        id: `${scope}.eReceiptSuccessMessage`,
        defaultMessage: 'Credit Request submitted successfully. The invoice in submitted request was previously paid. We are currently reversing your payment and your credit will be processed in 3 business days.',
    },
    rocOverridenMessage: {
        id: `${scope}.rocOverridenMessage`,
        defaultMessage: 'Are you sure you want to change the ROC? Please note that the ROC selected should match the ROC where the order was originally placed as it determines the Operations Centre where this credit request will be processed.If the selected ROC is incorrect this credit request will be rejected.',
    },
    submitConfirmation: {
        id: `${scope}.submitConfirmation`,
        defaultMessage: 'Submit Confirmation',
    },
    mcSKUConfirmation: {
        id: `${scope}.mcSKUConfirmation`,
        defaultMessage: 'Requesting a credit for MC SKU will result in unintended credit/rebills for customer azure overages. If MC value is credited, any overage invoice that was previously covered by the MC value will now generate as an invoice that requires payment. To proceed, please acknowledge that customer has been informed that crediting and rebilling of previous Azure invoices will result from the crediting of Azure prepayment. Any invoices that generated must be paid as standard invoices.',
    }
});
