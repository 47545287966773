import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectRebillDetailsDomain = state => state.rebillDetails;

/* Direct selector to the rebillSearch state domain */
const selectRebillSearchDomain = state => state.rebillPOSearch || initialState;

/* Default selector used by rebillSearch */
const makeSelectRebillSearch = () =>
    createSelector(
        selectRebillSearchDomain,
        substate => substate,
    );


const makeSelectIsRebill = () =>
    createSelector(selectRebillDetailsDomain, substate => substate.isRebill);

const makeSelectRebillpoNumber = () =>
    createSelector(selectRebillSearchDomain, substate => substate.rebillpoNumber);

const makeSelectRebillpo = () =>
    createSelector(selectRebillSearchDomain, substate => substate.rebillpo);

const makeSelectRebillSearchError = () =>
    createSelector(selectRebillSearchDomain, substate => substate.error);

const makeSelectLoading = () =>
    createSelector(selectRebillSearchDomain, substate => substate.loading);

export default makeSelectRebillSearch;
export {
    makeSelectLoading, makeSelectRebillSearch, makeSelectRebillpoNumber,
    makeSelectRebillpo, makeSelectRebillSearchError, makeSelectIsRebill
};
