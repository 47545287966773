import produce from 'immer';
import {
    MT_GET_RETURN,
    MT_GET_RETURN_ERROR,
    MT_GET_RETURN_SUCCESS
} from './constants';
export const initialState = {};

const MultiTenantFetchRequestReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case MT_GET_RETURN:
                draft.requestData = {};
                draft.loading = true;
                draft.error = false;
                break;
            case MT_GET_RETURN_SUCCESS:
                draft.leadEnrollmentNumber = action.leadEnrollmentNumber;
                draft.requestData = action.requestData;
                draft.loading = false;
                draft.error = false;
                console.log(JSON.stringify(action.requestData) + "-=-=-====");
                break;
            case MT_GET_RETURN_ERROR:
                draft.error = action.error;
                draft.loading = false;
                break;
        }
    });

export default MultiTenantFetchRequestReducer;
