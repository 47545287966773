import { DEFAULT_ACTION, IS_VALID_REQ_TO_SUBMIT } from './constants';


export function validReqToSubmit(isValidRequestToSubmit) {
    return {
        type: IS_VALID_REQ_TO_SUBMIT,
        isValidRequestToSubmit
    };
}


export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}
