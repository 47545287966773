import {
    MULTI_TENANT_CHANGE_LEAD_ENROLLMENT_NUMBER,
    MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER,
    MULTI_TENANT_CLEAR_LEAD_ENROLLMENT_NUMBER,
    MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER_SUCCESS,
    MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER_ERROR,
    MULTI_TENANT_CHANGE_COSMIC_CASE_NUMBER,
    MULTI_TENANT_CHANGE_TRACKING_ID,
    RESET_TO_DEFAULT
} from "./constants";

export function search() {
    return {
        type: MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER
    };
}

export function clearLeadEnrollmentNumber() {
    return {
        type: MULTI_TENANT_CLEAR_LEAD_ENROLLMENT_NUMBER
    };
}

export function searchCompleted(agreementDetails, searchObject) {
    return {
        type: MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER_SUCCESS,
        agreementDetails,
        searchObject
    };
}

export function searchError(error) {
    return {
        type: MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER_ERROR,
        error
    };
}

export function changeLeadEnrollmentNumber(leadEnrollmentNumber) {
    return {
        type: MULTI_TENANT_CHANGE_LEAD_ENROLLMENT_NUMBER,
        leadEnrollmentNumber
    };
}

export function changeCosmicCaseNumber(cosmicCaseNumber) {
    return {
        type: MULTI_TENANT_CHANGE_COSMIC_CASE_NUMBER,
        cosmicCaseNumber
    };
}

export function changeTrackingId(trackingId){
    return {
        type: MULTI_TENANT_CHANGE_TRACKING_ID,
        trackingId
    }
}

export function resetToDefault() {
    return {
        type: RESET_TO_DEFAULT
    }
}



