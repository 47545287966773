export const SUBMIT_RETURN = 'ReturnForm/SUBMIT_RETURN';
export const SUBMIT_RETURN_SUCCESS = 'ReturnForm/SUBMIT_RETURN_SUCCESS';
export const SUBMIT_RETURN_FAIL = 'ReturnForm/SUBMIT_RETURN_FAIL';
export const TOGGLE_ACTIONS = 'ReturnForm/TOGGLE_ACTIONS';
export const DELETE_DRAFT = 'ReturnForm/DELETE_DRAFT';
export const DELETE_DRAFT_SUCCESS = 'ReturnForm/DELETE_DRAFT_SUCCESS';
export const DELETE_DRAFT_FAIL = 'ReturnForm/DELETE_DRAFT_FAIL';
export const SAVE_RETURN = 'ReturnForm/SAVE_RETURN';
export const SAVE_RETURN_SUCCESS = 'ReturnForm/SAVE_RETURN_SUCCESS';
export const SAVE_RETURN_FAIL = 'ReturnForm/SAVE_RETURN_FAIL';
export const VALIDATE_FULL_CREDIT = 'ReturnForm/VALIDATE_FULL_CREDIT';
export const SHOW_REBILL_CONFIRMATION = 'ReturnForm/SHOW_REBILL_CONFIRMATION';
export const CLOSE_REBILL_CONFIRMATION = 'ReturnForm/CLOSE_REBILL_CONFIRMATION';
export const E_RECEIPT_SUCCESS = 'ReturnForm/E_RECEIPT_SUCCESS';
export const E_RECEIPT_FAIL = 'ReturnForm/E_RECEIPT_FAIL';
export const EXPORT_PDF = 'ReturnForm/EXPORT_PDF';
export const SHOW_MCSKU_CONFIRMATION = 'ReturnForm/SHOW_MCSKU_CONFIRMATION';
export const CLOSE_MCSKU_CONFIRMATION = 'ReturnForm/CLOSE_MCSKU_CONFIRMATION';
