import { put, select, takeLatest } from 'redux-saga/effects';
import { makeSelectAccessToken, makeSelectAuthType } from '../../../../common/app/selectors';
import { getFullOrderRequestCreate } from '../../../../common/internals/api/requests';
import { sagaFetchHandler } from '../../../../common/internals/api/sagaFetchHandler';
import { transformReturnResponse } from "../../../../common/internals/api/transformReturnResponse";
import { getFullOrderError, getFullOrderSuccess, partNumberLoadingSuccess } from './actions';
import { ADD_REALLOCATE } from './constants';
import { makeSelectOrderdetails } from './selectors';
export function* getFullOrder(action) {
    var externalIdentifier = action.data.externalIdentifier;
    const orderDetails = yield select(makeSelectOrderdetails());

    // Find External Identifier
    const filteredOrderDetails = orderDetails[externalIdentifier];

    if (!filteredOrderDetails) {
        var getFullOrderRequest = getFullOrderRequestCreate();
        yield* setAuthTokens(getFullOrderRequest);
        getFullOrderRequest.url = `api/Order/getFullOrder?externalIdentifier=${encodeURIComponent(externalIdentifier)}`;
        yield* sagaFetchHandler(getFullOrderRequest, getFullOrderSuccess, getFullOrderError, transformReturnResponse, { "externalIdentifier": externalIdentifier });
    }
    else {
        yield put(partNumberLoadingSuccess());
    }
}

function* setAuthTokens(request) {
    const accessToken = yield select(makeSelectAccessToken());
    const authType = yield select(makeSelectAuthType());
    request.options.headers["Authorization"] = "Bearer " + accessToken;
    request.options.headers["x-ms-AuthType"] = authType;
}

export default function* createRequestSaga() {
    yield takeLatest(ADD_REALLOCATE, getFullOrder);
}

