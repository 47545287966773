import produce from 'immer';
import { Approver_Comments, Approver_Callout, CHANGE_REJECTION_REASON, ADD_OTHER_REJECTION_REASON, SET_OTHER_REJECTION_REASON } from './constants';
import { COMMENTS_LENGTH } from '../../../../common/internals/utils/constants';
export const initialState = {};

const fbcApproverDetailsReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case Approver_Comments:
                draft.approverComment = action.value.substring(0, COMMENTS_LENGTH);
                break;
            case Approver_Callout:
                draft.approverCallout = action.value;
                break;
            case CHANGE_REJECTION_REASON:
                draft.rejectionReasonCode = action.value;
                break;
            case ADD_OTHER_REJECTION_REASON:
                draft.rejectionReasonDescripiton = action.value;
                break;
            case SET_OTHER_REJECTION_REASON:
                draft.isOtherRejectionReason = action.value;
                break;
        }
    });

export default fbcApproverDetailsReducer;
