import PropTypes from "prop-types";
import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import Input from '../../../../common/components/Input';
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import { useInjectReducer } from "../../../../common/internals/utils/injectReducer";
import { useInjectSaga } from "../../../../common/internals/utils/injectSaga";
import {
    changeCosmicCaseNumber,
    changeLeadEnrollmentNumber,
    changeTrackingId,
    clearLeadEnrollmentNumber,
    search
} from "./actions";
import messages from "./messages";
import reducer from "./reducer";
import saga from "./saga";
import {
    makeSelectCosmicCaseNumber,
    makeSelectTrackingId,
    makeSelectIsEnrollmentDetailsLoadedFromCreateRequest,
    makeSelectLeadEnrollmentNumberCreate,
    makeSelectMultiTenantCreateRequestLoading,
    makeUserProfileRole
    
} from "./selectors";

function MultiTenantCreateRequest({
    loading,
    leadEnrollmentNumber,
    onSearch,
    onChangeLeadEnrollmentNumber,
    cosmicCaseNumber,
    trackingId,
    onChangeTrackingId,
    onChangeCosmicCaseNumber,
    isEnrolmentDetailsLoaded,
    role
}) {
    useInjectReducer({ key: "multiTenantCreateRequest", reducer });
    useInjectSaga({ key: "multiTenantCreateRequest", saga });

    useEffect(() => {
        if (
            (leadEnrollmentNumber && leadEnrollmentNumber.trim().length > 0)
        ) {
            onSearch();
        }
    }, []);

    const handleKeyPress = event => {
        if (event.key === "Enter") {
            onSearch();
        }
    };

    const enableMTCRMIdVal = window.vlcredits.envData.Services.EnableMTCRMID;
    const isMTCRMIdEnabled = enableMTCRMIdVal && enableMTCRMIdVal.toLowerCase() === "true" ? true : false;

    return (
        <div>
            <div className="row">
                <div className="col-md-24 form-group">
                    <div className="row">
                        <form
                            onSubmit={onSearch}
                            role="form"
                            name="frmMultiTenantRequest"
                            id="frmMultiTenantRequest"
                        >
                            <div className="col-sm-8 col-md-8 col-lg-6 col-xl-4">
                                <Input
                                    type="text"
                                    name="btnLeadEnrollmentNumber"
                                    id="btnLeadEnrollmentNumber"
                                    required={true}
                                    className="form-control mainElement"
                                    label={messages.enterLeadEnrollmentNumber}
                                    defaultmessage={messages.enterLeadEnrollmentNumber}
                                    aria-label={messages.enterLeadEnrollmentNumber}
                                    autoComplete="off"
                                    value={leadEnrollmentNumber || ""}
                                    onChange={onChangeLeadEnrollmentNumber}
                                    onKeyDown={handleKeyPress}
                                />

                                <span style={{
                                    marginTop: "29px",
                                    marginRight: "24px",
                                }}
                                    className="search-icon" role="button" onClick={onSearch} tabIndex="0" aria-label="Search" onKeyDown={handleKeyPress}>
                                    <i className="win-icon win-icon-Search"></i>
                                </span>
                            </div>
                            {window.userRole != 'VPU' ?
                                <div className={isEnrolmentDetailsLoaded ? '' : 'hidden'}>
                                    <div className="col-sm-8 col-md-8 col-lg-6 col-xl-4">
                                        <Input id="cosmicCaseNumber"
                                            value={cosmicCaseNumber || ''}
                                            label={messages.enterCosmicCaseNumber}
                                            className="form-control"
                                            autoComplete="off"
                                            placeholder="Separate multiple values with a ','"
                                            onChange={onChangeCosmicCaseNumber}
                                            required={isMTCRMIdEnabled} />
                                    </div>
                                    {
                                        isMTCRMIdEnabled ? 
                                            <div className="col-sm-8 col-md-8 col-lg-6 col-xl-4">
                                            <Input id="trackingId"
                                                value={trackingId || ''}
                                                label={messages.enterTrackingId}
                                                className="form-control"
                                                autoComplete="off"
                                                placeholder="Separate multiple values with a ','"
                                                onChange={onChangeTrackingId}
                                                required={isMTCRMIdEnabled} />
                                        </div> : ""
                                    }
                                    
                                </div>
                                : ''}

                            <div className="col-sm-8 col-md-8 col-lg-6 col-xl-4">
                                <div className={(loading == true) ? "pull-right" : "hidden"}>
                                    <LoadingIndicator />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

MultiTenantCreateRequest.prototype = {
    loading: PropTypes.bool,
    isEnrolmentDetailsLoadedLoaded: PropTypes.bool,
    leadEnrollmentNumber: PropTypes.string,
    role: PropTypes.string,
    cosmicCaseNumber: PropTypes.string,
    onChangeLeadEnrollmentNumber: PropTypes.func,
    onClear: PropTypes.func,
    onChangeCosmicCaseNumber: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
    leadEnrollmentNumber: makeSelectLeadEnrollmentNumberCreate(),
    loading: makeSelectMultiTenantCreateRequestLoading(),
    isEnrolmentDetailsLoaded: makeSelectIsEnrollmentDetailsLoadedFromCreateRequest(),
    cosmicCaseNumber: makeSelectCosmicCaseNumber(),
    trackingId: makeSelectTrackingId(),
    role: makeUserProfileRole()
});

function mapDispatchToProps(dispatch) {
    return {
        onChangeCosmicCaseNumber: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(changeCosmicCaseNumber(evt.target.value));
        },
        onChangeTrackingId: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(changeTrackingId(evt.target.value));
        },
        onChangeLeadEnrollmentNumber: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(changeLeadEnrollmentNumber(evt.target.value.trim()))
        },
        onSearch: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(search());
        },
        onClearLeadEnrollmentNumber: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(clearLeadEnrollmentNumber());
        }
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(MultiTenantCreateRequest);