import produce from "immer";
import {
  CHANGE_ENROLLMENT_NUMBER,
  CHANGE_SYSTEM_PO_NUMBER,
    SEARCH_MULTI_INVOICE,
    CLEAR_ENROLLMENT_NUMBER,
    CLEAR_SYSTEM_PO_NUMBER,
  SEARCH_MULTI_INVOICE_SUCCESS,
    SEARCH_MULTI_INVOICE_ERROR,
    CHANGE_COSMIC_CASENUMBER,
    CHANGE_BILLING_DOCUMENT_NUMBER
} from "./constants";

export const initialState = {};

const createMultiInvoiceRequestReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CHANGE_ENROLLMENT_NUMBER:
        draft.enrollmentNumber = action.enrollmentNumber;
        break;
      case CHANGE_SYSTEM_PO_NUMBER:
        draft.systemPONumber = action.systemPONumber;
        break;
      case CHANGE_COSMIC_CASENUMBER:
        draft.invoices.cosmicCaseNumber = action.value;
            break;
       case CHANGE_BILLING_DOCUMENT_NUMBER:
            draft.invoices.invoiceNumber = action.billingDocumentNbr;
            break;
      case SEARCH_MULTI_INVOICE:
        draft.invoices = {};
        draft.loading = true;
        draft.error = false;
        break;
      case CLEAR_ENROLLMENT_NUMBER:
        draft.enrollmentNumber = "";
        draft.loading = false;
        draft.error = false;
            break;
        case CLEAR_SYSTEM_PO_NUMBER:
            draft.systemPONumber = "";
            draft.loading = false;
            draft.error = false;
            break;
        case SEARCH_MULTI_INVOICE_SUCCESS:
        draft.enrollmentNumber = action.searchObject.enrollmentNumber;
        draft.systemPONumber = action.searchObject.systemPONumber;
        draft.invoices = action.invoices;
        draft.loading = false;
        draft.error = false;
        break;
        case SEARCH_MULTI_INVOICE_ERROR:
        draft.error = action.error;
        draft.loading = false;
        break;
    }
  });

export default createMultiInvoiceRequestReducer;
