import produce from 'immer';
import { 
  SUBMIT_MultiInvoiceApprovalRequest ,
    Reject_MultiInvoiceApprovalRequest, SUBMIT_MultiInvoiceApprovalRequest_SUCCESS,SUBMIT_MultiInvoiceApprovalRequest_FAIL,
  Procced_MultiInvoiceApprovalRequest,Close_Dialog} from './constants';

export const initialState = {workflow:"0"};

const approvalMultiInvoiceRequestReducer = (state = initialState, action) =>
  produce(state, ( draft ) => {
    switch (action.type) {
        case SUBMIT_MultiInvoiceApprovalRequest:
        draft.action = action.value;
        draft.isOverride= false;
        draft.loading = true;
        draft.error = false;
        draft.successResponse = false;
      break;
        case Procced_MultiInvoiceApprovalRequest:
        draft.isOverride = action.value;
        draft.loading = false;
        draft.error = false;
        draft.successResponse = false;
      break;
      case Close_Dialog:
        draft.isOverride = action.value;
        draft.loading = false;
        draft.error = false;
        draft.successResponse = false;
      break;
        case Reject_MultiInvoiceApprovalRequest:
          draft.action = action.value;
          draft.loading = true;
          draft.error = false;
          draft.successResponse = false;
          break;
        case SUBMIT_MultiInvoiceApprovalRequest_SUCCESS:
          draft.loading = false;
          draft.error = false;                
          draft.successResponse = action.response;
          break;
        case SUBMIT_MultiInvoiceApprovalRequest_FAIL:
          draft.error = action.error;
          draft.loading = false;
          draft.successResponse = false;
            break;
        default:
            break;
    }
  });

export default approvalMultiInvoiceRequestReducer;