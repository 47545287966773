import produce from 'immer';
import { SEARCH_PO } from '../../containers/multiTenantSearchAndAdd/constants';

export const initialState = { poList: [] };

const poSelectionTable = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SEARCH_PO:
                draft.poNumber = action.selectedPO.value;
                break;
        }
    });

export default poSelectionTable;