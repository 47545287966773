import { defineMessages } from 'react-intl';

export const scope = 'fbcapprovalInfo';

export default defineMessages({
    approvalInfoMessage: {
        id: `${scope}.approvalInfoMessage`,
        defaultMessage: 'Reason for Readonly Screen',
    }
});
