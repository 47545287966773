import { FBC_GET_PO_DETAILS } from "./constants";
import { select, takeLatest, fork } from "redux-saga/effects";
import { getPoSuccess, getPoError } from "./actions";
import { newReturnRequestCreate } from "../../../../common/internals/api/requests";
//import { makeSelectEnrollmentNumberCreate, makeSelectSystemPONumberCreate } from "./selectors";
import { transformReturnResponse } from "../../../../common/internals/api/transformReturnResponse";
import {
  makeSelectAccessToken,
  makeSelectAuthType
} from "../../../../common/app/selectors";
import { sagaFetchHandler } from "../../../../common/internals/api/sagaFetchHandler";


export default function* fetchCancelFutureBill() {
    const task1 = yield fork(multiInvoiceReturnRequestSaga);
    const task2 =  yield takeLatest(FBC_GET_PO_DETAILS, getPoDetails);
}

export function* multiInvoiceReturnRequestSaga() {}

export function* getPoDetails(action) {
    //const action = yield take(FBC_GET_PO_DETAILS);
    const poId = action.payload;
    console.log("get_by_po_id_payload:",poId);
    var newReturnRequest = newReturnRequestCreate();
    newReturnRequest.url = `api/purchaseorder/GetFullOrderByPoId/${poId}`;
    yield* setAuthTokens(newReturnRequest);
    yield* sagaFetchHandler(
     newReturnRequest,
     getPoSuccess,
     getPoError,
     transformReturnResponse,
        { "purchaseOrderId": poId }
   );
}

function* setAuthTokens(request) {
    const accessToken = yield select(makeSelectAccessToken());
    const authType = yield select(makeSelectAuthType());
    request.options.headers["Authorization"] = "Bearer " + accessToken;
    request.options.headers["x-ms-AuthType"] = authType;
  }
