import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { customReactSelectStyles } from '../../../../common/app/global-style';
import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import { useInjectSaga } from '../../../../common/internals/utils/injectSaga';
import { makeSelectTenantAgreements } from '../../../common/components/multiTenantCreateRequest/selectors';
import { makeSelectTenant } from '../../containers/multiTenantSearchAndAdd/selectors';
import { onAddFormField, onAddReallocate } from "./actions";
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import { makeMTModalDetailsRemainingQty, makeSelectMTModalTableDetails } from './selectors';
import   '../../../../common/app/accessibility-style.css';

function Header({ isReadOnly, isApprovalScreen }) {
    return (
        <thead><tr>
            <th style={{ fontWeight: 'bolder', color: 'black' }} class="col-lg-10 col-sm-13 col-xs-13">Enrollment Number & Tenant Name</th>
            <th style={{ fontWeight: 'bolder', color: 'black' }} class="col-lg-4">Reallocate Quantity</th>
            <th style={{ fontWeight: 'bolder', color: 'black' }} class="col-lg-6">PO Number(Optional)</th>
        </tr></thead>);
}


const animatedComponents = makeAnimated();

function Table({ handleChange, reallocationDetails, addFormFields, remainingQuantity, destinationTenantAgreement }) {

    let handleChangeValues = (i, e, name) => {
        let newFormValues = [...reallocationDetails];
        if (name === 'reallocateQty' && e.target.value.includes(".")) {
            return;
        } else if (name == 'reallocateQty' && parseInt(e.target.value) < 0) {
            newFormValues[i][name] = '';
        }
        else {
            newFormValues[i][name] = name == 'enrolmentNumAndTenantName' ? e : e.target.value;
        }
        handleChange([...reallocationDetails]);
    }


    let removeFormFields = (i, event) => {
        reallocationDetails.splice(i, 1);
        handleChange([...reallocationDetails]);
        event.preventDefault();

    }

    let handleSubmit = (event) => {
        event.preventDefault();
        alert(JSON.stringify(reallocationDetails));
    }

    let availableDestTenantAgreements = [];

    if (destinationTenantAgreement) {
        for (let i = 0; i < destinationTenantAgreement.length; i++) {

            let filteredValue = reallocationDetails
                .filter(detail => {
                    return detail.enrolmentNumAndTenantName ?
                        detail.enrolmentNumAndTenantName.value.agreementNumber === destinationTenantAgreement[i].value.agreementNumber
                        : false;
                });

            if (filteredValue.length > 0) {
                continue;
            }

            availableDestTenantAgreements.push(destinationTenantAgreement[i]);
        }
    }

    return (
        <div style={{ paddingLeft: "3%", paddingBottom: '15%' }} >
            <div className="col-lg-25">
                <div className="row" >
                    <div className="col-lg-25 table-mt">
                        <table className="table table-responsive" >
                            <Header />
                        </table><form className="form-mt" onSubmit={handleSubmit} style={{ paddingBottom: '15%' }}>
                            {reallocationDetails && reallocationDetails.map((element, index) => (
                                <div className="table-responsive" key={index} style={{ overflowX: 'visible' , overflowY: 'visible'}}>
                                    <table className="table table-responsive" >
                                        <tbody>
                                            <tr>

                                                <td style={{ width: '50%', padding: '10px' }} >
                                                    <Select options={availableDestTenantAgreements}
                                                            styles={customReactSelectStyles}
                                                            placeholder="Select Destination Enrollment Number"
                                                            class="form-select form-select-lg mb-3"
                                                            aria-label="Select Destination Enrollment Number"
                                                            style={{ width: '90%' }}
                                                            value={element.enrolmentNumAndTenantName}
                                                            classNamePrefix="react-select"
                                                            onChange={e => handleChangeValues(index, e, 'enrolmentNumAndTenantName')} />

                                                </td>
                                                <td className="td-2">
                                                    <input type="number"
                                                           name="reallocateQty"
                                                           value={element.reallocateQty}
                                                           placeholder="Qty to Reallocate"
                                                           style={{ width: '85%' }}
                                                           step={1}
                                                           min={1}
                                                           max={remainingQuantity}
                                                           onChange={e => handleChangeValues(index, e, 'reallocateQty')}/>
                                                </td>
                                                <td>
                                                    <input type="text" name="poNumber" value={element.poNumber} placeholder="Enter the PO Number" maxlength="30" onChange={e => handleChangeValues(index, e, 'poNumber')} />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;

                                                    <button aria-label="delete" style={{color: '#3191E7', width: '10 px', minWidth: '0px', border: 'none' }} onClick={e => removeFormFields(index, e)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                        </svg>
                                                    </button>

                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                            <div className="button-section" style={{
                                padding: '10px',
                            }}>
                                {remainingQuantity > 0 ?
                                    <button className="btn btn-primary" type="button" onClick={() => addFormFields()} style={{ width: 'auto', minWidth: '0px', border: 'none', color: 'white'}}
                                    >
                                        <FormattedMessage {...messages.addMoreBtn} /> 
                                    </button>
                                    : ""}
                                {remainingQuantity < 0 ?
                                    <span style={{ color: "#e50000", padding: '10px' }} ><p aria-live="assertive">Reallocation Qty is exceeding the actual qty.Please Adjust accordingly.</p></span>
                                    : ""}
                                {remainingQuantity > 0 && remainingQuantity != '' ? <span style={{ color: 'blue', padding: '10px' }}>Remaining quantity: {remainingQuantity}</span> : ""}
                            </div>
                        </form>
                    </div>
                </div></div>
        </div>
    )
}
export function MTModalReallocationDetailsTable({ handleChange, reallocationDetails, addFormFields, remainingQuantity, tenantAgreements, selectedSourceTenant }) {
    useInjectReducer({ key: 'MTModalReallocationDetailsTable', reducer });
    useInjectSaga({ key: 'MTModalReallocationDetailsTable', saga });

    let destinantionTenantAgreements = [];

    if (tenantAgreements) {
        destinantionTenantAgreements = tenantAgreements
            .filter(tenant => tenant.agreementNumber !== selectedSourceTenant.value.agreementNumber)
            .map(tenant => {
                return {
                    value: {agreementNumber: tenant.agreementNumber, businessName: tenant.businessName},
                    label: tenant.agreementNumber + '-' + tenant.businessName
                };
            });
    }

    return (
        <Table handleChange={handleChange}
            reallocationDetails={reallocationDetails}
            addFormFields={addFormFields}
            remainingQuantity={remainingQuantity}
            destinationTenantAgreement={destinantionTenantAgreements}
        />
    );

    return (
        <div className="form-group">
            <label className="form-label">
                <FormattedMessage {...messages.noRecordsFound} />
            </label>
        </div>)

    MTModalReallocationDetailsTable.propTypes = {
        MTModalTableDetails: PropTypes.object,
        doSomething: PropTypes.func,
        handleChange: PropTypes.func,
        addFormFields: PropTypes.func,
        reallocationDetails: PropTypes.array,
        remainingQuantity: PropTypes.object
    };
}

function mapDispatchToProps(dispatch) {

    return {

        handleChange: (value) => {
            if (value !== undefined) {
                dispatch(onAddReallocate(value));
            }
        }
        ,
        addFormFields: (value) => {
            dispatch(onAddFormField(value));
        }
    };
}

const mapStateToProps = createStructuredSelector({
    reallocationDetails: makeSelectMTModalTableDetails(),
    remainingQuantity: makeMTModalDetailsRemainingQty(),
    tenantAgreements: makeSelectTenantAgreements(),
    selectedSourceTenant: makeSelectTenant()
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);
export default compose(withConnect)(MTModalReallocationDetailsTable);

