//#region import section
import React, { useEffect, memo } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { createStructuredSelector } from "reselect";
import { Link } from "react-router-dom";
import uuidv4 from "uuid/v4";

import GridControl from "../../../common/components/grid/GridControl";
import ActionControl from "../../../common/components/ActionControl/ActionDropdown"
import ErrorMessage from "../../../common/components/ErrorMessage/ErrorMessage";
import LoadingIndicator from "../../../common/components/LoadingIndicator/index";
import { makeSelectDomainData } from "../../../common/app/selectors";
import * as Helpers from "../../../common/internals/utils/helpers";
import DateRangeFilter from "../../../common/components/controls/DateRangeFilter";
import errorMessages from "../../../common/internals/api/errorMessages";

// makes input boxes
import FilterCriteria from "./FilterCriteria";
import {
    FilterReallocationRequests,
    changeDateRange,
} from "./actions";
import {
    makeSelectLoading,
    makeSelectError,
    makeSelectReallocation,
    makeSelectDateRange,
} from "./selectors";

import exportToExcelColumns from "../utils/exportToExcelColumns";

//#endregion

var dateRangeElement = "";

//#region function component declaration
const Search = (props) => {
    const { formatMessage } = props.intl;

    window.vlcredits.FeatureName = "MT";

    const userRole = window && window.userRole;
    const enableMTExportToExcelVal = window.vlcredits.envData.Services.EnableMTExportToExcel;
    const enableMTExportToExcel = enableMTExportToExcelVal && enableMTExportToExcelVal.toLowerCase() === "true" ? true : false;

    const linkFormatter = (cell, row) => {
        if (row) {
            var url = `../multiTenant/create?leadEnrollmentNumber=${row.enrollment_number}&requestId=${row.requestId}&status=${row.request_status_code}`
            return <Link to={url}> {row.requestId}</Link>;
        }
    };

    function onDateRangeSelection(e, value) {
        dateRangeElement = PrepareFilterButtons(value);
    }


    function handleApplyEvent(value) {
        dateRangeElement = PrepareFilterButtons(value);
    }

    function PrepareFilterButtons(lifetimeDays) {
        props.onChangeDateRange(lifetimeDays);
        props.onReallocationRequestsSearch();
        return (
            <DateRangeFilter
                key="lifetimebuttons"
                lifetimeDays={lifetimeDays}
                handleApplyEvent={handleApplyEvent}
                onSearchChange={props.onSearchChange}
                onDateRangeSelection={onDateRangeSelection}
            />
        );
    }

    function getCosmicTickets(cosmicCaseList) {
        if (Helpers.isEmpty(cosmicCaseList)) {
            return null;
        }
        const _strCosmicCases =
            cosmicCaseList &&
            cosmicCaseList.reduce((_strCosmicCases, data) => {
                if (!Helpers.isEmpty(data) && !Helpers.isEmpty(data.ticketId)) {
                    _strCosmicCases.push(data.ticketId);
                }
                return _strCosmicCases;
            }, []);
        return _strCosmicCases.join(",\r\n");
    }

    

    useEffect(() => {
        document.title =
            formatMessage({ id: "lblApplicationName" }) +
            " - " +
            formatMessage({ id: "lblSeachPageName" });
        dateRangeElement = PrepareFilterButtons("1D");
    }, []);

    

    let columns = [
        {
            dataField: "requestId",
            text: formatMessage({ id: "lblRequestId" }),
            title: true,
            headerTitle: true,
            formatter: linkFormatter,
        },
        {
            dataField: "request_date",
            text: formatMessage({ id: "lblRequestDate" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        },
        {
            dataField: "request_status",
            text: formatMessage({ id: "lblRequestStatues" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        },
        {
            dataField: 'enrollment_name',
            text: formatMessage({ id: "lblLeadEnrollmentName" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        },
        {
            dataField: "enrollment_number",
            text: formatMessage({ id: "lblLeadEnrolmentNumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        },
        {
            dataField: "tenant_number",
            text: formatMessage({ id: "lblTenantNumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        }
    ];

    const additionalColumnsForROCUser = [

        {
            dataField: "cosmic_cases",
            text: formatMessage({ id: "lblCosmicNumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        }

    ];

    //Display cosmic case only for ROC users.
      columns = (userRole != 'VPU') ? columns.concat(additionalColumnsForROCUser) : columns;


    let statusMessage = "";
    if (props && props.error && "errorMessages" in props.error) {
        const titleMessage = {
            id: "titleErrorMessage",
            defaultMessage: "Search Error",
        };
        statusMessage = (
            <ErrorMessage
                key="reallocationSearchErrorMessages"
                messageBody={props.error.errorMessages}
                messageTitle={titleMessage}
                defaultMessage={errorMessages.tokenExpiredMsg}
            />
        );
    }

    var reallocationRequestList = [];
    let reallocationData = [];

    if (props.response && props.response.length > 0) {
        reallocationRequestList = [];

        props.response.map((data) => {
            reallocationRequestList.push({
                uniqueId: uuidv4(),
                requestId: data.returnsIdentifier,
                request_date: Helpers.DateFormatForDisplay(data.requestSubmitDate),
                request_status: userRole === 'VPU'
                    ? Helpers.GetMTPartnerRequestStatusByCode(data.requestStatusCode)
                    : Helpers.GetMTOpsRequestStatusByCode(data.requestStatusCode),
                request_status_code: data.requestStatusCode,
                enrollment_name: data.agreementName,
                enrollment_number: data.agreementNumber,
                cosmic_cases: getCosmicTickets(data.cosmicCases),
                tenant_number: data.adjustedPoStatus[0].sourceTenant,
                submitter_email: data.submitUserEmail
            });

            data.adjustedPoStatus.map((transaction) => {
                let invoiceStatusTimestamp = '';
                let paidInvoices = '';
                let unpaidInvoices = '';
                let invalidInvoices = '';
                if (transaction.invoiceDetails != null) {
                    transaction.invoiceDetails.map((invoice) => {
                        invoiceStatusTimestamp = invoice.invoiceStatusTimestamp;
                        if (invoice.invoiceStatus === "1") {
                            paidInvoices += invoice.invoiceNumber + ' ,';
                        }
                        else if (invoice.invoiceStatus === "0") {
                            unpaidInvoices += invoice.invoiceNumber + ' ,';
                        }
                        else if (invoice.invoiceStatus === "2") {
                            invalidInvoices += invoice.invoiceNumber + ' ,';
                        }
                    })

                    if (paidInvoices.charAt(paidInvoices.length - 1) === ',') {
                       paidInvoices =  paidInvoices.slice(0, paidInvoices.length - 1);
                    }
                    if (unpaidInvoices != '' && unpaidInvoices.charAt(unpaidInvoices.length - 1) === ',') {
                        unpaidInvoices = unpaidInvoices.slice(0, unpaidInvoices.length - 1);
                    }
                    if (invalidInvoices!= '' && invalidInvoices.charAt(invalidInvoices.length - 1) === ',') {
                        invalidInvoices = invalidInvoices.slice(0, invalidInvoices.length - 1);
                    }

                }
                reallocationData.push({
                    uniqueId: data.returnsIdentifier || "",
                    submitUserEmail: data.submitUserEmail || "",
                    requestSubmitDate: Helpers.DateFormatForDisplay(data.requestSubmitDate) || "",
                    agreementNumber: data.agreementNumber || "",
                    agreementName: data.agreementName || "",
                    sourceTenantAgreementNumber: data.sourceTenantAgreementNumber || "",
                    programCode: data.programCode || "",
                    salesLocationCode: data.salesLocationCode || "",
                    organizationPCNs: data.organizationPCNs || "",
                    requestStatusCode: userRole === 'VPU'
                        ? Helpers.GetMTPartnerRequestStatusByCode(data.requestStatusCode)
                        : Helpers.GetMTOpsRequestStatusByCode(data.requestStatusCode),
                    externalIdentifier: data.externalIdentifier || "",
                    userRole: data.userRole || "",
                    rejectionReason: data.rejectionReason || "",
                    billingDocumentNbr: data.billingDocumentNbr || "",
                    cosmicCases: getCosmicTickets(data.cosmicCases) || "",
                    invoiceStatusTimestamp: invoiceStatusTimestamp,
                    paidInvoices: paidInvoices,
                    unpaidInvoices: unpaidInvoices,
                    invalidInvoices : invalidInvoices,
                    adjustedPoStatus: data.adjustedPoStatus,
                    transactionId: transaction.TransactionID,
                    purchaseOrderNumber: transaction.purchaseOrderNumber,
                    agreementNumber: transaction.agreementNumber,
                    externalIdentifier: transaction.externalIdentifier,
                    transactionType: transaction.TransactionType,
                    transactionStatus: transaction.TransactionStatus,
                    sourceTenant: transaction.sourceTenant,
                });
            })

           
        });
    }
    return (
        <div>
            <div>
                <div className="form-group">
                    <div className="col-lg-24">
                        <div className="row spacer-xs-bottom">
                            <div className="col-sm-24 col-md-24 col-lg-24">
                                {statusMessage}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h1 className="section-title">
                                    {formatMessage({ id: "lblMultiTenantSearchText" })}
                                </h1>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                {dateRangeElement}
                            </div>
                        </div>

                        <FilterCriteria
                            key="fbcFilterCriteria"
                            intl={props.intl}
                            domainData={props.domainData}
                            location={props && props.location}
                        />
                        <div className="row">
                            <div className="col-sm-10 col-md-10 col-lg-10">
                                <div className={!props.loading ? "hidden" : ""}>
                                    <LoadingIndicator />
                                </div>
                            </div>
                        </div>
                        <div className="row spacer-xs-bottom">
                            <GridControl
                                key="gridcontrolfordatadisplay"
                                columns={columns}
                                data={reallocationRequestList}
                                intl={props.intl}
                            />
                        </div>
                        {
                            enableMTExportToExcel && userRole === 'VCS' &&
                            <div className="row spacer-xs-bottom pull-right" >
                                <div className="col-sm-24 col-md-24 col-lg-24">
                                    <ActionControl id="multiTenantListActions" fileName="ReallocationList.csv" List={reallocationData} columns={exportToExcelColumns} intl={props.intl} />
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </div>
        </div>
    );
};

//#endregion

//#region Validating properties coming inside this function component through props param
Search.propTypes = {
    dateRange: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    loading: PropTypes.bool,
    onReallocationRequestsSearch: PropTypes.func,
    onChangeDateRange: PropTypes.func,
};

//#endregion

const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    error: makeSelectError(),
    response: makeSelectReallocation(),
    domainData: makeSelectDomainData(),
    dateRange: makeSelectDateRange(),
});

const mapDispatchToProps = (dispatch) => {
    return {
        onReallocationRequestsSearch: () => dispatch(FilterReallocationRequests()),
        onChangeDateRange: (data) => dispatch(changeDateRange(data)),
    };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(injectIntl(Search));
