const rules = {
    //Credit Submitter
    VCS: {
        static: [
            "SubmitterCreditSubmit:RW",
            "SubmitterCreditSearch:RW",
            "SubmitterApprovalSearch:R"
        ]
    },
    //CreditApprover
    VCA: {
        static: [
            "ApprovarApprovalPage:RW"
        ]
    },
    //SubsidiaryCFO
    VSC: {
        static: [
            "CFOApprovalSearch:RW",
            "CFOApprovalPage:RW"
        ]
    },
    //OpsUser
    VOU: {
        static: [
            "OpsCreditSearch:RW",
            "OpsCreditApprovalsSearch:RW"
        ]
    },
    //PartnerUser
    VPU: {
        static: [
            "PartnerCreditSubmit:RW",
            "PartnerCreditSearch:RW"
        ]
    },
    //FieldUser
    VFU: {
        static: [
            "FiledCreditCreate:RW",
            "FieldCreditSearch:RW"           
        ]
    },
    //Future billing cancellation
    //New role being introduced to handle FBC feature.
    //This role gets assigned by user profile service if 
    //the login user is a active internal user 
    //who doesn't have any VL credits roles assigned.
    VAU: {
        static: [
            "VLActiveUserCreate:RW",
            "VLActiveUserSearch:RW"
        ]
    },
    "NA": {
        static: [
            "UnAuthorised:RW",            
        ]
    }
};

export default rules;