import PropTypes from "prop-types";
import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import Input from '../../../../common/components/Input';
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import { useInjectReducer } from "../../../../common/internals/utils/injectReducer";
import { useInjectSaga } from "../../../../common/internals/utils/injectSaga";
import { getMTRequestData } from './actions';
import messages from "./messages";
import reducer from "./reducer";
import saga from "./saga";
import { makeLoading, makeSelectRequestData, makeSelectSubmitterEmail } from "./selectors";

function MultiTenantFetchRequest({
    getReturnOnLoad,
    cosmicCaseNumber,
    cosmicCaseNumberEnabled,
    submitterEmail,
    submitterEmailEnabled,
    requestData,
    loading
}) {
    useInjectReducer({ key: "multiTenantFetchRequest", reducer });
    useInjectSaga({ key: "multiTenantFetchRequest", saga });

    var searchParams = new URLSearchParams(window.location.search);
    var leadEnrollmentNumber = searchParams.get("leadEnrollmentNumber");
    var ldEnrollmentNbr = requestData && requestData.leadEnrollmentNumber;

    useEffect(() => {
        if (
            (leadEnrollmentNumber) &&
            (leadEnrollmentNumber.trim().length > 0)
        ) {
            var payload = {
                leadEnrollmentNumber,
                requestId: searchParams.get("requestId"),
                status: searchParams.get("status")
            };
            getReturnOnLoad(payload);
        }
    }, []);

    return (
        <div>
            <div className="row spacer-32-top">
                <div className="col-md-24 form-group">
                    <div className="row">
                        <div className="col-sm-8 col-md-8 col-lg-6 col-xl-4">
                            <Input
                                value={ldEnrollmentNbr || ""}
                                label={messages.leadEnrollmentNumber}
                                id="leadEnrollmentNumber"
                                editable={false}
                            />
                        </div>
                        {cosmicCaseNumberEnabled ?
                            <div className="col-sm-8 col-md-8 col-lg-6 col-xl-4">
                                <Input id="readonlycosmicCaseNumber" value={cosmicCaseNumber || ''}
                                    label={messages.cosmicCaseNumber} editable={false} />
                            </div> : ""
                        }

                        {submitterEmailEnabled ?
                            <div className="col-sm-8 col-md-8 col-lg-6 col-xl-4">
                                <Input id="readonlysubmitterEmail" value={submitterEmail || ''}
                                    label={messages.submitterEmail} editable={false} />
                            </div> : ""
                        }

                    </div>
                    <div className={(loading) ? "" : "hidden"}>
                        <div className="col-md-8  remove-all-padding">
                            <LoadingIndicator />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

MultiTenantFetchRequest.propTypes = {
    getReturnOnLoad: PropTypes.func,
    loading: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
    requestData: makeSelectRequestData(),
    submitterEmail: makeSelectSubmitterEmail(),
    loading: makeLoading()

});

function mapDispatchToProps(dispatch) {
    return {
        getReturnOnLoad: payload => dispatch(getMTRequestData(payload))
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(MultiTenantFetchRequest);
