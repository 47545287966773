import produce from 'immer';
import { SEARCH_INVOICE_SUCCESS } from '../../../common/components/createRequest/constants'
import { GET_RETURN_SUCCESS } from '../../../common/components/fetchReturnRequest/constants';
import { GET_MULTI_INVOICE_RETURN_SUCCESS } from '../../../common/components/fetchMultiInvoiceReturnRequest/constants';
import { SEARCH_MULTI_INVOICE_SUCCESS } from "../../../common/components/createMultiInvoiceRequest/constants";
import {
    CHANGE_ISBDAPPROVED,
    CHANGE_HANDLING_FEE,
    CHANGE_FULL_CREDIT,
    CHANGE_RETURN_REASON,
    UPDATE_NET_CREDIT,
    UPDATE_NET_CREDIT_LOCAL,
    UPDATE_GROSS_CREDIT,
    UPDATE_GROSS_CREDIT_LOCAL,
    CHANGE_ROC_VALUE,
    ROCVALUECHANGE_CONFIRMATION,
    CLOSE_ROCVALUECHANGE_CONFIRMATION,
    UPDATE_CURRENCY_DIFF
} from './constants'
import { getHandlingFeeFromRules, isLRDSFullCreditCountryCodeAndSubCheck, updateMultiInvoiceHandlingFees} from '../../../../common/internals/utils/HandlingFeeHelper';
//import getNetCreditAmount from '../../../../common/internals/utils/CreditAmountCalculations';


export const initialState = {};

const returnDetailsReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SEARCH_MULTI_INVOICE_SUCCESS:
                draft.isDirectAgreement = action.invoices.isDirectAgreement;
                draft.proposalId = action.invoices.proposalId;
                draft.isProposalId = action.invoices.isProposalId;
                draft.isBdApproved = action.invoices.isBdApproved;
                draft.programCode = action.invoices.programCode;
                draft.billToCountryCode = action.invoices.billToCountryCode;
                draft.isFullCredit = true;
                draft.netCreditAmount = action.invoices.netCreditUSD;
                draft.netCreditAmountLocal = action.invoices.netAmount;
                draft.returnReason = action.invoices.returnReason;
                draft.grossCredit = action.invoices.invoiceAmountUSD;
                draft.grossCreditLocal = action.invoices.invoiceAmount;
                // ToDo : Change Handling Fees to a Flag ( 5 is considered as False here)
                draft.handlingFeePercentage = 5;
                draft.operationsCenterCode = action.invoices.operationsCenterCode;
                draft.alternateROC = (action.invoices.alternateROC) ? action.invoices.alternateROC : action.invoices.operationsCenterCode;
                draft.programCode = action.invoices.programCode;
                draft.hasMultipleRequestCurrency = action.invoices.hasMultipleRequestCurrency;
                draft.hasMultipleRebillCurrency = action.invoices.hasMultipleRebillCurrency;
                draft.isCurrencyDiff = (draft.hasMultipleRequestCurrency || draft.hasMultipleRebillCurrency ) ? true : false;
                break;
            case GET_MULTI_INVOICE_RETURN_SUCCESS:
                draft.proposalId = action.invoices.proposalId;
                draft.programCode = action.invoices.programCode;
                draft.billToCountryCode = action.invoices.billToCountryCode;
                draft.handlingFeePercentage = 5;
                draft.isBdApproved = action.invoices.isBdApproved;
                draft.returnReason = action.invoices.returnReason;
                draft.netCreditAmount = action.invoices.netCreditUSD;
                draft.netCreditAmountLocal = action.invoices.netAmount;
                draft.grossCredit = action.invoices.invoiceAmountUSD;
                draft.grossCreditLocal = action.invoices.invoiceAmount;
                //draft.grossCredit = action.inv
                draft.isDirectAgreement = action.invoices.isDirectAgreement;
                draft.isFullCredit
                    = isLRDSFullCreditCountryCodeAndSubCheck(action.invoices.billToCountryCode, action.invoices.isSUB) ?
                        true : action.invoices.isFullCredit;
                draft.operationsCenterCode = action.invoices.operationsCenterCode;
                draft.alternateROC = (action.invoices.alternateROC) ? action.invoices.alternateROC : action.invoices.operationsCenterCode;
                draft.programCode = action.invoices.programCode;
                draft.hasMultipleRequestCurrency = action.invoices.hasMultipleRequestCurrency;
                draft.hasMultipleRebillCurrency = action.invoices.hasMultipleRebillCurrency;
                draft.isCurrencyDiff = (draft.hasMultipleRequestCurrency || draft.hasMultipleRebillCurrency ) ? true : false;
                break;
            case GET_RETURN_SUCCESS:
                draft.billToCountryCode = action.invoice.billToCountryCode;
                draft.licenseAge = action.invoice.licenseAge;
                draft.isBdApproved = action.invoice.isBdApproved;
                draft.handlingFeePercentage = action.invoice.handlingFeePercentage;
                draft.returnReason = action.invoice.returnReason;
                draft.netCreditAmount = action.invoice.netCreditUSD;
                draft.netCreditAmountLocal = action.invoice.netAmount;
                draft.proposalId = action.invoice.proposalId;
                draft.isProposalId = action.invoice.isProposalId;
                draft.programCode = action.invoice.programCode;
                draft.isDirectAgreement = action.invoice.isDirectAgreement;
                draft.isFullCredit
                    = isLRDSFullCreditCountryCodeAndSubCheck(action.invoice.billToCountryCode, action.invoice.isSUB) ?
                        true : action.invoice.isFullCredit;
                draft.operationsCenterCode = action.invoice.operationsCenterCode;
                draft.alternateROC = (action.invoice.alternateROC) ? action.invoice.alternateROC : action.invoice.operationsCenterCode;
                draft.programCode = action.invoice.programCode;
                draft.hasMultipleRequestCurrency = action.invoice.hasMultipleRequestCurrency;
                draft.hasMultipleRebillCurrency = action.invoice.hasMultipleRebillCurrency;
                draft.isCurrencyDiff = (draft.hasMultipleRequestCurrency || draft.hasMultipleRebillCurrency) ? true : false;

                break;
            case SEARCH_INVOICE_SUCCESS:
                draft.billToCountryCode = action.invoice.billToCountryCode;
                draft.licenseAge = action.invoice.licenseAge;
                draft.proposalId = action.invoice.proposalId;
                draft.isProposalId = action.invoice.isProposalId;
                draft.programCode = action.invoice.programCode;
                draft.isBdApproved = action.invoice.isBdApproved;
                draft.handlingFeePercentage = action.invoice.handlingFeePercentage;
                draft.returnReason = action.invoice.returnReason;
                draft.isDirectAgreement = action.invoice.isDirectAgreement;
                draft.netCreditAmount = isLRDSFullCreditCountryCodeAndSubCheck(action.invoice.billToCountryCode, action.invoice.isSUB) ?
                    action.invoice.invoiceAmountUSD : action.invoice.netCreditUSD;
                draft.netCreditAmountLocal = isLRDSFullCreditCountryCodeAndSubCheck(action.invoice.billToCountryCode, action.invoice.isSUB) ?
                    action.invoice.invoiceAmount : action.invoice.netAmount;
                draft.isFullCredit
                    = isLRDSFullCreditCountryCodeAndSubCheck(action.invoice.billToCountryCode,action.invoice.isSUB) ?
                        true : action.invoice.isFullCredit;
                draft.operationsCenterCode = action.invoice.operationsCenterCode;
                draft.alternateROC = (action.invoice.alternateROC) ? action.invoice.alternateROC : action.invoice.operationsCenterCode;
                draft.programCode = action.invoice.programCode;
                draft.hasMultipleRequestCurrency = action.invoice.hasMultipleRequestCurrency;
                draft.hasMultipleRebillCurrency = action.invoice.hasMultipleRebillCurrency;
                draft.isCurrencyDiff = (draft.hasMultipleRequestCurrency || draft.hasMultipleRebillCurrency) ? true : false;
                draft.isOptOut = action.invoice.isOptOut ? action.invoice.isOptOut : false;
                break;
            case CHANGE_ISBDAPPROVED:
                draft.isBdApproved = action.value;
                break;
            case CHANGE_HANDLING_FEE:
                draft.handlingFeePercentage = action.value;
                break;
            case CHANGE_FULL_CREDIT:
                draft.isFullCredit = action.value;
                break;
            case CHANGE_RETURN_REASON:
                draft.returnReason = action.value;
                draft.handlingFeePercentage
                    = getHandlingFeeFromRules(
                        draft.billToCountryCode, draft.licenseAge, action.value, draft.isDirectAgreement, draft.proposalId, draft.programCode);
                break;
            case UPDATE_NET_CREDIT:
                draft.netCreditAmount = action.value;
                break;
            case UPDATE_NET_CREDIT_LOCAL:
                draft.netCreditAmountLocal = action.value;
                break;
            case UPDATE_GROSS_CREDIT:
                draft.grossCredit = action.value;
                break;
            case UPDATE_GROSS_CREDIT_LOCAL:
                draft.grossCreditLocal = action.value;
                break;
            case UPDATE_CURRENCY_DIFF:
                draft.isCurrencyDiff = action.value;
                break;
            case CHANGE_ROC_VALUE:
                if (draft.alternateROC != action.value) {
                    draft.intermediatevalue = action.value;
                    draft.rocValueChanged = true;
                }
                else {
                    draft.operationsCenterCode = action.value;
                }
                break;
            case ROCVALUECHANGE_CONFIRMATION:
                draft.operationsCenterCode = draft.intermediatevalue;
                draft.rocValueChanged = false;
                break;
            case CLOSE_ROCVALUECHANGE_CONFIRMATION:
                draft.rocValueChanged = false;
                break;
        }
    });

export default returnDetailsReducer;
