import { defineMessages } from "react-intl";

export const scope = "multiInvoiceReturnForm";

export default defineMessages({
    submit: {
        id: `${scope}.submit`,
        defaultMessage: "Submit"
    },
    saveAsDraft: {
        id: `${scope}.saveAsDraft`,
        defaultMessage: "Save as Draft"
    },
    saveAsPDF: {
        id: `${scope}.saveAsPDF`,
        defaultMessage: "Save as PDF"
    },
    deleteDraft: {
        id: `${scope}.deleteDraft`,
        defaultMessage: "Delete Draft"
    },
    reset: {
        id: `${scope}.reset`,
        defaultMessage: "Reset"
    },
    actions: {
        id: `${scope}.actions`,
        defaultMessage: "Actions"
    },
    cosmicCaseErrorMessage: {
        id: `${scope}.cosmicCaseErrorMessage`,
        defaultMessage: "Please enter cosmic case number"
    },
    returnReasonErrorMessage: {
        id: `${scope}.returnReasonErrorMessage`,
        defaultMessage: "Please choose return reason"
    },
    rebillPoMandatoryErrorMessage: {
        id: `${scope}.rebillPoMandatoryErrorMessage`,
        defaultMessage: "Please enter rebill po number"
    },
    coveragePeriodErrorMessage: {
        id: `${scope}.coveragePeriodErrorMessage`,
        defaultMessage: "Please choose adjustment period"
    }
});
