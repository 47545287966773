import { Component } from 'react';
import { injectIntl } from 'react-intl';

export class LocalisedText extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        const { formatMessage } = this.props.intl;
        return (
            formatMessage({ id: this.props.id })
        );
    }
}
export default injectIntl(LocalisedText);
