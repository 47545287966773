import produce from 'immer';
import { RESET_TO_INITIAL_STATE } from '../multiTenantSearchAndAdd/constants';
import { ENABLE_ADD_MORE, PART_NUMBER_DELETED, PART_NUMBER_FOCUSED, SAVE_MODAL_DETAILS_WITH_EXTID } from '../partNumberMenu/constants';
import { CURRENT_CP_REALLOCATION_MODAL_DETAILS_CHANGED, CURRENT_REALLOCATION_MODAL_DETAILS_CHANGED, REALLOCATION_MODAL_DETAILS_CHANGED, UPDATE_REALLOCATION_BODY } from './constants';


export const initialState = {
    currentFormSelectedValue: {
        selectedPartNumber: null,
        coveragePeriod: null,
        productDescription: null,
        originalQuantity: null,
        reallocationdetails: []
    },
    reallocationBody: {},
    finalReallocationDetails: {},
    announcerText: ""
};

const reallocationBodyReducer = (state = initialState, action, dispatch) =>
    produce(state, (draft) => {
        switch (action.type) {
            case CURRENT_REALLOCATION_MODAL_DETAILS_CHANGED:
                delete draft.reallocationBody[draft.currentFormSelectedValue.selectedPartNumber];
                draft.currentFormSelectedValue.selectedPartNumber = action.value;
                draft.currentFormSelectedValue.productDescription = action.value.productDescription;
                draft.currentFormSelectedValue.originalQuantity = action.value.originalQuantity;
                draft.reallocationBody[action.value.value] = draft.currentFormSelectedValue;
                break;
            case CURRENT_CP_REALLOCATION_MODAL_DETAILS_CHANGED:
                draft.currentFormSelectedValue.coveragePeriod = action.value;
                draft.reallocationBody[draft.currentFormSelectedValue.selectedPartNumber.value] = draft.currentFormSelectedValue;
                break;

            case REALLOCATION_MODAL_DETAILS_CHANGED:
                draft.currentFormSelectedValue.reallocationdetails = [...action.data];
                draft.reallocationBody[draft.currentFormSelectedValue.selectedPartNumber.value] = draft.currentFormSelectedValue;
                break;
            case ENABLE_ADD_MORE:
                draft.currentFormSelectedValue = {
                    selectedPartNumber: null,
                    coveragePeriod: null,
                    productDescription: null,
                    originalQuantity: null,
                    reallocationdetails: []
                };
                draft.partNumberFocused = null;
                break;
            case PART_NUMBER_DELETED:
                draft.currentFormSelectedValue = {
                    selectedPartNumber: null,
                    coveragePeriod: null,
                    productDescription: null,
                    originalQuantity: null,
                    reallocationdetails: []
                };

                delete draft.reallocationBody[action.value.value];
                draft.partNumberFocused = null;
                break;
            case PART_NUMBER_FOCUSED:
                let temp = draft.reallocationBody[action.value.value];
                draft.currentFormSelectedValue = temp;
                draft.partNumberFocused = null;
                break;
            case SAVE_MODAL_DETAILS_WITH_EXTID:
                delete draft.reallocationBody[action.value];
                let reallocationBodyTemp = draft.reallocationBody;
                draft.finalReallocationDetails[action.value] = reallocationBodyTemp;
                draft.announcerText = "Reallocation Details saved";
                break;
            case RESET_TO_INITIAL_STATE:
                draft.currentFormSelectedValue = {
                    selectedPartNumber: null,
                    coveragePeriod: null,
                    productDescription: null,
                    originalQuantity: null,
                    reallocationdetails: []
                };
                draft.reallocationBody = {};
                draft.finalReallocationDetails = {};
                draft.announcerText = "";
                break;
            case UPDATE_REALLOCATION_BODY:
                draft.reallocationBody = action.value;
                draft.currentFormSelectedValue = Object.values(action.value)[0];
                break;
        }
    });

export default reallocationBodyReducer;

