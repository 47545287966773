import { defineMessages } from 'react-intl';

export const scope = 'poSelectionTable';

export default defineMessages({
    purchaseOrderNumber: {
        id: `${scope}.purchaseOrderNumber`,
        defaultMessage: 'Purchase Order Number',
    },
    noRecordsFound: {
        id: `${scope}.noRecordsFound`,
        defaultMessage: 'No records found.',
    },
    systemPONumber: {
        id: `${scope}.systemPONumber`,
        defaultMessage: "System PO Number",
    },
    createdDate: {
        id: `${scope}.createdDate`,
        defaultMessage: "Created Date",
    },
    orderType: {
        id: `${scope}.orderType`,
        defaultMessage: "Order Type",
    },
});
