import { makeSelectGlobalState, makeSelectisValidRequestToSubmit } from './selectors';
import { makeSelectMultiTenantFormData } from "../multiTenantRequest/selectors";
import { validReqToSubmit } from "./actions";
import { SUBMIT_MULTI_TENANT_FORM } from "../multiTenantRequest/constants"
import { put, select, takeLatest } from 'redux-saga/effects';
import { isEmptyOrSpaces } from '../../../../common/internals/utils/helpers';

export function* checkValidSubmit() {
    const globalState = yield select(makeSelectGlobalState());
    let isValidRequestToSubmit = true;

    const leadAgreementNumber = globalState.multiTenantCreateRequest.leadEnrollmentNumber;
    const sourceTenant = globalState.multiTenantSearchAndAdd.selectedTenant;

    if (leadAgreementNumber === null || leadAgreementNumber == '' || sourceTenant === null || sourceTenant.value == null || sourceTenant === '' || globalState.multiTenantSearchAndAdd.selectedPurchaseOrdersData == null || globalState.multiTenantSearchAndAdd.selectedPurchaseOrdersData.length == 0) {
        isValidRequestToSubmit = false
    }

    globalState.multiTenantSearchAndAdd.selectedPurchaseOrdersData.map((purchaseOrder) => {
        if (Object.keys(purchaseOrder.selectedTenants).length == 0) {
            isValidRequestToSubmit = false;
        }
    });

    console.log(isValidRequestToSubmit);
    yield put(validReqToSubmit(isValidRequestToSubmit));
}


export default function* multiTenantHomeSaga() {
    yield takeLatest(SUBMIT_MULTI_TENANT_FORM, checkValidSubmit);
}
