import { defineMessages } from 'react-intl';

export const scope = 'reallocationDetails';

export default defineMessages({
    reallocationDetailsSectionHeader: {
        id: `${scope}.reallocationDetailsSectionHeader`,
        defaultMessage: 'Reallocation Details'
    }
   
});
