import { put, select, takeLatest } from 'redux-saga/effects';
import * as Helpers from '../../../../common/internals/utils/helpers';
import { onModalDetailsChangeOnPartNumberFocus, OnReallocationQtyChanged } from '../../components/mtModalReallocationTable/actions';
import { makeSelectOrderdetails } from '../../components/reallocationDetailsTable/selectors';
import { updateAddMore } from '../../containers/partNumberMenu/actions';
import { PART_NUMBER_FOCUSED } from '../../containers/partNumberMenu/constants';
import { makeSelectPartNumberMenu } from '../../containers/partNumberMenu/selectors';
import { makeSelectExternalIdentifier } from '../../containers/reallocateModal/selectors';
import { CURRENT_CP_REALLOCATION_MODAL_DETAILS_CHANGED, CURRENT_REALLOCATION_MODAL_DETAILS_CHANGED } from '../../containers/reallocationBody/constants';
import { makeSelectReallocationBodyModal } from '../../containers/reallocationBody/selectors';
import { REALLOCATION_MODAL_DETAILS_CHANGED } from './constants';


export function* changeModalTableDetails() {
    const data = yield select(makeSelectReallocationBodyModal());
    const partNumberData = yield select(makeSelectPartNumberMenu());
    yield put(onModalDetailsChangeOnPartNumberFocus([...data.reallocationBody[partNumberData.partNumberFocused.value].reallocationdetails]));
}

export function* changeRemainingQuantity() {
    const data = yield select(makeSelectReallocationBodyModal());
    const orderDetails = yield select(makeSelectOrderdetails());
    const externalId = yield select(makeSelectExternalIdentifier());

    let totalSum = 0;
    for (let reallocationDetail in data.currentFormSelectedValue.reallocationdetails) {
        totalSum += data.currentFormSelectedValue.reallocationdetails[reallocationDetail].reallocateQty && data.currentFormSelectedValue.reallocationdetails[reallocationDetail].reallocateQty != "" ? parseInt(data.currentFormSelectedValue.reallocationdetails[reallocationDetail].reallocateQty) : 0;
    }
    var groupedColumns = Helpers.GroupBy(["poLineItemId", "quantity", "partNumber"], orderDetails[externalId].lineItemDetails);
    var partNumberDetailsData = Helpers.CalculateCoveragePeriod(groupedColumns);
    let partNumberDetails = partNumberDetailsData.filter((x) => x.externalIdentifier === data.currentFormSelectedValue.selectedPartNumber.value);
    yield put(OnReallocationQtyChanged(partNumberDetails[0].adjustedQuantity - totalSum));
    if (partNumberDetails[0].adjustedQuantity - totalSum < 0) {
        yield put(updateAddMore({
            disableAddMore: true,
            invalidPO: data.currentFormSelectedValue.selectedPartNumber.label,
            fromDetails: true
        }
        ));
    }
}

export default function* createRequestSaga() {
    yield takeLatest(PART_NUMBER_FOCUSED, changeModalTableDetails);
    yield takeLatest(PART_NUMBER_FOCUSED, changeRemainingQuantity);
    yield takeLatest(REALLOCATION_MODAL_DETAILS_CHANGED, changeRemainingQuantity);
    yield takeLatest(CURRENT_REALLOCATION_MODAL_DETAILS_CHANGED, changeRemainingQuantity);
    yield takeLatest(CURRENT_CP_REALLOCATION_MODAL_DETAILS_CHANGED, changeRemainingQuantity);
}
