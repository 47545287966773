import produce from 'immer';
import {
    CHANGE_REBILLPO_NUMBER,
    SEARCH_REBILLPO,
    CLEAR_REBILLPO,
    SEARCH_REBILLPO_ERROR,
    SEARCH_REBILLPO_SUCCESS
} from './constants';
import { SUBMIT_RETURN as SUBMIT_MULTI_RETURN } from '../multiInvoiceReturnForm/constants';
import { SUBMIT_RETURN } from '../returnForm/constants';


export const initialState = {};

const rebillSearchReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case CHANGE_REBILLPO_NUMBER:
                draft.rebillpoNumber = action.rebillpoNumber;
                break;
            case CLEAR_REBILLPO:
                draft.rebillpoNumber = '';
                break;
            case SEARCH_REBILLPO:
                draft.loading = true;
                draft.error = false;
                break;
            case SUBMIT_RETURN:
            case SUBMIT_MULTI_RETURN:
                draft.error = false;
                break;
            case SEARCH_REBILLPO_SUCCESS:
                draft.rebillpo = action.rebillpo;
                draft.rebillpoNumber = action.rebillpoNumber;
                draft.loading = false;
                break;
            case SEARCH_REBILLPO_ERROR:
                draft.error = action.error;
                draft.loading = false;
                break;
        }
    });

export default rebillSearchReducer;
