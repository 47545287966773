import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import reducer from './reducer';
import messages from './messages';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import FBCSubmitInfo from '../../components/fbcSubmitInfo';
import SubmitErrors from '../../components/SubmitErrors';

import Modal from '../../../../common/components/modal/modal';
import LoadingIndicator from '../../../../common/components/LoadingIndicator';

import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import { makeSelectInvoiceNumberCreate } from '../../../common/components/createRequest/selectors';

import { makeSelectInvoiceNumberCreate as makeSelectMultiInvoiceNumberCreate } from '../../../common/components/createMultiInvoiceRequest/selectors';
import { submitFBCReturnForm, closeRebillConfirmation, closeLineItemConfirmation } from '../cancelFutureBillingReturnForm/actions';

import {
    makeCancelFutureBillingReturnFormSuccess,
    makeCancelFutureBillingReturnFormLoading,
    makeListItemShowStatus
} from '../cancelFutureBillingReturnForm/selectors';
import {
    makeListItemsDifference
} from '../../../common/components/cfblineItemDetails/selectors';

import CancelFutureBillingRequest from '../cancelFutureBillingRequest';
import { isEmpty } from 'lodash';

const navigateAfterSubmit = () => {
    var uri = window.location.href.toString();
    const baseUri = uri.indexOf("?") > 0 ?
        uri.substring(0, uri.indexOf("?")) : uri;
    window.location.href = baseUri + '?submitSuccess=true';
};

function CancelFutureBillingHome({
    submitSuccess,
    loading,
    submit,
    closeLineItems,
    changeListItems,
    lineItemShowStatus }) {

    const submitSuccessMsg = messages.submitSuccess;
    window.vlcredits.FeatureName = "FBC";
    return (
        <div id="CancelFutureBillingRequestHome" className="spacer-18-top">
            <div className="row">
                <div className="col-md-24 spacer-20-top" >
                    <SubmitErrors />
                    <FBCSubmitInfo />
                    <Modal id="submitConfirmationModal"
                        isOpen={(submitSuccess && submitSuccess.success)}
                        headerMessage={submitSuccessMsg}
                        closeMessage={messages.closeAction}
                        close={navigateAfterSubmit}
                    />
                    <Modal id="listItemsStatusConfirmationModal"
                        isOpen={lineItemShowStatus}
                        lineItems={changeListItems && changeListItems.length > 0 ? changeListItems : null}
                        headerMessage={messages.listItemsStatusConfirmation}
                        actionMessage={messages.submitConfirmation}
                        action={submit}
                        closeMessage={messages.closeAction}
                        close={closeLineItems}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-24 spacer-32-top" >
                    <h1 className="section-title">
                        <FormattedMessage {...messages.createFutureBillingCancellationRequest} />
                    </h1>
                    <div className="col-sm-8 col-md-8 col-lg-5">
                        <div className={!loading ? 'hidden' : ''}>
                            <LoadingIndicator />
                        </div>
                    </div>
                </div>
            </div>
            {<CancelFutureBillingRequest />}
        </div>
    );
}

CancelFutureBillingHome.propTypes = {
    submitSuccess: PropTypes.bool,
    submit: PropTypes.func,
    closeDialog: PropTypes.func,
    loading: PropTypes.bool,
    invoiceNumber: PropTypes.string,
    multiInvoiceNumber: PropTypes.string,
    changeListItems: PropTypes.func,
    lineItemShowStatus: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
    submitSuccess: makeCancelFutureBillingReturnFormSuccess(),
    invoiceNumber: makeSelectInvoiceNumberCreate(),
    multiInvoiceNumber: makeSelectMultiInvoiceNumberCreate(),
    loading: makeCancelFutureBillingReturnFormLoading(),
    changeListItems: makeListItemsDifference(),
    lineItemShowStatus: makeListItemShowStatus()
});

function mapDispatchToProps(dispatch) {
    return {
        submit: () => dispatch(submitFBCReturnForm()),        
        closeDialog: evt => {
            dispatch(closeRebillConfirmation());            
        },
        closeLineItems: evt => {
            dispatch(closeLineItemConfirmation());            
        }
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(CancelFutureBillingHome);
