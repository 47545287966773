import produce from "immer";
import { MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER_SUCCESS } from '../../../common/components/multiTenantCreateRequest/constants';
import { MT_GET_RETURN_SUCCESS } from '../../../common/components/multiTenantFetchRequest/constants';
import {
    CLOSE_SUBMIT_CONFIRMATION, SUBMIT_MULTI_TENANT_FORM, SUBMIT_MULTI_TENANT_REQUEST, SUBMIT_RETURN_FAIL, SUBMIT_RETURN_SUCCESS,
    SUBMIT_MT_ApprovalRequest,
    SUBMIT_MT_ApprovalRequest_SUCCESS,
    SUBMIT_MT_ApprovalRequest_FAIL,
    CHANGE_MT_COSMIC_CASENUMBER,
    CHANGE_MT_TRACKING_ID
} from './constants';
export const initialState = {};

const MultiTenantRequestReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case SUBMIT_MT_ApprovalRequest:
                draft.action = action.value;
                draft.isOverride = false;
                draft.loading = true;
                draft.error = false;
                draft.successResponse = false;
                break;
            case SUBMIT_MT_ApprovalRequest_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.successResponse = action.response;
                break;
            case SUBMIT_MT_ApprovalRequest_FAIL:
                draft.approvalError = action.error;
                draft.loading = false;
                draft.successResponse = false;
                break;
            case SUBMIT_MULTI_TENANT_FORM:
                draft.multiTenantSubmitInitiated = true;
                break;
            case CLOSE_SUBMIT_CONFIRMATION:
                draft.multiTenantSubmitInitiated = false;
                break;
            case SUBMIT_MULTI_TENANT_REQUEST:
                draft.multiTenantSubmitInitiated = false;
                draft.loading = true;
                break;
            case SUBMIT_RETURN_SUCCESS:
                draft.multiTenantSubmitInitiated = false;
                draft.loading = false;
                if (!action.response.success) {
                    draft.multiTenantSubmitSuccess = false;
                    draft.error = { errorMessages: "" };
                    break;
                }
                draft.multiTenantSubmitSuccess = true;
                draft.submitResponseId = action.response.response.returnsIdentifier;
                break;
            case SUBMIT_RETURN_FAIL:
                draft.error = action.error;
                draft.multiTenantSubmitInitiated = false;
                draft.multiTenantSubmitSuccess = false;
                draft.loading = false;
                break;
            case MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER_SUCCESS:
                let leadAgreementDetails = action.agreementDetails.tenantAgreements
                    .filter(tenant => tenant.agreementNumber === action.agreementDetails.agreementNumber);

                let leadAgreementName = leadAgreementDetails && leadAgreementDetails.length > 0 ? leadAgreementDetails[0].businessName : "";

                draft.enrollmentDetails = {
                    enrollmentNumber: action.agreementDetails.agreementNumber,
                    programCode: action.agreementDetails.programCode,
                    masterAgmNumber: action.agreementDetails.masterAgreementNumber,
                    billToCountryCode: action.agreementDetails.billToCountry,
                    salesLocationCode: action.agreementDetails.salesLocation,
                    pricingCurrency: action.agreementDetails.pricingCurrency,
                    leadAgreementName: leadAgreementName.toUpperCase()
                };
                draft.isReadOnly = false;
                break;
            case MT_GET_RETURN_SUCCESS:
                draft.enrollmentDetails = {
                    enrollmentNumber: action.requestData.leadEnrollmentNumber,
                    leadAgreementName: action.requestData.leadAgreementName,
                    programCode: action.requestData.programCode,
                    masterAgmNumber: action.requestData.masterAgmNumber,
                    billToCountryCode: action.requestData.billToCountryCode,
                    salesLocationCode: action.requestData.salesLocationCode,
                    pricingCurrency: action.requestData.pricingCurrency,
                    errors: action.requestData.errors,
                    requestStatusCode: action.requestData.requestStatusCode,
                    cancellationReason: action.requestData.cancellationReason,
                    adjustedPOStatus: action.requestData.adjustedPOStatus,
                    ticket: action.requestData.ticket,
                };
                draft.isReadOnly = true;
                draft.ticket = action.requestData.ticket;
                break;
            case CHANGE_MT_COSMIC_CASENUMBER:
                draft.cosmicCaseNumberValue = action.cosmicCaseNumberValue
                break;
            case CHANGE_MT_TRACKING_ID:
                draft.crmId = action.crmId
                break;
        }
    });

export default MultiTenantRequestReducer;
