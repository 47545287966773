import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import { FileUploadTranslationsMap } from './constants';


export class FileInputSingle extends PureComponent {
    constructor(props) {
        super(props);
        //console.log(this.props);
    }

    render() {
        const { formatMessage } = this.props.intl;
       const ariaFileInput= FileUploadTranslationsMap.get('lblFileInput');
        return (<div style={{ border: '0.5px solid black' }}>
            {/* <input type="file" single onChange={this.getUploadedFileName}></input> */}
            {/* accept="audio/*,video/*,image/*" */}
            {/* https://www.w3schools.com/TAGS/att_input_accept.asp */}
            {/* https://stackoverflow.com/questions/21534729/show-custom-text-for-applied-file-type-filters-on-file-dialog-to-upload-particul */}
            {/* application/pdf */}
            {/* https://www.quirksmode.org/dom/inputfile.html */}
            <input
                id={this.props.id}
                className={this.props && this.props.className ? this.props.className : ''}
                name={this.props && this.props.name ? this.props.name : ''}
                placeholder={ariaFileInput}
                aria-label={ariaFileInput}
                aria-required="true"
                autoComplete={this.props && this.props.autoComplete ? this.props.autoComplete : 'off'}
                ref={this.props.fileSelector}
                type="file"
                accept={this.props.acceptFiles}
                single="single"
                required="true"
                aria-invalid="false"
                disabled={this.props.disabled}
                onChange={this.props.onChange}
                onClick={this.props.onClick}></input>
        </div>);
    }
}

export default injectIntl(FileInputSingle);