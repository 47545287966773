import React, { useEffect, memo } from 'react';
import PropTypes, { object } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectSaga } from '../internals/utils/injectSaga';
import { getDomainData, getUserRoles, getUserPref } from './actions';
import saga from './saga';
import $ from 'jquery';
import { initAppinsights } from "../components/appInsights/actions";
import { makeSelectAppinsight } from '../components/appInsights/selectors';

import { domainResourceList } from './domainResourceList';
import {
    makeSelectDomainData,
    makeSelectLoading,
    makeSelectError,
    makeSelectUserInfo,
    makeSelectUserPref,
    makeSelectRolesLoading
} from './selectors';


import DomainDataContext from '../context/DomainDataContext';
import CreditSubmitterMenu from '../components/menus/CreditSubmitterMenu';
import CreditApproverMenu from '../components/menus/CreditApproverMenu';
import FieldUserMenu from '../components/menus/FieldUserMenu';
import OpsUserMenu from '../components/menus/OpsUserMenu';
import PartnerUserMenu from '../components/menus/PartnerUserMenu';
import SubCFOMenu from '../components/menus/SubCFOMenu';
import VLActiveUserMenu from '../components/menus/VLActiveUserMenu';



import Can from '../rbac/Can'
import { injectIntl } from 'react-intl';
import { UnAuthorised } from '../components/unauthroised';
import { ResourceNotFound } from '../components/resourceNotFound';
import { WorkAccountMapped} from '../components/WorkAccountMapped';
import LoadingPage from '../../login/containers/loadingPage';
import ErrorPage from '../../login/containers/errorPage';
import { changeLocale } from '../components/LanguageProvider/actions';
import { isSubCFOEnabled, isFbcEnabled,isWamEnabled } from '../internals/utils/featureFlag'

export function App({
    loading,
    rolesloading,
    error,
    domainData,
    userInformation,
    getUserInformation,
    getUserPreferances,
    loadDomainData,
    userPreferances,
    changeLocale,
    initAppinsights,
    appinsightsKey,
    appInsights
}) {

    let isFbcEnabledFlag = isFbcEnabled();
    let isWamEnabledFlag = isWamEnabled();
    console.log("isFbcEnabledFlag: " + isFbcEnabledFlag);
    console.log("isWamEnabledFlag: " + isWamEnabledFlag);

    window.userRole = "";
    useInjectSaga({ key: "appRoot", saga });
    var userRole = "";
    var isSubCFOEnabledFlag = isSubCFOEnabled();
    var invalidRoles = [];
    if (isSubCFOEnabledFlag != "True") {
        invalidRoles.push("VFU");
        invalidRoles.push("VSC");
    }

    useEffect(() => {
        initAppinsights(appinsightsKey);
    }, [appinsightsKey]);

    if (domainData)
        window.domainData = domainData;

    useEffect(() => {
        if (!(userInformation && userInformation.roles && userInformation.roles.length > 0)) {
            getUserInformation();
            if (!rolesloading) {
                if (!window.domainData) {                    
                    loadDomainData(domainResourceList);
                } else if (window.domainData && window.domainData.length <= 0) {                   
                    loadDomainData(domainResourceList);
                }
            }
        }

    }, []);    

    window.userPref = "";
    if (userPreferances && userPreferances.status && userPreferances.status !== "error") {
        window.userPref = userPreferances.value;
        window.locale = window && window.userPref && window.userPref.cultureCode ? window.userPref.cultureCode : "en-US";
        window.dateFormat = window && window.userPref && window.userPref.dateFormat;
    } else {
        window.locale = "en-US";
        window.dateFormat = "YYYY-MM-DD";
    }
    var ErrorComponent = <LoadingPage />;
    var shouldRedirect = false;
    
    if (rolesloading) {
        ErrorComponent = <LoadingPage />;
    } else {
        if (userInformation && userInformation.roles) {

          let partnerEmail = userInformation.email && userInformation.email.split("@");
          appInsights && appInsights.trackTrace({ message: "Partner_Email-" + partnerEmail});
          let linkedAccountData = userInformation.linkedAccounts;
      
          var iswhitelistedaccounts = false;
          const whitelistedaccounts = window.vlcredits.envData.Services.wamWhitelistedaccounts;
    if(whitelistedaccounts){
        var keys = whitelistedaccounts.split(",");
        iswhitelistedaccounts = keys.includes(userInformation.email);
    }
      
          if (invalidRoles.includes(userInformation.roles[0])) {
              ErrorComponent = <ResourceNotFound />;
            } else if ((partnerEmail && partnerEmail[1] === window.vlcredits.envData.Services.wamPartnerDomainName && isWamEnabledFlag == "true" && (linkedAccountData == null || linkedAccountData.length == 0) && userInformation.authType != "aadupn") && ((!userInformation.isLinkedAccountOverride || userInformation.isLinkedAccountOverride == "False") && iswhitelistedaccounts == false)){
              shouldRedirect = true;
            }else if ( (isWamEnabledFlag == "true" && linkedAccountData && linkedAccountData.length > 0 && userInformation.authType != "aadupn") && ((!userInformation.isLinkedAccountOverride || userInformation.isLinkedAccountOverride == "False") && iswhitelistedaccounts == false)){
              let accountData = [];
              let accountNames = []
              linkedAccountData.forEach(element => {
                let parsedata = JSON.parse(element);
                accountData.push(parsedata);
                accountNames.push(parsedata.LinkedAccountCredentialValue);
              });

              let checkEmailId = accountData.filter(account => account.LinkedAccountCredentialValue == userInformation.email);
             if(checkEmailId.length == 0 && ((partnerEmail && partnerEmail[1] === window.vlcredits.envData.Services.wamPartnerDomainName) || userInformation.authType == "msa")){
              console.log("account_authtype:",userInformation.authType);
              console.log("login_with_same_email:",checkEmailId);
              let mappedAccounts = [... new Set(accountNames)];
              ErrorComponent = <WorkAccountMapped mappedAccounts={mappedAccounts} />;
             }
           } else if (userInformation.roles.length > 0) {
              userRole = userInformation.roles[0];

              //This is to handle active internal users login with no credits role assigned.
              //As a part of FBC feature -- introduced new role - 'VAU'
              //If its a active internal user who doesn't have any credits role assigned, then user profile service sends 'VAU' as a role.
              //Allow active internal users to login and access only FBC feature.

              //if FBC feature flag is not enabled then do not allow users login with VAU role.
              
              if (isFbcEnabledFlag != "True" && userRole === "VAU") {
                ErrorComponent = <UnAuthorised />;
              } else {
                window.userRole = userRole;
                if (userInformation.accessorGuid) {
                  if (
                    Object.entries(userPreferances).length === 0 &&
                    userPreferances.constructor === Object
                  ) {
                    getUserPreferances(userInformation.accessorGuid);
                  } else {
                    changeLocale(userPreferances.value.cultureCode);
                  }
                }
              }
            } else {
              ErrorComponent = <UnAuthorised />;
            }
        } else {
            ErrorComponent = <ErrorPage />
        }
    }

    var componentData = "";
    if (!rolesloading && userRole !== "") {
        //check if the role is vlactive role. if fbc flag not set then bind errorcomponent.
      if (isFbcEnabledFlag != "True" && userRole === "VAU") {
        componentData = ErrorComponent;
      } else {
        componentData = (
          <div className="row remove-all-margin">
            <Can
              role={userRole}
              perform="PartnerCreditSearch:RW"
              yes={() => <PartnerUserMenu />}
            />
            <Can
              role={userRole}
              perform="FieldCreditSearch:RW"
              yes={() => <FieldUserMenu />}
            />
            <Can
              role={userRole}
              perform="SubmitterCreditSearch:RW"
              yes={() => <CreditSubmitterMenu />}
            />
            <Can
              role={userRole}
              perform="ApprovarApprovalPage:RW"
              yes={() => <CreditApproverMenu />}
            />
            <Can
              role={userRole}
              perform="OpsCreditSearch:RW"
              yes={() => <OpsUserMenu />}
            />
            <Can
              role={userRole}
              perform="CFOApprovalSearch:RW"
              yes={() => <SubCFOMenu />}
            />
            <Can
              role={userRole}
              perform="VLActiveUserSearch:RW"
              yes={() => <VLActiveUserMenu />}
            />
          </div>
        );
      }
    } else {
      componentData = ErrorComponent;
    }
if(shouldRedirect == true){
 window.location = window.vlcredits.envData.Services.wamRedirectUrl;
}else{
    return (
        <DomainDataContext.Provider value={domainData} >
            {componentData}
        </DomainDataContext.Provider>
    );
}
}

App.propTypes = {
    loading: PropTypes.bool,
    rolesloading: PropTypes.bool,
    domainData: PropTypes.array,
    userInformation: PropTypes.object,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    getUserInformation: PropTypes.func,
    getUserPreferences: PropTypes.func,
    changeLocale: PropTypes.func,
    loadDomainData: PropTypes.func,
    appInsights: PropTypes.string
};

const mapStateToProps = createStructuredSelector({
    domainData: makeSelectDomainData(),
    loading: makeSelectLoading(),
    rolesloading: makeSelectRolesLoading(),
    error: makeSelectError(),
    userInformation: makeSelectUserInfo(),
    userPreferances: makeSelectUserPref(),
    appInsights: makeSelectAppinsight()
});

const mapDispatchToProps = (dispatch) => {
    return {
        loadDomainData: (list) => dispatch(getDomainData(list)),
        getUserInformation: () => dispatch(getUserRoles()),
        changeLocale: (locale) => dispatch(changeLocale(locale)),
        getUserPreferances: (accessorGuid) => dispatch(getUserPref(accessorGuid)),
        initAppinsights: (key) => dispatch(initAppinsights(key))
    };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, memo)(injectIntl(App));