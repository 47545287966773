import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectMTApprovalErrorsDomain = state => state.mtApprovalErrors || initialState;

const makeSelectMTRequestError = () =>
    createSelector(selectMTApprovalErrorsDomain, substate => substate.error);

const makeSelectMTApprovalErrors = () =>
  createSelector(
      selectMTApprovalErrorsDomain,
    substate => substate,
  );

export default makeSelectMTApprovalErrors;
export { selectMTApprovalErrorsDomain, makeSelectMTRequestError };
