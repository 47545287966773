import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectCancelFutureBillingSearchHomeDomain = (state) =>
  state.cancelFutureBillingSearchHome || initialState;

const makeSelectCancelFutureBillingSearchHome = () =>
  createSelector(
    selectCancelFutureBillingSearchHomeDomain,
    (substate) => substate
  );

export default makeSelectCancelFutureBillingSearchHome;
export { selectCancelFutureBillingSearchHomeDomain };
