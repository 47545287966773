import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGlobal = state => state.global || initialState;
const selectFileUploadRequestDomain = state => state.fileUploadModal || initialState;
const multiTenantRequestState = state => state.multiTenantRequest || initialState;

const makeSelectLoading = () => createSelector(selectFileUploadRequestDomain, substate => substate.loading);
const makeSelectFileSelectionError = () => createSelector(selectFileUploadRequestDomain, substate => substate.fileSelectionError);
const makeSelectDocumentTypeSelectionError = () => createSelector(selectFileUploadRequestDomain, substate => substate.documentTypeSelectionError);
const makeSelectFileUploadSuccessOrError = () => createSelector(selectFileUploadRequestDomain, substate => substate.fileUploadSuccessOrError);
const makeSelectFileUploadPayload = () => createSelector(selectFileUploadRequestDomain, substate => substate.fileInfo);
const makeSelectDisableUI = () => createSelector(selectFileUploadRequestDomain, substate => substate.disableUI);
const makeSelectAuthType = () => createSelector(selectGlobal, globalState => globalState.userInfo.authType);
const makeSelectAccessToken = () => createSelector(selectGlobal, globalState => globalState.userInfo.userAccessToken);
const makeSelectuploadedFiles = () => createSelector(selectFileUploadRequestDomain, substate => substate.uploadedFiles);
const makeSelectDocumentType = () => createSelector(selectFileUploadRequestDomain, substate => substate.document);
const makeSelectPopUpModalLoading = () => createSelector(selectFileUploadRequestDomain, substate => substate.IsModalOpen);
const makeSelectDownloadingFile = () => createSelector(selectFileUploadRequestDomain, substate => substate.Isdownloading);
const makeSelectDownloadErrorFile = () => createSelector(selectFileUploadRequestDomain, substate => substate.isDownloadError);
const makeSelectReadOnlyFlag = /*() => createSelector(multiTenantRequestState, substate => substate.isReadOnly);*/

    () => createSelector(selectFileUploadRequestDomain, substate => (substate.isReadOnly && substate.isReadOnly == true)
        || multiTenantRequestState && multiTenantRequestState.isReadOnly == true ? true : substate.isReadOnly);

const makeSelectErrorFlag = () => createSelector(selectFileUploadRequestDomain, substate => substate.isError);

export {
    selectFileUploadRequestDomain,
    makeSelectLoading,
    makeSelectFileSelectionError,
    makeSelectDocumentTypeSelectionError,
    makeSelectFileUploadSuccessOrError,
    makeSelectFileUploadPayload,
    makeSelectDisableUI,
    makeSelectAuthType,
    makeSelectAccessToken,
    makeSelectuploadedFiles,
    makeSelectDocumentType,
    makeSelectPopUpModalLoading,
    makeSelectDownloadingFile,
    makeSelectDownloadErrorFile,
    makeSelectReadOnlyFlag,
    makeSelectErrorFlag
};