export const CHANGE_ISBDAPPROVED = 'ReturnDetails/CHANGE_ISBDAPPROVED';
export const CHANGE_HANDLING_FEE = 'ReturnDetails/CHANGE_HANDLING_FEE';
export const CHANGE_FULL_CREDIT = 'ReturnDetails/CHANGE_FULL_CREDIT';
export const CHANGE_RETURN_REASON = 'ReturnDetails/CHANGE_RETURN_REASON';
export const UPDATE_NET_CREDIT = 'ReturnDetails/UPDATE_NET_CREDIT';
export const UPDATE_NET_CREDIT_LOCAL = 'ReturnDetails/UPDATE_NET_CREDIT_LOCAL';
export const UPDATE_GROSS_CREDIT = 'ReturnDetails/UPDATE_GROSS_CREDIT';
export const UPDATE_GROSS_CREDIT_LOCAL = 'ReturnDetails/UPDATE_GROSS_CREDIT_LOCAL';
export const CHANGE_ROC_VALUE = 'ReturnDetails/CHANGE_ROC_VALUE';
export const ROCVALUECHANGE_CONFIRMATION = 'ReturnDetails/ROCVALUECHANGE_CONFIRMATION';
export const CLOSE_ROCVALUECHANGE_CONFIRMATION = 'ReturnDetails/CLOSE_ROCVALUECHANGE_CONFIRMATION';
export const UPDATE_CURRENCY_DIFF = 'ReturnDetails/UPDATE_CURRENCY_DIFF';