import { createSelector } from 'reselect';
import { initialState } from './reducer';

/* Direct selector to the createRequest state domain */
const selectCreateRequestDomain = state => state.createRequest || initialState;

/* Default selector used by CreateRequest */
const makeSelectCreateRequest = () => 
    createSelector(
        selectCreateRequestDomain,
        substate => substate
    );

const makeSelectRequestID = () =>
    createSelector(selectCreateRequestDomain, substate => substate.requestID);

const makeSelectInvoiceNumber= () =>
    createSelector(selectCreateRequestDomain, substate => substate.invoiceNumber);

const makeSelectstatusCode= () =>
    createSelector(selectCreateRequestDomain, substate => substate.statusCode);
    

const makeSelectInvoice = () =>
    createSelector(selectCreateRequestDomain, substate => substate.invoice);


const makeSelectError = () =>
    createSelector(selectCreateRequestDomain, substate => substate.error);

const makeSelectLoading = () =>
    createSelector(selectCreateRequestDomain, substate => substate.loading);

export default makeSelectCreateRequest;
export { makeSelectLoading, makeSelectCreateRequest, 
    makeSelectRequestID, makeSelectInvoiceNumber,makeSelectstatusCode,makeSelectInvoice, makeSelectError };
