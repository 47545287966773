import { createSelector } from "reselect";

const selectRouter = (state) => state.router;

const makeSelectLocation = () =>
  createSelector(selectRouter, (routerState) => routerState.location);

const selectAppinsights = (state) => state.appinsight;
const makeSelectAppinsight = () =>
  createSelector(
    selectAppinsights,
    (substate) => substate && substate.appInsights
  );
export { makeSelectLocation, makeSelectAppinsight };
