import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectCFBReturnDetailsDomain = state => state.cfbReturnDetails || initialState;

const makeSelectCosmicCaseNumber = () => createSelector(selectCFBReturnDetailsDomain,substate => substate.cosmicCaseNumber);

const makeSelectRebillPONumber = () => createSelector(selectCFBReturnDetailsDomain,    substate => substate.rebillSystemPONumber);

const makeSelectisFullCancellation = () => createSelector(selectCFBReturnDetailsDomain, substate => substate.isFullCancellation);

const makeSelectReturnReason = () => createSelector( selectCFBReturnDetailsDomain, substate => substate.returnReason,);

const makeSelectBillToCountryCode = () => createSelector(selectCFBReturnDetailsDomain, substate => substate.billToCountryCode,);

const makeSelectinvoicesDetails = () => createSelector(selectCFBReturnDetailsDomain, substate => substate.invoicesDetails,);

const makeSelectReturnDetails = () => createSelector( selectCFBReturnDetailsDomain, substate => substate );

const makeSelectProgramCode = () => createSelector(selectCFBReturnDetailsDomain,substate => substate.programCode);

export default makeSelectReturnDetails;
export {
    selectCFBReturnDetailsDomain,
    makeSelectisFullCancellation,
    makeSelectReturnReason,
    makeSelectBillToCountryCode,
    makeSelectinvoicesDetails,    
    makeSelectProgramCode,
    makeSelectCosmicCaseNumber,
    makeSelectRebillPONumber
};