import { defineMessages } from 'react-intl';

export const scope = 'SubmitErrors';

export default defineMessages({
    invoiceSearchError: {
        id: `${scope}.invoiceSearchError`,
        defaultMessage: 'Search Invoice Error',
    },
    submitRequestError: {
        id: `${scope}.submitRequestError`,
        defaultMessage: 'Submit Request Failed',
    },
    saveRequestError: {
        id: `${scope}.saveRequestError`,
        defaultMessage: 'Save as draft request failed',
    },
    deleteRequestError: {
        id: `${scope}.deleteRequestError`,
        defaultMessage: 'Delete draft request failed',
    },
    rebillSearchError: {
        id: `${scope}.rebillSearchError`,
        defaultMessage: 'Search Error',
    },
    multiInvoiceSearchError: {
        id: `${scope}.multiInvoiceSearchError`,
        defaultMessage: 'Multi-Invoice Search Error'
    },
    cancelError: {
        id: `${scope}.cancelError`,
        defaultMessage: 'Cancel Invoice Error'
    },
    fbcSearchError: {
        id: `${scope}.fbcSearchError`,
        defaultMessage: 'Search Error'
    },
    fbcSubmitError: {
        id: `${scope}.fbcSubmitError`,
        defaultMessage: 'Future Billing Cancellation Request failed'
    },
    fbcPartnerSubmitError: {
        id: `${scope}.fbcPartnerSubmitError`,
        defaultMessage: 'License reduction request failed'
    },
    fbcSelectPoError: {
        id: `${scope}.fbcSelectPoError`,
        defaultMessage: 'Search Error'
    },
    reallocationDetailsError: {
        id: `${scope}.reallocationDetailsError`,
        defaultMessage: 'Reallocation Error'
    },
    reallocationModalError: {
        id: `${scope}.reallocationModalError`,
        defaultMessage: 'Reallocation Error'
    },
    reallocationSearchPOError: {
        id: `${scope}.reallocationSearchPOError`,
        defaultMessage: 'Reallocation PO Search Failed'
    },
    reallocationCreateRequestError: {
        id: `${scope}.reallocationCreateRequestError`,
        defaultMessage: 'Rellocation Request Failed'
    },
    reallocationSubmitError: {
        id: `${scope}.reallocationSubmitError`,
        defaultMessage: 'Failed to submit reallocation request'
    },
    multitenantFetchError: {
        id: `${scope}.multitenantFetchError`,
        defaultMessage: 'Search License Error'
    }
});
