import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import reducer from './reducer';
import messages from './messages';
import { makeSelectCreateRequestError } from './selectors';
import { makeSelectSelectApprovalError } from '../../approver/creditApprover/containers/request/selectors';
import { makeSelectMultiInvoiceApprovalError } from '../../approver/creditApprover/containers/multiInvoiceRequest/selectors';
import { useInjectReducer } from '../../../common/internals/utils/injectReducer';
import ErrorMessage from '../../../common/components/ErrorMessage/ErrorMessage';
import errorMessages from '../../../common/internals/api/errorMessages';

export function ApprovalErrors({ approvalSearchError, approvalRequestError, approvalMultiInvoiceRequestError }) {
    useInjectReducer({ key: 'approvalErrors', reducer });

    if (approvalSearchError && 'errorMessages' in approvalSearchError)
        return (
            <ErrorMessage messageBody={approvalSearchError.errorMessages}
                messageTitle={messages.approvalSearchError} defaultMessage={errorMessages.approveMsg} />
        );

    if (approvalRequestError && 'errorMessages' in approvalRequestError)
        return (
            <ErrorMessage messageBody={approvalRequestError.errorMessages}
                messageTitle={messages.approvalRequestError} defaultMessage={errorMessages.approveMsg} />
        );

    if (approvalMultiInvoiceRequestError && 'errorMessages' in approvalMultiInvoiceRequestError)
        return (
            <ErrorMessage messageBody={approvalMultiInvoiceRequestError.errorMessages}
                messageTitle={messages.approvalRequestError} defaultMessage={errorMessages.approveMsg} />
        );

    return null;
}

ApprovalErrors.propTypes = {
    dispatch: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    approvalSearchError: makeSelectCreateRequestError(),
    approvalRequestError: makeSelectSelectApprovalError(),
    approvalMultiInvoiceRequestError: makeSelectMultiInvoiceApprovalError()
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(ApprovalErrors);
