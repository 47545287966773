import produce from 'immer';
import { SEARCH_INVOICE_SUCCESS } from '../../../common/components/createRequest/constants'
import { GET_RETURN_SUCCESS } from '../../../common/components/fetchReturnRequest/constants';
import {
    CHANGE_ISBDAPPROVED,
    CHANGE_HANDLING_FEE,
    CHANGE_FULL_CREDIT,
    CHANGE_RETURN_REASON,
    UPDATE_NET_CREDIT,
    UPDATE_NET_CREDIT_LOCAL,
    CHANGE_ROC_VALUE,
    ROCVALUECHANGE_CONFIRMATION,
    CLOSE_ROCVALUECHANGE_CONFIRMATION
} from './constants'
import {
    getHandlingFeeFromRules,
    isLRDSFullCreditCountryCodeAndSubCheck
} from '../../../../common/internals/utils/HandlingFeeHelper';

export const initialState = {};

const returnDetailsReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_RETURN_SUCCESS:
                draft.proposalId = action.invoice.proposalId;
                draft.isProposalId = action.invoice.isProposalId;
                draft.programCode = action.invoice.programCode;
                draft.billToCountryCode = action.invoice.billToCountryCode;
                draft.licenseAge = action.invoice.licenseAge;
                draft.isBdApproved = action.invoice.isBdApproved;
                draft.handlingFeePercentage = action.invoice.handlingFeePercentage;
                draft.returnReason = action.invoice.returnReason;
                draft.netCreditAmount = action.invoice.netCreditUSD;
                draft.netCreditAmountLocal = action.invoice.netAmount;
                draft.isDirectAgreement = action.invoice.isDirectAgreement;
                draft.isSUB = action.invoice.isSUB;
                draft.isFullCredit
                    = isLRDSFullCreditCountryCodeAndSubCheck(action.invoice.billToCountryCode, action.invoice.isSUB) ?
                        true : action.invoice.isFullCredit;
                draft.operationsCenterCode = action.invoice.operationsCenterCode;
                draft.alternateROC = (action.invoice.alternateROC) ? action.invoice.alternateROC : action.invoice.operationsCenterCode;
                draft.programCode = action.invoice.programCode;
                break;
            case SEARCH_INVOICE_SUCCESS:
                draft.billToCountryCode = action.invoice.billToCountryCode;
                draft.licenseAge = action.invoice.licenseAge;
                draft.isBdApproved = action.invoice.isBdApproved;
                draft.handlingFeePercentage = action.invoice.handlingFeePercentage;
                draft.returnReason = action.invoice.returnReason;
                draft.isSUB = action.invoice.isSUB;
                draft.isDirectAgreement = action.invoice.isDirectAgreement;
                draft.netCreditAmount = isLRDSFullCreditCountryCodeAndSubCheck(action.invoice.billToCountryCode, action.invoice.isSUB) ?
                    action.invoice.invoiceAmountUSD : action.invoice.netCreditUSD;
                draft.netCreditAmountLocal = isLRDSFullCreditCountryCodeAndSubCheck(action.invoice.billToCountryCode, action.invoice.isSUB) ?
                    action.invoice.invoiceAmount : action.invoice.netAmount;
                draft.isFullCredit
                    = isLRDSFullCreditCountryCodeAndSubCheck(action.invoice.billToCountryCode, action.invoice.isSUB) ?
                        true : action.invoice.isFullCredit;
                draft.operationsCenterCode = action.invoice.operationsCenterCode;
                draft.alternateROC = (action.invoice.alternateROC) ? action.invoice.alternateROC : action.invoice.operationsCenterCode;
                draft.programCode = action.invoice.programCode;
                draft.invoiceDate = action.invoice.invoiceDate;
                break;
            case CHANGE_ISBDAPPROVED:
                draft.isBdApproved = action.value;
                break;
            case CHANGE_HANDLING_FEE:
                draft.handlingFeePercentage = action.value;
                break;
            case CHANGE_FULL_CREDIT:
                draft.isFullCredit = action.value;
                break;
            case CHANGE_RETURN_REASON:
                draft.returnReason = action.value;
                draft.handlingFeePercentage
                    = getHandlingFeeFromRules(
                        draft.billToCountryCode, draft.licenseAge, action.value, draft.isDirectAgreement, draft.proposalId, draft.programCode);
                break;
            case UPDATE_NET_CREDIT:
                draft.netCreditAmount = action.value;
                break;
            case UPDATE_NET_CREDIT_LOCAL:
                draft.netCreditAmountLocal = action.value;
                break;
            case CHANGE_ROC_VALUE:
                if (draft.alternateROC != action.value) {
                    draft.intermediatevalue = action.value;
                    draft.rocValueChanged = true;
                }
                else {
                    draft.operationsCenterCode = action.value;
                }
                break;
            case ROCVALUECHANGE_CONFIRMATION:
               // draft.alternateROC = draft.intermediatevalue;
                draft.operationsCenterCode = draft.intermediatevalue;
                draft.rocValueChanged = false;
                break;
            case CLOSE_ROCVALUECHANGE_CONFIRMATION:
                draft.rocValueChanged = false;
                break;
        }
    });

export default returnDetailsReducer;
