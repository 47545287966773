import produce from 'immer';
import { DEFAULT_ACTION, IS_VALID_REQ_TO_SUBMIT } from './constants';

export const initialState = {};

const MultiTenantHomeReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
        case IS_VALID_REQ_TO_SUBMIT:
            console.log("IS_VALID_REQ_TO_SUBMIT")
            console.log(action.isValidRequestToSubmit)
            draft.isValidRequestToSubmit = action.isValidRequestToSubmit;
        break;
      case DEFAULT_ACTION:
        break;
    }
  });

export default MultiTenantHomeReducer;
