import { defineMessages } from 'react-intl';

export const scope = 'SubmitErrors';

export default defineMessages({
    invoiceSearchError: {
        id: `${scope}.invoiceSearchError`,
        defaultMessage: 'Search Invoice Error',
    },
    multiInvoiceSearchError: {
        id: `${scope}.multiInvoiceSearchError`,
        defaultMessage: 'Search Multi Invoice Error',
    },
    submitRequestError: {
        id: `${scope}.submitRequestError`,
        defaultMessage: 'Submit Request Failed',
    },
    saveRequestError: {
        id: `${scope}.saveRequestError`,
        defaultMessage: 'Save as draft request failed',
    },
    deleteRequestError: {
        id: `${scope}.deleteRequestError`,
        defaultMessage: 'Delete draft request failed',
    },
    rebillSearchError: {
        id: `${scope}.rebillSearchError`,
        defaultMessage: 'Search Error',
    },
});
