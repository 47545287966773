import { defineMessages } from 'react-intl';

export const scope = 'subcfoSubmitHome';

export default defineMessages({
    createCreditRequest: {
        id: `${scope}.createCreditRequest`,
        defaultMessage: 'Subsidiary CFO Consult Request',
    },
    submitSuccess: {
        id: `${scope}.submitSuccess`,
        defaultMessage: 'Subsidiary CFO Consult Request submitted successfully',
    },
    rebillConfirmation: {
        id: `${scope}.rebillConfirmation`,
        defaultMessage: 'No rebill invoices added for full credit request. Do you want to continue ?',
    },
    closeAction: {
        id: `${scope}.closeAction`,
        defaultMessage: 'close',
    },
    continueAction: {
        id: `${scope}.continueAction`,
        defaultMessage: 'Continue',
    },
    goBackAction: {
        id: `${scope}.goBackAction`,
        defaultMessage: 'Go Back',
    }
});
