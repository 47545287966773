import PropTypes from 'prop-types';

const collapse = (expanded,idarr) => {

     for(var num=0; num<idarr.length;num++)
    {
        var current = document.getElementById(idarr[num]);
        if(current!=null)
        {
        current.setAttribute("aria-expanded",expanded);
        current.setAttribute("class", idarr[num] +" collapse"+ ((expanded == true) ? " in" : ""));
        current.setAttribute("style", "");
        }
        
        
        var current = document.getElementById("up"+idarr[num]);
        if(current!=null)
        current.setAttribute("class",idarr[num] +" collapse"+ ((expanded == true) ? "" : " in"));

        var current = document.getElementById("down"+idarr[num]);
        if(current!=null)
        current.setAttribute("class", idarr[num] +" collapse"+ ((expanded == true) ? " in" : ""));
    }
}

collapse.propTypes = {
    expanded: PropTypes.bool,
    idarr: PropTypes.array,
};

export default collapse;
