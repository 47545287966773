import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectApprovalHomeDomain = state => state.approvalHome || initialState;

const makeSelectApprovalHome = () =>
  createSelector(
    selectApprovalHomeDomain,
    substate => substate,
  );

export default makeSelectApprovalHome;
export { selectApprovalHomeDomain };
