import {
    CHANGE_ISBDAPPROVED,
    CHANGE_HANDLING_FEE,
    CHANGE_FULL_CREDIT,
    CHANGE_RETURN_REASON,
    UPDATE_NET_CREDIT,
    UPDATE_NET_CREDIT_LOCAL,
    CHANGE_ROC_VALUE,
    ROCVALUECHANGE_CONFIRMATION,
    CLOSE_ROCVALUECHANGE_CONFIRMATION
} from './constants';

export function changeIsBDApproved(value) {
    return {
        type: CHANGE_ISBDAPPROVED,
        value
    };
}

export function changeHandlingFee(value) {
    return {
        type: CHANGE_HANDLING_FEE,
        value
    };
}

export function changeIsFullCredit(value) {
    return {
        type: CHANGE_FULL_CREDIT,
        value
    };
}

export function changeReturnReason(value) {
    return {
        type: CHANGE_RETURN_REASON,
        value
    };
}

export function updateNetCreditAmount(value) {
    return {
        type: UPDATE_NET_CREDIT,
        value
    };
}
export function updateNetCreditAmountLocal(value) {
    return {
        type: UPDATE_NET_CREDIT_LOCAL,
        value
    };
}

export function changeROCValue(value) {
    return {
        type: CHANGE_ROC_VALUE,
        value
    };
}

export function updaterocvaluechangeconfimation(value) {
    return {
        type: ROCVALUECHANGE_CONFIRMATION,
        value
    };
}

export function closerocvaluechangeconfimation(value) {
    return {
        type: CLOSE_ROCVALUECHANGE_CONFIRMATION,
        value
    };
}