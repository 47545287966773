import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectRebillpoTableDomain = state => state.rebillpoTable || initialState;
const selectRoot = state => state;

const makeSelectRebillpoList = () =>
    createSelector(
        selectRebillpoTableDomain,
        substate => substate.rebillInvoicepo,
    );

const makeSelectShowPOConfirmation = () =>
    createSelector(
        selectRebillpoTableDomain,
        substate => substate.showPOConfirmation,
    );


const makeSelectRebillpoTable = () =>
    createSelector(
        selectRebillpoTableDomain,
        substate => substate,
    );

const makeSelectIsReadOnly = () =>
    createSelector(
        selectRoot,
        globalState => {
            var readOnlyFlag = null;
            if (globalState.fetchReturnRequest && globalState.fetchReturnRequest.invoice)
                readOnlyFlag = globalState.fetchReturnRequest.invoice.isReadOnly
                    || globalState.fetchReturnRequest.invoice.isUserReadOnly;

            if (globalState.createRequest && globalState.createRequest.invoice)
                readOnlyFlag = globalState.createRequest.invoice.isReadOnly
                    || globalState.createRequest.invoice.isUserReadOnly;

            if (globalState.fetchMultiInvoiceReturnRequest && globalState.fetchMultiInvoiceReturnRequest.invoices)
                readOnlyFlag = globalState.fetchMultiInvoiceReturnRequest.invoices.isReadOnly
                    || globalState.fetchMultiInvoiceReturnRequest.invoices.isUserReadOnly;

            if (globalState.createMultiInvoiceRequest && globalState.createMultiInvoiceRequest.invoices)
                readOnlyFlag = globalState.createMultiInvoiceRequest.invoices.isReadOnly
                    || globalState.createMultiInvoiceRequest.invoices.isUserReadOnly;

            return readOnlyFlag;
        },
    );



export default makeSelectRebillpoTable;
export { selectRebillpoTableDomain, makeSelectRebillpoList, makeSelectShowPOConfirmation, makeSelectRebillpoTable, makeSelectIsReadOnly };
