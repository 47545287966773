import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectMultiTenantSearchAndAddDomain = state => state.multiTenantSearchAndAdd || initialState;
const selectRoot = state => state || initialState;

const makeSelectMultiTenantSearchAndAddDetails = () =>
    createSelector(
        selectMultiTenantSearchAndAddDomain,
        substate => substate,
    );

const makeSelectPurchaseOrderList = () =>
    createSelector(selectMultiTenantSearchAndAddDomain, substate => substate.purchaseOrdersList);

const makeSelectTenant = () =>
    createSelector(selectMultiTenantSearchAndAddDomain, substate => substate.selectedTenant);

const makeSelectPOs = () =>
    createSelector(selectMultiTenantSearchAndAddDomain, substate => substate.selectedPOs);

const makeSelectSelectPO = () =>
    createSelector(selectMultiTenantSearchAndAddDomain, substate => substate.selectedPO);

const makeSelectIsPOLoading = () =>
    createSelector(selectMultiTenantSearchAndAddDomain, substate => substate.isPOLoading);

const makeSelectPurchaseOrdersDropDownOptions = () =>
    createSelector(selectMultiTenantSearchAndAddDomain, substate => substate.purchaseOrdersDropDownOptions);

const makeSelectSelectedPurchaseOrdersData = () =>
    createSelector(selectMultiTenantSearchAndAddDomain, substate => substate.selectedPurchaseOrdersData);

const makeSelectMTSearchPOError = () => 
    createSelector(selectMultiTenantSearchAndAddDomain, substate => substate.error);

const makeSelectPOList = () =>
    createSelector(selectMultiTenantSearchAndAddDomain, substate => substate.poList);

const makeSelectAnnouncerText = () =>
    createSelector(selectMultiTenantSearchAndAddDomain, substate => substate.announcerText);

export default makeSelectMultiTenantSearchAndAddDetails;

export { makeSelectTenant, makeSelectPOs, makeSelectIsPOLoading, makeSelectPurchaseOrdersDropDownOptions, makeSelectSelectedPurchaseOrdersData, makeSelectSelectPO, makeSelectPOList, makeSelectPurchaseOrderList, makeSelectMTSearchPOError, makeSelectAnnouncerText };
