export const SUBMIT_MULTI_TENANT_FORM = "MultiTenantRequest/SUBMIT_MULTI_TENANT_FORM";
export const CLOSE_SUBMIT_CONFIRMATION = "MultiTenantRequest/CLOSE_SUBMIT_CONFIRMATION";
export const SUBMIT_MULTI_TENANT_REQUEST = "MultiTenantRequest/SUBMIT_MULTI_TENANT_REQUEST";
export const SUBMIT_RETURN_FAIL = "MultiTenantRequest/SUBMIT_RETURN_FAIL";
export const SUBMIT_RETURN_SUCCESS = "MultiTenantRequest/SUBMIT_RETURN_SUCCESS";
export const SUBMIT_MT_ApprovalRequest = "MultiTenantRequest/SUBMIT_MT_ApprovalRequest";
export const SUBMIT_MT_ApprovalRequest_SUCCESS = "MultiTenantRequest/SUBMIT_MT_ApprovalRequest_SUCCESS";
export const SUBMIT_MT_ApprovalRequest_FAIL = "MultiTenantRequest/SUBMIT_MT_ApprovalRequest_FAIL";
export const Proceed_MT_ApprovalRequest = "MultiTenantRequest/Proceed_MT_ApprovalRequest";
export const CHANGE_MT_TRACKING_ID = "MultiTenantRequest/CHANGE_MT_TRACKING_ID";
export const CHANGE_MT_COSMIC_CASENUMBER = "MultiTenantRequest/CHANGE_MT_COSMIC_CASENUMBER";

export const Close_Dialog = "FbcApprovalRequest/Close_Dialog";

export const DEFAULT_ACTION = "FbcApprovalRequest/DEFAULT_ACTION";

