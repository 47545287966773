import { REALLOCATION_MODAL_DETAILS_CHANGED, PART_NUMBER_SELECTED, CURRENT_REALLOCATION_MODAL_DETAILS_CHANGED, CURRENT_CP_REALLOCATION_MODAL_DETAILS_CHANGED, UPDATE_REALLOCATION_BODY} from './constants';

export function onAddReallocate(data) {
    return {
        type: REALLOCATION_MODAL_DETAILS_CHANGED,
        data
    }
}

export function partNumberSelected(value) {
    return { type: PART_NUMBER_SELECTED, value };
}


export function currentValueChanged(value) {
    return { type: CURRENT_REALLOCATION_MODAL_DETAILS_CHANGED, value };
}

export function currentCPValueChanged(value) {
    return { type: CURRENT_CP_REALLOCATION_MODAL_DETAILS_CHANGED, value };
}

export function updateReallocationBody(value) {
    return { type: UPDATE_REALLOCATION_BODY, value };

}