import { createGlobalStyle } from 'styled-components';

export const customReactSelectStyles = {
	placeholder: (defaultStyles) => {
		return {
			...defaultStyles,
			color: '#727272',
		}
	},
	singleValue: (defaultStyles, state) => {
		if (state.isDisabled) {
			return { ...defaultStyles, color: '#6e6e6e' }
		} else {
			return { ...defaultStyles }
		}
	},
	option: (defaultStyles, state) => {
		if(state.isFocused || state.isSelected ) {
			return {...defaultStyles, backgroundColor: '#289DE0', color: '#FFFFFF'}
		}
		return {...defaultStyles};
	},
	dropdownIndicator: base => ({
		...base,
		color: '#727272'
	}),

	loadingIndicator: (defaultStyles) => {
		return {...defaultStyles, color: '#289DE0'};

	}
}

const GlobalStyle = createGlobalStyle`
	.modal.left .modal-dialog,
	.modal.right .modal-dialog {
		position: fixed;
		margin: auto;
		width: 80%;
		height: 100%;
		-webkit-transform: translate3d(0%, 0, 0);
		    -ms-transform: translate3d(0%, 0, 0);
		     -o-transform: translate3d(0%, 0, 0);
		        transform: translate3d(0%, 0, 0);
	}

	.modal.left .modal-content,
	.modal.right .modal-content {
		height: 100%;
		overflow-y: auto;
	}
	
	.modal.left .modal-body,
	.modal.right .modal-body {
		padding: 15px 15px 140px;
	}

/*Left*/
	.modal.left.fade .modal-dialog{
		left: -320px;
		-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, left 0.3s ease-out;
		        transition: opacity 0.3s linear, left 0.3s ease-out;
	}
	
	.modal.left.fade.in .modal-dialog{
		left: 0;
	}
        
/*Right*/
	.modal.right.fade .modal-dialog {
		right: -320px;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
	}
	
	.modal.right.fade.in .modal-dialog {
		right: 0;
	}

/* ----- MODAL STYLE ----- */
	.modal-content {
		border-radius: 0;
		border: none;
	}

	.modal-header {
		border-bottom-color: #EEEEEE;
		background-color: #FAFAFA;
	}


    html,
    body {
        height: 100%;
        width: 100%;
    }

    body {
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    .onedash-side-navigation.is-open {
        width: 235px
    }

    .whiteFocus:focus {
        border: 3px  #D3D3D3 dotted;
        outline: 3px  #D3D3D3 dotted;
        outline-offset: -2px;
    }
	
	.blackFocus:focus {
		outline: black solid 1px !important;
	}

	.blackFocusOffset:focus {
		outline: black solid 2px !important;
		outline-offset: 2px;
	}

	.sectionHeader:focus-within{         
        outline: 2px  #939393 dotted !important;
        outline-offset: 15px;
    }

//     a:not(.hide-focus):focus {
//        border: 2px  #D3D3D3 dotted !important;
//        outline: 2px  #D3D3D3 dotted !important;
//        outline-offset: -2px;
//    }

    a:not(.hide-focus):focus{
            border-left: 1px dotted #D3D3D3 !important;;        
            border-bottom: 1px dotted #D3D3D3 !important;;
            border-top: 1px dotted #D3D3D3 !important;;
            border-radius: 0px 0px 5px 5px !important;;
            //padding-left: 100% !important;
            //padding-bottom: 2% !important;
    }

    h1 {
        font-size: 26px;
    }

    h2 {
        font-size: 16px;
    }
    .linkband ul li a {
        max-width : 300px !important;
    }

	@media screen and (forced-colors: active) {
		
		.react-select__option--is-focused {
			forced-color-adjust: none;
		}

		.react-select__menu {
			forced-color-adjust: none;
		}
		
		.react-select__loading-indicator {
			forced-color-adjust: none;
		}

		.react-select__control--is-focused {
			forced-color-adjust: none;
		}
	 }
}`;

export default GlobalStyle;
