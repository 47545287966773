import { defineMessages } from 'react-intl';
export const scope = 'CancelFutureBillingSearchHome';
export default defineMessages({
    fbcPartnerRequestTitle: {
        id: `${scope}.fbcPartnerRequestTitle`,
        defaultMessage: "License Reduction Request",
    },
    fbcOpsRequestTitle: {
        id: `${scope}.fbcOpsRequestTitle`,
        defaultMessage:"Future Billing Cancellation Request",
    },
});