import produce from "immer";
import {
  SUBMIT_FBC_ApprovalRequest,
  SUBMIT_FBC_ApprovalRequest_SUCCESS,
  SUBMIT_FBC_ApprovalRequest_FAIL,
  Proceed_FBC_ApprovalRequest,
  Close_Dialog,
} from "./constants";

export const initialState = { workflow: "0" };

const fbcApprovalRequestReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SUBMIT_FBC_ApprovalRequest:
        draft.action = action.value;
        draft.isOverride = false;
        draft.loading = true;
        draft.error = false;
        draft.successResponse = false;
        break;
      case Proceed_FBC_ApprovalRequest:
        draft.isOverride = action.value;
        draft.loading = false;
        draft.error = false;
        draft.successResponse = false;
        break;
      case Close_Dialog:
        draft.isOverride = action.value;
        draft.loading = false;
        draft.error = false;
        draft.successResponse = false;
        break;
      case SUBMIT_FBC_ApprovalRequest_SUCCESS:
        draft.loading = false;
        draft.error = false;
        draft.successResponse = action.response;
        break;
      case SUBMIT_FBC_ApprovalRequest_FAIL:
        draft.error = action.error;
        draft.loading = false;
        draft.successResponse = false;
        break;
    }
  });

export default fbcApprovalRequestReducer;
