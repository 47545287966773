import produce from 'immer';
import {
    SEARCH_INVOICE_ERROR,
    SEARCH_INVOICE_SUCCESS,
    SEARCH_INVOICE
} from './constants';
import { LOCATION_CHANGE } from "react-router-redux";

export const initialState = {};

const createRequestReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SEARCH_INVOICE:
                draft.invoice = "";
                draft.invoiceNumber = action.invoiceNumber;
                draft.requestID = action.requestID;
                draft.statusCode = action.statusCode;
                draft.loading = true;
                break;
            case SEARCH_INVOICE_SUCCESS:
                draft.invoice = action.invoice;
                draft.loading = false;
                break;
            case SEARCH_INVOICE_ERROR:
                draft.error = action.error;
                draft.loading = false;
                break;
            case LOCATION_CHANGE:
                draft.loading = false;
                draft.invoice = null;
                draft.invoiceNumber = null;
                draft.requestID = null;
                draft.statusCode = null;
                draft.error = null;
                break;
        }
    });

export default createRequestReducer;
