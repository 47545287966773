import produce from 'immer';
import { FBC_LINEITEM_CHANGED, FBC_SHOWHIDE_ADJUSTED, FBC_COLUMN_CHANGED, FBC_SHOWHIDE_ADJUSTED_WITHOUT_PAGINATION, FBC_COCP_LINEITEM_CHANGED } from './constants';
import { FBC_SEARCH_INVOICE_SUCCESS, FBC_SEARCH_WITH_POAA_SUCCESS } from '../cancelFutureBillingCreateRequest/constants';
import { FBC_GET_RETURN_SUCCESS } from '../cancelFutureBillingFetchRequest/constants';
import { FBC_GET_PO_SUCCESS, FBC_GET_PO_ERROR } from '../../../operations/containers/cancelFutureBillingRequest/constants';

export const initialState = {
    hiddenColumnsList: [], lineItemDetails: {}, cocpLineItemDetails: []
};
const rebillpoTableReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case FBC_SHOWHIDE_ADJUSTED:
                draft.checked = action.checked;
                draft.isExportPDF = false;
                break;
            case FBC_SHOWHIDE_ADJUSTED_WITHOUT_PAGINATION:
                draft.isExportPDF = action.value;
                break;
            case FBC_SEARCH_WITH_POAA_SUCCESS:
                draft.checked = false;               
                draft.changelineItemDetails = [];
                draft.lineItemDetails = action && action.invoices && action.invoices.lineItemDetails;
                break;
                case FBC_GET_PO_SUCCESS:
                draft.checked = false;
                draft.changelineItemDetails = [];               
                draft.lineItemDetails = {};
                draft.lineItemDetails = action && action.invoices && action.invoices.lineItemDetails;
                break;
                case FBC_GET_PO_ERROR:            
                draft.lineItemDetails = {};
                draft.lineItemDetails = action && action.invoices && action.invoices.lineItemDetails;
                break;
            case FBC_SEARCH_INVOICE_SUCCESS:
            case FBC_GET_RETURN_SUCCESS:
                draft.lineItemDetails = {};
                draft.lineItemDetails = action && action.invoice && action.invoice.lineItemDetails;
                break;
            case FBC_LINEITEM_CHANGED:
                var index = draft.lineItemDetails
                    .findIndex(x => x.salesOrderLineItemId == action.lineItem.salesOrderLineItemId);
                if (index >= 0){
                    draft.lineItemDetails[index] = action.lineItem;
                    draft.changelineItemDetails.push(action.lineItem);
                }else{
                    draft.lineItemDetails.push(action.lineItem);
                }
                break;
            case FBC_COCP_LINEITEM_CHANGED:
                draft.cocpLineItemDetails = action.lineItem;
                break;
            case FBC_COLUMN_CHANGED:
                var index = draft.hiddenColumnsList.indexOf(action.column);
                if (!action.isShown && index < 0)
                    draft.hiddenColumnsList.push(action.column);
                if (action.isShown && index >= 0)
                    draft.hiddenColumnsList.splice(index, 1);
                break;
        }
    });

export default rebillpoTableReducer;