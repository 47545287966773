import { defineMessages } from 'react-intl';

export const scope = "MultiTenantRequest";

export default defineMessages({
    submit: {
        id: `${scope}.submit`,
        defaultMessage: 'Submit',
    },
    btnApprove: {
        id: `${scope}.btnApprove`,
        defaultMessage: 'Approve',
    },
    btnReject: {
        id: `${scope}.btnReject`,
        defaultMessage: 'Reject',
    },
    modalClose: {
        id: `${scope}.modalClose`,
        defaultMessage: 'Close',
    },
    modalRejectMessage: {
        id: `${scope}.modalRejectMessage`,
        defaultMessage: 'Request Rejected. Please communicate the partner accordingly.',
    },
    modalApproveMessage: {
        id: `${scope}.modalApproveMessage`,
        defaultMessage: 'Request Processed Successfully',
    },
    trackingId: {
        id: `${scope}.trackingId`,
        defaultMessage: 'CRM ID',
    },
   cosmicCaseNumber: {
       id: `${scope}.cosmicCaseNumber`,
        defaultMessage: 'Cosmic Case Number',
    },
    cosmicCaseErrorMessage: {
        id: `${scope}.cosmicCaseErrorMessage`,
        defaultMessage: "Please enter cosmic case number",
    },
    trackingIdErrorMessage: {
        id: `${scope}.trackingIdErrorMessage`,
        defaultMessage: "Please enter CRM ID",
    },

});
