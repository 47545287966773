
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import POSelectionTable from '../../components/poSelectionTable';
import { onAddSelectedPOs, onPOSelectionModalClose } from './actions';
import reducer from './reducer';
import {
    makeSelectIsOpen,
    makeSelectPONumber
} from './selectors';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import * as FocusTrap from 'focus-trap-react';


function POSelectionModal({
    isOpen,
    poNumber,
    onPOSelectionModalCloseClick,
    addSelectedPOs
}) {

    useInjectReducer({ key: 'poSelectionModal', reducer });

    if (isOpen) {
        return (
            
            <div>
                <FocusTrap>
                <div class="modal" id="myModal2" role="dialog" style={{ display: 'block', opacity: '1' }} tabIndex="-1">
                    <div class="modal-dialog"  style={{ width: '70%' }}>
                        <div class="modal-content" style={{ maxHeight: '95vh', overflow: 'scroll' }}>
                            <header id="onedash-header-wrapper" role="none" style={{ backgroundColor: 'white', color: 'black' }}>
                                <div class="onedash-header-title pull-left">
                                    <span class="onedash-header-title-text" aria-label="PO Selection | PO Number">PO Selection | {poNumber}</span>
                                </div>
                                <div class="pull-right" id="dvProfilePopUp">
                                        <button type="button" class="btn btn-primary close" data-dismiss="modal" aria-label="Close" onClick={onPOSelectionModalCloseClick}>
                                            <span aria-hidden="true" >&times;</span>
                                        </button>
                                </div>
                            </header>
                            <div class="modal-body" style={{ maxHeight: '90vh' }}>
                                <div class="col-md-24">
                                    <POSelectionTable />
                                </div>
                                <div  style={{ textAlign: 'right' }}>
                                        <button class="btn btn-primary" style={{width: '200px' }} onClick={addSelectedPOs}><FormattedMessage {...messages.addBtn} /> </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </FocusTrap>
            </div >
        );
    }
    return null;
}

POSelectionModal.propTypes = {
    isOpen: PropTypes.bool,
    poNumber: PropTypes.string
};

const mapStateToProps = createStructuredSelector({
    isOpen: makeSelectIsOpen(),
    poNumber: makeSelectPONumber()
});

function mapDispatchToProps(dispatch) {
    return {
        onPOSelectionModalCloseClick: evt => {
            if (evt !== undefined) {
                return dispatch(onPOSelectionModalClose());
            }
        },
        addSelectedPOs: evt => {
            if (evt !== undefined) {
                return dispatch(onAddSelectedPOs());
            }
        }

    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(POSelectionModal);