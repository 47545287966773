const exportToExcelColumns = [
    {
        dataField: "uniqueId",
        text: "Request ID",
        title: true,
        headerTitle: true,
    },
    {
        dataField: "requestSubmitDate",
        text: "Request Date",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "requestStatusCode",
        text: "Request Status",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: 'agreementName',
        text: "Lead Enrollment Name",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true
    },
    {
        dataField: "agreementNumber",
        text: "Lead Enrollment Number",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "submitUserEmail",
        text: "Submitted User Email",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "sourceTenantAgreementNumber",
        text: "Source Tenant Agreement Number",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "programCode",
        text: "Program Code",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "salesLocationCode",
        text: "Sales Location Code",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "organizationPCNs",
        text: "Organization PCNs",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "externalIdentifier",
        text: "External Identifier",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "userRole",
        text: "User Role",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "rejectionReason",
        text: "Rejection Reason",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "billingDocumentNbr",
        text: "Billing Document NBR",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "cosmicCases",
        text: "Cosmic Case",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "transactionId",
        text: "Transaction Id",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "purchaseOrderNumber",
        text: "Purchase Order Number",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "agreementNumber",
        text: "Agreement Number",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "externalIdentifier",
        text: "External Identifier",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "transactionType",
        text: "Transaction Type",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "transactionStatus",
        text: "Transaction Status",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "sourceTenant",
        text: "Source Tenant",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "invoiceStatusTimestamp",
        text: "Invoice Status Timestamp",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "paidInvoices",
        text: "Paid Invoices",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "unpaidInvoices",
        text: "Unpaid Invoices",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    },
    {
        dataField: "invalidInvoices",
        text: "Invalid Invoices",
        title: (cell, row, rowIndex, colIndex) => `${cell}`,
        headerTitle: true,
    }
];

export default exportToExcelColumns;