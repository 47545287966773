import {
  CHANGE_ENROLLMENT_NUMBER,
  CHANGE_SYSTEM_PO_NUMBER,
    SEARCH_MULTI_INVOICE,
    CLEAR_ENROLLMENT_NUMBER,
    CLEAR_SYSTEM_PO_NUMBER,
    SEARCH_MULTI_INVOICE_SUCCESS,
    SEARCH_MULTI_INVOICE_ERROR,
    CHANGE_COSMIC_CASENUMBER,
    CHANGE_BILLING_DOCUMENT_NUMBER
} from "./constants";

export function search() {
  return {
      type: SEARCH_MULTI_INVOICE
  };
}

export function clearEnrollmentNumber() {
  return {
    type: CLEAR_ENROLLMENT_NUMBER
  };
}

export function clearSystemPONumber() {
    return {
        type: CLEAR_SYSTEM_PO_NUMBER
    };
}

export function searchCompleted(invoices, searchObject) {
  return {
      type: SEARCH_MULTI_INVOICE_SUCCESS,
    invoices,
    searchObject
  };
}

export function searchError(error) {
  return {
      type: SEARCH_MULTI_INVOICE_ERROR,
    error
  };
}

export function changeEnrollmentNumber(enrollmentNumber) {
  return {
    type: CHANGE_ENROLLMENT_NUMBER,
    enrollmentNumber
  };
}

export function changeSystemPONumber(systemPONumber) {
  return {
    type: CHANGE_SYSTEM_PO_NUMBER,
    systemPONumber
  };
}

export function changeManualCosmicCaseNumber(value) {
    return {
        type: CHANGE_COSMIC_CASENUMBER,
        value
    };
}

export function changeBillingDocumentNbr(billingDocumentNbr) {
    return {
        type: CHANGE_BILLING_DOCUMENT_NUMBER,
        billingDocumentNbr   
    }
}

    