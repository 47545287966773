import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the returnRequest state domain
 */

const selectApprovalRequestDomain = state => state.approvalRequest || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by ReturnRequest
 */

const makeSelectApprovalRequest = () =>
  createSelector(
    selectApprovalRequestDomain,
    substate => substate,
  );
  
  const makeSelectAction = () =>
    createSelector(
      selectApprovalRequestDomain, substate =>substate.action
    );

    const makeSelectWorkflow = () =>
    createSelector(
      selectApprovalRequestDomain, substate =>substate.workflow
    );
    

    const makeL2Override = () =>
    createSelector(
      selectApprovalRequestDomain, substate =>substate.isOverride
    );


    const makeSelectSelectApprovalError = () => 
    createSelector(selectApprovalRequestDomain, substate => substate.error);

  const makeSelectSelectApprovalLoading = () =>
      createSelector(selectApprovalRequestDomain, substate => substate.loading);

  const makeSelectSelectApprovalSuccess = () =>
      createSelector(selectApprovalRequestDomain, substate => substate.successResponse);


export default makeSelectApprovalRequest;
export { selectApprovalRequestDomain,makeSelectAction,makeSelectWorkflow,
  makeSelectSelectApprovalError,makeSelectSelectApprovalLoading,makeSelectSelectApprovalSuccess,makeL2Override
};
