import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectFbcApprovalRequestDomain = (state) =>
  state.fbcApprovalRequest || initialState;

const makeSelectFbcApprovalRequest = () =>
  createSelector(selectFbcApprovalRequestDomain, (substate) => substate);

const makeSelectFbcAction = () =>
  createSelector(selectFbcApprovalRequestDomain, (substate) => substate.action);

const makeSelectFbcWorkflow = () =>
  createSelector(
    selectFbcApprovalRequestDomain,
    (substate) => substate.workflow
  );

const makeSelectSelectFbcApprovalError = () =>
  createSelector(selectFbcApprovalRequestDomain, (substate) => substate.error);

const makeSelectSelectFbcApprovalLoading = () =>
  createSelector(
    selectFbcApprovalRequestDomain,
    (substate) => substate.loading
  );

const makeSelectSelectFbcApprovalSuccess = () =>
  createSelector(
    selectFbcApprovalRequestDomain,
    (substate) => substate.successResponse
  );

export default makeSelectFbcApprovalRequest;
export {
  selectFbcApprovalRequestDomain,
  makeSelectFbcAction,
  makeSelectFbcWorkflow,
  makeSelectSelectFbcApprovalError,
  makeSelectSelectFbcApprovalLoading,
  makeSelectSelectFbcApprovalSuccess,
};
