const exportColumns=
[{
    dataField: 'requestid',
    text: "Request Id",
    title: true,
    headerTitle: true
}, {
    dataField: 'request_status',
    text: "Request Status",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'invoice_number',
    text: "Invoice Number",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, 
  {
      dataField: 'handling_fee',
      text: "Handling Fee %",
      title: (cell, row, rowIndex, colIndex) => `${cell}`,
      headerTitle: true
},
  {
    dataField: 'invoice_date',
    text: "Invoice Date",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'invoice_amount',
    text: "Invoice Amount",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'currency_code',
    text: "Currency Code",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'po_number',
    text: "PO Number",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'order_type',
    text: "Order Type",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'enrollment_number',
    text:"Enrollment Number",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'return_reason_code',
    text: "Return Reason Code",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'return_reason',
    text: "Return Reason",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'rebill_po_number',
    text: "Rebill PO Numbers",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'request_submit_date',
    text: "Request Submit Date",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'request_approval_date',
    text: "Request Approval Date",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
},  {
    dataField: 'request_approval_status',
    text: "Request Approval Status",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'approver_contact',
    text: "Approver Contact",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'approval_or_rejection_comments',
    text: "Approver/Rejection Comments",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'customer_pcn',
    text: "Customer PCN",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'customer_name',
    text: "Customer Name",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'partner_pcn',
    text: "Partner PCN",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'partner_name',
    text: "Partner Name",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
},  {
    dataField: 'reseller_pcn',
    text: "Reseller PCN",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
}, {
    dataField: 'reseller_name',
    text: "Reseller Name",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
},  {
    dataField: 'program_code',
    text: "Program Code",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
   headerTitle: true
},  {
    dataField: 'country_code',
    text: "Country Code",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
},  {
    dataField: 'cosmic_case',
    text: "COSMIC Case",
    title: (cell, row, rowIndex, colIndex) => `${cell}`,
    headerTitle: true
},
];

export default exportColumns;

