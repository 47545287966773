import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import LoadingIndicator from '../../../../common/components/LoadingIndicator';
import SubmitErrors from '../../components/SubmitErrors';
import MultiTenantRequest from '../multiTenantRequest';
import messages from './messages';
import { injectIntl } from "react-intl";


function MultiTenantSearchHome({
    props,
    loading
}) {
    window.vlcredits.FeatureName = "MT";
    return (
        <div id="CancelFutureBillingRequestHome" className="spacer-18-top">
            <div className="row">
                <div className="col-md-24 spacer-20-top" >
                    {<SubmitErrors />}


                </div>
            </div>
            <div className="row">
                <div className="col-md-24 spacer-32-top" >
                    <h1 className="section-title">
                        <FormattedMessage {...messages.reallocateLicenses} />
                    </h1>
                    <div className="col-sm-8 col-md-8 col-lg-5">
                        <div className={!loading ? 'hidden' : ''}>
                            <LoadingIndicator />
                        </div>
                    </div>
                </div>
            </div>
            {<MultiTenantRequest props={props} isSubmit={true} isReadOnly={true} />}
        </div>
    );
}

MultiTenantSearchHome.propTypes = {
    loading: PropTypes.bool,

};

const mapStateToProps = createStructuredSelector({

});

function mapDispatchToProps(dispatch) {
    return {

    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(injectIntl(MultiTenantSearchHome));
