import produce from 'immer';
import { SEARCH_INVOICE_SUCCESS, SEARCH_INVOICE } from '../../../common/components/createRequest/constants';
import { GET_RETURN_SUCCESS } from '../../../common/components/fetchReturnRequest/constants';
import {
    SUBMIT_RETURN,
    SUBMIT_RETURN_SUCCESS,
    SUBMIT_RETURN_FAIL,
    TOGGLE_ACTIONS,
    SAVE_RETURN,
    DELETE_DRAFT,
    SAVE_RETURN_SUCCESS,
    DELETE_DRAFT_SUCCESS,
    SAVE_RETURN_FAIL,
    DELETE_DRAFT_FAIL,
    E_RECEIPT_SUCCESS,
    SHOW_REBILL_CONFIRMATION,
    CLOSE_REBILL_CONFIRMATION
} from './constants';

export const initialState = { isActionsOpen: false };

const returnFormReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SEARCH_INVOICE_SUCCESS:
            case GET_RETURN_SUCCESS:
                draft.requestStatusCode = action.invoice.requestStatusCode;
                break;
            case SEARCH_INVOICE:
                draft.error = false;
                break;
            case E_RECEIPT_SUCCESS:
                draft.eReceiptStatus = action.response;
                break;
            case SUBMIT_RETURN:
                draft.showRebillConfirmation = false;
                draft.loading = true;
                draft.deleteError = false;
                draft.error = false;
                draft.saveError = false;
                draft.successResponse = false;
                break;
            case SUBMIT_RETURN_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.successResponse = action.response;
                break;
            case SUBMIT_RETURN_FAIL:
                draft.error = action.error;
                draft.loading = false;
                draft.successResponse = false;
                break;
            case SAVE_RETURN:
                draft.loading = true;
                draft.deleteError = false;
                draft.error = false;
                draft.saveError = false;
                draft.saveSuccessResponse = false;
                break;
            case SAVE_RETURN_SUCCESS:
                draft.loading = false;
                draft.saveError = false;
                draft.saveSuccessResponse = action.response;
                break;
            case SAVE_RETURN_FAIL:
                draft.saveError = action.error;
                draft.loading = false;
                draft.saveSuccessResponse = false;
                break;
            case DELETE_DRAFT:
                draft.loading = true;
                draft.deleteError = false;
                draft.error = false;
                draft.saveError = false;
                draft.deleteSuccessResponse = false;
                break;
            case DELETE_DRAFT_SUCCESS:
                draft.loading = false;
                draft.deleteError = false;
                draft.deleteSuccessResponse = action.response;
                break;
            case DELETE_DRAFT_FAIL:
                draft.deleteError = action.error;
                draft.loading = false;
                draft.deleteSuccessResponse = false;
                break;
            case TOGGLE_ACTIONS:
                draft.isActionsOpen = !draft.isActionsOpen;
                break;
            case SHOW_REBILL_CONFIRMATION:
                draft.showRebillConfirmation = true;
                break;
            case CLOSE_REBILL_CONFIRMATION:
                draft.showRebillConfirmation = false;
                break;
        }
    });

export default returnFormReducer;
