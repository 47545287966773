import { defineMessages } from 'react-intl';

export const scope = 'errorMessages';

export default defineMessages({
    20004: {
        id: `${scope}.20004`,
        defaultMessage: 'Invalid restock fee. Please check again.'
    },
    20083: {
        id: `${scope}.20083`,
        defaultMessage: 'Invalid restock fee for CPS Orders. Please check again.'
    },
    20007: {
        id: `${scope}.20007`,
        defaultMessage: 'Please attach ROC Director Approval to continue with this adjustment.'
    },
    20029: {
        id: `${scope}.20029`,
        defaultMessage: 'Please specify Empowerment ID to change the restock fee.'
    },
    20031: {
        id: `${scope}.20031`,
        defaultMessage: 'Order cannot be adjusted. Please select a valid Return Reason as this order was originally created on a Microsoft Customer/Channel Price Sheet (CPS).'
    },
    20032: {
        id: `${scope}.20032`,
        defaultMessage: 'Invalid Remaining quantity.'
    },
    20033: {
        id: `${scope}.20033`,
        defaultMessage: 'Invalid Adjusted price.'
    },
    21035: {
        id: `${scope}.21035`,
        defaultMessage: 'Cannot add rebill po/invoice belonging to the credit order.'
    },
    20038: {
        id: `${scope}.20038`,
        defaultMessage: 'No lineitems were adjusted in partial credit request.'
    },
    20040: {
        id: `${scope}.20040`,
        defaultMessage: 'Draft doesnt exist.'
    },
    20043: {
        id: `${scope}.20043`,
        defaultMessage: 'InvalidApprovalLevel.'
    },
    20051: {
        id: `${scope}.20051`,
        defaultMessage: 'Invalid restock fee.'
    },
    20074: {
        id: `${scope}.20074`,
        defaultMessage: 'Invalid restock fee for Direct Agreement.'
    },
    20072: {
        id: `${scope}.20072`,
        defaultMessage: 'Invoice payment is due before adjustment is possible. Please pay all relevant invoices before adjusting the order.'
    },
    20090: {
        id: `${scope}.20090`,
        defaultMessage: 'This request is not supported or you are not authorized to place this request.'
    },
    21010: {
        id: `${scope}.21010`,
        defaultMessage: 'This request cannot be submitted because an existing order adjustment request for this invoice is already in draft or review status.'
    },
    21011: {
        id: `${scope}.21011`,
        defaultMessage: 'Invoice not found.'
    },
    21012: {
        id: `${scope}.21012`,
        defaultMessage: 'Invoice/System PO not found.'
    },
    21014: {
        id: `${scope}.21014`,
        defaultMessage: 'System error. Please try after sometime.( 4x series code).'
    },
    21015: {
        id: `${scope}.21015`,
        defaultMessage: 'No rebill purchase order found.'
    },
    21016: {
        id: `${scope}.21016`,
        defaultMessage: 'Both credit invoice and rebill invoice cannot be same.'
    },
    21018: {
        id: `${scope}.21018`,
        defaultMessage: 'You are not authorized to view selected enrollment details.'
    },
    561040: {
        id: `${scope}.561040`,
        defaultMessage: ' No REF*AH enrollment number found '
    },
    561551: {
        id: `${scope}.561551`,
        defaultMessage: ' This order cannot be cancelled.Please check whether it is already cancelled or retry after some time. '
    },
    561186: {
        id: `${scope}.561186`,
        defaultMessage: ' The PO cannot be accepted because the line count supplied in the EDI 850 transaction does not agree with the detail items '
    },
    50028: {
        id: `${scope}.50028`,
        defaultMessage: 'POLI ExternalIdentifier cannot be null '
    },
    50031: {
        id: `${scope}.50031`,
        defaultMessage: 'PurchaseorderLineItem ExternalIdentifier does not exist for the given PurchaseOrder '
    },
    50033: {
        id: `${scope}.50033`,
        defaultMessage: 'The order which is being adjusted is not in Accepted state '
    },
    561126: {
        id: `${scope}.561126`,
        defaultMessage: 'Purchase Order Usage Period Date is Missing.'
    },
    568021: {
        id: `${scope}.568021`,
        defaultMessage: ' Invoice payment is due before adjustment is possible. Please pay all relevant invoices before adjusting the order.'
    },
    50040: {
        id: `${scope}.50040`,
        defaultMessage: 'All PO/POLI external identifier should be unique.'
    },
    50069: {
        id: `${scope}.50069`,
        defaultMessage: 'Quantity can not be less than zero or more than the original quantity '
    },
    610005: {
        id: `${scope}.610005`,
        defaultMessage: ' Purchase Order number should not be null or empty '
    },
    610006: {
        id: `${scope}.610006`,
        defaultMessage: ' Length of the Purchase Order Number cannot be more than 30 characters '
    },
    50010: {
        id: `${scope}.50010`,
        defaultMessage: 'SourceSystem is Null/Empty.'
    },
    50011: {
        id: `${scope}.50011`,
        defaultMessage: 'InvoiceTypeCode is invalid.'
    },
    50013: {
        id: `${scope}.50013`,
        defaultMessage: 'Agreement Number is either invalid or not in active status '
    },
    50014: {
        id: `${scope}.50014`,
        defaultMessage: 'Currency Code does not match with Agreement.'
    },
    50024: {
        id: `${scope}.50024`,
        defaultMessage: 'Ordered Quantity cannot be 2.'
    },
    50025: {
        id: `${scope}.50025`,
        defaultMessage: 'POLI\'s UsageDate or CoverageStartDate cannot be null.'
    },
    50029: {
        id: `${scope}.50029`,
        defaultMessage: 'Program Code does not match with Agreement.'
    },
    50030: {
        id: `${scope}.50030`,
        defaultMessage: 'Authentication failed for User OrgGuid.'
    },
    50041: {
        id: `${scope}.50041`,
        defaultMessage: 'Quantity Ordered for POLI is not equal to total Quantity Consumed.'
    },
    50072: {
        id: `${scope}.50072`,
        defaultMessage: 'AgreementNumber passed in the request is not matching with AgreementNumber in the PO '
    },
    50070: {
        id: `${scope}.50070`,
        defaultMessage: 'POLI Coverage should match with atleast one of the SOLI Coverage or InvoiceNumber should not be null '
    },
    50071: {
        id: `${scope}.50071`,
        defaultMessage: '04 order is in HRW/HPN State '
    },
    50074: {
        id: `${scope}.20075`,
        defaultMessage: 'This order cannot be cancelled.Please check whether it is already cancelled or retry after some time.'
    },
    20075: {
        id: `${scope}.20075`,
        defaultMessage: 'Order cannot be adjusted because it contains Microsoft Azure Consumption Commitment (MACC) SKU. Please submit a revised CPS/amendment.'
    },
    20076: {
        id: `${scope}.20076`,
        defaultMessage: 'MACC SKU quantity cannot be partially adjusted. For full credit, please use quantity zero.'
    },
    20077: {
        id: `${scope}.20077`,
        defaultMessage: 'This credit request involves Restock Fee and Microsoft Azure Consumption Commitment (MACC), please follow manual process.'
    },
    20078: {
        id: `${scope}.20078`,
        defaultMessage: 'Microsoft Azure Consumption Commitment (MACC) credits are not supported, please follow manual process.'
    },
    20079: {
        id: `${scope}.20079`,
        defaultMessage: 'Invalid Return Reason for Sub CFO consult.'
    },
    20080: {
        id: `${scope}.20080`,
        defaultMessage: 'Please specify Empowerment ID.'
    },
    5680674: {
        id: `${scope}.5680674`,
        defaultMessage: 'This Request cannot be submitted because an existing order adjustment request for this invoice is already in draft or review status'
    },
    5680675: {
        id: `${scope}.5680675`,
        defaultMessage: 'Please submit adjustment requests for Azure products through the Azure Support Portal (https://ea.azure.com)'
    },
    5680676: {
        id: `${scope}.5680676`,
        defaultMessage: '{dynamicMsg}',
        dynamic: true
    },
    5680714: {
        id: `${scope}.5680714`,
        defaultMessage: '{dynamicMsg}',
        dynamic: true
    },
    5680715: {
        id: `${scope}.5680715`,
        defaultMessage: '{dynamicMsg}',
        dynamic: true
    },
    5680677: {
        id: `${scope}.5680677`,
        defaultMessage: 'We are unable to accept this order adjustment. Please check the order/agreement compliance or log a support(CLT) at explore.ms'
    },
    5680678: {
        id: `${scope}.5680678`,
        defaultMessage: 'This request cannot be submitted at this time, because there is an existing request for this Invoice/Enrolment/Purchase Order Number awaiting activity to be completed. Please try after some time to allow completion of current request.'
    },
    5680679: {
        id: `${scope}.5680679`,
        defaultMessage: 'The Restocking fee can have only one of two values - either 5% or 0'
    },
    5680680: {
        id: `${scope}.5680680`,
        defaultMessage: 'MACC SKU quantity cannot be partially adjusted. For full credit, please use quantity zero.'
    },
    5680681: {
        id: `${scope}.5680681`,
        defaultMessage: 'This invoice cannot be adjusted because there is subsequent invoices with later invoice date for this purchase order. Please return your newest invoice first.'
    },
    21032: {
        id: `${scope}.21032`,
        defaultMessage: 'This request cannot be submitted at this time because an existing order adjustment request for this invoice has a Credit Approval completed and is awaiting invoicing activity to complete. Please try after some time.'
    },
    20101: {
        id: `${scope}.20101`,
        defaultMessage: 'Please take note that the COSMIC case has not been created for this request as COSMIC tool is currently down '
    },
    20100: {
        id: `${scope}.20100`,
        defaultMessage: 'Order cannot be adjusted because it contains Microsoft Azure Consumption Commitment (MC) SKU. Please submit a revised CPS/amendment.'
    },
    20102: {
        id: `${scope}.20102`,
        defaultMessage: 'This credit request involves Restock Fee and Microsoft Azure Consumption Commitment (MC), please follow manual process.'
    },
    20103: {
        id: `${scope}.20103`,
        defaultMessage: 'Microsoft Azure Consumption Commitment (MC) credits are not supported, please follow manual process.'
    },
    20104: {
        id: `${scope}.20104`,
        defaultMessage: 'MC SKU quantity cannot be partially adjusted. For full credit, please use quantity zero.'
    },
    21019: {
        id: `${scope}.21019`,
        defaultMessage: 'Order cannot be adjusted because the rebill invoice provided is associated with a different program. Please remove rebill invoice to continue as a full credit with no rebill or submit a rebill invoice that corresponds to the same program.'
    },
    21027: {
        id: `${scope}.21027`,
        defaultMessage: 'Order cannot be adjusted because the rebill invoice provided belongs to a different fiscal month. Please remove rebill invoice to continue as a full credit with no rebill or submit a rebill invoice that corresponds to the same fiscal month.'
    },
    21021: {
        id: `${scope}.21021`,
        defaultMessage: 'Order cannot be adjusted because it was originally created on a Microsoft Customer/Channel Price Sheet (CPS). Please submit a revised CPS.'
    },
    20082: {
        id: `${scope}.20082`,
        defaultMessage: 'Order cannot be adjusted because it was originally created on a Microsoft Customer/Channel Price Sheet (CPS). Please submit a revised CPS.'
    },
    20055: {
        id: `${scope}.20055`,
        defaultMessage: 'Since this order adjustment request is over 60 days from invoice date. Please attach End Customer Acknowledgement Form to continue.'
    },
    20056: {
        id: `${scope}.20056`,
        defaultMessage: 'This order cannot be adjusted because it is over 180 days from invoice date.'
    },
    20057: {
        id: `${scope}.20057`,
        defaultMessage: 'Ack and Roc Director Approval Missing '
    },
    20058: {
        id: `${scope}.20058`,
        defaultMessage: 'Invalid restock fee for given Return Reason'
    },
    20059: {
        id: `${scope}.20059`,
        defaultMessage: 'Please specify Empowerment ID to change the restock fee.'
    },
    20060: {
        id: `${scope}.20060`,
        defaultMessage: 'Line item adjustment is not allowed for orders originally created on a Microsoft Customer/Channel Price Sheet (CPS). Please do a Full Credit.'
    },
    20061: {
        id: `${scope}.20061`,
        defaultMessage: 'No lineitems were adjusted in partial credit request.'
    },
    20062: {
        id: `${scope}.20062`,
        defaultMessage: 'Line item adjustment is not allowed.'
    },
    20063: {
        id: `${scope}.20063`,
        defaultMessage: 'Local invoice number not available. Please check and resubmit again.'
    },
    20064: {
        id: `${scope}.20064`,
        defaultMessage: 'Order cannot be adjusted as Price and Quantity adjustments are not allowed for a single line item.'
    },
    20065: {
        id: `${scope}.20065`,
        defaultMessage: 'Not a valid Sub CFO consult as Credit is not Cross Quarter.'
    },
    20066: {
        id: `${scope}.20066`,
        defaultMessage: 'Missing BD Approval in submitted request. '
    },
    20067: {
        id: `${scope}.20067`,
        defaultMessage: 'Quantity can not be less than zero or more than the original quantity '
        //this is when we submit with zero original quantity
    },
    20068: {
        id: `${scope}.20068`,
        defaultMessage: 'Gross amount does not qualifies for Sub CFO consult.'
    },
    20069: {
        id: `${scope}.20069`,
        defaultMessage: 'Gross amount does not qualifies for Sub CFO consult.'
    },
    20070: {
        id: `${scope}.20070`,
        defaultMessage: 'Invalid program code for Sub CFO consult.'
    },

    21028: {
        id: `${scope}.21028`,
        defaultMessage: ' The Invoice searched does not meet the requirements for this request.'
    },
    21055: {
        id: `${scope}.21055`,
        defaultMessage: ' The Invoice searched does not meet the requirements for this request.'
    },
    21033: {
        id: `${scope}.21033`,
        defaultMessage: ' Purchase order not found.'
    },
    21034: {
        id: `${scope}.21034`,
        defaultMessage: ' Please submit adjustment requests for Azure products through the Azure Support Portal (https://ea.azure.com).'
    },
    20036: {
        id: `${scope}.20036`,
        defaultMessage: ' Invalid Approver Action '
    },

    20085: {
        id: `${scope}.20085`,
        defaultMessage: ' Credit is out of policy since gross credit is above 250k USD and request is above 60 days. Please attach the required approval documentation and set "Non Ops approved" flag, if applicable.'
    },

    20086: {
        id: `${scope}.20086`,
        defaultMessage: ' This order cannot be adjusted because it is over 60 days from invoice date and gross credit amount is over $250K.'
    },
    20087: {
        id: `${scope}.20087`,
        defaultMessage: ' Invalid Rejection Reason'
    },
    20088: {
        id: `${scope}.20088`,
        defaultMessage: ' SubCFO Request Not Allowed '
    },
    //For partner EU order's future billing cancellation not allowed 
    21036: {
        id: `${scope}.21036`,
        defaultMessage: ' This function supports only Enterprise Standard (E6) Programs for PO types: TrueUp, New and BEC.'
    },
    21037: {
        id: `${scope}.21037`,
        defaultMessage: ' There are no future Billing period found in this PO. Please search another PO.'
    },
    21038: {
        id: `${scope}.21038`,
        defaultMessage: ' This function supports only Enterprise Standard (E6) Programs for PO types: TrueUp, New and BEC.'
    },
    21039: {
        id: `${scope}.21039`,
        defaultMessage: ' This function supports only Enterprise Standard (E6) Programs for PO types: TrueUp, New and BEC.'
    },
    21040: {
        id: `${scope}.21040`,
        defaultMessage: ' All PO types except Reservation, Zero Usage, Gallacake, Azure overage orders will be supported.'
    },
    21041: {
        id: `${scope}.21041`,
        defaultMessage: ' All PO types except Reservation, Zero Usage, Gallacake, Azure overage orders will be supported.'
    },
    21042: {
        id: `${scope}.21042`,
        defaultMessage: ' All PO types except Reservation, Zero Usage, Gallacake, Azure overage orders will be supported.'
    },
    21043: {
        id: `${scope}.21043`,
        defaultMessage: ' All PO types except Reservation, Zero Usage, Gallacake, Azure overage orders will be supported.'
    },
    21044: {
        id: `${scope}.21044`,
        defaultMessage: ' Search resulted in multiple POs. Please submit the request through CLT.'
    },
    21045: {
        id: `${scope}.21045`,
        defaultMessage: ' Search resulted in multiple POs. Please process the request through manual route.'
    },
    21046: {
        id: `${scope}.21046`,
        defaultMessage: 'This request cannot be submitted because an existing order adjustment is already in review status.'
    },
    21047: {
        id: `${scope}.21047`,
        defaultMessage: 'You are not authorized to view selected enrollment details (If your PO was created before the COCP activation in Direct EA, please submit your request manually).'
    },
    21048: {
        id: `${scope}.21048`,
        defaultMessage: 'Validation Failing.'
    },
    21056: {
        id: `${scope}.21056`,
        defaultMessage: "Basic Validation Failed, ProductTypeCode = CTC , MC"
    },
    20099: {
        id: `${scope}.20099`,
        defaultMessage: "Unable to submit this request as Bill on date is today or in the past. Please reject this request and use the VL Credit tool to submit a credit for the invoice once it's generated."
    },
    "21065.ILR": {
        id: `${scope}.mtInvalidLocationIndia`,
        deafaultMessage: "License reallocation self-serve is not supported for India. Please use standard process & submit request via revised CPS."
    },
    "21065.BR1": {
        id: `${scope}.mtInvalidLocationBrazil`,
        deafaultMessage: "License reallocation self-serve is not supported for Brazil. Please use standard process & submit request via revised CPS."
    },
    "21065.CHI": {
        id: `${scope}.mtInvalidLocationChina`,
        deafaultMessage: "License reallocation self-serve is not supported for China. Please use standard process & submit request via revised CPS."
    },
    "21065.JAP": {
        id: `${scope}.mtInvalidLocationJapan`,
        deafaultMessage: "License reallocation self-serve is not supported for Japan. Please use standard process & submit request via revised CPS."
    },
    "21070.98": {
        id: `${scope}.mtInvalidLocationIndia`,
        deafaultMessage: "License reallocation self-serve is not supported for India. Please use standard process & submit request via revised CPS."
    },
    "21070.57": {
        id: `${scope}.mtInvalidLocationBrazil`,
        deafaultMessage: "License reallocation self-serve is not supported for Brazil. Please use standard process & submit request via revised CPS."
    },
    "21070.22": {
        id: `${scope}.mtInvalidLocationChina`,
        deafaultMessage: "License reallocation self-serve is not supported for China. Please use standard process & submit request via revised CPS."
    },
    "21070.79": {
        id: `${scope}.mtInvalidLocationJapan`,
        deafaultMessage: "License reallocation self-serve is not supported for Japan. Please use standard process & submit request via revised CPS."
    },
    21071: {
        id: `${scope}.21071`,
        defaultMessage: "No Part Numbers eligible for reallocation"
    },
    21073: {
        id: `${scope}.21073`,
        defaultMessage: "There are no active OLS coverages for this PO. Hence reallocation is not allowed"
    },
    21067: {
        id: `${scope}.21067`,
        defaultMessage: "Reallocation request on this Lead enrolment is in process. Please try after completion of prior request."
    },
    21068: {
        id: `${scope}.21068`,
        defaultMessage: "Lead enrollment either expired or not valid or no tenant enrollments available for reallocation. Please enter valid lead enrollment."
    },
    561025: {
        id: `${scope}.561025`,
        defaultMessage: "The Transaction Version Code does match the Delivery Mechanism Code for this PO."
    },
    562710: {
        id: `${scope}.562710`,
        defaultMessage: "The Transaction Version Code is blank."
    },
    5680571: {
        id: `${scope}.5680571`,
        defaultMessage: "Purchase Order was set to pending because the same Part Number was placed more than once, Reject the duplicate line item(s) and submit."
    },
    561001: {
        id: `${scope}.561001`,
        defaultMessage: "The PO failed header validation.  It's status has been set to Pending."
    },
    561149: {
        id: `${scope}.561149`,
        defaultMessage: "Usage date indicated falls before the start date of the parent line items."
    },
    561247: {
        id: `${scope}.561247`,
        defaultMessage: "The agreement specified is currently on hold pending an amendment.  The PO has been placed on hold until the agreement amendment has been finalized."
    },
    561232: {
        id: `${scope}.561232`,
        defaultMessage: "The Master Agreement status is not active."
    },
    561233: {
        id: `${scope}.561233`,
        defaultMessage: "The Usage Period Date is before the Agreement Start Effective Date for the Agreement Number, or the Usage Period Date is invalid."
    },
    5680508: {
        id: `${scope}.5680508`,
        defaultMessage: "Target enrollment don't have any Base purchases for this Step Up reallocation."
    },
    20106: {
        id: `${scope}.20106`,
        defaultMessage: "Mandatory fields/details are missing. Please fill the required details."
    },
    5680695: {
        id: `${scope}.5680695`,
        defaultMessage: "Midterm orders are not allowed after compliance window end up to anniversary."
    },
    20107: {
        id: `${scope}.20107`,
        defaultMessage: "You are not authorized to submit the request. Please contact VL Ordering Support team."
    },
    5680507: {
        id: `${scope}.5680507`,
        defaultMessage: "Target enrollment don't have any Base purchases for this Step Up reallocation."
    },
    50015: {
        id: `${scope}.50015`,
        defaultMessage: "No Agreement with Lead Agreement Type exists."
    },
    50017: {
        id: `${scope}.50017`,
        defaultMessage: "Selected enrolment is expired. Tenant allocation cannot be proceeded."
    },
    50020: {
        id: `${scope}.50020`,
        defaultMessage: "Billing linked to selected enrolment is on credit hold. Tenant allocation cannot be proceeded, please contact Credit and Collections."
    },
    561266: {
        id: `${scope}.561266`,
        defaultMessage: "Agreement Status is either not active or not valid for reallocation."
    },
    561146: {
        id: `${scope}.561146`,
        defaultMessage: "Usage date indicated falls after the end date of the agreement specified."
    },
    563064: {
        id: `${scope}.563064`,
        defaultMessage: "The coverage period for the SKU goes beyond the agreement end date. Reallocation is not allowed."
    },
    561075: {
        id: `${scope}.561075`,
        defaultMessage: "The Bill-to-party specified is not valid for this agreement usage period or Sales Location specified."
    },
    5680673: {
        id: `${scope}.5680673`,
        defaultMessage: "The order has been put on hold pending Secondary Deal Desk review."
    },
    5680553: {
        id: `${scope}.5680553`,
        defaultMessage: "Transition is associated with a complex deal. Please contact ROC support to address any related orders."
    },
    5680532: {
        id: `${scope}.5680532`,
        defaultMessage: "Usage date indicated falls before the effective start date of the agreement specified."
    },
    5680558: {
        id: `${scope}.5680558`,
        defaultMessage: "Usage date indicated falls outside the extended period term coverage date."
    },
    5680533: {
        id: `${scope}.5680533`,
        defaultMessage: "Usage date indicated falls after the end date of the agreement specified."
    },
    5680534: {
        id: `${scope}.5680534`,
        defaultMessage: "Usage Period Date does not fall within the direct customer effective date range."
    },
    5680699: {
        id: `${scope}.5680699`,
        defaultMessage: "Usage Date should be within the current agreement year."
    },
    562520: {
        id: `${scope}.562520`,
        defaultMessage: "The requested billing option does not meet program guidelines.  Item has been changed to reflect billing options specified in reference agreement."
    },
    562430: {
        id: `${scope}.562430`,
        defaultMessage: "This SKU is a License or Immediate Shipment SKU, and billing options are only accepted with Maintenance, Upgrade Advantage, and Subscription SKU's."
    },
    562470: {
        id: `${scope}.562470`,
        defaultMessage: "Billing option is required for maintenance and subscription items.  The billing option has been set to an appropriate value."
    },
    5680565: {
        id: `${scope}.5680565`,
        defaultMessage: "Billing Option must be Prepay for the Item Product Type."
    },
    563069: {
        id: `${scope}.563069`,
        defaultMessage: "OV Platform: OS quantity must equal Office quantity."
    },
    563070: {
        id: `${scope}.563070`,
        defaultMessage: "OV Platform: Office quantity must not exceed CAL quantity."
    },
    562500: {
        id: `${scope}.562500`,
        defaultMessage: "The requested length of Maintenance or Upgrade Advantage coverage extends beyond the agreement end date.  Item has been adjusted to end of agreement."
    },
    562440: {
        id: `${scope}.562440`,
        defaultMessage: "EDI supplied billing option is not a valid code."
    },
    563059: {
        id: `${scope}.563059`,
        defaultMessage: "Billing Option must be Prepay for fee products."
    },
    562444: {
        id: `${scope}.562444`,
        defaultMessage: "The Billing Option Code for this Manual Purchase Order Line Item maintenance item is blank."
    },
    562598: {
        id: `${scope}.562598`,
        defaultMessage: "Received Date for fulfillment item must be between Agreement Start Effective Date and Agreement Resolved End Date."
    },
    5680658: {
        id: `${scope}.5680658`,
        defaultMessage: "Subscription items cannot be ordered."
    },
    563024: {
        id: `${scope}.563024`,
        defaultMessage: "Fulfillment items cannot be ordered for an inactive Agreement."
    },
    562434: {
        id: `${scope}.562434`,
        defaultMessage: "SKU is not available for the specified Ship To Country."
    },
    5680665: {
        id: `${scope}.5680665`,
        defaultMessage: "BillTo PCN is either not valid or not mapped to the Agreements Sales Location. Please work with Processing team."
    },
    5680664: {
        id: `${scope}.5680664`,
        deafaultMessage: "Either currency is not BRL or Agreement is not marked as Subsidiary. Please modify the Agreement and resubmit the order."
    },
    562260: {
        id: `${scope}.562260`,
        defaultMessage: "SKU is a maintenance or Upgrade Advantage SKU and not available under this agreement number."
    },
    562270: {
        id: `${scope}.562270`,
        defaultMessage: "The Purchase Order Line Item does not have a valid Agreement Offering as of the PO Usage Period Date."
    },
    562297: {
        id: `${scope}.562297`,
        defaultMessage: "Multiple License Pools cannot be resolved for this item."
    },
    562480: {
        id: `${scope}.562480`,
        defaultMessage: "The price has been changed to reflect the correct price.  The AMT segment contains the difference in Microsofts price minus submitted price."
    },
    562002: {
        id: `${scope}.562002`,
        defaultMessage: "The PO line item has been rejected, when only one line item specified for process. Otherwise, all line items with the PO have been rejected."
    },
    563056: {
        id: `${scope}.563056`,
        defaultMessage: "Invalid Purchase Unit Quantity."
    },
    561260: {
        id: `${scope}.561260`,
        defaultMessage: "Agreement Status is either not active or not valid for reallocation."
    },
    5680692: {
        id: `${scope}.5680692`,
        defaultMessage: "Target agreement is out of compliance for the prior year. Reallocation is not allowed."
    },
    562420: {
        id: `${scope}.562420`,
        defaultMessage: "Part number is either of Extended Coverage or not eligible for reallocation."
    },
    561262: {
        id: `${scope}.561262`,
        defaultMessage: "Agreement Status is either not active or not valid for reallocation."
    },
    20105: {
        id: `${scope}.20105`,
        defaultMessage: "Custom Acknowledgement form required for request submission"
    },
    561148: {
        id: `${scope}.561148`,
        defaultMessage: "Usage Period Date is more than one month beyond the Purchase Order received date"
    },
    5680573: {
        id: `${scope}.5680573`,
        defaultMessage: "Ship To information is required for this Sales Location"
    },
    duplicateRebillPO: {
        id: `${scope}.duplicateRebillPO`,
        defaultMessage: 'A record already exists with matching identifier in Rebill details.'
    },
    approveMsg: {
        id: `${scope}.approveMsg`,
        defaultMessage: ' There is an error with the approval request. Please contact ECIT Orders Help [orderhlp@microsoft.com].'
    },
    opsMsg: {
        id: `${scope}.opsMsg`,
        defaultMessage: ' There is an error in processing the order adjustment request. Please try after some time or contact OrderSupport OrderL3@microsoft.com.'
    },
    notFoundMsg: {
        id: `${scope}.notFoundMsg`,
        defaultMessage: 'The server could not find what was requested.'
    },
    partnerMsg: {
        id: `${scope}.partnerMsg`,
        defaultMessage: ' There is an error in processing the order adjustment request. Please try after some time or log a CLT.'
    },
    subCFOMsg: {
        id: `${scope}.subCFOMsg`,
        defaultMessage: ' The Invoice searched does not meet the requirements for Subsidiary CFO consult.'
    },
    tokenExpiredMsg: {
        id: `${scope}.tokenExpiredMsg`,
        defaultMessage: ' Your session has timed out. Please refresh the browser to continue.'
    }
});
