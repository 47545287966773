import {
    SUBMIT_RETURN,
    SUBMIT_RETURN_SUCCESS,
    SUBMIT_RETURN_FAIL,
    SAVE_RETURN,
    SAVE_RETURN_SUCCESS,
    SAVE_RETURN_FAIL,
    DELETE_DRAFT,
    DELETE_DRAFT_SUCCESS,
    DELETE_DRAFT_FAIL,
    EXPORT_PDF,
    SHOW_REBILL_CONFIRMATION,
    CLOSE_REBILL_CONFIRMATION,
    E_RECEIPT_SUCCESS,
    E_RECEIPT_FAIL,
    TOGGLE_ACTIONS,
    CLOSE_RESTOCK_FEE_CONFIRMATION,
    VALIDATE_SUBMIT_FORM,
    SHOW_RESTOCK_FEE_CONFIRMATION
} from "./constants";

export function submitMultiInvoiceReturnForm() {
    return { type: SUBMIT_RETURN };
}

export function submitReturnFormSuccess(response) {
    return { type: SUBMIT_RETURN_SUCCESS, response };
}

export function submitReturnFormFailure(error) {
    return { type: SUBMIT_RETURN_FAIL, error };
}

export function deleteDraft() {
    return { type: DELETE_DRAFT };
}

export function deleteDraftSuccess(response) {
    return { type: DELETE_DRAFT_SUCCESS, response };
}

export function deleteDraftFailure(error) {
    return { type: DELETE_DRAFT_FAIL, error };
}

export function toggleActions() {
    return { type: TOGGLE_ACTIONS };
}

export function saveReturnFormSuccess(response) {
    return { type: SAVE_RETURN_SUCCESS, response };
}

export function saveReturnFormFailure(error) {
    return { type: SAVE_RETURN_FAIL, error };
}

export function eReceiptSuccess(response) {
    return { type: E_RECEIPT_SUCCESS, response };
}

export function eReceiptFailure(error) {
    return { type: E_RECEIPT_FAIL, error };
}

export function exportPDF() {
    return { type: EXPORT_PDF };
}

export function saveReturnForm() {
    return { type: SAVE_RETURN };
}

export function showMultiInvoiceRestockFeeConfirmation() {
    return {
        type: SHOW_RESTOCK_FEE_CONFIRMATION
    }
}

export function closeMultiInvoiceRestockFeeConfirmation() {
    return {
        type: CLOSE_RESTOCK_FEE_CONFIRMATION
    }
}

export function closeMultiInvoiceShowRebillConfirmation() {
    return {
        type: CLOSE_REBILL_CONFIRMATION
    }
}

export function showMultiInvoiceShowRebillConfirmation() {
    return {
        type: SHOW_REBILL_CONFIRMATION
    }
}

export function validateSubmitForm() {
    return { type: VALIDATE_SUBMIT_FORM };
}
