import { defineMessages } from 'react-intl';

export const scope = 'submitInfo';

export default defineMessages({
    readOnlyInfoMessage: {
        id: `${scope}.readOnlyInfoMessage`,
        defaultMessage: 'Showing Readonly Screen',
    },
    readOnlyMessage: {
        id: `${scope}.readOnlyMessage`,
        defaultMessage: 'Showing credit request in read only mode.',
    },
    userReadOnlyMessage: {
        id: `${scope}.userReadOnlyMessage`,
        defaultMessage: 'Logged in user has read only access to this Request.',
    },
    rocInfoMessage: {
        id: `${scope}.rocInfoMessage`,
        defaultMessage: 'ROC Override Update',
    },
    workAccountMapped: {
        id: `${scope}.workAccountMapped`,
        defaultMessage: 'Please mapped your account to work account.',
    },
    workAccountTitle: {
        id: `${scope}.workAccountTitle`,
        defaultMessage: 'Work account migration status:',
    },
    workAccountMappedLinked: {
        id: `${scope}.workAccountMappedLinked`,
        defaultMessage: 'Use Following Accounts for login.',
    },
    workAccountTitleLinked: {
        id: `${scope}.workAccountTitleLinked`,
        defaultMessage: 'Use Following Accounts for login:',
    }
});
