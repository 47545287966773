import produce from "immer";
import {
    FBC_CHANGE_ENROLLMENT_NUMBER,
    FBC_CHANGE_SYSTEM_PO_NUMBER,
    FBC_SEARCH_WITH_POAA,
    FBC_CLEAR_ENROLLMENT_NUMBER,
    FBC_CLEAR_SYSTEM_PO_NUMBER,
    FBC_SEARCH_WITH_POAA_SUCCESS,
    FBC_SEARCH_WITH_POAA_ERROR,
    FBC_CHANGE_COSMIC_CASENUMBER,
    FBC_CHANGE_BILLING_DOCUMENT_NUMBER
} from "./constants";
import { FBC_GET_PO_SUCCESS, FBC_GET_PO_ERROR } from '../../../operations/containers/cancelFutureBillingRequest/constants';

export const initialState = {};

const CancelFutureBillingCreateRequestReducer = (state = initialState, action) =>
    produce(state, draft => {

        console.log(action.type);
        switch (action.type) {
           
            case FBC_CHANGE_ENROLLMENT_NUMBER:
                draft.enrollmentNumber = action.enrollmentNumber;
                break;
            case FBC_CHANGE_SYSTEM_PO_NUMBER:
                draft.systemPONumber = action.systemPONumber;
                break;
            case FBC_CHANGE_COSMIC_CASENUMBER:
                draft.invoices.cosmicCaseNumber = action.value;
                break;
            case FBC_CHANGE_BILLING_DOCUMENT_NUMBER:
                draft.invoices.invoiceNumber = action.billingDocumentNbr;
                break;
            case FBC_SEARCH_WITH_POAA:
                draft.invoices = {};
                draft.loading = true;
                draft.error = false;
                break;
            case FBC_CLEAR_ENROLLMENT_NUMBER:
                draft.enrollmentNumber = "";
                draft.loading = false;
                draft.error = false;
                break;
            case FBC_CLEAR_SYSTEM_PO_NUMBER:
                draft.systemPONumber = "";
                draft.loading = false;
                draft.error = false;
                break;
            case FBC_SEARCH_WITH_POAA_SUCCESS:
                draft.enrollmentNumber = action.searchObject.enrollmentNumber;
                draft.systemPONumber = action.searchObject.systemPONumber;
                draft.invoices = action.invoices;
                if(action && action.invoices && action.invoices.length > 0){
                    draft.isSelectPo = false;
                }else {
                    draft.isSelectPo = true;
                }
                draft.loading = false;
                draft.error = false;
                break;
            case FBC_SEARCH_WITH_POAA_ERROR:
                draft.error = action.error;
                draft.isSelectPo = false;
                draft.loading = false;
                break;
                case FBC_GET_PO_SUCCESS:
                draft.isSelectPo = true;
                break;
                case FBC_GET_PO_ERROR:
                draft.isSelectPo = false;
                break;
        }
    });

export default CancelFutureBillingCreateRequestReducer;
