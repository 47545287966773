export const SUBMIT_MultiInvoiceApprovalRequest = 'ApprovalMultiInvoiceRequest/SUBMIT_ApprovalRequest';
export const SUBMIT_MultiInvoiceApprovalRequest_SUCCESS = 'ApprovalMultiInvoiceRequest/SUBMIT_ApprovalRequest_SUCCESS';
export const SUBMIT_MultiInvoiceApprovalRequest_FAIL = 'ApprovalMultiInvoiceRequest/SUBMIT_ApprovalRequest_FAIL';

export const Reject_MultiInvoiceApprovalRequest = 'ApprovalMultiInvoiceRequest/Reject_ApprovalRequest';

export const Reject_MultiInvoiceApprovalReset = 'ApprovalMultiInvoiceRequest/Reject_ApprovalReset';
export const Procced_MultiInvoiceApprovalRequest = 'ApprovalMultiInvoiceRequest/Procced_ApprovalRequest';
export const Close_Dialog = 'ApprovalMultiInvoiceRequest/Close_Dialog';



export const DEFAULT_ACTION = 'ApprovalMultiInvoiceRequest/DEFAULT_ACTION';


