import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectRoot = state => state;

const selectCancelFutureBillingReturnFormDomain = state => state.CancelFutureBillingReturnForm || initialState;

const makeCancelFutureBillingReturnForm = () => createSelector(selectCancelFutureBillingReturnFormDomain, substate => substate);

const makeCancelFutureBillingReturnFormReadOnly = () => createSelector(selectRoot, globalState => {
    if (globalState.CancelFutureBillingFetchRequest && globalState.CancelFutureBillingFetchRequest.invoices)
        return globalState.CancelFutureBillingFetchRequest.invoices.isReadOnly
            || globalState.CancelFutureBillingFetchRequest.invoices.isUserReadOnly;

    if (globalState.CancelFutureBillingCreateRequest && globalState.CancelFutureBillingCreateRequest.invoices)
        return globalState.CancelFutureBillingCreateRequest.invoices.isReadOnly
            || globalState.CancelFutureBillingCreateRequest.invoices.isUserReadOnly;

    return null;
});

const makeCancelFutureBillingReturnFormError = () => createSelector(selectCancelFutureBillingReturnFormDomain, substate => substate.error);

const makeCancelFutureBillingReturnFormLoading = () => createSelector(selectCancelFutureBillingReturnFormDomain, substate => substate.loading);

const makeCancelFutureBillingReturnFormSuccess = () => createSelector(selectCancelFutureBillingReturnFormDomain, substate => substate.successResponse);

const makeCancelFutureBillingReturnFormIsActionOpen = () => createSelector(selectCancelFutureBillingReturnFormDomain, substate => substate.isActionsOpen);

const makeSelectFBCReturnReason = () =>
    createSelector(
        selectRoot,
        globalState => {
            const {
                cfbReturnDetails: {
                    returnReason: returnReasonCode
                }
            } = globalState;
            return returnReasonCode
        }
    );


const makeSelectFBCRebillPONumber = () =>
    createSelector(
        selectRoot,
        globalState => {
            const {
                cfbReturnDetails: {
                    rebillSystemPONumber: rebillPOList
                }
            } = globalState;

            const rebillPOListData = { "rebillPOList" : rebillPOList ? rebillPOList.split(',') : [] }
            return rebillPOListData
        }
    );


const makeSelectFBCCosmicCaseNumber = () =>
    createSelector(
        selectRoot,
        globalState => {
            const {
                cfbReturnDetails: {
                    cosmicCaseNumber: cosmicCaseNumber                    
                }
            } = globalState;
            return { cosmicCaseNumber }
        }
    );


const makeSelectFBCIsFullCancellation = () =>
    createSelector(
        selectRoot,
        globalState => {
            const {
                cfbReturnDetails: {
                    isFullCancellation: isFullCancellation
                }
            } = globalState;

            return { isFullCancellation }
        }
    );


const makeCancelFutureBillingReturnFormData = () =>
    createSelector(
        selectRoot,
        globalState => {

            const purchaseOrderId =
            (globalState.CancelFutureBillingCreateRequest && globalState.CancelFutureBillingCreateRequest.invoices) ? (globalState.CancelFutureBillingCreateRequest.invoices.enrollmentNumber || globalState.CancelFutureBillingCreateRequest.invoices.systemPONumber) ? globalState.CancelFutureBillingCreateRequest.invoices.purchaseOrderId : globalState.CancelFutureBillingRequest.podetails.purchaseOrderId :
            globalState.CancelFutureBillingFetchRequest.invoices.purchaseOrderId;

            const {

                cfblineItemDetails: {
                    lineItemDetails: lineItemDetails
                },
                cfbReturnDetails: {
                    isFullCancellation: isFullCredit,
                    cosmicCaseNumber: cosmicCaseNumber,
                    rebillSystemPONumber: rebillPOList,
                    returnReason: returnReasonCode
                },
                submitNotes: {
                    notes: notes
                }
            } = globalState;

            lineItemDetails.forEach(function (v) { delete v.isValid });
            const returnRequestPayload = {
                "openOrder": null,
                "vlOrder": null,
                "vlfbOrder": {
                    "isFullCredit": (isFullCredit ? isFullCredit : false),
                    returnReasonCode,
                    "purchaseOrderLineItemCollection": isFullCredit ? [] : lineItemDetails.filter(item => item.isAdjusted),
                    "rebillPOList": isFullCredit ? (rebillPOList ? rebillPOList.split(',') : []) : [],
                    "tickets": cosmicCaseNumber ? [{ "ticketId": cosmicCaseNumber.trim(), "type": "manual" }] : null,
                    notes,
                    "purchaseOrderId":purchaseOrderId                   
                }
            };
            return returnRequestPayload;
        }
    );
    const makeListItemShowStatus = () => createSelector(selectRoot, globalState => {
        if (globalState.CancelFutureBillingReturnForm && globalState.CancelFutureBillingReturnForm.showLineItemConfirmation)
            return globalState.CancelFutureBillingReturnForm.showLineItemConfirmation;

        return null;
    });

export default makeCancelFutureBillingReturnForm;

export {
    selectCancelFutureBillingReturnFormDomain,
    makeCancelFutureBillingReturnFormReadOnly,
    makeCancelFutureBillingReturnFormIsActionOpen,
    makeCancelFutureBillingReturnFormError,
    makeCancelFutureBillingReturnFormLoading,
    makeCancelFutureBillingReturnFormSuccess,
    makeCancelFutureBillingReturnFormData,
    makeSelectFBCReturnReason,
    makeSelectFBCRebillPONumber,
    makeSelectFBCCosmicCaseNumber,
    makeSelectFBCIsFullCancellation,
    makeListItemShowStatus
};
