export const REMOVE_REBILLPO_NUMBER = 'rebillpoTable/REMOVE_REBILLPO_NUMBER';
export const CLOSE_PO_CONFIRMATION = 'rebillpoTable/CLOSE_PO_CONFIRMATION';
export const SELECT_REBILL_INVOICE = 'rebillpoTable/SELECT_REBILL_INVOICE';
export const CHANGE_PO_EXPAND = 'rebillpoTable/CHANGE_PO_EXPAND';

export const Rebill_Status_Map = {
    'HAD': 'Accepted',
    'HIN': 'Invalid',
    'HLD': 'Hold',
    'HPB': 'Partially Billed',
    'HPN': 'Pending',
    'HRJ': 'Rejected',
    'HRW': 'Review',
    'HVD': 'Validation in progress'

}