import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectFilterCancellationRequestDomain = (state) =>
    state.cancellationList || initialState;

const fetchCancellationListRequestPayload = () =>
    createSelector(
        selectFilterCancellationRequestDomain,
        (substate) => substate.payload
    );

const makeSelectCancellation = () =>
    createSelector(
        selectFilterCancellationRequestDomain,
        (substate) => substate.cancellationListResponse
    );

const makeSelectError = () =>
    createSelector(
        selectFilterCancellationRequestDomain,
        (substate) => substate.error
    );

const makeSelectLoading = () =>
    createSelector(
        selectFilterCancellationRequestDomain,
        (substate) => substate.loading
    );

const makeSelectRequestId = () =>
    createSelector(
        selectFilterCancellationRequestDomain,
        (substate) => substate.requestIdentifier
    );
const makeSelectRequestStatus = () =>
    createSelector(
        selectFilterCancellationRequestDomain,
        (substate) => (substate.requestStatus != null && substate.requestStatus[0] === 'PEN') ? ["RAP", "RP", "RA"]:substate.requestStatus
    );
const makeSelectPoNumber = () =>
    createSelector(
        selectFilterCancellationRequestDomain,
        (substate) => substate.poNumber
    );
const makeSelectSystemPoNumber = () =>
    createSelector(
        selectFilterCancellationRequestDomain,
        (substate) => substate.systemPONumber
    );
const makeSelectEnrollmentNumber = () =>
    createSelector(
        selectFilterCancellationRequestDomain,
        (substate) => substate.enrollmentNumber
    );
const makeSelectCosmicNumber = () =>
    createSelector(
        selectFilterCancellationRequestDomain,
        (substate) => substate.cosmicNumber
    );
const makeSelectCustomerName = () =>
    createSelector(
        selectFilterCancellationRequestDomain,
        (substate) => substate.endCustomerName
    );
const makeSelectDateRange = () =>
    createSelector(
        selectFilterCancellationRequestDomain,
        (substate) => substate.dateRange
    );
export default fetchCancellationListRequestPayload;

export {
    fetchCancellationListRequestPayload,
    makeSelectCancellation,
    makeSelectLoading,
    makeSelectError,
    makeSelectRequestId,
    makeSelectRequestStatus,
    makeSelectPoNumber,
    makeSelectSystemPoNumber,
    makeSelectEnrollmentNumber,
    makeSelectCosmicNumber,
    makeSelectCustomerName,
    makeSelectDateRange
};
