export const SUBMIT_ApprovalRequest = 'ApprovalRequest/SUBMIT_ApprovalRequest';
export const SUBMIT_ApprovalRequest_SUCCESS = 'ApprovalRequest/SUBMIT_ApprovalRequest_SUCCESS';
export const SUBMIT_ApprovalRequest_FAIL = 'ApprovalRequest/SUBMIT_ApprovalRequest_FAIL';

export const Reject_ApprovalRequest = 'ApprovalRequest/Reject_ApprovalRequest';

export const Reject_ApprovalReset = 'ApprovalRequest/Reject_ApprovalReset';
export const Procced_ApprovalRequest = 'ApprovalRequest/Procced_ApprovalRequest';
export const Close_Dialog = 'ApprovalRequest/Close_Dialog';



export const DEFAULT_ACTION = 'ApprovalRequest/DEFAULT_ACTION';


