import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectReallocateModalDomain = state => state.reallocateModal || initialState;
const selectRoot = state => state || initialState;

const makeSelectReallocateModal = () =>
    createSelector(
        selectReallocateModalDomain,
        substate => substate,
    );

const makeSelectIsOpen = () =>
    createSelector(selectReallocateModalDomain, substate => substate.isReallocateModalOpen);

const makeSelectPONumber = () =>
    createSelector(selectReallocateModalDomain, substate => substate.poNumber);

const makeSelectExternalIdentifier = () =>
    createSelector(selectReallocateModalDomain, substate => substate.externalIdentifier);

const makeSelectReallocateModalError = () =>
    createSelector(selectReallocateModalDomain, substate => substate.error);

const makeGlobalState = () =>
    createSelector(selectRoot, substate => substate);

export default makeSelectReallocateModal;

export { makeSelectIsOpen, makeSelectPONumber, makeSelectExternalIdentifier, makeGlobalState, makeSelectReallocateModalError };