import { defineMessages } from 'react-intl';

export const scope = 'CancelFutureBillingFetechRequest';

export default defineMessages({
    invoiceNumber: {
        id: `${scope}.invoiceNumber`,
        defaultMessage: 'Invoice Number',
    },
    localInvoiceNumber: {
        id: `${scope}.localInvoiceNumber`,
        defaultMessage: 'Local Invoice Number',
    },
    cosmicCaseNumber: {
        id: `${scope}.cosmicCaseNumber`,
        defaultMessage: 'Cosmic Case Number',
    },
    enrollmentNumber: {
        id: `${scope}.enrollmentNumber`,
        defaultMessage: 'Enrollment Number',
    },
    systemPONumber: {
        id: `${scope}.systemPONumber`,
        defaultMessage: 'System Purchase Order Number'
    }
});
