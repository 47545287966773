import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import messages from './messages';
import InfoMessage from '../../../common/components/InfoMessage/InfoMessage';
import { OpsRequestStatus_FBC, PartnerRequestStatus_FBC } from "../../../common/internals/utils/requestStatusRoleBased";
import { makeSelectFormData } from './selectors'
import * as Helpers from "../../../common/internals/utils/helpers";

export function FBCApprovalInfo({ formData }) {
    const loggedinuserRole = window && window.userRole;
    const readonly = formData && formData.isReadOnly;
    const isUserReadOnly = formData && formData.isUserReadOnly;    
    const isReadonlyApproval = (readonly || isUserReadOnly) ? true : false;
    const statusCode = formData && (formData.statusCode || formData.requestStatusCode);
    var statusString = loggedinuserRole === 'VPU'
        ? Helpers.GetFbcPartnerStatusByCode(statusCode)
        : Helpers.GetFbcOpsStatusByCode(statusCode);
    var message = isUserReadOnly ? "This user has readonly permissions as status is in " + statusString : statusString ;

    if (statusCode === "REJ") {
        if (formData && formData.rejectionReason && formData.rejectionReason.rejectReasonCode) {
            message = statusString + ":" + formData.rejectionReason.rejectReason;
        } 
    }

    if (isReadonlyApproval)
    {
        return (
            <InfoMessage messageBody={message} messageTitle={messages.approvalInfoMessage} />
        );
    } 
   return null;
}

FBCApprovalInfo.propTypes = {
    formData:PropTypes.object
};

const mapStateToProps = createStructuredSelector({
    formData: makeSelectFormData()
});

const withConnect = connect(
    mapStateToProps
);

export default compose(withConnect)(FBCApprovalInfo);
