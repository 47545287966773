import { defineMessages } from 'react-intl';

export const scope = 'poSelectionModal';

export default defineMessages({
    addBtn: {
        id: `${scope}.addBtn`,
        defaultMessage : "Add"
    },
});
