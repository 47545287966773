import {
    CHANGE_MT_COSMIC_CASENUMBER, MT_GET_RETURN, MT_GET_RETURN_ERROR, MT_GET_RETURN_SUCCESS
} from './constants';

export function getMTRequestData(payload) {
    return {
        type: MT_GET_RETURN,
        payload
    };
}

export function getMTRequestDataCompleted(requestData, leadEnrollmentNumber) {
    return {
        type: MT_GET_RETURN_SUCCESS,
        requestData,
        leadEnrollmentNumber,
    };
}

export function getMTRequestDataError(error) {
    return {
        type: MT_GET_RETURN_ERROR,
        error,
    };
}
