import { select, takeLatest } from "redux-saga/effects";

import { cancellationListRequestCreate } from "../../../common/internals/api/requests";
import { sagaFetchHandler } from "../../../common/internals/api/sagaFetchHandler";
import { formatSearchRequest } from "../../../common/internals/utils/formatRequest";
import {
    makeSelectAccessToken,
    makeSelectAuthType,
} from "../../../common/app/selectors";

import {
    FilterCancellationRequestsList,
    FilterCancellationRequestsListError,
} from "./actions";
import { FILTER_CANCELLATION_REQUESTS } from "./constants";
import {
    makeSelectRequestId,
    makeSelectRequestStatus,
    makeSelectPoNumber,
    makeSelectEnrollmentNumber,
    makeSelectCosmicNumber,
    makeSelectCustomerName,
    makeSelectDateRange,
} from "./selectors";

export function* getCancellationList() {

    var cancellationListRequest = cancellationListRequestCreate();
    yield* setAuthTokens(cancellationListRequest);

    var requestParams = {
        dateRange: yield select(makeSelectDateRange()),
        returnsIdentifier: yield select(makeSelectRequestId()), // requestId
        purchaseOrderNumber: yield select(makeSelectPoNumber()), //poNumber
        systemPONumber: yield select(makeSelectPoNumber()), //systemPONumber
        agreementNumber: yield select(makeSelectEnrollmentNumber()), //enrollmentNumber
        cosmicNumber: yield select(makeSelectCosmicNumber()), //cosmicNumber
        endCustomerName: yield select(makeSelectCustomerName()), //customerName
        requestStatusCode: yield select(makeSelectRequestStatus())
    };

    var requestPayload = formatSearchRequest(requestParams);
    cancellationListRequest.options.body = requestPayload;
    yield* sagaFetchHandler(
        cancellationListRequest,
        FilterCancellationRequestsList,
        FilterCancellationRequestsListError
    );
}

function* setAuthTokens(request) {
    const accessToken = yield select(makeSelectAccessToken());
    const authType = yield select(makeSelectAuthType());
    request.options.headers["Authorization"] = "Bearer " + accessToken;
    request.options.headers["x-ms-AuthType"] = authType;
}

export default function* filterCancellationListRequestSaga() {
    yield takeLatest(FILTER_CANCELLATION_REQUESTS, getCancellationList);
}
