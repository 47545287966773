export const CHANGE_INVOICE_NUMBER = 'submit/InvoiceSearch/CHANGE_INVOICE_NUMBER';
export const SEARCH_INVOICE = 'submit/InvoiceSearch/SEARCH_INVOICE';
export const SEARCH_INVOICE_SUCCESS = 'submit/InvoiceSearch/SEARCH_INVOICE_SUCCESS';
export const SEARCH_INVOICE_ERROR = 'submit/InvoiceSearch/SEARCH_INVOICE_ERROR';

export const FILTER_CREDITS = 'credits/List/FILTER_CREDITS';
export const FILTER_CREDITS_SUCCESS = 'credits/List/FILTER_CREDITS_SUCCESS';
export const FILTER_CREDITS_ERROR = 'credits/List/FILTER_CREDITS_ERROR';

export const CHANGE_REQUEST_IDENTIFIER = 'credits/List/CHANGE_REQUEST_IDENTIFIER';
export const CHANGE_REQUEST_STATUS = 'credits/List/CHANGE_REQUEST_STATUS';
export const CHANGE_SALES_LOCATION = 'credits/List/CHANGE_SALES_LOCATION';
export const CHANGE_OPERATION_CENTER = 'credits/List/CHANGE_OPERATION_CENTER';
export const CHANGE_BILLING_DOCUMENT = 'credits/List/CHANGE_BILLING_DOCUMENT';
export const CHANGE_PO_NUMBER = 'credits/List/CHANGE_PO_NUMBER';
export const CHANGE_ENROLLMENT_NUMBER = 'credits/List/CHANGE_ENROLLMENT_NUMBER';
export const CHANGE_CUSTOMER_NUMBER = 'credits/List/CHANGE_CUSTOMER_NUMBER';
export const CHANGE_COSMIC_NUMBER = 'credits/List/CHANGE_COSMIC_NUMBER';
export const CHANGE_CUSTOMER_NAME = 'credits/List/CHANGE_CUSTOMER_NAME';
export const CHANGE_PROGRAM_CODE = 'credits/List/CHANGE_PROGRAM_CODE';
export const CHANGE_DATE_RANGE = 'credits/List/CHANGE_DATE_RANGE';

