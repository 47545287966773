import PropTypes from 'prop-types';
import React, { memo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Select from 'react-select';
import { compose } from 'redux';
import Announcer from 'react-a11y-announcer';
import { createStructuredSelector } from 'reselect';
import { customReactSelectStyles } from '../../../../common/app/global-style';
import Input from '../../../../common/components/Input/Input';
import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import { useInjectSaga } from '../../../../common/internals/utils/injectSaga';
import { makeSelectTenantAgreements } from '../../../common/components/multiTenantCreateRequest/selectors';
import { makeSelectRequestData } from '../../../common/components/multiTenantFetchRequest/selectors';
import { makeSelectIsOpen } from './../poSelectionModal/selectors';
import { searchPOs, searchtenant, selectPO } from './actions';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import {
    makeSelectAnnouncerText,
    makeSelectIsPOLoading,
    makeSelectPurchaseOrdersDropDownOptions, makeSelectSelectedPurchaseOrdersData, makeSelectSelectPO, makeSelectTenant
} from './selectors';

import '../../../../common/app/accessibility-style.css'

function MultiTenantSearchAndAdd({
    onSourceTenantSelect,
    selectedTenant,
    isPOLoading,
    purchaseOrdersDropDownOptions,
    onPOSelection,
    selectedPO,
    selectedPurchaseOrdersData,
    isReadOnly,
    requestData,
    tenantAgreements,
    isPOSelectionModalOpen,
    announcerText
}) {
    useInjectReducer({ key: 'multiTenantSearchAndAdd', reducer });
    useInjectSaga({ key: 'multiTenantSearchAndAdd', saga });

    let tenantAgrementsSelector = [];
    const poSelectionRef = useRef();

    if (tenantAgreements) {
        tenantAgrementsSelector = tenantAgreements.map(tenant => {
            return {
                value: { agreementNumber: tenant.agreementNumber, businessName: tenant.businessName },
                label: tenant.agreementNumber + '-' + tenant.businessName
            };
        });
    }

    let poSelectionDropdown = (
       
                <div>
                    <div className="col-lg-8" aria-live="polite">
                        {(selectedTenant !== null && !isReadOnly) ?
                        <div>
                            <label className='form-label' >
                                PO Number
                            </label>
                            <Select
                                classNamePrefix="react-select"
                                options={purchaseOrdersDropDownOptions}
                                value={selectedPO}
                                placeholder="Select PO Number"
                                isLoading={isPOLoading}
                                onChange={onPOSelection}
                                styles={customReactSelectStyles}
                                aria-label="Select P O Number"
                                aria-live="polite"
                                autoFocus={true}
                                ref={poSelectionRef}/>
                        </div>
                        :
                        ""}
                    </div>
                </div>
         )

    let noPOFound = (
        <div className="col-lg-8">
            <br></br><br></br>
            <FormattedMessage {...messages.noPOFound} />
        </div>);

    let poDisplay;

    if (!isPOLoading && purchaseOrdersDropDownOptions.length === 0 && selectedTenant !== null && !isReadOnly) {
        poDisplay = noPOFound;
    } else {
        poDisplay = poSelectionDropdown;
    }

    let sourceTenantName = requestData && requestData.multiTenantPODetails && requestData.multiTenantPODetails[0].sourceTenant;

    if(requestData && requestData.tenants) {
        let filteredData = requestData.tenants.filter(tenant => tenant.tenantId === sourceTenantName);

        if(filteredData && filteredData.length > 0) {
            sourceTenantName += "-" + filteredData[0].tenantName;
        }
    }

    return (
        <div>
            <Announcer text={announcerText}/>
            <div className="col-lg-8" role="alert">
                {!isReadOnly ?
                    <div aria-live="polite">
                        <label className='form-label'>
                            Source Enrollment Number
                        </label>


                        <Select
                            options={tenantAgrementsSelector}
                            value={selectedTenant}
                            aria-label="Select Source Enrollment Number"
                            aria-live="assertive"
                            styles={customReactSelectStyles}
                            onChange={
                                onSourceTenantSelect
                            }
                            classNamePrefix="react-select"
                            placeholder="Select Source Enrollment Number"
                            isDisabled={!(selectedTenant == null || selectedPurchaseOrdersData.length == 0)}
                        />
                    </div>
                    :
                    <Input
                        value={sourceTenantName || ""}
                        label={messages.sourceTenant}
                        id="sourceTenant"
                        editable={false}
                    />

                }
            </div>
            {poDisplay}
        </div>
    );
}

MultiTenantSearchAndAdd.propTypes = {
    selectedTenant: PropTypes.string,
    onSourceTenantSelect: PropTypes.func,
    selectedPOs: PropTypes.array
};

const mapStateToProps = createStructuredSelector({
    selectedTenant: makeSelectTenant(),
    isPOLoading: makeSelectIsPOLoading(),
    purchaseOrdersDropDownOptions: makeSelectPurchaseOrdersDropDownOptions(),
    selectedPO: makeSelectSelectPO(),
    selectedPurchaseOrdersData: makeSelectSelectedPurchaseOrdersData(),
    requestData: makeSelectRequestData(),
    tenantAgreements: makeSelectTenantAgreements(),
    isPOSelectionModalOpen: makeSelectIsOpen(),
    announcerText: makeSelectAnnouncerText()
});

function mapDispatchToProps(dispatch) {
    return {
        onSourceTenantSelect: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(searchtenant(evt));

        },
        onPOsChange: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(searchPOs(evt));
        },
        onPOSelection: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(selectPO(evt));
        }
    }
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(
    withConnect,
    memo,
)(MultiTenantSearchAndAdd);
