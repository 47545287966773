import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

export class TextBox extends Component {

    constructor(props) {
        super(props);
    }

    

    render() {
       
        const { formatMessage } = this.props.intl;
        return (
            <input type={this.props.type}
                className={this.props && this.props.className ? this.props.className : ''}
                id={this.props.id}
                name={this.props && this.props.name ? this.props.name : ''}
                placeholder={formatMessage({ id: this.props.id })}
                aria-label={formatMessage({ id: this.props.id })}
                autoComplete={this.props && this.props.autoComplete ? this.props.autoComplete : 'off'} onChange={this.props.onChange} />
        );
    }
}
export default injectIntl(TextBox);
