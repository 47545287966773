import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import messages from './messages';
import { createStructuredSelector } from 'reselect';
import errorMessages from '../../../../common/internals/api/errorMessages';
import ErrorMessage from '../../../../common/components/ErrorMessage/ErrorMessage';
import { makeSelectRebillSearchError } from '../../containers/rebillSearch/selectors';
import { makeSelectCreateRequestError } from '../../../common/components/createRequest/selectors';
import { makeSelectCreateMultiInvoiceRequestError } from '../../../common/components/createMultiInvoiceRequest/selectors';
import makeSelectFetchReturnRequest from '../../../common/components/fetchReturnRequest/selectors';
import { makeSelectCancelError } from '../../../common/components/cancelrequest/selectors';

import {
    makeSelectReturnFormError,
    makeSelectReturnFormSaveError,
    makeSelectReturnFormDeleteError
} from '../../../partners/containers/returnForm/selectors';

export function SubmitErrors({
    multiInvoiceSearchError,
    invoiceSearchError,
    returnFormError,
    returnFormSaveError,
    returnFormDeleteError,
    returnError,
    rebillError,
    cancelError
}) {
    if (rebillError) window.scrollTo(0, 0);
    var defaultMessage = errorMessages.partnerMsg;
    if (window.userRole === "VFU") {
        defaultMessage = errorMessages.subCFOMsg;
    }

    return (
        <div>
            {(returnFormSaveError && 'errorMessages' in returnFormSaveError) ?
                <ErrorMessage messageBody={returnFormSaveError.errorMessages}
                    messageTitle={messages.saveRequestError} defaultMessage={defaultMessage} /> : ""}

            {(returnFormDeleteError && 'errorMessages' in returnFormDeleteError) ?
                <ErrorMessage messageBody={returnFormDeleteError.errorMessages}
                    messageTitle={messages.deleteRequestError} defaultMessage={defaultMessage} /> : ""}

            {(returnFormError && 'errorMessages' in returnFormError) ?
                <ErrorMessage messageBody={returnFormError.errorMessages}
                    messageTitle={messages.submitRequestError} defaultMessage={defaultMessage} /> : ""}

            {(rebillError && 'errorMessages' in rebillError) ?
                <ErrorMessage messageBody={rebillError.errorMessages}
                    messageTitle={messages.rebillSearchError} defaultMessage={defaultMessage} /> : ""}

            {(invoiceSearchError && 'errorMessages' in invoiceSearchError) ?
                <ErrorMessage messageBody={invoiceSearchError.errorMessages}
                    messageTitle={messages.invoiceSearchError} defaultMessage={defaultMessage} /> : ""}

            {(multiInvoiceSearchError && 'errorMessages' in multiInvoiceSearchError) ?
                <ErrorMessage messageBody={multiInvoiceSearchError.errorMessages}
                    messageTitle={messages.multiInvoiceSearchError} defaultMessage={defaultMessage} /> : ""}

            {(returnError && returnError.error && 'errorMessages' in returnError.error) ?
                <ErrorMessage messageBody={returnError.error.errorMessages}
                    messageTitle={messages.invoiceSearchError} defaultMessage={defaultMessage} /> : ""}

            {(cancelError && 'errorMessages' in cancelError) ?
                <ErrorMessage messageBody={cancelError.error.errorMessages}
                    messageTitle={messages.cancelError} defaultMessage={defaultMessage} /> : ""}
        </div>
    );
}

SubmitErrors.propTypes = {
    dispatch: PropTypes.func,
    multiInvoiceSearchError: PropTypes.any,
    invoiceSearchError: PropTypes.any,
    returnFormError: PropTypes.any,
    returnFormSaveError: PropTypes.any,
    returnFormDeleteError: PropTypes.any,
    getReturnError: PropTypes.any,
    rebillError: PropTypes.any,
    cancelError: PropTypes.any,
};

const mapStateToProps = createStructuredSelector({
    multiInvoiceSearchError: makeSelectCreateMultiInvoiceRequestError(),
    invoiceSearchError: makeSelectCreateRequestError(),
    returnError: makeSelectFetchReturnRequest(),
    returnFormError: makeSelectReturnFormError(),
    returnFormSaveError: makeSelectReturnFormSaveError(),
    returnFormDeleteError: makeSelectReturnFormDeleteError(),
    rebillError: makeSelectRebillSearchError(),
    cancelError: makeSelectCancelError()

});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(SubmitErrors);
