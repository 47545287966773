import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectMTApproverDetailsDomain = (state) =>
  state.multiTenantApproverDetails || initialState;

const makeSelectMTApproverDetails = () =>
  createSelector(selectMTApproverDetailsDomain, (substate) => substate);

const makeSelectMTApproverComment = () =>
  createSelector(
    selectMTApproverDetailsDomain,
    (substate) => substate.approverComment
  );

  const makeSelectMTApproverCallout = () =>
  createSelector(
    selectMTApproverDetailsDomain,
      substate => substate.approverCallout,
    );

const makeSelectMTRejectionReasonCode = () =>
    createSelector(
      selectMTApproverDetailsDomain,
        substate => substate.rejectionReasonCode,
    );

const makeSelectMTRejectionReasonDescripiton = () =>
    createSelector(
      selectMTApproverDetailsDomain,
        substate => substate.rejectionReasonDescripiton,
    );


const makeSelectIsMTOtherRejectionReason = () =>
    createSelector(
      selectMTApproverDetailsDomain,
        substate => substate.isOtherRejectionReason,
    );

const makeSelectMTWorkflow = () =>
    createSelector(
        selectMTApproverDetailsDomain,
        substate => substate.workflow,
    );

export default makeSelectMTApproverDetails;
export { makeSelectMTWorkflow , selectMTApproverDetailsDomain, makeSelectMTApproverComment, makeSelectMTApproverCallout, makeSelectMTRejectionReasonCode, makeSelectMTRejectionReasonDescripiton, makeSelectIsMTOtherRejectionReason };
