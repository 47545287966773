import { createSelector } from 'reselect';
import { initialState } from './reducer';

/* Direct selector to the FetchReturnRequest state domain */
const selectCFBFetechReturnRequestDomain = state => state.CancelFutureBillingFetechRequest || initialState;

/* Default selector used by FetchReturnRequest */
const makeSelectCFBFetechReturnRequest = () => createSelector(selectCFBFetechReturnRequestDomain, substate => substate);

const makeSelectEnrollmentNumberFetch = () => createSelector(selectCFBFetechReturnRequestDomain, substate => substate.invoices ? substate.invoices.enrollmentNumber : "");

const makeSelectSystemPONumberFetch = () => createSelector(selectCFBFetechReturnRequestDomain, substate => substate.invoices ? substate.invoices.PONumber : "");

const makeSelectLicensesFetch = () => createSelector(selectCFBFetechReturnRequestDomain, substate => substate.invoices);

const makeSelectCosmicCaseNumber = () => createSelector(selectCFBFetechReturnRequestDomain, substate => substate.invoices ? substate.invoices.cosmicCaseNumber : null);

const makeSelectCFBFetchRequestError = () => createSelector(selectCFBFetechReturnRequestDomain, substate => substate.error);

const makeSelectLoading = () => createSelector(selectCFBFetechReturnRequestDomain, substate => substate.loading);

export default makeSelectCFBFetechReturnRequest;
export {
    makeSelectLoading,
    makeSelectCosmicCaseNumber,    
    makeSelectEnrollmentNumberFetch,
    makeSelectSystemPONumberFetch,
    makeSelectLicensesFetch,
    makeSelectCFBFetchRequestError
};
