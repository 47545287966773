import 'react-app-polyfill/ie11';//always keeps this in first line as it is mandatory for IE
import '@babel/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router'
import App from './common/app';
import configureStore from './common/internals/store/configureStore';
import history from './common/internals/utils/history';
import LoadingPage from './login/containers/loadingPage';
import ErrorPage from './login/containers/errorPage';
import Header from './common/components/Header';
import GlobalStyle from './common/app/global-style';
import authentication from './login/containers/msal-react';
import LanguageProvider from './common/components/LanguageProvider';
import { translationMessages } from './common/i18n';
import AppInsightsWrapper from "./common/components/appInsights/index"

const uuidv4 = require('uuid/v4');
window.corelationId = uuidv4();

const rootElement = document.getElementById('root');
fetch('./environment.json', { method: 'GET' }).then(res => {
    if (!res.ok) {
        console.log("Error fetching environment data");
        ReactDOM.render(
            <ErrorPage />,
            rootElement);
    }
    return res.json();
}).then(envData => {
    if (!window.vlcredits)
        window.vlcredits = {};
 
    window.vlcredits.envData = envData;
    let key = envData.appInsights.instrumentationKey;
    //RegisterAppInsights(envData.appInsights.instrumentationKey);
    const initialState = {};
    const store = configureStore(initialState, history);

    authentication.initialize({
        instance: window.vlcredits.envData.Authentication.instance,
        tenant: window.vlcredits.envData.Authentication.tenant,
        applicationId: window.vlcredits.envData.Authentication.firstPartyEnabled === 'false' ? window.vlcredits.envData.Authentication.thirdPartyClientId : window.vlcredits.envData.Authentication.firstPartyClientId,
        cacheLocation: window.vlcredits.envData.Authentication.cacheLocation,
        scopes: [window.vlcredits.envData.Authentication.loginScope, window.vlcredits.envData.Authentication.accessTokenScope],
        redirectUri: window.vlcredits.envData.Authentication.redirectUrl,
        postLogoutRedirectUri: window.vlcredits.envData.Authentication.postLogoutRedirectUrl,
        validateAuthority: window.vlcredits.envData.Authentication.validateAuthority === 'true' ? true : false,
        accessTokenScope: window.vlcredits.envData.Authentication.accessTokenScope,
        liveLoginScope: window.vlcredits.envData.Authentication.liveLoginScope,
        msaTenantId: window.vlcredits.envData.Authentication.msaTenantId,
        firstPartyEnabled: window.vlcredits.envData.Authentication.firstPartyEnabled === 'false' ? false : true,
        instrumentationKey: key
    });
    window.isApprovar = false;
    window.vlcredits.FeatureName = null;
    checkEmailLink();
    window.vlcredits.FBCROCReturnReasons = window && window.vlcredits && window.vlcredits.envData && window.vlcredits.envData.Services && window.vlcredits.envData.Services.FBCROCReturnReasons && window.vlcredits.envData.Services.FBCROCReturnReasons.split(",");
    window.vlcredits.FBCPartnerReturnReasons = window && window.vlcredits && window.vlcredits.envData && window.vlcredits.envData.Services && window.vlcredits.envData.Services.FBCPartnerReturnReasons && window.vlcredits.envData.Services.FBCPartnerReturnReasons.split(",");
    window.vlcredits.FBCCPSROCReturnReasons = window && window.vlcredits && window.vlcredits.envData && window.vlcredits.envData.Services && window.vlcredits.envData.Services.FBCCPSROCReturnReasons && window.vlcredits.envData.Services.FBCCPSROCReturnReasons.split(",");
    ReactDOM.render(
        <Provider store={store}>
            <AppInsightsWrapper appinsightsKey={key}>
            <LanguageProvider messages={translationMessages}>
                <ConnectedRouter history={history}>
                    <div>
                        <Header logoutFn={authentication.signOut} nameFn={authentication.getName} emailFn={authentication.getEmail} />
                        <div >
                            <Switch>
                                    <Route path="/" component={authentication.required(App, loginInProgress, ErrorPage, store)} />
                            </Switch>
                        </div>
                        <GlobalStyle />
                    </div>
                </ConnectedRouter>
            </LanguageProvider>
            </AppInsightsWrapper>
        </Provider>,
        rootElement);
}).catch(err => {
    console.log("Error loading environment data");
    console.log(err);
    ReactDOM.render(
        <ErrorPage />,
        rootElement);
});

function checkEmailLink() {
    const url = window.location.href;
    if (url.includes('invoice') && url.includes('approve')) {
        console.log('Saving Url: ' + url);
        sessionStorage.setItem('VLReturnsRedirectUri', url);
    }
}

function loginInProgress() {
    return <LoadingPage />;
}

function RegisterAppInsights(iKey) {
    var appInsights = window.appInsights || function (config) {
        function s(config) {
            t[config] = function () {
                var i = arguments; t.queue.push(function () { t[config].apply(t, i) })
            }
        }
        var t = { config: config }, r = document, f = window, e = "script", o = r.createElement(e), i, u;
        for (o.src = config.url || "//az416426.vo.msecnd.net/scripts/a/ai.0.js", r.getElementsByTagName(e)[0].parentNode.appendChild(o),
            t.cookie = r.cookie, t.queue = [], i = ["Event", "Exception", "Metric", "PageView", "Trace","Requests"]; i.length;)s("track" + i.pop());
        return config.disableExceptionTracking || (i = "onerror", s("_" + i), u = f[i], f[i] = function (config, r, f, e, o) { var s = u && u(config, r, f, e, o); return s !== !0 && t["_" + i](config, r, f, e, o), s }
        ), t
    }({
        instrumentationKey: iKey,
        disableExceptionTracking: true
    });
    window.appInsights = appInsights;
    appInsights.trackPageView();
    
}