import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { onPORemoval, onAddReallocate } from './actions';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import * as Helpers from '../../../../common/internals/utils/helpers';
import { useTable, useExpanded } from 'react-table';
import { makeSelectSelectedPurchaseOrdersData, makeSelectPOs } from '../../containers/multiTenantSearchAndAdd/selectors';
import { useInjectSaga } from '../../../../common/internals/utils/injectSaga';
import '../../../../common/app/accessibility-style.css';

function Header({ isReadOnly, isApprovalScreen, selectedPurchaseOrdersList , onRemoval}) {
    return (
        <thead><tr>
            <th></th>
            <th style={{ fontWeight: 'bolder', color: 'black' }}><FormattedMessage {...messages.purchaseOrderNumber} /></th>
            <th style={{ fontWeight: 'bolder', color: 'black' }}><FormattedMessage {...messages.systemPONumber} /></th>
            <th style={{ fontWeight: 'bolder', color: 'black' }}><FormattedMessage {...messages.createdDate} /></th>
            <th style={{ fontWeight: 'bolder', color: 'black' }}><FormattedMessage {...messages.orderType} /></th>
            {(!isReadOnly && !isApprovalScreen) ? <th style={{ fontWeight: 'bolder', color: 'black' }} ></th> : ""}
            {!isReadOnly ?
                <th style={{ fontWeight: 'bolder', color: 'black' }} ><button onClick={() => {
                    for (let i = 0; i < selectedPurchaseOrdersList.length; i++) {
                        onRemoval(selectedPurchaseOrdersList[i].externalIdentifier);
                    }
                }}>
                    <FormattedMessage {...messages.deleteAllBtn} /> 
            </button>
                </th> : ""}
          
        </tr></thead>);
}

function Table({ columns: userColumns, data, renderRowSubComponent, isReadOnly, isApprovalScreen, selectedPurchaseOrdersList, onRemoval }) {


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { expanded },
    } = useTable(
        {
            columns: userColumns,
            data
        },
        useExpanded
    )

    return (
        <div className="table-responsive">
            <table {...getTableProps()} className="table table-responsive reallocationTable">
                <Header isReadOnly={isReadOnly} isApprovalScreen={isApprovalScreen} selectedPurchaseOrdersList={selectedPurchaseOrdersList} onRemoval={onRemoval} />
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <React.Fragment {...row.getRowProps()}>
                                <tr>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        )
                                    })}
                                </tr>
                                {}
                                {(row.original.tenants && row.original.tenants.length > 0 && row.isExpanded) ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length}>
                                            { }
                                            {renderRowSubComponent({ row })}
                                        </td>
                                    </tr>
                                ) : null}
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

function SubTable({ columns: userColumns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        state: { expanded },
    } = useTable(
        {
            columns: userColumns,
            data,
        },
    )

    return (
        <div>
            <table {...getTableProps()} className="table table-bordered table-dark">
                <thead><tr>
                    <th style={{ fontWeight: 'bolder', color: 'black', backgroundColor: '#efece9' }}><FormattedMessage {...messages.partNumber} /></th>
                    <th style={{ fontWeight: 'bolder', color: 'black', backgroundColor: '#efece9' }}><FormattedMessage {...messages.productDescription} /> </th>
                    <th style={{ fontWeight: 'bolder', color: 'black', backgroundColor: '#efece9' }}><FormattedMessage {...messages.coverageStartDate} /> </th>
                    <th style={{ fontWeight: 'bolder', color: 'black', backgroundColor: '#efece9' }}><FormattedMessage {...messages.coverageEndDate} /></th>
                    <th style={{ fontWeight: 'bolder', color: 'black', backgroundColor: '#efece9' }}><FormattedMessage {...messages.tenantNumber} /></th>
                    <th style={{ fontWeight: 'bolder', color: 'black', backgroundColor: '#efece9' }}><FormattedMessage {...messages.tenantName} /></th>
                    <th style={{ fontWeight: 'bolder', color: 'black', backgroundColor: '#efece9' }}><FormattedMessage {...messages.reallocateQuantity} /></th>
                    <th style={{ fontWeight: 'bolder', color: 'black', backgroundColor: '#efece9' }}><FormattedMessage {...messages.purchaseOrderNumber} /></th>
                </tr></thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <React.Fragment {...row.getRowProps()}>
                                <tr>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        )
                                    })}
                                </tr>
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export function ReallocationDetailsTable({ isReadOnly, isApprovalScreen, onAddReallocateClick, onRemoval, selectedPurchaseOrdersList, polist}) {
    useInjectReducer({ key: 'reallocationDetailsTable', reducer });
    useInjectSaga({ key: "reallocationDetailsTable", saga });

   
    const buttonstyle = {
        padding: "0",
        border: "none",
        minWidth: "0"
    };

    const columns = React.useMemo(
        () => [
            {
                Header: () => null,
                id: 'expander',
                Cell: ({ row }) => (
                    <button class={row.original.tenants && row.original.tenants.length > 0 ? "" : "hidden"
            } {...row.getToggleRowExpandedProps()} id={row.externalIdentifier} style={buttonstyle}
                        data-toggle="collapse" aria-expanded={row.isExpanded ? "true" : "false"} >
                        {
                            (row.original.tenants && row.original.tenants.length > 0) ?
                                (row.isExpanded ? <span>-</span> : <span>+</span>) : ""
                        }
                    </button>

                ),
                isVisible: isReadOnly ? true : false
            },
            {
                Header: 'Purchase Order Number',
                accessor: 'purchaseOrderNumber'
            },
            {
                Header: 'System PO Number',
                accessor: 'systemPONumber'
            },
            {
                Header: 'Created Date',
                accessor: 'createdDate'
            },
            {
                Header: 'Order Type',
                accessor: 'orderType'
            },
            {
                Header: () => null,
                id: 'addButton',
                accessor: 'addButton',
                isVisible: (!isReadOnly && !isApprovalScreen) ? true : false

            },
            {
                Header: 'Action',
                accessor: 'action',
                isVisible: (!isReadOnly && !isApprovalScreen) ? true : false
            },
            {
                Header: 'Expand',
                accessor: 'expanded',
                isVisible: false

            },
            {
                Header: 'Source Tenant',
                accessor: 'sourceTenant',
                isVisible: false
            }
        ]
    )

    const subTableColumns = React.useMemo(
        () => [

            {
                Header: 'Part Number',
                accessor: 'partNumber'
            },
            {
                Header: 'Product Description',
                accessor: 'productDescription'
            },
            {
                Header: 'Coverage Start',
                accessor: 'coverageStartDate'
            },
            {
                Header: 'Coverage End',
                accessor: 'coverageEndDate'
            },
            {
                Header: 'Destination Enrollment Number',
                accessor: 'tenantNumber'
            },
            {
                Header: 'Tenant Name',
                accessor: 'tenantName'
            },
            {
                Header: 'Reallocate Quantity',
                accessor: 'reallocateQuantity'
            },
            {
                Header: 'Purchase Order Number',
                accessor: 'purchaseOrderNumber'
            }
        ]
    )


    const renderRowSubComponent = React.useCallback(
        ({ row }) => (
            <SubTable columns={subTableColumns} data={row.original.tenants} />
        ),
        []
    )

    if (selectedPurchaseOrdersList && Array.isArray(selectedPurchaseOrdersList) && selectedPurchaseOrdersList.length > 0) {
        var data = [];
        let purchaseOrder = {};

        selectedPurchaseOrdersList.map((purchaseorder, key) => {
            purchaseOrder['purchaseOrderNumber'] = purchaseorder.purchaseOrderNumber;
            purchaseOrder['systemPONumber'] = purchaseorder.systemPONumber ? purchaseorder.systemPONumber : purchaseorder.purchaseOrderNumber;
            purchaseOrder['createdDate'] = purchaseorder.createdDate ? Helpers.DateFormatForDisplay(purchaseorder.createdDate) : '';
            purchaseOrder['orderType'] = purchaseorder.purchaseOrderTypeCode;
            purchaseOrder['action'] = !isReadOnly && !isApprovalScreen ?
                <button type="button" aria-label="delete" className="btn-link" onClick={() => onRemoval(purchaseorder.externalIdentifier)} data-tag={purchaseorder.externalIdentifier}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#3191e7" className="bi bi-trash" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                    </svg></button>
                : "";

            let selectedTenants = purchaseorder.selectedTenants;
            let reallocList = [];
            Object.keys(selectedTenants).length > 0 && Object.keys(selectedTenants).map((key) => {
                let reallocData = selectedTenants[key];
                reallocData.reallocationdetails && reallocData.reallocationdetails.map((reallocation) => {
                    let realloc = {};
                    realloc['partNumber'] = reallocData.selectedPartNumber.label;
                    realloc['productDescription'] = reallocData.productDescription;
                    realloc['coverageStartDate'] = reallocData.coveragePeriod.coverageStartDate;
                    realloc['coverageEndDate'] = reallocData.coveragePeriod.coverageEndDate;
                    realloc['tenantNumber'] = isReadOnly ? reallocation.enrolmentNumAndTenantName.tenantId :
                                                           reallocation.enrolmentNumAndTenantName.value.agreementNumber;
                    realloc['tenantName'] = reallocation.enrolmentNumAndTenantName.label;
                    realloc['reallocateQuantity'] = reallocation.reallocateQty;
                    realloc['purchaseOrderNumber'] = reallocation.poNumber;
                    reallocList.push(realloc);
                })

            });
            purchaseOrder['tenants'] = reallocList;
            purchaseOrder['addButton'] = !isReadOnly && !isApprovalScreen ?
                <button autoFocus="true" onClick={() => onAddReallocateClick({poNumber: purchaseorder.purchaseOrderNumber, externalIdentifier: purchaseorder.externalIdentifier, tenant: "TO1" })}>
                <FormattedMessage {...messages.reallocateBtn}/>
            </button> : "";


            data.push({ ...purchaseOrder });

        });

        return (
            <div>
            <Table
                columns={columns}
                data={data}
                renderRowSubComponent={renderRowSubComponent}
                isReadOnly={isReadOnly}
                isApprovalScreen={isApprovalScreen}
                selectedPurchaseOrdersList={selectedPurchaseOrdersList}
                onRemoval={onRemoval}
            />
               
                </div>
        );

    }
    return (
        <div className="form-group">
           {/* <label className="form-label">
                <FormattedMessage {...messages.noRecordsFound} />
            </label>*/}
        </div>)
}

ReallocationDetailsTable.propTypes = {
    isReadOnly: PropTypes.bool,
    onAddReallocateClick: PropTypes.func,
    onRemoval: PropTypes.func
};

function mapDispatchToProps(dispatch) {
    return {
        onAddReallocateClick: (data) => {
            if (data !== undefined) {
                let reallocationObject =
                {
                    poNumber: data.poNumber,
                    tenant: data.tenant,
                    externalIdentifier: data.externalIdentifier
                };

               
                return dispatch(onAddReallocate(reallocationObject));
            }
        },
        onRemoval: externalIdentifier => {
            if (externalIdentifier !== undefined) {
                return dispatch(onPORemoval(externalIdentifier));
            }
        }
    };
}

const mapStateToProps = createStructuredSelector({
    selectedPurchaseOrdersList: makeSelectSelectedPurchaseOrdersData(),
    polist: makeSelectPOs(),
    
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(ReallocationDetailsTable);

