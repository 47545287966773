import React from 'react';
import { injectIntl } from 'react-intl';
import $ from 'jquery';
export function PrivacyStatment(props) {


    $(document).ready(function(){        
                
        $("#create_privacy").blur(function(){
            console.log("create_privacy");
            $( ".onedash-hamburger-btn" ).trigger( "click" );
        });
    });

    const { formatMessage } = props.intl;
    var lblPrivacyStatment = formatMessage({ id: "lblPrivacyStatment" });
    return (
        <nav id="onedash-side-navigation_privacy" className="onedash-side-navigation is-open" aria-label="menubar">
            <div id="onedash-navigation-list_privacy" className="onedash-navigation-list">
                <div id="onedash-top-menus_privacy">                   
                    <div id="onedash-internaltools-menu-title_privacy" className="onedash-navigation-menu-title is-active">
                        <ul role="menu">
                            <li className="onedash-navigation-menu-item" role="none">
                                <a href="https://go.microsoft.com/fwlink/?LinkId=521839" id="create_privacy" role="menuitem" target="_blank">
                                    <div className="onedash-navigation-category" aria-label={lblPrivacyStatment} title={lblPrivacyStatment}>{lblPrivacyStatment}</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    );
}
export default injectIntl(PrivacyStatment);

