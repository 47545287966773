import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
//import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { injectIntl } from 'react-intl';
import $ from 'jquery';
export class GridControlFbc extends Component {
    componentDidMount() {               
        $('#tblCreditsListfbc').find('thead > tr > th').each(function (index, item) {
            $(item).attr("tabindex", "-1");
        });
        this.handleDataChange = this.handleDataChange.bind(this);
    }

    handleDataChange = ({ dataSize }) => {
        $('#tblCreditsListfbc').attr("aria-rowcount", dataSize);
    }
    render() {

        const { formatMessage } = this.props.intl;
        var _msg = formatMessage({ id: "grdNoRecords" });
        const NoRecordsFound = <div aria-label={_msg} aria-live="polite">{_msg}</div>;

        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            //alwaysShowAllBtns: true, // Always show next and previous button            
            hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: formatMessage({ id: "grdPaginationFirstText" }),
            prePageText: formatMessage({ id: 'grdPaginationBackText' }),
            nextPageText: formatMessage({ id: 'grdPaginationNextText' }),
            lastPageText: formatMessage({ id: 'grdPaginationLastText' }),
            nextPageTitle: formatMessage({ id: 'grdPaginationNextPageText' }),
            prePageTitle: formatMessage({ id: 'grdPaginationPrevPageText' }),
            firstPageTitle: formatMessage({ id: 'grdPaginationFirstPageText' }),
            lastPageTitle: formatMessage({ id: 'grdPaginationLastPageText' }),
            showTotal: false,
            sizePerPageList: [{
                text: '25', value: 25
            }, {
                text: '50', value: 50
            }, {
                text: formatMessage({ id: 'grdPaginationAllText' }), value: this.props.data.length
            }]
        };

        const tableCaption = <div style={{ "display": "none" }}>{formatMessage({ id: 'tblTitle' })}</div>;
        const tableTitle = formatMessage({ id: 'tblTitle' });
        $('#tblCreditsListfbc').attr("aria-label", tableTitle);
        const { SearchBar } = Search;
        


        return (
            <div className="col-sm-24 col-md-24 col-lg-24">
               <div aria-label={tableTitle}>
                <ToolkitProvider
                    keyField='purchaseOrderId'
                    columns={this.props.columns}
                    data={this.props.data}                   
                    search={{
                        searchFormatted: true
                    }}
                    aria-label={tableTitle}
                >
                    {
                        toolkitprops => (
                            <div>
                               
                                <BootstrapTable
                                    id="tblCreditsListfbc"
                                    onDataSizeChange={this.handleDataChange}
                                    caption={tableCaption}
                                    role="table"
                                    loading="Loading"
                                    aria-label={tableTitle}
                                    hover
                                    {...toolkitprops.baseProps}
                                    condensed
                                    noDataIndication={NoRecordsFound}
                                    selectRow={this.props.selectRowProp}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>
            </div>
            </div>
        );

    }
}
export default injectIntl(GridControlFbc);
