import { pick, map, mapKeys } from 'lodash';
import { responseFilter } from './responseFilter';
import { responseMap } from './responseMap';

export const transformResponse = (resource, data) => {
    const getMappedKey = (_value, key) => {
        return (responseMap.hasOwnProperty(resource)
            && responseMap[resource].hasOwnProperty(key)) ?
            responseMap[resource][key] : key;
    };
    const filteredData
        = (Array.isArray(data)) ?
            map(data, d => pick(d, responseFilter[resource])) :
            map(data, () => pick(data, responseFilter[resource]));   

    return map(filteredData, d =>mapKeys(d, getMappedKey));
};


