import produce from 'immer';
import {
    GET_DOMAIN_DATA, DOMAIN_DATA_FETCHED, DOMAIN_DATA_ERROR,
    USER_ROLES, USER_ROLES_FETCHED, USER_ROLES_ERROR,
    ACCESSTOKEN, AUTHTYPE,
    USER_PREFS, USER_PREFS_FETCHED, USER_PREFS_ERROR
} from './constants';

export const initialState = {
    loading: false,
    error: false,
    userInfo: {
        userAccessToken: null, authType: null, email: null, name: null, accessorGuid: null, roles: null, status: null,linkedAccounts: null,isLinkedAccountOverride: null,userPref: {}
    },    
    domainData: []
};

const appReducer = (state = initialState, action) =>

    produce(state, draft => {

        switch (action.type) {

            case GET_DOMAIN_DATA:
                draft.loading = true;
                draft.error = false;
                draft.domainData = [];
                break;

            case DOMAIN_DATA_FETCHED:
                draft.domainData = action.domainData;
                draft.loading = false;
                break;

            case DOMAIN_DATA_ERROR:
                draft.error = action.error;
                draft.loading = false;
                break;

            case USER_ROLES:
                draft.rolesloading = true;
                draft.error = false;
                break;

            case USER_ROLES_FETCHED:                
                draft.userInfo.email = action.userInfo.email;
                draft.userInfo.name = action.userInfo.name;
                draft.userInfo.roles = action.userInfo.roles;
                draft.userInfo.accessorGuid = action.userInfo.uid;
                draft.userInfo.status = action.userInfo.status;
                draft.userInfo.linkedAccounts = action.userInfo.linkedAccounts; 
                draft.userInfo.isLinkedAccountOverride = action.userInfo.isLinkedAccountOverride;               
                draft.rolesloading = false;
                break;

            case USER_ROLES_ERROR:
                if (action.error.message === "Unauthorized") {
                    draft.userInfo.roles = [];
                } else {
                    draft.userInfo.roles = null;
                }
                draft.error = action.error;
                draft.rolesloading = false;
                break;

            case USER_PREFS:                              
                draft.loading = true;
                draft.error = false;
                break;

            case USER_PREFS_FETCHED:                
                draft.userInfo.userPref = action.userPrefs;                                
                draft.loading = false;
                break;

            case USER_PREFS_ERROR:
                draft.error = action.error;
                draft.loading = false;
                break;

            case ACCESSTOKEN:
                draft.userInfo.userAccessToken = action.accessToken;
                draft.loading = false;
                draft.error = false;
                break;

            case AUTHTYPE:
                draft.userInfo.authType = action.authType;
                draft.loading = false;
                draft.error = false;
                break;
            default:
                draft.loading = false;
                draft.error = false;
                break;
        }
    });

export default appReducer;
