import React, { memo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import SearchCreditsOps from '../../../search/operations/components/index';
import ApprovalSearch from '../../../approvalSearch/components/index';
import SubmitHome from '../../../submit/operations/containers/submitHome';
import Approve from '../../../approval/approver/creditApprover/containers/home';
import PrivacyStatement from '../privacy/index';
import CancelFutureBillingMenu from './CancelFutureBillingMenu';
import CancelFutureBillingSearch from '../../../search/cancelFutureBilling/components/index';
import CancelFutureBillingSearchHome from "../../../approval/approver/fbcApprover/home";
import { isFbcEnabled } from '../../internals/utils/featureFlag';
import CancelFutureBilligOpsHome from '../../../submit/operations/containers/cancelFutureBillingHome';
import MultiTenantHome from '../../../submit/operations/containers/multiTenantHome';
import MultiTenantMenu from './MultiTenantMenu';
import multiTenant from '../../../search/multiTenant/components/index';
import { isMtEnabledForUser } from "./MultiTenantMenu";
import UnAuthorized from "./../unauthroised";
import '../../app/accessibility-style.css';
import { RetirementMessage } from '../RetirementMessage/RetirementMessage';


export function OpsUserMenu(props) {

    const { formatMessage } = props.intl;
    const isFbcEnabledFlag = isFbcEnabled();
    const isMTEnabled = isMtEnabledForUser(props.userInfo);
    var lblCreateRequestText = formatMessage({ id: "lblCreateRequestText" });
    var lblSearchText = formatMessage({ id: "lblSearchText" });
    var lblApprovalText = formatMessage({ id: "lblApprovalText" });

    return (


        <div className="row remove-all-margin">
            <div className="col-md-5 col-lg-3 side-nav-wrap">
                <div className="onedash-container" data-bi-area="OneDashboardSide" role="navigation">
                    <nav id="onedash-side-navigation" className="onedash-side-navigation mobileHide is-open" aria-label="menubar">

                        <div id="onedash-navigation-list" className="onedash-navigation-list">
                            <div id="onedash-top-menus">
                                <input type="hidden" id="applicationMenuDropDownCommon" data-accountid="0" name="common-accountid" />
                                <div id="partner-company-name"
                                    className="onedash-accountpicker-account is-visible">
                                    <span className="activeAccount activeAccountTitle" aria-label={lblCreateRequestText}>{lblCreateRequestText}</span>
                                </div>

                                <div id="onedash-internaltools-menu-title" className="onedash-navigation-menu-title is-active">
                                    <ul role="menu">
                                        <li className="onedash-navigation-menu-item" role="none">
                                            <a href="/ops/search" id="linkListSubmitterSearch" role="menuitem" className="focusdiv">
                                                <div className="onedash-navigation-category" aria-label={lblCreateRequestText + lblSearchText}>{lblSearchText}</div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div id="partner-company-name2"
                                    className="onedash-accountpicker-account is-visible">
                                    <span className="activeAccount" aria-label={lblApprovalText} >{lblApprovalText}</span>
                                </div>

                                <div id="onedash-internaltools-menu-title-2" className="onedash-navigation-menu-title is-active">
                                    <ul role="menu">
                                        <li className="onedash-navigation-menu-item" role="none">
                                            <a href="/approver/approvalSearch" id="linkListApproverSearch" role="menuitem">
                                                <div className="onedash-navigation-category" aria-label={lblApprovalText + lblSearchText}>{lblSearchText}</div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    {isFbcEnabledFlag === "True" ? <CancelFutureBillingMenu /> : null}
                                </div>

                                <div>
                                    {isMTEnabled && <MultiTenantMenu />}
                                </div>

                            </div>
                        </div>
                        <div><PrivacyStatement /></div>
                    </nav>
                </div>

            </div>
            <div className="col-sm-24 col-md-19 col-lg-21 rightview">
                {RetirementMessage(props?.userInfo?.roles)}
                <Switch>
                    <Route path="/ops/search" component={SearchCreditsOps} />
                    <Route path="/ops/create/:invoice?/:requestId?/:requestStatus?" component={SubmitHome} />
                    <Route path="/approver/approvalSearch" component={ApprovalSearch} />
                    <Route path="/fbc/request:docId?/:requestId?/:requestStatus?" component={CancelFutureBillingSearchHome} />
                    <Route path="/fbc/search" component={CancelFutureBillingSearch} />
                    <Route path="/fbc/create/:enrollmentNumber?/:systemPONumber?/:requestId?/:requestStatus?" render={() => <CancelFutureBilligOpsHome />} />
                    <Route path="/approver/approve/:invoice?/:requestId?/:requestStatus?" component={Approve} />
                    <Route path="/ops/multiCreate/:enrollmentNumber?/:systemPONumber?/:requestId?/:requestStatus?" render={() => <SubmitHome isMultiInvoice={true} />} />
                    {isMTEnabled ?
                        <>
                            <Route path="/multitenant/create/:leadEnrollmentNumber?" render={() => <MultiTenantHome />} />
                            <Route path="/multitenant/search" component={multiTenant} />
                        </> :
                        <>
                            <Route path="/multitenant/create/:leadEnrollmentNumber?" render={() => <UnAuthorized />} />
                            <Route path="/multitenant/search" component={() => <UnAuthorized />} />
                        </>}
                    <Route path="/" render={() => (<Redirect to="/ops/search" push={true} />)} />
                </Switch>
            </div>
        </div>

    );
}

const withConnect = connect(null, null);
export default compose(withConnect, memo)(injectIntl(OpsUserMenu));
