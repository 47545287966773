import { defineMessages } from 'react-intl';

export const scope = 'multiTenantSearchHome';

export default defineMessages({
    reallocateLicenses: {
        id: `${scope}.reallocateLicenses`,
        defaultMessage: 'Reallocate Licenses',
    }


});
