import { CHANGE_CANCEL_REASON, ADD_OTHER_CANCEL_REASON, SET_OTHER_CANCEL_REASON, INVOKE_CANCEL_REASON_REQUEST_SUCCESS, INVOKE_CANCEL_REASON_REQUEST_FAIL, INVOKE_CANCEL_REASON_APIREQUEST, SHOW_CANCEL_CONFIRMATION, CLOSE_CANCEL_CONFIRMATION } from './constants';

export function changeCancelReason(value) {
    return {
        type: CHANGE_CANCEL_REASON,
        value
    };
}

export function addOtherCancelReason(value) {    
    return {
        type: ADD_OTHER_CANCEL_REASON,
        value
    };
}

export function setOtherCancelReason(value) {   
    return {
        type: SET_OTHER_CANCEL_REASON,
        value
    };
}

export function cancelAPIInvoke(value) {
    return {
        type: INVOKE_CANCEL_REASON_APIREQUEST,
        value
    };
}

export function cancelAPIInvokeSuccess(value) {    
    return {
        type: INVOKE_CANCEL_REASON_REQUEST_SUCCESS,
        value
    };
}

export function cancelAPIInvokeFail(value) {    
    return {
        type: INVOKE_CANCEL_REASON_REQUEST_FAIL,
        value
    };
}

export function showCancelConfirmation() {
    return { type: SHOW_CANCEL_CONFIRMATION };
}

export function closeCancelConfirmation() {
    return { type: CLOSE_CANCEL_CONFIRMATION };
}