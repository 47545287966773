import {
    FBC_SUBMIT_RETURN,
    FBC_SUBMIT_RETURN_SUCCESS,
    FBC_SUBMIT_RETURN_FAIL,
    FBC_TOGGLE_ACTIONS,
    FBC_VALIDATE_SUBMIT_FORM,
    FBC_SHOW_REBILL_CONFIRMATION,
    FBC_CLOSE_REBILL_CONFIRMATION,
    FBC_SHOW_LINEITEM_CONFIRMATION,
    FBC_CLOSE_LINEITEM_CONFIRMATION
} from "./constants";

export function submitFBCReturnForm() {
    return { type: FBC_SUBMIT_RETURN };
}

export function submitReturnFormSuccess(response) {
    return { type: FBC_SUBMIT_RETURN_SUCCESS, response };
}

export function submitReturnFormFailure(error) {
    return { type: FBC_SUBMIT_RETURN_FAIL, error };
}

export function toggleActions() {
    return { type: FBC_TOGGLE_ACTIONS };
}

export function validateSubmitForm() {
    return { type: FBC_VALIDATE_SUBMIT_FORM };
}
export function showRebillConfirmation() {
    return { type: FBC_SHOW_REBILL_CONFIRMATION };
}

export function closeRebillConfirmation() {
    return { type: FBC_CLOSE_REBILL_CONFIRMATION };
}
export function showLineItemConfirmation() {
    return { type: FBC_SHOW_LINEITEM_CONFIRMATION };
}

export function closeLineItemConfirmation() {
    return { type: FBC_CLOSE_LINEITEM_CONFIRMATION };
}

