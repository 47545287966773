import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { pick } from "lodash";

import reducer from "../../../operations/containers/cancelFutureBillingRequest/reducer";
import saga from "../../../operations/containers/cancelFutureBillingRequest/saga";

import { useInjectSaga } from "../../../../common/internals/utils/injectSaga";
import { useInjectReducer } from "../../../../common/internals/utils/injectReducer";


import CancelFutureBillingCreateRequest from "../../../common/components/cancelFutureBillingCreateRequest";
import CancelFutureBillingFetchRequest from "../../../common/components/cancelFutureBillingFetchRequest";

import { collapseAllSections, expandAllSections, getPoData } from "../../../operations/containers/cancelFutureBillingRequest/actions";


import EnrollmentDetails from "../../../../common/components/enrollmentDetails/Loadable";
import ContactDetails from "../../../../common/components/contactDetails/Loadable";
import FBCPartnerReturnForm from "../fbcPartnerReturnForm/index";
import collapse from "../../../../common/components/collapsableSection/collapseAll";
import makeSelectCancelFutureBillingRequest,{makeSelectPoFetch,makeSelectPoError,makeSelectPoIsLoading} from "../../../operations/containers/cancelFutureBillingRequest/selectors";
import FBCPartnerReturnDetails from '../../components/fbcPartnerReturnDetails/Loadable'; //'../../../operations/components/cfbReturnDetails/Loadable';

import { makeSelectLicensesCreate,makeSelectSystemPONumberCreate,makeSelectPoItem } from '../../../common/components/cancelFutureBillingCreateRequest/selectors';
import { makeSelectLicensesFetch } from '../../../common/components/cancelFutureBillingFetchRequest/selectors';

import { makeSelectIsFBCReturnFormReadOnly } from './selectors';
import CFBLineItemDetails from "../../../common/components/cfblineItemDetails/Loadable";
import CFBLineItemDetailsReadOnly from "../../../common/components/cfblineItemDetailsReadOnly/Loadable";
import { makeSelectisFullCancellation, makeSelectRebillPONumber, makeSelectReturnReason } from '../../components/fbcPartnerReturnDetails/selectors' //'./selectors';
import GridControl from "../../../../common/components/grid/GridControlFbc";

//var onSelectPo = false;
function FBCPartnerReturnRequest({
    onCollapseSections,
    licensesFromCreate,
    licensesFromFetch,
    isReadOnly,
    isFullCancellation,
    rebillPONumber, 
    returnReason,
    selectPoFetch,
    fetchPoDetails,
    getSelectPoError,
    isPoLoading,
    searchSystemPONumber,
    isSelectPo
}) {
    //useInjectReducer({ key: "CancelFutureBillingRequest", reducer });
    useInjectSaga({ key: "CancelFutureBillingRequest", saga });
    useInjectReducer({ key: "CancelFutureBillingRequest", reducer });

    var licensenumber = new URLSearchParams(window.location.search).get("licensenumber");

    const invoices = licensenumber ? licensesFromFetch : licensesFromCreate;

    const status = invoices && invoices.requestStatusCode;

    var selectPoDetails = '';

    var isApprovalPage = false;
    if (licensenumber) {
        isApprovalPage = true;
    }

    if(invoices && (invoices.enrollmentNumber || invoices.systemPONumber)){
        selectPoDetails = invoices;
        //onSelectPo = false;
    }else if(invoices && invoices.length > 0 && isSelectPo == true){
        const selectedPoError = getSelectPoError;
        /*if(selectedPoError && selectedPoError.errorMessages){
            onSelectPo = false;
        }*/
            selectPoDetails = selectPoFetch;
        
    }

    const { enrollmentDetailsProps, contactDetailsProps } = selectPoDetails ? sliceStateForComponents(selectPoDetails) : {};

    let columns = [
        {
            dataField: "purchaseOrderNo",
            text: "Purchase Order Number",
            title: true,
            headerTitle: true,
        },{
            dataField: "SystemPurchaseOrderNo",
            text: "System Purchase Order Number",
            title: true,
            headerTitle: true,
        },
        {
            dataField: "agreementNo",
            text: "Enrollment Number",
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        },
        {
            dataField: "endCustomerName",
            text: "End Customer",
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        },
        {
            dataField: 'orderCreatedDate',
            text: "Created Date",
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        },
        {
            dataField: "orderType",
            text: "Order Type",
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        },
        {
            dataField: "invoice_number",
            isDummyField: true,
            text: "Invoice Number",
            formatter: (cellContent, row) => {
                var mostRecentObject = "";
                if(row.billingPurchaseDataObjectList && row.billingPurchaseDataObjectList.length > 0){
                    var list = row.billingPurchaseDataObjectList;
                    const max_date = new Date(Math.max(...list.map(e => e.invoiceNumber && new Date(e.billOnDate))));
                    if(max_date){
                        mostRecentObject = list.filter( e => { var d = new Date( e.billOnDate ); return d.getTime() == max_date.getTime();})[0];
                    }
                    console.log("mostRecentObject_partner:",mostRecentObject);
                }
               if(mostRecentObject && mostRecentObject.invoiceNumber){
                return mostRecentObject.invoiceNumber;
               }else{
                   return "";
               }
                },
            title: true,
            headerTitle: true,
        }
    ];

    const selectRow = {
        mode: 'radio', // single row selection
        onSelect: (row, isSelect, rowIndex, e) => {
            //alert(JSON.stringify(row));
            const data_po = fetchPoDetails(row.purchaseOrderId);
            //onSelectPo = true;
          }
      };

    return (
        <div>

            {licensenumber ? <CancelFutureBillingFetchRequest cosmicCaseNumberEnabled={true} /> : <CancelFutureBillingCreateRequest cosmicCaseNumberEnabled={true} />}
            <div>
            <div style={{marginTop:'10px',marginBottom:'10px'}}>{invoices && invoices.length > 0 ? (<h2>Search resulted in multiple POs. Select one to proceed.</h2>):null}</div>
            {invoices && invoices.length > 0 ? (
                <div className="row spacer-xs-bottom">
                            <GridControl
                                key="gridcontrolfordatadisplay"
                                columns={columns}
                                data={invoices}
                                selectRowProp={selectRow}
                            />
                        </div>):null}
                <div className={(invoices && (invoices.enrollmentNumber || invoices.systemPONumber)) || (invoices && invoices.length > 0 && isSelectPo == true && !isPoLoading) ? "" : "hidden"} >
                    <div className="pull-right">
                        <input
                            name="collapsebtn"
                            style={invoices && invoices.length > 0 && isSelectPo == true ? { marginTop: 0 } : { marginTop: -200 }}
                            type="button"
                            value="Expand All"
                            id="CancelFutureBillingRequestCollapsebtn"
                            onClick={onCollapseSections}
                        ></input>                        
                    </div>
                    <EnrollmentDetails {...enrollmentDetailsProps} isMultiInvoice={true} />
                    <ContactDetails {...contactDetailsProps} />
                    <FBCPartnerReturnDetails />
                    <div className="row spacer-ml-bottom">
                        <div className="col-lg-24">
                            {
                                isFullCancellation || isReadOnly ?
                                    <CFBLineItemDetailsReadOnly /> :
                                    <CFBLineItemDetails />
                            }
                        </div>
                    </div>
                    <FBCPartnerReturnForm invoices={invoices} />
                </div>
            </div>
        </div>
    );
}

function sliceStateForComponents(licensesFromState) {
    const enrollmentDetailsProps = licensesFromState.enrollmentNumber || licensesFromState.systemPONumber ? pick(licensesFromState,
        [
            "enrollmentNumber",
            "programCode",
            "masterAgmNumber",
            "billToCountryCode",
            "salesLocationCode"
        ]) : {};

    const contactDetailsProps = licensesFromState.enrollmentNumber || licensesFromState.systemPONumber ? pick(licensesFromState,
        [
            "customerPCN",
            "customerName",
            "customerEmail",
            "directPartnerPCN",
            "partnerName",
            "partnerEmail",
            "resellerPCN",
            "softwareAdvisorPCN",
            "resellerName",
            "softwareAdvisorName",
            "resellerEmail"
        ]) : {};

    return {
        enrollmentDetailsProps,
        contactDetailsProps
    };
}

FBCPartnerReturnRequest.propTypes = {
    invoicesFromState: PropTypes.object,
    onCollapseSections: PropTypes.func,
    isReadOnly: PropTypes.bool,
    isFullCancellation: PropTypes.bool,
    fetchPoDetails: PropTypes.func,
    getSelectPoError: PropTypes.func,
    isPoLoading: PropTypes.bool,
    searchSystemPONumber: PropTypes.string,
    isSelectPo: PropTypes.bool
};
    

const mapStateToProps = createStructuredSelector({
    multiInvoiceReturnRequest: makeSelectCancelFutureBillingRequest(),
    licensesFromCreate: makeSelectLicensesCreate(),
    licensesFromFetch: makeSelectLicensesFetch(),
    isReadOnly: makeSelectIsFBCReturnFormReadOnly(),
    isFullCancellation: makeSelectisFullCancellation(),
    rebillPONumber: makeSelectRebillPONumber(),
    returnReason: makeSelectReturnReason(),
    selectPoFetch: makeSelectPoFetch(),
    getSelectPoError: makeSelectPoError(),
    isPoLoading: makeSelectPoIsLoading(),
    searchSystemPONumber: makeSelectSystemPONumberCreate(),
    isSelectPo: makeSelectPoItem()
});

function mapDispatchToProps(dispatch) {
    return {
        onCollapseSections: evt => {
            var elem = document.getElementById("CancelFutureBillingRequestCollapsebtn");
            var idarr = [
                "invoiceDetails",
                "lineItemDetails",
                "enrollmentDetails",
                "contactDetails",
                "rebillDetails",
                "fileUpload",
                "multiInvoiceReturnDetails"
            ];
            if (elem.value == "Expand All") {
                elem.setAttribute("value", "Collapse All");
                collapse(true, idarr);
                return dispatch(expandAllSections());
            } else {
                elem.setAttribute("value", "Expand All");
                collapse(false, idarr);
                return dispatch(collapseAllSections());
            }
        },
        fetchPoDetails: (poId) => dispatch(getPoData(poId))
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FBCPartnerReturnRequest);
