import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { onCheckboxAction } from './actions';
import reducer from './reducer';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import * as Helpers from '../../../../common/internals/utils/helpers';
import { useTable, usePagination } from 'react-table';
import { makeSelectPOList } from '../../containers/poSelectionModal/selectors';
function Header({ }) {
    return (
        <thead><tr>
            <th></th>
            <th style={{ fontWeight: 'bolder', color: 'black' }}><FormattedMessage {...messages.purchaseOrderNumber} /></th>
            <th style={{ fontWeight: 'bolder', color: 'black' }}><FormattedMessage {...messages.systemPONumber} /></th>
            <th style={{ fontWeight: 'bolder', color: 'black' }}><FormattedMessage {...messages.createdDate} /></th>
            <th style={{ fontWeight: 'bolder', color: 'black' }}><FormattedMessage {...messages.orderType} /></th>
        </tr></thead>);
}

function Table({ columns: userColumns, data}) {


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns: userColumns,
            data,
            initialState: { pageIndex: 0 },

        },
        usePagination
    )

    return (
        <div className="table-responsive"style={{ overflow: 'inherit' }}>
            <table {...getTableProps()} className="table table-responsive" aria-live='polite' role="table">
                <Header />
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <React.Fragment>
                                <tr role="row">
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        )
                                    })}
                                </tr>

                            </React.Fragment>
                        )
                    })}
                </tbody>
            </table>
            <div className="pagination" aria-live="polite">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} aria-label="first page">
                    {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage} aria-label="previous page">
                    {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage} aria-label="next page">
                    {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} aria-label="last page">
                    {'>>'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        aria-label="go to page"
                        style={{ width: '100px' }}
                    />
                </span>{' '}

            </div>
        </div>
    )
}

export function POSelectionTable({  poList, onCheckBoxAction }) {
    useInjectReducer({ key: 'poSelectionTable', reducer });

    const columns = React.useMemo(
        () => [
            {
                Header: 'Action',
                accessor: 'action'
            },
            {
                Header: 'Purchase Order Number',
                accessor: 'purchaseOrderNumber'
            },
            {
                Header: 'System PO Number',
                accessor: 'systemPONumber'
            },
            {
                Header: 'Created Date',
                accessor: 'createdDate'
            },
            {
                Header: 'Order Type',
                accessor: 'orderType'
            }
        ]
    )

    if (poList && Array.isArray(poList) && poList.length > 0) {
        var data = [];
        let purchaseOrder = {};
        poList.map((purchaseorder, key) => {
            if (poList.length === 1) {
                purchaseOrder['action'] = <input id={purchaseorder.externalIdentifier}
                    type="checkbox" checked={true}
                    aria-label="chosenPurchaseOrder"/>;
                
                purchaseorder.isSelected = true;
            }
            else {
                purchaseOrder['action'] = <input id={purchaseorder.externalIdentifier}
                    type="checkbox"
                    onClick={onCheckBoxAction}
                    aria-label="chosenPurchaseOrder"
                />;
            }
            purchaseOrder['purchaseOrderNumber'] = purchaseorder.purchaseOrderNumber;
            purchaseOrder['systemPONumber'] = purchaseorder.systemPONumber ? purchaseorder.systemPONumber : purchaseorder.purchaseOrderNumber;
            purchaseOrder['createdDate'] = purchaseorder.createdDate ? Helpers.DateFormatForDisplay(purchaseorder.createdDate) : '';
            purchaseOrder['orderType'] = purchaseorder.purchaseOrderTypeCode;

            data.push({ ...purchaseOrder });

        });


   

        return (
            <Table
                columns={columns}
                data={data}
            />
        );

    }
    return (
        <div className="form-group">
            <label className="form-label">
                <FormattedMessage {...messages.noRecordsFound} />
            </label>
        </div>)
}


POSelectionTable.propTypes = {
    isReadOnly: PropTypes.bool,
    onAddReallocateClick: PropTypes.func,
    onRemoval: PropTypes.func
};

function mapDispatchToProps(dispatch) {
    return {
        onCheckBoxAction: evt => {
            if (evt !== undefined) {
                return dispatch(onCheckboxAction(evt.target.id, evt.target.checked));
            }
        }
    };
}

const mapStateToProps = createStructuredSelector({
    poList: makeSelectPOList()
});

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(POSelectionTable);