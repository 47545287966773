import React, { useEffect, memo } from "react";
import { createStructuredSelector } from "reselect";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import messages from "./messages";
import reducer from "./reducer";
import saga from "./saga";
import Input from '../../../../common/components/Input';

import { useInjectReducer } from "../../../../common/internals/utils/injectReducer";
import LoadingIndicator from "../../../../common/components/LoadingIndicator";
import { FormattedMessage } from 'react-intl';
import { useInjectSaga } from "../../../../common/internals/utils/injectSaga";
import {
    changeSystemPONumber,
    search,
    clearEnrollmentNumber,
    clearSystemPONumber,
    changeEnrollmentNumber
} from "./actions";
import {
    makeSelectCancelFutureBillingCreateRequest,
    makeSelectEnrollmentNumberCreate,
    makeSelectSystemPONumberCreate,
    makeSelectCancelFutureBillingCreateRequestLoading,
    makeSelectCancelFutureBillingCreateRequestError,
    makeSelectCancelFutureBillingPoRequestLoading,
} from "./selectors";

function CancelFutureBillingCreateRequest({
    loading,
    enrollmentNumber,
    systemPONumber,
    onSearch,
    onClearEnrollmentNumber,
    onClearSystemPONumber,
    onChangeEnrollmentNumber,
    onChangeSystemPONumber,
    isPoLoading
}) {
    useInjectReducer({ key: "CancelFutureBillingCreateRequest", reducer });
    useInjectSaga({ key: "CancelFutureBillingCreateRequest", saga });

    useEffect(() => {
        if (
            (enrollmentNumber && enrollmentNumber.trim().length > 0) ||
            (systemPONumber && systemPONumber.trim().length > 0)
        ) {
            onSearch();
        }
    }, []);

    const handleKeyPress = event => {
        if (event.key === "Enter") {
            onSearch();
        }
    };
    var userRole = window && window.userRole

    return (
      <div>
        <div className="row">
          <div className="col-md-24 form-group">
            <div className="row">
              <form
                onSubmit={onSearch}
                role="form"
                name="frmFBCCancellationRequest"
                id="frmFBCCancellationRequest"
              >
                <div className="col-sm-8 col-md-8 col-lg-6 col-xl-4">
                  <Input
                    type="text"
                    name="btnEnrollmentNumber"
                    id="btnEnrollmentNumber"
                    required={true}
                    className="form-control mainElement"
                    label={messages.enterEnrollmentNumber}
                    defaultmessage={messages.enterEnrollmentNumber}
                    aria-label={messages.enterEnrollmentNumber}
                    autoComplete="off"
                    value={enrollmentNumber || ""}
                    onChange={onChangeEnrollmentNumber}
                    onKeyDown={handleKeyPress}
                  />
                </div>

                <div className="col-sm-11 col-md-11 col-lg-9 col-xl-6">
                  {userRole === "VPU" && (
                    <div>
                      <Input
                        type="text"
                        name="btnSystemPONumber"
                        id="btnSystemPONumber"
                        required={true}
                        className="form-control"
                        aria-label={messages.enterPONumber}
                        label={messages.enterPONumber}
                        autoComplete="off"
                        value={systemPONumber || ""}
                        onChange={onChangeSystemPONumber}
                        onKeyDown={handleKeyPress}
                      />
                    </div>
                  )}
                  {userRole !== "VPU" && (
                    <div>
                      <Input
                        type="text"
                        name="btnSystemPONumber"
                        id="btnSystemPONumber"
                        required={true}
                        className="form-control"
                        label={messages.enterSystemPONumber}
                        aria-label={messages.enterSystemPONumber}
                        autoComplete="off"
                        value={systemPONumber || ""}
                        onChange={onChangeSystemPONumber}
                        onKeyDown={handleKeyPress}
                      />
                    </div>
                  )}
                </div>

                <div className="col-sm-8 col-md-8 col-lg-6 col-xl-4">
                  <button
                    style={{
                      marginLeft: "20px",
                      background: "black",
                      color: "white",
                      top: "28px",
                    }}
                    type="submit"
                  >
                    Search
                  </button>

                  <div className={(loading == true || isPoLoading == true) ? "pull-right" : "hidden"}>
                    <LoadingIndicator />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
}

CancelFutureBillingCreateRequest.prototype = {
    loading: PropTypes.bool,
    enrollmentNumber: PropTypes.string,
    systemPONumber: PropTypes.string,
    onChangeSystemPONumber: PropTypes.func,
    onChangeEnrollmentNumber: PropTypes.func,
    onClear: PropTypes.func,
    isPoLoading: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
    createMultiInvoiceRequest: makeSelectCancelFutureBillingCreateRequest(),
    enrollmentNumber: makeSelectEnrollmentNumberCreate(),
    systemPONumber: makeSelectSystemPONumberCreate(),
    loading: makeSelectCancelFutureBillingCreateRequestLoading(),
    error: makeSelectCancelFutureBillingCreateRequestError(),
    isPoLoading: makeSelectCancelFutureBillingPoRequestLoading()

});

function mapDispatchToProps(dispatch) {
    return {

        onChangeEnrollmentNumber: evt => {
            //console.log(evt.target.value);
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(changeEnrollmentNumber(evt.target.value.trim()))
        },

        onChangeSystemPONumber: evt => {
            //console.log(evt.target.value);
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(changeSystemPONumber(evt.target.value))
        },

        onSearch: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(search());
        },
        onClearEnrollmentNumber: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(clearEnrollmentNumber());
        },
        onClearSystemPONumber: evt => {
            if (evt !== undefined && evt.preventDefault) evt.preventDefault();
            dispatch(clearSystemPONumber());
        }
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(CancelFutureBillingCreateRequest);
