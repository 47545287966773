import { put, select, takeLatest } from 'redux-saga/effects';
import { REALLOCATION_MODAL_DETAILS_CHANGED } from '../../components/mtModalReallocationTable/constants';
import { makeGlobalState } from '../../containers/reallocateModal/selectors';
import { CURRENT_CP_REALLOCATION_MODAL_DETAILS_CHANGED, CURRENT_REALLOCATION_MODAL_DETAILS_CHANGED } from '../../containers/reallocationBody/constants';
import { UPDATE_REALLOCATION_BODY } from '../reallocationBody/constants';
import { partNumberToFocus, updateAddMore, updatePartNumberdetails } from './actions';
import { PART_NUMBER_DELETED } from './constants';

export function* updatePartNumberMenu(action) {
    let partNumberMenu = Object.keys(action.value).map((key) => action.value[key].selectedPartNumber);
    let partNumberFocussed = partNumberMenu && partNumberMenu[0];

    yield put(updatePartNumberdetails(partNumberMenu));
    yield put(partNumberToFocus(partNumberFocussed));
}

export function* isReallocationDetailsValid(action) {
    const globalState = yield select(makeGlobalState());
    if (globalState && globalState.reallocationBody && globalState.reallocationBody.reallocationBody) {
        let reallocationBody = globalState.reallocationBody.reallocationBody;
        let keys = Object.keys(reallocationBody);
        let invalidPOs = [];
        let disableAddMore = false;
        for (let key in keys) {
            let currentTenant = reallocationBody[keys[key]];
            let originalQty = currentTenant.originalQuantity;
            let reallocatedQty = 0;

            if (currentTenant['selectedPartNumber'] != null && currentTenant['coveragePeriod'] != null && currentTenant['reallocationdetails'] != null && currentTenant['reallocationdetails'].length > 0) {
                for (let [i, currentReallocationDetail] of currentTenant.reallocationdetails.entries()) {

                    reallocatedQty += parseInt(currentReallocationDetail.reallocateQty);

                    if (currentReallocationDetail.enrolmentNumAndTenantName == null ||
                        currentReallocationDetail.enrolmentNumAndTenantName == '' ||
                        currentReallocationDetail.reallocateQty == null ||
                        currentReallocationDetail.reallocateQty <= 0 ||
                        currentReallocationDetail.reallocateQty == '' ||
                        currentReallocationDetail.reallocateQty == '0'

                    ) {

                        invalidPOs.push(currentTenant['selectedPartNumber'].label);
                        disableAddMore = true;
                        break;
                    }
                }

                if (reallocatedQty > originalQty) {
                    if (currentTenant['selectedPartNumber']) {
                        invalidPOs.push(currentTenant['selectedPartNumber'].label);
                    }
                    disableAddMore = true;
                }
            }
            else {
                if (currentTenant['selectedPartNumber']) {
                    invalidPOs.push(currentTenant['selectedPartNumber'].label);
                }
                disableAddMore = true;
            }
        }
        yield put(updateAddMore({ invalidPOs, disableAddMore }));
        return;
    }
}


export default function* createRequestSaga() {
    yield takeLatest(UPDATE_REALLOCATION_BODY, updatePartNumberMenu);
    yield takeLatest(REALLOCATION_MODAL_DETAILS_CHANGED, isReallocationDetailsValid);
    yield takeLatest(CURRENT_REALLOCATION_MODAL_DETAILS_CHANGED, isReallocationDetailsValid);
    yield takeLatest(CURRENT_CP_REALLOCATION_MODAL_DETAILS_CHANGED, isReallocationDetailsValid);
    yield takeLatest(PART_NUMBER_DELETED, isReallocationDetailsValid);
}

