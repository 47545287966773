import { RESET_TO_INITIAL_STATE, SEARCH_PO, SEARCH_TENANT, SEARCH_TENANT_ERROR, SEARCH_TENANT_SUCCESS, SELECT_PO, UPDATE_DROPDOWN_OPTIONS, UPDATE_PO_LIST, UPDATE_SELECTED_POS, UPDATE_SELECTED_POS_REALLOCATION } from './constants';


export function searchtenant(tenant) {
    return {
        type: SEARCH_TENANT,
        tenant
    };
}

export function searchPOs(polist) {
    return {
        type: SEARCH_PO,
        polist
    }
}

export function selectPO(selectedPO) {
    return {
        type: SELECT_PO,
        selectedPO
    }
}

export function updatePOList(unchecked) {
    return {
        type: UPDATE_PO_LIST,
        unchecked
    }
}



export function getTenantCompleted(purchaseOrdersList, tenantNumber) {
    return {
        type: SEARCH_TENANT_SUCCESS,
        purchaseOrdersList,
        tenantNumber,
    }
}

export function getTenantSearchError(error) {
    return {
        type: SEARCH_TENANT_ERROR,
        error,
    }
}

export function updateSelectedPOs(data) {
    return {
        type: UPDATE_SELECTED_POS,
        data
    }
}


export function updateSelectedPOsReallocationDetails(data) {
    return {
        type: UPDATE_SELECTED_POS_REALLOCATION,
        data
    }
}


export function resetToInitialState() {
    return {
        type: RESET_TO_INITIAL_STATE
    }
}


export function updateDropDownOptions(name) {
    return {
        type: UPDATE_DROPDOWN_OPTIONS,
        name
    }
}