import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import reducer from './reducer';
import messages from './messages'
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import SubmitInfo from '../../../common/components/submitInfo';
import ReturnRequest from '../returnRequest/Loadable';
import SubmitErrors from '../../../common/components/SubmitErrors';
import { submitReturnForm, closeRebillConfirmation, closeMCSKUConfirmation } from '../returnForm/actions';
import Modal from '../../../../common/components/modal/modal';
import LoadingIndicator from '../../../../common/components/LoadingIndicator';
import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import { makeSelectInvoiceNumberCreate } from '../../../common/components/createRequest/selectors';
import { closerocvaluechangeconfimation, updaterocvaluechangeconfimation } from '../../../partners/components/returnDetails/actions';
import {
    makeSelectReturnFormSuccess,
    makeSelectReturnFormLoading,
    makeSelectReturnFormEReceiptStatus,
    makeSelectReturnFormSaveSuccessResponse,
    makeSelectReturnFormDeleteSuccessResponse,
    makeSelectShowRebillConfirmation,
    makeSelectShowMCSKUConfirmation
} from '../returnForm/selectors';
import { makeSelectrocValueChanged } from '../../../partners/components/returnDetails/selectors';
import MultiModal from '../../../../common/components/MultiModal/MultiModal';

function PartnerSubmitHome({
    submitSuccess,
    saveDraftSuccess,
    deleteDraftSuccess,
    invoiceNumber,
    loading,
    submit,
    closeDialog,
    eReceiptStatus,
    showRebillConfirmation,
    rocOverriddenConfirmation,
    closeROCConfirmation,
    changeROCValue,
    showMCSKUConfirmation
}) {
    useInjectReducer({ key: 'partnerSubmitHome', reducer });

    const submitSuccessMsg
        = eReceiptStatus && Array.isArray(eReceiptStatus)
            && eReceiptStatus.length > 0 && eReceiptStatus[0].eReceiptStatus === "true"
            ? messages.eReceiptSuccessMessage
            : messages.submitSuccess;
    var submitMessageConfirmation = [];
    (showMCSKUConfirmation) && submitMessageConfirmation.push(messages.mcSKUConfirmation);
    (showRebillConfirmation) && submitMessageConfirmation.push(messages.rebillConfirmation);
    return (
        <div id='partnerSubmitHome' className="spacer-32-top">
            <div className="row">
                <div className="col-md-24 spacer-32-top" >
                    <SubmitErrors />
                    <SubmitInfo />
                    <Modal id="submitConfirmationModal"
                        isOpen={submitSuccess && submitSuccess.success}
                        headerMessage={submitSuccessMsg}
                        closeMessage={messages.closeAction}
                        close={() => { window.location.href += `?submitSuccess=${invoiceNumber}` }}
                    />
                    <Modal id="deleteDraftConfirmationModal"
                        isOpen={deleteDraftSuccess && deleteDraftSuccess.success}
                        headerMessage={messages.deleteDraftSuccess}
                        closeMessage={messages.closeAction}
                        close={() => { window.location.href = `/` }}
                    />
                    <Modal id="saveConfirmationModal"
                        isOpen={saveDraftSuccess && saveDraftSuccess.success}
                        headerMessage={messages.saveDraftSuccess}
                        closeMessage={messages.closeAction}
                        close={() => { window.location.href += `?invoice=${invoiceNumber}&status=DFT` }}
                    />
                    <Modal id="rocOverriddenModal"
                        isOpen={rocOverriddenConfirmation}
                        headerMessage={messages.rocOverridenMessage}
                        actionMessage={messages.continueAction}
                        action={changeROCValue}
                        closeMessage={messages.goBackAction}
                        close={closeROCConfirmation}
                    />
                    <MultiModal id="submitConfirmationModal"
                        isOpen={showRebillConfirmation || showMCSKUConfirmation}
                        headerMessage={messages.submitConfirmation}
                        messageArray={submitMessageConfirmation}
                        actionMessage={messages.continueAction}
                        action={submit}
                        closeMessage={messages.goBackAction}
                        close={closeDialog}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-24 spacer-32-top" >
                    <h1 className="section-title">
                        <FormattedMessage {...messages.createCreditRequest} />
                    </h1>
                    <div className="col-sm-8 col-md-8 col-lg-5">
                        <div className={!loading ? 'hidden' : ''}>
                            <LoadingIndicator />
                        </div>
                    </div>
                </div>
            </div>
            <ReturnRequest />
        </div>
    );
}

PartnerSubmitHome.propTypes = {
    submitSuccess: PropTypes.bool,
    saveDraftSuccess: PropTypes.bool,
    deleteDraftSuccess: PropTypes.bool,
    submit: PropTypes.func,
    closeDialog: PropTypes.func,
    showRebillConfirmation: PropTypes.bool,
    loading: PropTypes.bool,
    invoiceNumber: PropTypes.string,
    eReceiptStatus: PropTypes.array,
    rocOverriddenConfirmation: PropTypes.bool,
    closeROCConfirmation: PropTypes.func,
    changeROCValue: PropTypes.func,
    showMCSKUConfirmation: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
    submitSuccess: makeSelectReturnFormSuccess(),
    saveDraftSuccess: makeSelectReturnFormSaveSuccessResponse(),
    deleteDraftSuccess: makeSelectReturnFormDeleteSuccessResponse(),
    showRebillConfirmation: makeSelectShowRebillConfirmation(),
    invoiceNumber: makeSelectInvoiceNumberCreate(),
    loading: makeSelectReturnFormLoading(),
    eReceiptStatus: makeSelectReturnFormEReceiptStatus(),
    rocOverriddenConfirmation: makeSelectrocValueChanged(),
    showMCSKUConfirmation: makeSelectShowMCSKUConfirmation()
});

function mapDispatchToProps(dispatch) {
    return {
        submit: () => dispatch(submitReturnForm()),        
        closeDialog: evt => {
            dispatch(closeRebillConfirmation());
            dispatch(closeMCSKUConfirmation());
        },
        closeROCConfirmation: evt => {
            dispatch(closerocvaluechangeconfimation());
            focusRocOverrideDropdown();
        },
        changeROCValue: () => {
            dispatch(updaterocvaluechangeconfimation());
            focusRocOverrideDropdown();
        }
    }
}

function focusRocOverrideDropdown() {
    if (document.getElementById('rocOveride'))
        document.getElementById('rocOveride').focus();
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(PartnerSubmitHome);
