import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IntlProvider } from 'react-intl';
import reducer from './reducer';
import { makeSelectLocale } from './selectors';
import { useInjectReducer } from '../../internals/utils/injectReducer';

function LanguageProvider(props) {

    useInjectReducer({ key: 'language', reducer });
    return (
        <IntlProvider
            locale={props.locale}
            key={props.locale}
            messages={props.messages[props.locale]}
        >
            {React.Children.only(props.children)}
        </IntlProvider>
    );
}

LanguageProvider.propTypes = {
    locale: PropTypes.string,
    messages: PropTypes.object,
    children: PropTypes.element.isRequired,
};

const mapStateToProps = createSelector(
    makeSelectLocale(),
    locale => ({
        locale,
    }),
);

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LanguageProvider);
