import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectCancelFutureBillingCreateRequestDomain = state => state.CancelFutureBillingCreateRequest || initialState;

const selectCancelFutureBillingRequestPo = state => state.CancelFutureBillingRequest || initialState;

const makeSelectCancelFutureBillingCreateRequest = () => createSelector(selectCancelFutureBillingCreateRequestDomain, substate => substate);

const makeSelectSystemPONumberCreate = () => createSelector(selectCancelFutureBillingCreateRequestDomain, substate => substate.systemPONumber);

const makeSelectEnrollmentNumberCreate = () => createSelector(selectCancelFutureBillingCreateRequestDomain, substate => substate.enrollmentNumber);

const makeSelectCancelFutureBillingCreateRequestLoading = () => createSelector( selectCancelFutureBillingCreateRequestDomain, substate => substate.loading  );

const makeSelectCancelFutureBillingCreateRequestError = () =>
    createSelector(
        selectCancelFutureBillingCreateRequestDomain,
        substate => substate.error
    );

const makeSelectLicensesCreate = () =>
    createSelector(
        selectCancelFutureBillingCreateRequestDomain,
        substate => substate.invoices
    );

const makeSelectLicensesFetch = () =>
    createSelector(
        selectCancelFutureBillingCreateRequestDomain,
        substate => substate.invoices
    );

    const makeSelectCancelFutureBillingPoRequestLoading = () => createSelector( selectCancelFutureBillingRequestPo, substate => substate.loading  );
    const makeSelectPoItem = () => createSelector(selectCancelFutureBillingCreateRequestDomain, substate => substate.isSelectPo);

export default makeSelectCancelFutureBillingCreateRequest;

export {
    makeSelectCancelFutureBillingCreateRequest,
    makeSelectSystemPONumberCreate,
    makeSelectEnrollmentNumberCreate,
    makeSelectCancelFutureBillingCreateRequestLoading,
    makeSelectCancelFutureBillingCreateRequestError,
    makeSelectLicensesCreate,
    makeSelectLicensesFetch,
    makeSelectCancelFutureBillingPoRequestLoading,
    makeSelectPoItem
};
