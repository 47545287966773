import React from 'react';

export const WorkflowIDMappings = {
  credits: "d8e8c311-ef12-46d0-bd9b-970232367d4a",
  futureBillingCancellation: "0ae2a169-12ea-42a6-93d4-00a432259eba",
  multiTenant: "903efa32-03be-4a52-911b-17831ff42610",
};

const vlCentral = 'https://vlcentral.microsoft.com/#home';
function getApprovalLink(workflowId, approvalId) {
  if (!approvalId) {
    return vlCentral;
  }
  // return `https://rc-vlcentral.portal.azure.com/#view/VLCentral_Approval/CreditRequestDetailsView.ReactView/workflowId/${workflowId}/requestId/${approvalId}`;
  return `https://vlcentral.microsoft.com/#view/VLCentral_Approval/CreditRequestDetailsView.ReactView/workflowId/${workflowId}/requestId/${approvalId}`;
}

export function NewApproverWorkflowBanner(entitySourceSystem, workflowId, approvalId) {
  if (entitySourceSystem !== 'VLCTRL') {
    return null;
  }
  return <div className='RetirementBackGround'>
    Read-Only Mode: This request was submitted through the VL Central. To approve or reject the request, please click the link <a href={getApprovalLink(workflowId, approvalId)}>Volume Licensing Central</a> and complete the action in the VL Central Approval Workspace.
  </div>
}
