import { defineMessages } from 'react-intl';

export const scope = 'ReallocationDetailsTable';

export default defineMessages({
    purchaseOrderNumber: { 
        id: `${scope}.purchaseOrderNumber`,
        defaultMessage: 'Purchase Order Number',
    },
   
    noRecordsFound: {  
        id: `${scope}.noRecordsFound`,
        defaultMessage: 'No records found.',
    },
   
    partNumber: {  
        id: `${scope}.partNumber`,
        defaultMessage: "Part Number",
    },
   
    tenantName: {  
        id: `${scope}.tenantName`,
        defaultMessage: "Tenant Name",
    },
    tenantNumber: {  
        id: `${scope}.tenantNumber`,
        defaultMessage: "Tenant Number",
    },
    reallocateQuantity: { 
        id: `${scope}.reallocateQuantity`,
        defaultMessage: "Reallocate Quantity",
    },
 
    systemPONumber: {  
        id: `${scope}.systemPONumber`,
        defaultMessage: "System PO Number",
    },
    createdDate: {  
        id: `${scope}.createdDate`,
        defaultMessage: "Created Date",
    },
    orderType: {  
        id: `${scope}.orderType`,
        defaultMessage: "Order Type",
    },
    reallocateBtn: {
        id: `${scope}.reallocateBtn`,
        defaultMessage: "Reallocate",
    },
    deleteAllBtn: {
        id: `${scope}.deleteAllBtn`,
        defaultMessage: "Delete All",
    },
    productDescription: {
        id: `${scope}.productDescription`,
        defaultMessage: "Product Description",
    },
    coverageStartDate: {
        id: `${scope}.coverageStartDate`,
        defaultMessage: "Coverage Start Date",
    },
    coverageEndDate: {
        id: `${scope}.coverageEndDate`,
        defaultMessage: "Coverage End Date",
    }
});
