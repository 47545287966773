import {
    EXPORT_PDF,
    TOGGLE_ACTIONS,
    VALIDATE_FULL_CREDIT,
    E_RECEIPT_SUCCESS, E_RECEIPT_FAIL,
    SAVE_RETURN, SAVE_RETURN_SUCCESS, SAVE_RETURN_FAIL,
    SUBMIT_RETURN, SUBMIT_RETURN_SUCCESS, SUBMIT_RETURN_FAIL,
    DELETE_DRAFT, DELETE_DRAFT_SUCCESS, DELETE_DRAFT_FAIL,
    SHOW_REBILL_CONFIRMATION, CLOSE_REBILL_CONFIRMATION, 
    SHOW_MCSKU_CONFIRMATION,CLOSE_MCSKU_CONFIRMATION,
} from './constants';

export function validateFullCredit() {
    return { type: VALIDATE_FULL_CREDIT };
}

export function showRebillConfirmation() {
    return { type: SHOW_REBILL_CONFIRMATION };
}

export function closeRebillConfirmation() {
    return { type: CLOSE_REBILL_CONFIRMATION };
}

export function showMCSKUConfirmation() {
    return { type: SHOW_MCSKU_CONFIRMATION };
}

export function closeMCSKUConfirmation() {
    return { type: CLOSE_MCSKU_CONFIRMATION };
}

export function submitReturnForm() {
    return { type: SUBMIT_RETURN };
}

export function submitReturnFormSuccess(response) {
    return { type: SUBMIT_RETURN_SUCCESS, response };
}

export function submitReturnFormFailure(error) {
    return { type: SUBMIT_RETURN_FAIL, error };
}

export function deleteDraft() {
    return { type: DELETE_DRAFT };
}

export function deleteDraftSuccess(response) {
    return { type: DELETE_DRAFT_SUCCESS, response };
}

export function deleteDraftFailure(error) {
    return { type: DELETE_DRAFT_FAIL, error };
}

export function toggleActions() {
    return { type: TOGGLE_ACTIONS };
}

export function saveReturnForm() {
    return { type: SAVE_RETURN };
}

export function saveReturnFormSuccess(response) {
    return { type: SAVE_RETURN_SUCCESS, response };
}

export function saveReturnFormFailure(error) {
    return { type: SAVE_RETURN_FAIL, error };
}

export function eReceiptSuccess(response) {
    return { type: E_RECEIPT_SUCCESS, response };
}

export function eReceiptFailure(error) {
    return { type: E_RECEIPT_FAIL, error };
}

export function exportPDF() {
    return { type: EXPORT_PDF };
}