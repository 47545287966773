import { defineMessages } from 'react-intl';

export const scope = 'additionalDetails';

export default defineMessages({
    additionalDetailsHeader: {
        id: `${scope}.additionalDetailsHeader`,
        defaultMessage: 'Additional Details',
    },
});
