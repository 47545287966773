import React from 'react';
import { injectIntl } from 'react-intl';
import { LocalisedText } from './LocalisedText';

export function Button(props) {
    return (
        <button key={props.id} id={props.id} className={props && props.className ? props.className : ''} type={props.type} aria-label={props.ariaLabel} onClick={props.onClick}>
            <LocalisedText id={props.buttonTextId} intl={props.intl} />
        </button>
    );
}

Button.defaultProps = {
    onClick: () => {
        //do nothing
    }
};
export default injectIntl(Button);