import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectFilterReallocationRequestDomain= (state) =>
    state.reallocationList || initialState;

const fetchReallocationListRequestPayload = () =>
  createSelector(
      selectFilterReallocationRequestDomain,
    (substate) => substate.payload
  );

const makeSelectReallocation = () =>
  createSelector(
      selectFilterReallocationRequestDomain,
      (substate) => substate.reallocationListResponse
  );

const makeSelectError = () =>
  createSelector(
      selectFilterReallocationRequestDomain,
    (substate) => substate.error
  );

const makeSelectLoading = () =>
  createSelector(
      selectFilterReallocationRequestDomain,
    (substate) => substate.loading
  );

const makeSelectRequestId = () =>
  createSelector(
      selectFilterReallocationRequestDomain,
    (substate) => substate.requestIdentifier
  );
const makeSelectRequestStatus = () =>
  createSelector(
      selectFilterReallocationRequestDomain,
      (substate) => (substate.requestStatus != null && substate.requestStatus[0] === 'PEN') ? ["RAP", "RP","RF"]:substate.requestStatus
  );
const makeSelectEnrollmentName = () =>
  createSelector(
      selectFilterReallocationRequestDomain,
      (substate) => substate.enrollmentName
    );
const makeSelectTenantNumber = () =>
    createSelector(
        selectFilterReallocationRequestDomain,
        (substate) => substate.tenantNumber
    );

const makeSelectEnrollmentNumber = () =>
  createSelector(
      selectFilterReallocationRequestDomain,
    (substate) => substate.enrollmentNumber
  );
const makeSelectCosmicNumber = () =>
  createSelector(
      selectFilterReallocationRequestDomain,
    (substate) => substate.cosmicNumber
  );
const makeSelectDateRange = () =>
  createSelector(
      selectFilterReallocationRequestDomain,
    (substate) => substate.dateRange
  );
export default fetchReallocationListRequestPayload;

export {
  fetchReallocationListRequestPayload,
  makeSelectReallocation,
  makeSelectLoading,
  makeSelectError,
  makeSelectRequestId,
  makeSelectRequestStatus,
  makeSelectEnrollmentName,
  makeSelectTenantNumber,
  makeSelectEnrollmentNumber,
  makeSelectDateRange,
  makeSelectCosmicNumber
};
