import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectRoot = state => state;
const selectlineItemDetailsDomain = state => state.cfblineItemDetails || initialState;

const makeSelectlineItemDetailsList = () =>
    createSelector(
        selectlineItemDetailsDomain,
        substate => substate.lineItemDetails,
    );

const makeSelectHiddenColumnsList = () =>
    createSelector(
        selectlineItemDetailsDomain,
        substate => substate.hiddenColumnsList,
    );

const makeSelectAdjustedFlag = () =>
    createSelector(
        selectlineItemDetailsDomain,
        substate => substate.checked,
    );

const makeSelectIsExportPDF = () =>
    createSelector(
        selectlineItemDetailsDomain,
        substate => substate.isExportPDF,
    );

const makeSelectlineItemDetails = () =>
    createSelector(
        selectlineItemDetailsDomain,
        substate => substate,
    ); 
    const makeListItemsDifference = () =>
    createSelector(selectRoot, globalState => {
        if (globalState && globalState.cfblineItemDetails && globalState.cfblineItemDetails.lineItemDetails && globalState.cfblineItemDetails.changelineItemDetails){
            //console.log("change_line_item_selct:",JSON.stringify(globalState.cfblineItemDetails.changelineItemDetails));
 return globalState.cfblineItemDetails.changelineItemDetails;
        }
           
            return null;
            
    });
export default makeSelectlineItemDetails;
export {
    makeSelectlineItemDetails,
    makeSelectlineItemDetailsList, makeSelectHiddenColumnsList, makeSelectAdjustedFlag,
    makeSelectIsExportPDF,
    makeListItemsDifference
};
