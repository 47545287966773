import { ADD_FORMFIELD, CHANGE_MODAL_TABLE_ONPARTNUMBERFOCUS, CHANGE_REMAINING_QTY, REALLOCATION_MODAL_DETAILS_CHANGED } from './constants';

export function onAddReallocate(data) {
    return {
        type: REALLOCATION_MODAL_DETAILS_CHANGED,
        data
    }
}

export function onAddFormField(data) {
    return {
        type: ADD_FORMFIELD,
        data
    }
}


export function onModalDetailsChangeOnPartNumberFocus(data) {
    return {
        type: CHANGE_MODAL_TABLE_ONPARTNUMBERFOCUS,
        data
    }
}


export function OnReallocationQtyChanged(data) {
    return {
        type: CHANGE_REMAINING_QTY,
        data
    }
}