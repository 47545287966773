import { defineMessages } from 'react-intl';

export const scope = 'MultiTenantFetechRequest';

export default defineMessages({
    cosmicCaseNumber: {
        id: `${scope}.cosmicCaseNumber`,
        defaultMessage: 'Cosmic Case Number',
    },
    submitterEmail: {
        id: `${scope}.submitterEmail`,
        defaultMessage: 'Submitter Email',
    },
    leadEnrollmentNumber: {
        id: `${scope}.leadEnrollmentNumber`,
        defaultMessage: 'Lead Enrollment Number',
    }
});
