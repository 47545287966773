import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectMTModalTableDetailsDomain = state => state.MTModalReallocationDetailsTable || initialState;
const selectRoot = state => state || initialState;

const makeSelectMTModalTableDetails = () =>
    createSelector(
        selectMTModalTableDetailsDomain, substate => [...substate.mtModalTableDetails]

    );

const makeMTModalDetailsRemainingQty = () =>
    createSelector(
        selectMTModalTableDetailsDomain, substate => substate.remainingQty

    );

const makePartNumberList = () =>
    createSelector(
        selectRoot, substate => substate.partNumberMenu.partNumberList

    );

export default makeSelectMTModalTableDetails;

export { makeSelectMTModalTableDetails, makePartNumberList, makeMTModalDetailsRemainingQty };
