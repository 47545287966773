
import differenceBy from 'lodash/differenceBy';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import * as Helpers from '../../../../common/internals/utils/helpers';
import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import { useInjectSaga } from '../../../../common/internals/utils/injectSaga';
import MTModalReallocationDetailsTable from '../../components/mtModalReallocationTable';
import { makeSelectIsLoading, makeSelectOrderdetails } from '../../components/reallocationDetailsTable/selectors';
import SubmitErrors from '../../components/SubmitErrors';
import { makeSelectExternalIdentifier } from '../reallocateModal/selectors';
import { currentCPValueChanged, currentValueChanged, onAddReallocate, partNumberSelected } from './actions';
import reducer from './reducer';
import saga from './saga';
import { makePartNumberList, makeSelectSelectedPOLIValue } from './selectors';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import { customReactSelectStyles } from '../../../../common/app/global-style';
import Announcer from 'react-a11y-announcer';
import { makeSelectAnnouncerText } from '../partNumberMenu/selectors';

function ReallocationBody({
    changeCurrentFormSelectedValue, currentFormSelectedValue, reallocationBody, announcerText,
    changeCurrentCoveragePeriodSelected, isPOLILoading, externalIdentifier, orderDetails, selectedPartNumberList
}) {
    useInjectReducer({ key: 'reallocationBody', reducer });
    useInjectSaga({ key: 'reallocationBody', saga });

    let partNumberList = [];
    let partNumberDetails = {};
    let coveragePeriodOptions = [];

    if (orderDetails && orderDetails[externalIdentifier]) {

        var groupedColumns = Helpers.GroupBy(["poLineItemId", "quantity", "partNumber"],
            orderDetails[externalIdentifier].lineItemDetails);
        var data = Helpers.CalculateCoveragePeriod(groupedColumns);

        data.filter(d => d.adjustedQuantity > 0).map(d =>
            partNumberList.push({
                value: d.externalIdentifier,
                label: d.partNumber,
                productDescription: d.productDescription,
                originalQuantity: d.adjustedQuantity
            })
        );
        partNumberList = differenceBy(partNumberList, selectedPartNumberList, 'value');

        if (currentFormSelectedValue && currentFormSelectedValue.selectedPartNumber) {
            partNumberDetails = data.filter((x) => x.externalIdentifier === currentFormSelectedValue.selectedPartNumber.value);
        }

        if (partNumberDetails && partNumberDetails.length > 0) {

            partNumberDetails[0].coverage_period.map((d, key) => {
                // MT Bug Fix#: 41351786 - Commenting out the if condition because no extra filters are needed if the data obtained
                // from RFS is already fitered.

                //if ((new Date(d.coverageEndDate)).getTime() > new Date().getTime()){
                coveragePeriodOptions.push({
                    value: d.coveragePeriod + "To" + d.salesOrderLineItemId + "To" + d.billingMultipler + "To" + d.multiplier,
                    label: d.coveragePeriod,
                    billingMultipler: d.billingMultipler,
                    coverageEndDate: d.coverageEndDate,
                    coveragePeriod: d.coveragePeriod,
                    coverageStartDate: d.coverageStartDate,
                    multiplier: d.multiplier,
                    salesOrderLineItemId: d.salesOrderLineItemId
                })
            //}
            });



        }


    }

    return (
        <>
            <div className="row" style={{ paddingTop: "6%", paddingBottom: '15%', paddingLeft:'3%' }}>
                <div className="col-lg-24">
                    {<SubmitErrors isModalError={true} />}
                    <Announcer text={announcerText}/>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-xs-13 col-sm-5 col-md-12 col-lg-12 pullleft" style={{ paddingBottom: '1%' }}>
                                <span id="selectPartNumberLabel"><b>
                                    <FormattedMessage {...messages.selectPartNumber} />
                                </b>
                                </span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-13 col-sm-13 col-md-10 col-lg-10 pullleft" style={{ paddingBottom: '1%' }}>
                               <Select
                                    classNamePrefix="react-select"
                                    styles={customReactSelectStyles}
                                    aria-labelledby="selectPartNumberLabel"
                                    options={partNumberList}
                                    placeholder="Select Part Number"
                                    value={currentFormSelectedValue.selectedPartNumber}
                                    onChange={changeCurrentFormSelectedValue}
                                    isLoading={isPOLILoading}
                                    isDisabled={currentFormSelectedValue && currentFormSelectedValue.reallocationdetails
                                        && currentFormSelectedValue.reallocationdetails.length > 0}
                                />
                            </div>
                        </div>
                        {currentFormSelectedValue && currentFormSelectedValue.selectedPartNumber && currentFormSelectedValue.selectedPartNumber.value ?
                            <>
                                <div className="row">
                                    <div className="col-xs-28 col-sm-15 col-md-24 col-lg-24 pullleft" style={{ paddingBottom: '1%' }}>
                                        <label class="label"> <FormattedMessage {...messages.productDescription} /> :</label>
                                        <span style={{ fontSize: "small" }}>
                                            {currentFormSelectedValue.productDescription}
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-19 col-md-12 col-lg-12 pullleft" style={{ paddingTop: '2%', paddingBottom: '1%' }}>
                                        <span id="coveragePeriodSelectionLabel">
                                            <b>
                                                <FormattedMessage {...messages.selectCoveragePeriod} />
                                                <label class="label" style={{ fontSize: "small" }}> <FormattedMessage {...messages.coverageMessage} /> </label>
                                            </b>
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-13 col-sm-13 col-md-10 col-lg-10 pullleft" style={{ paddingBottom: '1%' }}>
                                        <Select
                                            aria-labelledby="coveragePeriodSelectionLabel"
                                            options={coveragePeriodOptions}
                                            placeholder="Select the Coverage Period "
                                            value={currentFormSelectedValue.coveragePeriod}
                                            onChange={changeCurrentCoveragePeriodSelected}
                                            styles={customReactSelectStyles}
                                            classNamePrefix="react-select"
                                        />
                                    </div>
                                    {currentFormSelectedValue && currentFormSelectedValue.coveragePeriod && currentFormSelectedValue.coveragePeriod.value ?
                                        <div className="col-sm-15 col-md-6 col-sm-8 col-md-6 col-lg-offset-6 pullleft  pullleft" style={{ paddingTop: '2%', paddingBottom: '1%' }}>
                                            <span><b>Present Quantity  : {currentFormSelectedValue.originalQuantity}</b></span>
                                        </div>
                                        : ""}
                                </div>
                                {currentFormSelectedValue && currentFormSelectedValue.coveragePeriod && currentFormSelectedValue.coveragePeriod.value ?
                                    <div className="row">
                                        <div className="col-sm-8 col-md-20 col-lg-24 pullleft" style={{ paddingBottom: '1%' }}>
                                            <MTModalReallocationDetailsTable />
                                        </div>
                                    </div>
                                    : ""}
                            </>
                            : ""}
                    </div>
                </div>
            </div>
        </>

    );
}

ReallocationBody.propTypes = {
    currentFormSelectedValue: PropTypes.object,
    selectedPartNumberList: PropTypes.object,
    reallocationBody: PropTypes.object,
    changeCurrentFormSelectedValue: PropTypes.func,
    changeCurrentCoveragePeriodSelected: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
    currentFormSelectedValue: makeSelectSelectedPOLIValue(),
    isPOLILoading: makeSelectIsLoading(),
    orderDetails: makeSelectOrderdetails(),
    externalIdentifier: makeSelectExternalIdentifier(),
    selectedPartNumberList: makePartNumberList(),
    announcerText: makeSelectAnnouncerText()

});

function mapDispatchToProps(dispatch) {
    return {
        handleChange: (value) => {
            if (value !== undefined) {
                dispatch(onAddReallocate(value));
            }
        },

        changeCurrentFormSelectedValue: (evt) => {
            if (evt !== undefined) {
                dispatch(currentValueChanged(evt));
            }
        },
        changeCurrentCoveragePeriodSelected: (evt) => {
            if (evt !== undefined) {
                dispatch(currentCPValueChanged(evt));
            }
        },
        onSelectPartNumber: evt => {
            if (evt !== undefined) {
                dispatch(partNumberSelected(evt.value));
            }
        }
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(ReallocationBody);