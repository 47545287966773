import React from "react";
import 'bootstrap-daterangepicker/daterangepicker.css';
import '../../../common/components/DateRange.css';
import moment from 'moment';
import * as Helpers from '../../../common/internals/utils/helpers';
import DateRangeActionControl from '../DateControl/DateRangeActionControl';
export default function DateRangeFilter(props) {

    var today = Helpers.DateFormatForDisplay(moment());
    var customEndDate = Helpers.DateFormatForDisplay(moment().subtract(90, 'days').calendar());
    var lifetimeDays = props.lifetimeDays ? props.lifetimeDays : "1D";
    const listItems = Helpers.FilterButtonDaysList.map((a) => {
        switch (a.value.trim()) {

            case "1D":
            case "1W":
            case "1M":
                return (
                    <button key={"btn_" + a.value} type="radio" aria-label={a.value} aria-pressed={lifetimeDays === a.value ? "true" : "undefined"} className="btn-datefilter" onClick={(e) => props.onDateRangeSelection(e, a.value)} style={{ "minWidth": "50px" }}>
                        {a.value}
                    </button>
                );
            case Helpers.CUSTOMDATE:
                var valueToBeDisplayed = (a.value === "Custom" && lifetimeDays.indexOf("to") !== -1) ? lifetimeDays : a.value;
                return (
                    <DateRangeActionControl key={"btn_" + a.value} startDate={customEndDate} endDate={today} value={valueToBeDisplayed} onApply={props.handleApplyEvent} style={{ "minWidth": "50px" }} />
                );
            default:
                console.log("lifetimeDays");
                console.log(lifetimeDays);

        }
    });

    return (
        <div className="btn-toolbar pull-right  col-sm-16 col-md-20 col-lg-21" role="toolbar" aria-label="Date Range Filters" key="ddlDateRangeItems" >
            <div className="btn-group mr-2" role="group" aria-label="Filter group">
                {listItems}
            </div>
        </div>
    );

}

