import produce from "immer";
import {
    SEARCH_MULTI_INVOICE_SUCCESS,
    SEARCH_MULTI_INVOICE
} from "../../../common/components/createMultiInvoiceRequest/constants";
// Need to Change
import { GET_MULTI_INVOICE_RETURN_SUCCESS } from "../../../common/components/fetchMultiInvoiceReturnRequest/constants";
import { INVOKE_CANCEL_REASON_APIREQUEST, INVOKE_CANCEL_REASON_REQUEST_SUCCESS, INVOKE_CANCEL_REASON_REQUEST_FAIL } from "../../../../submit/common/components/cancelrequest/constants";
import {
    SUBMIT_RETURN,
    SUBMIT_RETURN_SUCCESS,
    SUBMIT_RETURN_FAIL,
    SAVE_RETURN,
    SAVE_RETURN_SUCCESS,
    SAVE_RETURN_FAIL,
    DELETE_DRAFT,
    DELETE_DRAFT_FAIL,
    DELETE_DRAFT_SUCCESS,
    SHOW_REBILL_CONFIRMATION,
    CLOSE_REBILL_CONFIRMATION,
    E_RECEIPT_SUCCESS,
    TOGGLE_ACTIONS,
    CLOSE_RESTOCK_FEE_CONFIRMATION,
    SHOW_RESTOCK_FEE_CONFIRMATION
} from "./constants";

export const initialState = { isActionsOpen: false };

const mulltiInvoiceReturnFormReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case INVOKE_CANCEL_REASON_APIREQUEST:
                draft.loading = true;
                break;
            case INVOKE_CANCEL_REASON_REQUEST_SUCCESS:
            case INVOKE_CANCEL_REASON_REQUEST_FAIL:
                draft.loading = false;
                break;
            case SEARCH_MULTI_INVOICE_SUCCESS:
            case GET_MULTI_INVOICE_RETURN_SUCCESS:
                draft.requestStatusCode = action.invoices.requestStatusCode;
                break;
            case SEARCH_MULTI_INVOICE:
                draft.error = false;
                break;
            case E_RECEIPT_SUCCESS:
                draft.eReceiptStatus = action.response;
                break;
            case SUBMIT_RETURN:

                draft.showRebillConfirmation = false;
                draft.restockFeeConfirmation = false;
                draft.loading = true;
                draft.deleteError = false;
                draft.error = false;
                draft.saveError = false;
                draft.successResponse = false;
                break;
            case SUBMIT_RETURN_SUCCESS:
                draft.loading = false;
                draft.error = false;
                draft.successResponse = action.response;
                break;
            case SUBMIT_RETURN_FAIL:
                draft.error = action.error;
                draft.loading = false;
                draft.successResponse = false;
                break;
            case SAVE_RETURN:
                draft.loading = true;
                draft.deleteError = false;
                draft.error = false;
                draft.saveError = false;
                draft.saveSuccessResponse = false;
                break;
            case SAVE_RETURN_SUCCESS:
                draft.loading = false;
                draft.saveError = false;
                draft.saveSuccessResponse = action.response;
                break;
            case SAVE_RETURN_FAIL:
                draft.saveError = action.error;
                draft.loading = false;
                draft.saveSuccessResponse = false;
                break;
            case DELETE_DRAFT:
                draft.loading = true;
                draft.deleteError = false;
                draft.error = false;
                draft.saveError = false;
                draft.deleteSuccessResponse = false;
                break;
            case DELETE_DRAFT_SUCCESS:
                draft.loading = false;
                draft.deleteError = false;
                draft.deleteSuccessResponse = action.response;
                break;
            case DELETE_DRAFT_FAIL:
                draft.deleteError = action.error;
                draft.loading = false;
                draft.deleteSuccessResponse = false;
                break;
            case TOGGLE_ACTIONS:
                draft.isActionsOpen = !draft.isActionsOpen;
                break;
            case SHOW_REBILL_CONFIRMATION:
                draft.showRebillConfirmation = true;
                break;
            case CLOSE_REBILL_CONFIRMATION:
                draft.showRebillConfirmation = false;
                break;
            case CLOSE_RESTOCK_FEE_CONFIRMATION:
                draft.restockFeeConfirmation = false;
                break;
            case SHOW_RESTOCK_FEE_CONFIRMATION:
                draft.restockFeeConfirmation = true;
                break;

        }
    });

export default mulltiInvoiceReturnFormReducer;
