export function isEPTEnabled() {
    return window.vlcredits.envData.Services.IsEPTEnabled;
}

export function isCancelledEnabled() {
    return window.vlcredits.envData.Services.IsCancelledEnabled;
}

export function isROCOverridenEnabled() {
    return window.vlcredits.envData.Services.IsROCOverridenEnabled;
}

export function allowedProgramCode() {
    return window.vlcredits.envData.Services.AllowedProgramCode;
}

export function isSubCFOEnabled() {
    return window.vlcredits.envData.Services.isSubCFOEnabled;
}

export function isSearchEnhancementEnabled() {
    return window.vlcredits.envData.Services.isSearchEnhancementEnabled;
}

//New feature flag added for FBC feature.
export function isFbcEnabled() {
    return window.vlcredits.envData.Services.isFbcEnabled;
}

export function isRebillChangeEnabled() {
    return window.vlcredits.envData.Services.isRebillChangeEnabled;
}

export function isWamEnabled() {
    return window.vlcredits.envData.Services.isWamEnabled;
}

export function BlockedCPSCountryCode() {
    return window.vlcredits.envData.Services.BlockedCPSCountryCode;
}

export function FBCCPSROCReturnReasons() {
    return window.vlcredits.envData.Services.FBCCPSROCReturnReasons;
}

export function MCPartNumbers() {
    return window.vlcredits.envData.Services.MCPartNumbers;
}

export function IsMCConfirmationFlag() {
    return window.vlcredits.envData.Services.isMCConfirmationFlag;
}