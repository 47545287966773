import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../controls/Button';

export const collapsableSection = (sectionName, expanded) => {
    return WrappedComponent => {
        const collapsableSection = props => {
            const id = sectionName.id.split('.')[0];
            return (

                <div className="row">
                    <div className="col-lg-24 spacer-32-top">
                        <div className="sectionHeader" role="tree">
                            <a className="hide-focus" data-toggle="collapse" aria-expanded={expanded} role="button"
                                aria-controls={id} href={`#${id}`} aria-live="polite">
                                <h2 className="win-color-fg-primary">
                                    <FormattedMessage {...sectionName}/>
                                    <hr />
                                </h2>
                            </a>
                            <div className={`collapse ${expanded ? " in " : ""}`} id={id} role="treeitem">
                                <div className="row">
                                    <div className="section-body col-lg-24"><WrappedComponent {...props} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            );
        };
        return (collapsableSection);
    };
}

export default collapsableSection
