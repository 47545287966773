import React, { memo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import reducer from '../fileUploadModal/reducer'
import saga from '../fileUploadModal/saga';
import { useInjectReducer } from '../../../../common/internals/utils/injectReducer';
import { useInjectSaga } from '../../../../common/internals/utils/injectSaga';
import messages from './messages';
import collapsableSection from '../../../../common/components/collapsableSection';
import FileUploadModal from '../fileUploadModal';
import { DocumentType } from '../fileUploadModal/constants';
//import { makeSelectLoading } from './selectors';
import { makeSelectuploadedFiles, makeSelectDocumentType, makeSelectPopUpModalLoading, makeSelectDownloadingFile, makeSelectReadOnlyFlag, makeSelectDownloadErrorFile } from '../fileUploadModal/selectors';
//import { OpenModal } from './actions';
import { AttachmentFileRemoved, DowloadAttachedFile, OpenModal } from '../fileUploadModal/actions';
import LoadingIndicator from '../../../../common/components/LoadingIndicator';
import '../../../../common/app/accessibility-style.css';
import { isReadonlyVersion } from '../../../../common/internals/utils/helpers';

var documentTypes = [{ "key": "0", "value": "Customer Acknowledgement Form" },
{ "key": "1", "value": "ROC Director Approval Form" },
{ "key": "2", "value": "Others" }
];





export function FileUpload(
    {
        IsModalOpen,
        onAttachmentClick,
        uploadedFiles,
        selectedDocument,
        onRemoveFileAttachment,
        onFileNameSelected,
        Isdownloading,
        isDownloadError,
        isReadOnly,
        isApprovalScreen,
        docTypes
    }) {

    useInjectReducer({ key: 'fileUploadModal', reducer });
    useInjectSaga({ key: 'fileUploadModal', saga });

    return (
        <div>
            <div className={isDownloadError ? '' : 'hidden'} ><div className="fileDownloadError"> Error downloading this file!</div></div>
            <div className="row">
                <div className="col-lg-24">
                    <div className="form-group spacer-ml-top">
                        <div className="col-sm-4 col-md-4 col-lg-4">

                            {!isReadOnly && !isApprovalScreen ? <button id="Attachment" type="button" onClick={onAttachmentClick} ><FormattedMessage {...messages.attachmentsSectionHeader} /></button> : null}
                            {IsModalOpen ? <FileUploadModal acceptedFiles=".pdf,.doc,.docx,.png,.jpg,.xlsx,.msg" documentTypes={docTypes ? docTypes : documentTypes} selectedDocument={selectedDocument ? selectedDocument : (docTypes ? docTypes[0].key : documentTypes[0].key)} fileSelectionError={""} /> : null}
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">

                {uploadedFiles && Array.isArray(uploadedFiles) ? <AttachmentTable key="tblAttachmentTable" uploadedFiles={uploadedFiles} onClick={onRemoveFileAttachment} ondownloadClicked={onFileNameSelected} isReadOnly={isReadOnly} /> : null}
            </div>
            <div className={!Isdownloading ? 'hidden' : ''}>
                <div className='col-md-8  remove-all-padding'>
                    <LoadingIndicator />
                </div>
            </div>
        </div>
    );
}

function Header(props) {
    return (
        <thead><tr>
            <th><FormattedMessage {...messages.attachmentsFileName} /></th>
            <th><FormattedMessage {...messages.attachmentsDocumentType} /></th>
            {
                !props.isReadOnly ?
                    < th > <FormattedMessage {...messages.attachmentsAction} /></th> : null}
        </tr></thead>);
}

function AttachmentTable(uploadedFiles) {

    let attachmentList = [];
    if (uploadedFiles && uploadedFiles.uploadedFiles.length > 0) {

        uploadedFiles.uploadedFiles.map(data => (
            attachmentList.push({
                identifier: data.identifier,
                type: DocumentType[data.type],
                name: data.name,
            })
        ));
    }
    return (
        <div className="table-responsive fileupload">
            <table className="table table-responsive">
                <Header isReadOnly={uploadedFiles.isReadOnly} />
                <tbody>
                    {attachmentList && attachmentList.map((data, key) =>
                        <tr>
                            <td>
                                <button type="button" className="btn-link" data-tag={data.identifier} onClick={uploadedFiles.ondownloadClicked}>{data.name}</button>
                            </td>
                            <td>{data.type}</td>
                            {isReadonlyVersion() || (window.vlcredits.FeatureName === "MT" && uploadedFiles.isReadOnly != null) ? null : !uploadedFiles.isReadOnly ?
                                <td>
                                    <button type="button" data-tag={data.identifier} onClick={uploadedFiles.onClick} className="btn-link" >Remove
                                    </button>
                                </td> : null}
                        </tr>)}
                </tbody>
            </table>
        </div>


    )
}



FileUpload.propTypes = {
    IsModalOpen: PropTypes.bool,
    onAttachmentClick: PropTypes.func,
    uploadedFiles: PropTypes.array,
    isReadOnly: PropTypes.bool,
    onRemoveFileAttachment: PropTypes.func,
    selectedDocument: PropTypes.string,
    onFileNameSelected: PropTypes.func,
    Isdownloading: PropTypes.bool,
    isDownloadError: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
    IsModalOpen: makeSelectPopUpModalLoading(),
    uploadedFiles: makeSelectuploadedFiles(),
    selectedDocument: makeSelectDocumentType(),
    Isdownloading: makeSelectDownloadingFile(),
    isDownloadError: makeSelectDownloadErrorFile(),
    isReadOnly: makeSelectReadOnlyFlag()
});

function mapDispatchToProps(dispatch) {
    return {
        onAttachmentClick: () => dispatch(OpenModal()),
        onRemoveFileAttachment: (evt) => dispatch(AttachmentFileRemoved(evt.target.getAttribute("data-tag"))),
        onFileNameSelected: (evt) => dispatch(DowloadAttachedFile({ identifer: evt.target.getAttribute("data-tag"), fileName: evt.target.textContent }))
    }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default collapsableSection(messages.attachmentsSectionHeader, true)(compose(withConnect, memo)(FileUpload));
