

export const Status_List = {
    "DFT": "Draft",
    "PSV": "Pending System Validation",
    "PL1": "L1 Approver Review",
    "PCFO": "Subsidiary CFO Review",
    "PL2": "L2 Approver Review",
    "PL3": "L3 Approver Review",
    "PL4": "L4 Approver Review",
    "RA" : "Request Approved" ,
    "CVF": "Credit Validation Failed" ,
    "CP" : "Credit Pending" ,
    "CLS": "Completed",
    "REJ": "Rejected",
    "CAN": "Cancelled"
};                  

export const FBCPartnerStatus_List = {
    "RAP": "Submit For Approval",
    "RA": "Submit For Approval",
    "RVF": "Request Validation Failed",
    "RP": "Submit For Approval",
    "CLS": "Completed",
    "REJ": "Rejected",
    "CAN": "Cancelled"
};  

export const FBCOpsStatus_List = {
    "RAP": "Submit For Approval",
    "RA": "Request Approved",
    "RVF": "Request Validation Failed",
    "RP": "Request Pending",
    "CLS": "Completed",
    "REJ": "Rejected",
    "CAN": "Cancelled"
};  