import React, { useEffect, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { injectIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';

import GridControl from '../../common/components/grid/GridControl';
import ErrorMessage from '../../common/components/ErrorMessage/ErrorMessage';
import SuccessMessage from '../../common/components/controls/SuccessMessage';
import LoadingIndicator from '../../common/components/LoadingIndicator/index';

import FilterCriteria from '../../common/search/components/filters/FilterCriteria';
import { FilterCredits, changeDateRange } from '../../common/search/components/filters/actions';
import { makeSelectLoading, makeSelectError, makeSelectCredits, makeSelectDateRange } from '../../common/search/components/filters/selectors';

import { makeSelectDomainData } from '../../common/app/selectors';
import { Link } from "react-router-dom";
import * as Helpers from '../../common/internals/utils/helpers';
import { ActionControl } from '../../common/components/ActionControl/ActionDropdown';
import exportColumns from '../../common/internals/utils/exportExcelColumnsList';
import DomainDataContext from '../../common/context/DomainDataContext';
import DateRangeFilter from '../../common/components/controls/DateRangeFilter';
import errorMessages from '../../common/internals/api/errorMessages';
import uuidv4 from 'uuid/v4';
import { isSearchEnhancementEnabled } from '../../common/internals/utils/featureFlag';
let creditsApprovalList, exportCreditsApprovalList = [];
var dateRangeElement = "";

export function ApprovalSearch(props) {

    const myDomainData = useContext(DomainDataContext);

    const { formatMessage } = props.intl;
    const isSearchEnhancementEnabledFlag = isSearchEnhancementEnabled();

    const linkFormatter = (cell, row) => {
        if (row) {
            var url = "";

            if (props.location.pathname.indexOf('/approver/') !== -1) {

                url = row.hasMultiple ? `../approver/approve?multiInvoiceNumber=${row.multiInvoiceNumber}&requestId=${row.approvalid}&status=${row.request_status_code}` : `../approver/approve?invoice=${row.invoice_number}&requestId=${row.approvalid}&status=${row.request_status_code}`;
            }
            if (props.location.pathname.indexOf('/ops/') !== -1) {
                url = `../ops/create?invoice=${row.invoice_number}&requestId=${row.approvalid}&status=${row.request_status_code}`;
            }
            return (
                <Link to={url}> {row.approvalid}</Link>
            );
        }
    };

    function onDateRangeSelection(e, value) {
        dateRangeElement = PrepareFilterButtons(value);
    }

    function getCosmicTickets(cosmicCaseList) {
        if (Helpers.isEmpty(cosmicCaseList)) {
            return null;
        }
        const _strCosmicCases = cosmicCaseList && cosmicCaseList.reduce((_strCosmicCases, data) => {

            if (!Helpers.isEmpty(data) && !Helpers.isEmpty(data.ticketId)) {
                _strCosmicCases.push(
                    data.ticketId
                );
            }
            return _strCosmicCases;
        }, []);

        return _strCosmicCases.join(',\r\n');
    }

    function handleApplyEvent(value) {
        dateRangeElement = PrepareFilterButtons(value);
    }

    function PrepareFilterButtons(lifetimeDays) {
        props.onChangeDateRange(lifetimeDays);
        props.onApprovalSearchCredits();
        return <DateRangeFilter key="lifetymebuttons" lifetimeDays={lifetimeDays} handleApplyEvent={handleApplyEvent} onSearchChange={props.onSearchChange} onDateRangeSelection={onDateRangeSelection} />;
    }

    const columns = [
        {
            dataField: 'approvalid',
            text: formatMessage({ id: "lblApprovalId" }),
            title: true,
            headerTitle: true,
            formatter: linkFormatter
        }, {
            dataField: 'invoice_number',
            text: formatMessage({ id: "lblInvoiceNumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
            style: { whiteSpace: "pre-wrap", wordWrap: "break-word" }
        }, {
            dataField: 'request_date',
            text: formatMessage({ id: "lblRequestDate" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'request_status',
            text: formatMessage({ id: "lblRequestStatues" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'customer_name',
            text: formatMessage({ id: "lblCustomerName" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'enrollment_number',
            text: formatMessage({ id: "lblEnrolmentNumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }, {
            dataField: 'po_number',
            text: formatMessage({ id: "lblPONumber" }),
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true
        }];

    if (isSearchEnhancementEnabledFlag == "True") {
        columns.push(
            {
                dataField: 'cosmic_cases',
                text: formatMessage({ id: "lblCosmicNumber" }),
                title: (cell, row, rowIndex, colIndex) => `${cell}`,
                headerTitle: true
            })
    }

    useEffect(() => {
        document.title = formatMessage({ id: "lblApplicationName" }) + " - " + formatMessage({ id: "lblApprovalSeachPageName" });
        dateRangeElement = PrepareFilterButtons("1D");
    }, []);

    let statusMessage = "";
    if (props && props.error && 'errorMessages' in props.error) {
        const titleMessage = { id: "titleErrorMessage", defaultMessage: "Search Error" };
        statusMessage = <ErrorMessage key="cfoSearcherrorMessages" messageBody={props.error.errorMessages} messageTitle={titleMessage} defaultMessage={errorMessages.tokenExpiredMsg} />
    }

    creditsApprovalList = [];
    if (props.response && props.response.length > 0) {
        creditsApprovalList = [];

        props.response.map(data => (
            creditsApprovalList.push({
                uniqueId: uuidv4(),
                approvalid: data.returnsIdentifier,
                hasMultiple: data.hasMultipleInvoice,
                invoice_number: data.hasMultipleInvoice ? Helpers.getMultiPleInvoices(data.multipleInvoiceList, "invoiceNumber") : data.billingDocumentNbr,
                request_date: Helpers.DateFormatForDisplay(data.requestSubmitDate),
                request_status: Helpers.GetStatusByCode(data.requestStatusCode),
                request_status_code: data.requestStatusCode,
                customer_name: data.endCustomerName,
                customer_number: data.endCustomerPurchaseOrderNumber,
                enrollment_number: data.agreementNumber,
                po_number: data.purchaseOrderNumber,
                multiInvoiceNumber: data.billingDocumentNbr,
                cosmic_cases: getCosmicTickets(data.cosmicCases)
            })
        ));
    }

    exportCreditsApprovalList = [];
    if (props.response && props.response.length > 0) {
        exportCreditsApprovalList = [];
        props.response.map(data => (
            exportCreditsApprovalList.push({
                requestid: data.returnsIdentifier,
                request_status: (props.location.pathname.indexOf('/partner/') !== -1) ? Helpers.GetPartnerStatusByCode(data.requestStatusCode) : Helpers.GetStatusByCode(data.requestStatusCode),
                invoice_number: data.hasMultipleInvoice ? Helpers.getMultiPleInvoices(data.multipleInvoiceList, "invoiceNumber") : data.billingDocumentNbr,
                handling_fee: data.hasMultipleInvoice ? Helpers.getMultiPleInvoices(data.multipleInvoiceList, "restockFee") : data.handlingFee,
                invoice_date: data.hasMultipleInvoice ? Helpers.getMultiPleInvoices(data.multipleInvoiceList, "invoiceDate") : Helpers.DateFormatForDisplay(new Date(data.invoiceDate)),
                invoice_amount: data.hasMultipleInvoice ? Helpers.getMultiPleInvoices(data.multipleInvoiceList, "invoiceTotalAmount") : data.invoiceAmount,
                currency_code: data.currencyCode,
                po_number: data.purchaseOrderNumber,
                order_type: data.orderTypeCode,
                enrollment_number: data.agreementNumber,
                return_reason_code: data.returnReasonCode,
                return_reason: Helpers.GetReturnReasonsByCodeNew(data.returnReasonCode, myDomainData),
                rebill_po_number: Helpers.GetReBillPOAsString(data.rebillPOList),
                request_submit_date: data.requestSubmitDate ? Helpers.DateFormatForDisplay(new Date(data.requestSubmitDate)) : "",
                request_approval_date: data.requestApprovalDate ? Helpers.DateFormatForDisplay(new Date(data.requestApprovalDate)) : "",
                request_approval_status: data.requestApprovalStatus ? data.requestApprovalStatus : "",
                approver_contact: data.approverContact ? data.approverContact : "" ,
                approval_or_rejection_comments: data.approverComments ? data.approverComments : "",
                customer_pcn: data.endCustomerPurchaseOrderNumber,
                customer_name: data.endCustomerName,
                partner_pcn: data.partnerPCN,
                partner_name: data.partnerName,
                reseller_pcn: data.resellerPCN,
                reseller_name: data.resellerName,
                program_code: data.programCode,
                country_code: data.countryCode,
                cosmic_case: Helpers.GetCosmicCaseTicketsAsString(data.cosmicCases),
                cosmic_cases: getCosmicTickets(data.cosmicCases),
            })
        ));
    }

    var relativeUrl = null;
    const url = sessionStorage.getItem('VLReturnsRedirectUri');
    console.log('Saved URL: ' + url);
    if (url && url.includes('invoice') && url.includes('approve')) {
        console.log('Clearing URL: ' + url);
        sessionStorage.removeItem('VLReturnsRedirectUri');
        try {
            const newUrl = new URL(url);
            relativeUrl = newUrl.pathname + newUrl.search;
        } catch (error) {
            console.log('URL: ' + url + ' is not a valid url. Error: ' + JSON.stringify(error));
        }
    }
    console.log('Parsed URL: ' + relativeUrl);

    return (
        <div>
            <div>
                {relativeUrl && <Redirect to={relativeUrl} push={true} />}
            </div>
            <div>
                {
                    !relativeUrl &&
                    <div className="form-group">
                        <div className="col-lg-24">
                            <div className="row spacer-xs-bottom" >
                                <div className="col-sm-24 col-md-24 col-lg-24">
                                    {statusMessage}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12" >
                                    <h1 className="section-title">
                                        {formatMessage({ id: "lblApprovalSearchHeading" })}
                                    </h1>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12" >
                                    {dateRangeElement}
                                </div>
                            </div>
                            <FilterCriteria key="filtercriteria" intl={props.intl} domainData={props.domainData} location={props && props.location} />
                            <div className="row" >
                                <div className="col-sm-10 col-md-10 col-lg-10">
                                    <div className={!props.loading ? 'hidden' : ''}>
                                        <LoadingIndicator />
                                    </div>
                                </div>
                            </div>
                            <div className="row spacer-xs-bottom">
                                <GridControl key="gridcontrolfordatadisplay" columns={columns} data={creditsApprovalList} intl={props.intl} />
                            </div>
                            <div className="row spacer-xs-bottom pull-right" >
                                <div className="col-sm-24 col-md-24 col-lg-24">
                                    <ActionControl id="ApprovalListActions" fileName="ApprovalList.csv" List={exportCreditsApprovalList} columns={exportColumns} intl={props.intl} />
                                </div>
                            </div>
                        </div>
                    </div >
                }
            </div>
        </div>
    );

}

ApprovalSearch.propTypes = {
    dateRange: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    loading: PropTypes.bool,
    onApprovalSearchCredits: PropTypes.func,
    onChangeDateRange: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    error: makeSelectError(),
    response: makeSelectCredits(),
    domainData: makeSelectDomainData(),
    dateRange: makeSelectDateRange()
});

const mapDispatchToProps = (dispatch) => {
    return {
        onApprovalSearchCredits: () => dispatch(FilterCredits()),
        onChangeDateRange: (data) => dispatch(changeDateRange(data))
    };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, memo)(injectIntl(ApprovalSearch));
