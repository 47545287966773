import {
    SUBMIT_FBC_ApprovalRequest,
    SUBMIT_FBC_ApprovalRequest_SUCCESS,
    SUBMIT_FBC_ApprovalRequest_FAIL,
    Proceed_FBC_ApprovalRequest,
    Close_Dialog,
} from "./constants";

export function submitFbcApprovalRequest(value) {
    return {
        type: SUBMIT_FBC_ApprovalRequest,
        value,
    };
}

export function proceedFbcApprovalRequest(value) {
    return {
        type: Proceed_FBC_ApprovalRequest,
        value,
    };
}

export function closeDialog(value) {
    return {
        type: Close_Dialog,
        value,
    };
}


export function submitFbcApprovalRequestSuccess(response) {
    return {
        type: SUBMIT_FBC_ApprovalRequest_SUCCESS,
        response,
    };
}

export function submitFbcApprovalRequestFailure(error) {
    return {
        type: SUBMIT_FBC_ApprovalRequest_FAIL,
        error,
    };
}