import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectCancelReasonDomain = state => state.cancellationrequest || initialState;
const selectRoot = state => state;

const makeSelectCancelReason = () =>
    createSelector(
        selectCancelReasonDomain,
        substate => substate,
    );
const makeSelectCancelReasonCode = () =>
    createSelector(
        selectCancelReasonDomain,
        substate => substate.cancelReasonCode,
    );

const makeSelectCancelReasonDescripiton = () =>
    createSelector(
        selectCancelReasonDomain,
        substate => substate.cancelReasonDescripiton,
    );


const makeSelectIsOtherCancelReason = () =>
    createSelector(
        selectCancelReasonDomain,
        substate => substate.isOtherReasonSelected,
    );
const makeSelectIsLoading = () =>
    createSelector(
        selectCancelReasonDomain,
        substate => substate.loading,
    );

const makeSelectShowCancelConfirmation = () =>
    createSelector(
        selectCancelReasonDomain,
        substate => substate.showCancelConfirmation,
    );

const makeSelectCancelError = () =>
    createSelector(
        selectCancelReasonDomain,
        substate => substate.cancelFail,
    );

const makeSelectCancelSuccess = () =>
    createSelector(
        selectCancelReasonDomain,
        substate => substate.cancelSuccess,

    );
const makeSelectFormData = () =>
    createSelector(
        selectRoot,
        globalState => {
            var formData = null;
            if (globalState.fetchReturnRequest && globalState.fetchReturnRequest.invoice)
                formData = globalState.fetchReturnRequest.invoice;

            if (globalState.createRequest && globalState.createRequest.invoice)
                formData = globalState.createRequest.invoice;

            if (globalState.fetchMultiInvoiceReturnRequest && globalState.fetchMultiInvoiceReturnRequest.invoices)
                formData = globalState.fetchMultiInvoiceReturnRequest.invoices;

            if (globalState.createMultiInvoiceRequest && globalState.createMultiInvoiceRequest.invoices)
                formData = globalState.createMultiInvoiceRequest.invoices;

            return formData;
        },
    );


export default makeSelectCancelReason;
export { selectCancelReasonDomain, makeSelectCancelReason, makeSelectCancelReasonCode, makeSelectCancelReasonDescripiton, makeSelectIsOtherCancelReason, makeSelectIsLoading, makeSelectFormData, makeSelectShowCancelConfirmation, makeSelectCancelError, makeSelectCancelSuccess};
