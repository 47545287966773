//#region  import section 

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import reducer from "./reducer";
import messages from "./messages";
import { makeSelectFbcRequestError } from "./selectors";
import { makeSelectSelectFbcApprovalError } from "../../fbcApprover/request/selectors";
import { useInjectReducer } from "../../../../common/internals/utils/injectReducer";
import ErrorMessage from "../../../../common/components/ErrorMessage/ErrorMessage";
import errorMessages from "../../../../common/internals/api/errorMessages";

//#endregion
//#region functional component 

const FbcApprovalErrors = ({ approvalSearchError, approvalRequestError }) => {
  useInjectReducer({ key: "fbcApprovalErrors", reducer });

  if (approvalSearchError && "errorMessages" in approvalSearchError)
    return (
      <ErrorMessage
        messageBody={approvalSearchError.errorMessages}
        messageTitle={messages.approvalSearchError}
        defaultMessage={errorMessages.approveMsg}
      />
    );

  if (approvalRequestError && "errorMessages" in approvalRequestError)
    return (
      <ErrorMessage
        messageBody={approvalRequestError.errorMessages}
        messageTitle={messages.approvalRequestError}
        defaultMessage={errorMessages.approveMsg}
      />
    );
  return null;
};

//#endregion

//#region properties validation 

FbcApprovalErrors.propTypes = {
  dispatch: PropTypes.func,
};

//#endregion

//#region  state mapping 

const mapStateToProps = createStructuredSelector({
  approvalSearchError: makeSelectFbcRequestError(),
  approvalRequestError: makeSelectSelectFbcApprovalError()
});

//#endregion

//#region dispatch section 
function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}
//#endregion


const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(FbcApprovalErrors);
