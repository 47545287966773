import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectFilterCreditsRequestDomain = state => state.creditList || initialState;

const fetchCreditsListRequestPayload = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.payload );

const makeSelectCredits = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.creditsListResponse);

const makeSelectError = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.error);

const makeSelectLoading = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.loading);

const makeSelectRequestId = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.requestIdentifier);
const makeSelectRequestStatus = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.requestStatus);
const makeSelectSalesLocation = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.salesLocation);
const makeSelectOperationCenter = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.operationCenterCode);
const makeSelectInvoiceNumber = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.billingdocumentNBR);
const makeSelectPoNumber = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.poNumber);
const makeSelectEnrollmentNumber = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.enrollmentNumber);
const makeSelectCustomerNumber = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.customerNumber);
const makeSelectCosmicNumber = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.cosmicNumber);
const makeSelectCustomerName = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.endCustomerName);
const makeSelectProgramCode = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.programCode);
const makeSelectDateRange = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.dateRange);
const makeSelectSubCFORequest = () => createSelector(selectFilterCreditsRequestDomain, substate => substate.isSubCFORequest);
export default fetchCreditsListRequestPayload;

export { fetchCreditsListRequestPayload, makeSelectCredits, makeSelectLoading, makeSelectError, makeSelectRequestId, makeSelectRequestStatus, makeSelectSalesLocation, makeSelectOperationCenter, makeSelectInvoiceNumber, makeSelectPoNumber, makeSelectEnrollmentNumber, makeSelectCustomerNumber, makeSelectCosmicNumber, makeSelectCustomerName, makeSelectProgramCode, makeSelectDateRange, makeSelectSubCFORequest };