import React from 'react'
import PropTypes from 'prop-types'
import InfoMsg from '../InfoMsg'
import { FormattedMessage } from 'react-intl';

export const wrapperLabel = () => {
    return WrappedComponent => {
        const wrapperLabel = props => {
            const { error, label, hint, required, id } = props;
            return (
                <div>
                    {label && (
                        <label id={id + "Label"} className='form-label' htmlFor={id} name={id + "Label"}>
                            <FormattedMessage {...label} /> {`${required ? ' *' : ''}`}
                        </label>)}
                    <WrappedComponent {...props} />

                    {!!error && (<InfoMsg errorMsg={error} />)}
                    {!!hint && (<InfoMsg hintMsg={hint} />)}
                </div >
            );
        };

        wrapperLabel.propTypes = {
            name: PropTypes.string,
            label: PropTypes.object,
            hint: PropTypes.string,
            placeholder: PropTypes.string,
            type: PropTypes.string,
            disabled: PropTypes.bool,
            required: PropTypes.bool,
            editable: PropTypes.bool,
            error: PropTypes.string,
            value: PropTypes.any,
        };
        return (wrapperLabel);
    };
}

export default wrapperLabel
