import produce from 'immer';
import { MT_GET_RETURN_SUCCESS } from '../../../common/components/multiTenantFetchRequest/constants';
import { ON_PO_REMOVAL } from '../../components/reallocationDetailsTable/constants';
import { RESET_TO_DEFAULT } from '../../../common/components/multiTenantCreateRequest/constants';
import { SEARCH_PO, SEARCH_TENANT, SEARCH_TENANT_ERROR, SEARCH_TENANT_SUCCESS, SELECT_PO, UPDATE_DROPDOWN_OPTIONS, UPDATE_PO_LIST, UPDATE_SELECTED_POS, UPDATE_SELECTED_POS_REALLOCATION } from './constants';

export const initialState = { selectedTenant: null, selectedPOs: [], purchaseOrdersDropDownOptions: [], selectedPurchaseOrdersData: [], announcerText: "" };

const multiTenantSearchAndAddReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SEARCH_TENANT:
                draft.selectedTenant = action.tenant;
                draft.isPOLoading = true;
                break;
            case SELECT_PO:
                draft.selectedPO = action.selectedPO;
                break;
            case UPDATE_SELECTED_POS:
                let arr = [];
                action.data.map(data =>
                    arr.push({
                        purchaseOrderNumber: data.purchaseOrderNumber,
                        purchaseOrderID: data.purchaseOrderID,
                        createdDate: data.createdDate,
                        purchaseOrderTypeCode: data.purchaseOrderTypeCode,
                        externalIdentifier: data.externalIdentifier,
                        systemPONumber: data.systemPONumber,
                        selectedTenants: data.selectedTenants ? data.selectedTenants : {}
                    })
                );

                draft.selectedPurchaseOrdersData = arr;
                break;
            case MT_GET_RETURN_SUCCESS:
                let selectedPOData = [];
                action.requestData.multiTenantPODetails.map(data => {
                    let selectedTenantsData = {};
                    let obj = {};

                    data.poliDetails.map(poli => {
                        obj = {};
                        obj["selectedPartNumber"] = {
                            value: poli.partNumber, label: poli.partNumber
                        };
                        obj["coveragePeriod"] = {
                            coverageStartDate: poli.coveragePeriodStartDate,
                            coverageEndDate: poli.coveragePeriodEndDate
                        };
                        obj["productDescription"] = poli.productDescription;
                        obj["reallocationdetails"] = [];

                        let enrolmentNumAndTenantNameData = {};

                        poli.destinationTenant.map(realloc => {
                            enrolmentNumAndTenantNameData = {};
                            enrolmentNumAndTenantNameData["value"] = realloc.tenantName;
                            enrolmentNumAndTenantNameData["label"] = realloc.tenantName;
                            enrolmentNumAndTenantNameData["tenantId"] = realloc.tenantId;

                            obj["reallocationdetails"].push({
                                enrolmentNumAndTenantName: enrolmentNumAndTenantNameData,
                                reallocateQty: realloc.quantity,
                                poNumber: realloc.newPONumber
                            })
                        });
                        selectedTenantsData[poli.externalIdentifier] = obj;

                    });

                    selectedPOData.push({
                        purchaseOrderNumber: data.purchaseOrderNumber,
                        purchaseOrderID: data.purchaseOrderId,
                        createdDate: data.createdDate,
                        systemPONumber: data.systemPONumber,
                        purchaseOrderTypeCode: data.purchaseOrderTypeCode,
                        externalIdentifier: data.externalIdentifier,
                        selectedTenants: selectedTenantsData
                    })
                });

                draft.selectedPurchaseOrdersData = selectedPOData;


                break;
            case UPDATE_PO_LIST:

                const purchaseOrdersListTemp = [];
                for (let i = 0; i < action.unchecked.length; i++) {
                    purchaseOrdersListTemp.push(action.unchecked[i]);
                }
                draft.purchaseOrdersList = purchaseOrdersListTemp;

                break;

            case SEARCH_PO:
                if (draft.selectedPOs.length > action.polist.length) {
                    let selectedPOsCopy = draft.selectedPOs;

                    action.polist.map(
                        data =>
                            selectedPOsCopy = selectedPOsCopy.filter(item => item.value !== data.value)
                    )

                    selectedPOsCopy.map(data =>
                        draft.selectedPurchaseOrdersData =
                        draft.selectedPurchaseOrdersData.filter(item => item.purchaseOrderNumber !== data.value)
                    )
                }
                else {

                    draft.purchaseOrdersList.filter(x => x.purchaseOrderNumber === action.polist[action.polist.length - 1].value).map(
                        data =>
                            draft.selectedPurchaseOrdersData.push({
                                purchaseOrderNumber: data.purchaseOrderNumber,
                                purchaseOrderID: data.purchaseOrderID,
                                createdDate: data.createdDate,
                                purchaseOrderTypeCode: data.purchaseOrderTypeCode,
                                externalIdentifier: data.externalIdentifier,
                                systemPONumber: data.systemPONumber,
                                selectedTenants: {}
                            })
                    )
                }
                draft.selectedPOs = action.polist;
                break;

            case SEARCH_TENANT_SUCCESS:
                draft.isPOLoading = false;
                draft.purchaseOrdersList = action.purchaseOrdersList;
                draft.purchaseOrdersListCopy = action.purchaseOrdersList;
                draft.purchaseOrdersDropDownOptions = [];
                action.purchaseOrdersList.map(
                    data =>
                        // Bug Fix for Bug# 40604234. "TESTPO-RECO" and "TESTPO" were being treated as the same PO as a result of which,
                        // the users were not able to view "TESTPO" in PO Dropdown. Hence, the filter has been modified.

                        // draft.purchaseOrdersDropDownOptions.filter(item => item.value.includes(data.purchaseOrderNumber)).length == 0 &&
                        addUniquePOs(draft, data)
                )
                draft.announcerText = "P O Search complete";
                break;

            case UPDATE_DROPDOWN_OPTIONS:
                let name = action.name;
                draft.purchaseOrdersDropDownOptions =
                    draft.purchaseOrdersDropDownOptions.filter(item => {
                        if (item.value === name) {
                            return false;
                        }
                        return true;
                    })

                break;

            case ON_PO_REMOVAL:
                draft.selectedPurchaseOrdersData =
                    draft.selectedPurchaseOrdersData.filter(item => {

                        if (item.externalIdentifier === action.data) {
                            draft.purchaseOrdersList.push(item);

                            addUniquePOs(draft, item)

                            return false;
                        }
                        return true;
                    });
                draft.announcerText = "Removed P O from reallocation request";
                break;
            case UPDATE_SELECTED_POS_REALLOCATION:
                draft.announcerText = "Reallocation details saved";

                for (let po in draft.selectedPurchaseOrdersData) {
                    if (draft.selectedPurchaseOrdersData[po].externalIdentifier == action.data.extId) {
                        draft.selectedPurchaseOrdersData[po].systemPONumber = action.data.systemPONumber;
                        draft.selectedPurchaseOrdersData[po].selectedTenants = action.data.reallocationBody;

                        return;
                    }
                }
                break;
            case SEARCH_TENANT_ERROR:
                draft.isPOLoading = false;
                draft.error = action.error;
                break;

            case RESET_TO_DEFAULT:
                draft.selectedTenant = null;
                draft.selectedPurchaseOrdersData = [];
                draft.selectedPO = null;
                draft.purchaseOrdersList = [];
                draft.purchaseOrdersDropDownOptions = [];
                draft.announcerText = "";
                break;
        }
    });

function addUniquePOs(draft, data) {

    draft.purchaseOrdersDropDownOptions.filter(item => item.value === data.purchaseOrderNumber).length == 0 &&
        draft.purchaseOrdersDropDownOptions.push({
            value: data.purchaseOrderNumber,
            label: data.purchaseOrderNumber
        })
}

export default multiTenantSearchAndAddReducer;
