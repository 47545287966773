import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import messages from './messages';
import { createStructuredSelector } from 'reselect';
import errorMessages from '../../../../common/internals/api/errorMessages';
import ErrorMessage from '../../../../common/components/ErrorMessage/ErrorMessage';
import { makeSelectRebillSearchError } from '../../containers/rebillSearch/selectors';
import { makeSelectCreateRequestError } from '../../../common/components/createRequest/selectors';
import { makeSelectCreateMultiInvoiceRequestError } from '../../../common/components/createMultiInvoiceRequest/selectors';
import { makeSelectFetchReturnRequestError } from '../../../common/components/fetchReturnRequest/selectors';
import { makeSelectFetchMultiInvoiceReturnRequestError } from '../../../common/components/fetchMultiInvoiceReturnRequest/selectors';
import { makeSelectCancelFutureBillingCreateRequestError } from '../../../common/components/cancelFutureBillingCreateRequest/selectors';
import { makeSelectPoError } from '../../../operations/containers/cancelFutureBillingRequest/selectors';

import { makeSelectCancelError } from '../../../common/components/cancelrequest/selectors';
import {
    makeSelectReturnFormError,
    makeSelectReturnFormSaveError,
    makeSelectReturnFormDeleteError
} from '../../containers/returnForm/selectors';
import {
    makeSelectMultiInvoiceReturnFormError,
    makeSelectMultiInvoiceReturnFormSaveError,
    makeSelectMultiInvoiceReturnFormDeleteError
} from '../../containers/multiInvoiceReturnForm/selectors';

import {
    makeCancelFutureBillingReturnFormError    
} from '../../containers/cancelFutureBillingReturnForm/selectors';
import { makeSelectReallocateModalError } from '../../containers/reallocateModal/selectors';
import { makeSelectReacllocationDetailsError } from '../reallocationDetailsTable/selectors';
import { makeSelectMTSearchPOError } from '../../containers/multiTenantSearchAndAdd/selectors';
import { makeSelectMultiTenantCreateRequestError } from '../../../common/components/multiTenantCreateRequest/selectors';
import { makeSelectSubmitError } from '../../containers/multiTenantRequest/selectors';
import { makeSelectFetchError } from '../../../common/components/multiTenantFetchRequest/selectors';


export function SubmitErrors({
    multiInvoiceSearchError,
    invoiceSearchError,
    multiInvoiceReturnFormError,
    returnFormError,
    multiInvoiceReturnFormSaveError,
    returnFormSaveError,
    multiInvoiceReturnFormDeleteError,
    returnFormDeleteError,
    returnError,
    rebillError,
    cancelError,
    fetchMultiInvoiceReturnRequestError,
    fbcSearchError,
    fbcSubmitError,
    fbcSelectPoError,
    reallocationModalError,
    reallocationDetailsError,
    reallocationSearchPOError,
    reallocationCreateRequestError,
    reallocationSubmitError,
    isModalError,
    multitenantFetchError

}) {
    console.log(fbcSubmitError);
    if (rebillError) {
        window.scrollTo(0, 0);
        }
    const userRole = window && window.userRole;
    const defaultMessage = (userRole === 'VPU') ? errorMessages.partnerMsg : errorMessages.opsMsg;
    
    let mainScreenError = (
        <div>
            {(returnFormSaveError && 'errorMessages' in returnFormSaveError) ?
                <ErrorMessage messageBody={returnFormSaveError.errorMessages}
                    messageTitle={messages.saveRequestError} defaultMessage={defaultMessage} /> : ""}

            {(multiInvoiceReturnFormSaveError && 'errorMessages' in multiInvoiceReturnFormSaveError) ?
                <ErrorMessage messageBody={multiInvoiceReturnFormSaveError.errorMessages}
                    messageTitle={messages.saveRequestError} defaultMessage={defaultMessage} /> : ""}

            {(returnFormDeleteError && 'errorMessages' in returnFormDeleteError) ?
                <ErrorMessage messageBody={returnFormDeleteError.errorMessages}
                    messageTitle={messages.deleteRequestError} defaultMessage={defaultMessage} /> : ""}

            {(multiInvoiceReturnFormDeleteError && 'errorMessages' in multiInvoiceReturnFormDeleteError) ?
                <ErrorMessage messageBody={multiInvoiceReturnFormDeleteError.errorMessages}
                    messageTitle={messages.deleteRequestError} defaultMessage={defaultMessage} /> : ""}

            {(returnFormError && 'errorMessages' in returnFormError) ?
                <ErrorMessage messageBody={returnFormError.errorMessages}
                    messageTitle={messages.submitRequestError} defaultMessage={defaultMessage} /> : ""}

            {(multiInvoiceReturnFormError && 'errorMessages' in multiInvoiceReturnFormError) ?
                <ErrorMessage messageBody={multiInvoiceReturnFormError.errorMessages}
                    messageTitle={messages.submitRequestError} defaultMessage={defaultMessage} /> : ""}

            {(rebillError && 'errorMessages' in rebillError) ?
                <ErrorMessage messageBody={rebillError.errorMessages}
                    messageTitle={messages.rebillSearchError} defaultMessage={defaultMessage} /> : ""}

            {(invoiceSearchError && 'errorMessages' in invoiceSearchError) ?
                <ErrorMessage messageBody={invoiceSearchError.errorMessages}
                    messageTitle={messages.invoiceSearchError} defaultMessage={defaultMessage} /> : ""}

            {(multiInvoiceSearchError && 'errorMessages' in multiInvoiceSearchError) ?
                <ErrorMessage messageBody={multiInvoiceSearchError.errorMessages}
                    messageTitle={messages.multiInvoiceSearchError} defaultMessage={defaultMessage} /> : ""}

            {(returnError && 'errorMessages' in returnError) ?
                <ErrorMessage messageBody={returnError.errorMessages}
                    messageTitle={messages.invoiceSearchError} defaultMessage={defaultMessage} /> : ""}

            {(fetchMultiInvoiceReturnRequestError && 'errorMessages' in fetchMultiInvoiceReturnRequestError) ?
                <ErrorMessage messageBody={fetchMultiInvoiceReturnRequestError.errorMessages}
                    messageTitle={messages.invoiceSearchError} defaultMessage={defaultMessage} /> : ""}

            {(cancelError && 'errorMessages' in cancelError) ?
                <ErrorMessage messageBody={cancelError.errorMessages}
                    messageTitle={messages.cancelError} defaultMessage={defaultMessage} /> : ""}

            {(fbcSearchError && 'errorMessages' in fbcSearchError) ?
                <ErrorMessage messageBody={fbcSearchError.errorMessages} key="fbcSearchError"
                    messageTitle={messages.fbcSearchError} defaultMessage={defaultMessage} /> : ""}

            {(fbcSubmitError && 'errorMessages' in fbcSubmitError) ?
                <ErrorMessage messageBody={fbcSubmitError.errorMessages} key="fbcSubmitError"
                    messageTitle={messages.fbcSubmitError} defaultMessage={defaultMessage} /> : ""}
                    
            {(fbcSelectPoError && 'errorMessages' in fbcSelectPoError) ?
                <ErrorMessage messageBody={fbcSelectPoError.errorMessages} key="fbcSelectPoError"
                    messageTitle={messages.fbcSelectPoError} defaultMessage={defaultMessage} /> : ""}
            
            {(reallocationSearchPOError && 'errorMessages' in reallocationSearchPOError) ?
                <ErrorMessage messageBody={reallocationSearchPOError.errorMessages} key="reallocationSearchPOError"
                    messageTitle={messages.reallocationSearchPOError} defaultMessage={defaultMessage} /> : ""}


            {(reallocationCreateRequestError && 'errorMessages' in reallocationCreateRequestError) ?
                <ErrorMessage messageBody={reallocationCreateRequestError.errorMessages} key="reallocationCreateRequestError"
                    messageTitle={messages.reallocationCreateRequestError} defaultMessage={defaultMessage} /> : ""}

            {(reallocationSubmitError && 'errorMessages' in reallocationSubmitError) ?
                <ErrorMessage messageBody={reallocationSubmitError.errorMessages} key="reallocationSubmitError"
                    messageTitle={messages.reallocationSubmitError} defaultMessage={defaultMessage} /> : ""}

            {(multitenantFetchError && 'errorMessages' in multitenantFetchError) ?
                <ErrorMessage messageBody={multitenantFetchError.errorMessages} key="multitenantFetchError"
                    messageTitle={messages.multitenantFetchError} defaultMessage={defaultMessage} /> : ""}

        </div>
    );

    let modalError = (
        <>
            {(reallocationModalError && 'errorMessages' in reallocationModalError) ?
            <ErrorMessage messageBody={reallocationModalError.errorMessages} key="reallocationModalError"
            messageTitle={messages.reallocationModalError} defaultMessage={defaultMessage} /> : ""}

            {(reallocationDetailsError && 'errorMessages' in reallocationDetailsError) ?
            <ErrorMessage messageBody={reallocationDetailsError.errorMessages} key="reallocationDetailsError"
            messageTitle={messages.reallocationDetailsError} defaultMessage={defaultMessage} /> : ""}
        </>
        );

    if(isModalError) {
        return modalError;
    } 

    return mainScreenError;
}

SubmitErrors.propTypes = {
    dispatch: PropTypes.func,
    multiInvoiceSearchError: PropTypes.any,
    invoiceSearchError: PropTypes.any,
    returnFormError: PropTypes.any,
    returnFormSaveError: PropTypes.any,
    returnFormDeleteError: PropTypes.any,
    getReturnError: PropTypes.any,
    rebillError: PropTypes.any,
    cancelError: PropTypes.any,
    fetchMultiInvoiceReturnRequestError: PropTypes.any,
    fbcSearchError: PropTypes.any,
    fbcSubmitError: PropTypes.any,
    fbcSelectPoError: PropTypes.func,
    reallocationModalError: PropTypes.any,
    reallocationDetailsError: PropTypes.any,
    reallocationSearchPOError: PropTypes.any,
    reallocationCreateRequestError: PropTypes.any
};

const mapStateToProps = createStructuredSelector({
    multiInvoiceSearchError: makeSelectCreateMultiInvoiceRequestError(),
    invoiceSearchError: makeSelectCreateRequestError(),
    returnError: makeSelectFetchReturnRequestError(),
    returnFormError: makeSelectReturnFormError(),
    multiInvoiceReturnFormError: makeSelectMultiInvoiceReturnFormError(),
    returnFormSaveError: makeSelectReturnFormSaveError(),
    multiInvoiceReturnFormSaveError: makeSelectMultiInvoiceReturnFormSaveError(),
    returnFormDeleteError: makeSelectReturnFormDeleteError(),
    multiInvoiceReturnFormDeleteError: makeSelectMultiInvoiceReturnFormDeleteError(),
    rebillError: makeSelectRebillSearchError(),
    cancelError: makeSelectCancelError(),
    fetchMultiInvoiceReturnRequestError: makeSelectFetchMultiInvoiceReturnRequestError(),
    fbcSearchError: makeSelectCancelFutureBillingCreateRequestError(),
    fbcSubmitError: makeCancelFutureBillingReturnFormError(),
    fbcSelectPoError: makeSelectPoError(),
    reallocationModalError: makeSelectReallocateModalError(),
    reallocationDetailsError: makeSelectReacllocationDetailsError(),
    reallocationSearchPOError: makeSelectMTSearchPOError(),
    reallocationCreateRequestError: makeSelectMultiTenantCreateRequestError(),
    reallocationSubmitError: makeSelectSubmitError(),
    multitenantFetchError: makeSelectFetchError()
});

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);

export default compose(withConnect)(SubmitErrors);
