import React, { Component } from 'react';
import { ExportToExcel } from './ExportExcel';
import { injectIntl } from 'react-intl';

export class ActionControl extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { formatMessage } = this.props.intl;
        var lblActions = formatMessage({ id: "lblActions" });
        var exportToExcel = formatMessage({ id: "lblExportToExcel" });
        return (
            <div>
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {lblActions}
                </button>
                    <div className="dropdown-menu" aria-label="dropdownMenuButton">
                        <ul>
                            <li>
                                <ExportToExcel key="ExportExcel" value={exportToExcel} id={this.props.id} List={this.props.List} columns={this.props.columns} fileName={this.props.fileName} intl={this.props.intl} />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
export default (injectIntl(ActionControl));