//#region import
import React, { memo, useContext } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { createStructuredSelector } from "reselect";

import reducer from "./reducer";
import saga from "./saga";
import DropDownBox from "../../../common/components/controls/DropDown";
import LocalisedLabel from "../../../common/components/controls/LocalisedLabel";
import TextBox from "../../../common/components/controls/TextBox";
import { useInjectSaga } from "../../../common/internals/utils/injectSaga";
import { useInjectReducer } from "../../../common/internals/utils/injectReducer";
import {
    FilterReallocationRequests,
    changeRequestIdentifier,
    changeRequestStatusCode,
    changeEnrollmentName,
    changeAgreementNumber,
    changeCosmicNumber,
    changeTenantNumber
} from "./actions";

import {
    makeSelectLoading,
    makeSelectError,
    makeSelectRequestId,
    makeSelectRequestStatus,
    makeSelectEnrollmentName,
    makeSelectEnrollmentNumber,
    makeSelectTenantNumber,
    makeSelectCosmicNumber
} from "./selectors";

import { makeSelectUserInfo } from "../../../common/app/selectors";

import {
    MT_PartnerRequestStatus_Search,
    MT_OpsRequestStatus,
    MT_OpsRequestStatusV2
} from "../../../common/internals/utils/requestStatusRoleBased";
//#endregion

//#region function component
const FilterCriteria = (props) => {

    const { formatMessage } = props.intl;

    useInjectReducer({ key: "reallocationList", reducer });
    useInjectSaga({ key: "reallocationList", saga });

    const userRole = window && window.userRole;
    const enableMTApprovalVal = window.vlcredits.envData.Services.EnableMTApproval;
    const isMTApprovalEnabled = enableMTApprovalVal && enableMTApprovalVal.toLowerCase() === "true" ? true : false;

    var lblSearchText = formatMessage({ id: "lblSearchText" });
    return (
        <div className="row spacer-xs-bottom">
            <div className="col-sm-24 col-md-24 col-lg-24">
                <div className="row spacer-xs-bottom">
                    <div className="col-sm-8 col-md-9 col-lg-6 ">
                        <LocalisedLabel htmlFor="txtRequestId" className="form-label" ariaLabel="Request ID" id="lblRequestId" value="Request ID" />
                        <TextBox type="text" className="form-control mainElement" id="txtRequestId" value={props.requestId} onChange={props.onChangeRequestIdentifier} />

                    </div>
                    <div className="col-sm-8 col-md-9 col-lg-6">
                        <LocalisedLabel
                            htmlFor="txtLeadEnrolmentNumber"
                            className="form-label"
                            id="lblLeadEnrolmentNumber"

                        />
                        <TextBox
                            type="text"
                            className="form-control"
                            id="txtLeadEnrolmentNumber"
                            value={props.enrollmentNumber}
                            onChange={props.onChangeAgreementNumber}
                        />


                    </div>

                    <div className="col-sm-8 col-md-9 col-lg-6">
                        <LocalisedLabel
                            htmlFor="txtLeadEnrollmentName"
                            className="form-label"
                            id="lblLeadEnrollmentName"
                        />
                        <TextBox
                            type="text"
                            className="form-control"
                            id="txtLeadEnrollmentName"
                            value={props.enrollmentName}
                            onChange={props.onChangeEnrollmentName}
                        />
                    </div>
                    <div className="col-sm-8 col-md-9 col-lg-6">
                        <LocalisedLabel
                            className="form-label"
                            htmlFor="ddlRequestStatus"
                            id="lblRequestStatus"
                            ariaLabel="Request Status"
                            value="Request Status"
                        />
                        <DropDownBox
                            id="ddlRequestStatus"
                            ariaLabel="Request Status"
                            inputList={
                                userRole === "VPU"
                                    ? MT_PartnerRequestStatus_Search
                                    : isMTApprovalEnabled ? MT_OpsRequestStatusV2 : MT_OpsRequestStatus
                            }
                            includeAll="true"
                            value={props.requestStatus}
                            onChange={props.onChangeRequestStatusCode}
                        />
                    </div>

                    <div className="col-sm-8 col-md-9 col-lg-6">
                        <LocalisedLabel
                            htmlFor="txtTenantNumber"
                            className="form-label"
                            id="lblTenantNumber"

                        />
                        <TextBox
                            type="text"
                            className="form-control"
                            id="txtTenantNumber"
                            value={props.tenantNumber}
                            onChange={props.onChangeTenantNumber}
                        />


                    </div>



                    {userRole != "VPU" ? (
                        <div className="col-sm-8 col-md-9 col-lg-6">
                            <LocalisedLabel
                                htmlFor="txtCosmicNumber"
                                className="form-label"
                                id="lblCosmicNumber"
                            />
                            <TextBox
                                type="text"
                                className="form-control"
                                id="txtCosmicNumber"
                                value={props.cosmicNumber}
                                onChange={props.onChangeCosmicNumber}
                            />
                        </div>
                    ) : null}
                </div>

                <div className="row spacer-xs-bottom pull-right">
                    <div className="col-sm-24 col-md-24 col-lg-24 ">
                        <div className="btn-group">
                            <button
                                id="btnSearch"
                                className="btn btn-default active"
                                type="submit"
                                aria-label={lblSearchText}
                                onClick={props.onSearchLicenseRequestList}
                            >
                                {lblSearchText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

//#endregion

//#region Validating property types getting inside this component through prop param.

FilterCriteria.propTypes = {
    cosmicNumber: PropTypes.string,
    dateRange: PropTypes.string,
    enrollmentNumber: PropTypes.string,
    tenantNumber: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    loading: PropTypes.bool,
    enrollmentName: PropTypes.string,
    requestId: PropTypes.string,
    requestStatus: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
    ]),
    onChangeAgreementNumber: PropTypes.func,
    onChangeCosmicNumber: PropTypes.func,
    onChangeEnrollmentName: PropTypes.func,
    onChangeRequestIdentifier: PropTypes.func,
    onChangeRequestStatusCode: PropTypes.func,
    onSearchLicenseRequestList: PropTypes.func,
    onChangeTenantNumber: PropTypes.func
};

//#endregion

//#region map state and dispatch 

const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    error: makeSelectError(),
    requestId: makeSelectRequestId(),
    requestStatus: makeSelectRequestStatus(),
    enrollmentName: makeSelectEnrollmentName(),
    enrollmentNumber: makeSelectEnrollmentNumber(),
    tenantNumber: makeSelectTenantNumber(),
    cosmicNumber: makeSelectCosmicNumber(),
    userInformation: makeSelectUserInfo()
});

const mapDispatchToProps = (dispatch) => {
    return {
        onChangeRequestIdentifier: (evt) =>
            dispatch(changeRequestIdentifier(evt.target.value)),
        onChangeRequestStatusCode: (evt) =>
            dispatch(changeRequestStatusCode(evt.target.value.split(","))),
        onChangeEnrollmentName: (evt) =>
            dispatch(changeEnrollmentName(evt.target.value)),
        onChangeAgreementNumber: (evt) =>
            dispatch(changeAgreementNumber(evt.target.value)),
        onChangeCosmicNumber: (evt) =>
            dispatch(changeCosmicNumber(evt.target.value)),
        onSearchLicenseRequestList: evt =>
            dispatch(FilterReallocationRequests()),
        onChangeTenantNumber: (evt) =>
            dispatch(changeTenantNumber(evt.target.value)),

    };
};

//#endregion

//#region  connect to store 

const withConnect = connect(mapStateToProps, mapDispatchToProps);

//#endregion

export default compose(withConnect, memo)(injectIntl(FilterCriteria));
