import { defineMessages } from 'react-intl';

export const scope = 'mtsubmitInfo';

export default defineMessages({
    readOnlyInfoMessage: {
        id: `${scope}.readOnlyInfoMessage`,
        defaultMessage: 'Showing Readonly Screen',
    },
    readOnlyMessage: {
        id: `${scope}.readOnlyMessage`,
        defaultMessage: '{requestStatus} - Showing reallocation request in read only mode.',
    },
    partialRequestSucess: {
        id: `${scope}.partialRequestSucess`,
        defaultMessage: "One or more Reallocation Orders got rejected and Credit is not processed. Please contact processing team to take necessary steps to complete/reject the request"
    },
    partialRequestSucessTitle: {
        id: `${scope}.partialRequestSucessTitle`,
        defaultMessage: "The request was processed partially"
    },
    userReadOnlyMessage: {
        id: `${scope}.userReadOnlyMessage`,
        defaultMessage: 'Logged in user has read only access to this Request.',
    },
    rocInfoMessage: {
        id: `${scope}.rocInfoMessage`,
        defaultMessage: 'ROC Override Update',
    },
    errorMessage: {
        id: `${scope}.errorMessage`,
        defaultMessage: 'Error Occurred during processing of reallocation request'
    }
});
