import * as Helpers from '../utils/helpers';

export default function formatSearchRequest(payload) {
    var startDate, endDate;
    var filters = [];

    if (window && window.isApprovar) {
        filters.push({ Name: "requestStatusCode", Operator: 'neq', Value: ["DFT"] });
    }

    Object.entries(payload).map(([key, value]) => {
        if (key === 'dateRange') {
            switch (payload[key]) {
                case '1D':
                    startDate = Helpers.getNextDays(-1);                    
                    endDate = Helpers.getNextDays(1);
                    break;
                case '1W':
                    startDate = Helpers.getNextDays(-7);
                    endDate = Helpers.getNextDays(1);
                    break;
                case '1M':
                    startDate = Helpers.getNextDays(-30);
                    endDate = Helpers.getNextDays(1);
                    break;
                default:
                    [startDate, endDate] = value.split("to");
                    startDate = Helpers.DateFormatSearch(startDate.trim());
                    endDate = Helpers.DateFormatSearch(endDate.trim());
                    break;

            }
        } else {             
            if (payload[key] !== null && payload[key] !== "undefined" && payload[key] !== "-1") {
                if (key !== '') {
                    if (Array.isArray(payload[key])) {
                        if (payload[key].length > 0 && payload[key][0] !== "-1") {
                            filters.push({ Name: key, Operator: 'eq', Value: payload[key] });
                        }
                    } else if (typeof payload[key] === "boolean") {
                        filters.push({ Name: key, Operator: 'eq', Value: [payload[key]]});
                    } else if (payload[key] && !Helpers.isEmpty(payload[key].trim())) {
                        //UI has only one po number text box for search 
                        //User can given po number or system po number as an input
                        //This below check to add to filter criteria before caling RFS service
                        if (window.vlcredits.FeatureName === "FBC" && key === 'systemPONumber') {
                            console.log("Add system po number to filter for search" + payload[key]);
                            filters.push({ Name: key, Operator: 'eq', Value: [payload[key].trim().toUpperCase()], isOptional: true });
                        }
                        else if (key === 'endCustomerName') {
                            filters.push({ Name: key, Operator: 'eq', Value: [payload[key].trim().toUpperCase()] });
                        }
                        else {
                            filters.push({ Name: key, Operator: 'eq', Value: [payload[key].trim().toUpperCase()] });
                        }
                    } else {
                        console.log("no condition match");
                    }
                } else {
                    console.log("Key is Empty");
                }
            }
        }
        return filters;

    });

    var requestPayload = {
        "top": 9000,
        "skip": 0,
        "startDate": startDate,
        "endDate": endDate,
        "orderBy": {
            "filterAttribute": "requestSubmitDate",
            "isAsc": false
        },
        filters: filters
    }
    return JSON.stringify(requestPayload);

}
export { formatSearchRequest };