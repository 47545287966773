import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { injectIntl } from 'react-intl';
import $ from 'jquery';
import '../../app/accessibility-style.css';
export class GridControlWithoutSerachandPage extends Component {

    componentDidMount() {
        $('#tblAdditionalDetails').find('thead > tr > th').each(function (index, item) {
            $(item).attr("tabindex", "-1");
        });
        this.handleDataChange = this.handleDataChange.bind(this);
    }

    handleDataChange = ({ dataSize }) => {
        $('#tblAdditionalDetails').attr("aria-rowcount", dataSize);
    }

    render() {

        const { formatMessage } = this.props.intl;
        const options = {
            custom: true,
            paginationSize: 4,
            pageStartIndex: 1,
            //alwaysShowAllBtns: true, // Always show next and previous button            
            hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: formatMessage({ id: "grdPaginationFirstText" }),
            prePageText: formatMessage({ id: 'grdPaginationBackText' }),
            nextPageText: formatMessage({ id: 'grdPaginationNextText' }),
            lastPageText: formatMessage({ id: 'grdPaginationLastText' }),
            nextPageTitle: formatMessage({ id: 'grdPaginationNextPageText' }),
            prePageTitle: formatMessage({ id: 'grdPaginationPrevPageText' }),
            firstPageTitle: formatMessage({ id: 'grdPaginationFirstPageText' }),
            lastPageTitle: formatMessage({ id: 'grdPaginationLastPageText' }),
            showTotal: false,
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: formatMessage({ id: 'grdPaginationAllText' }), value: this.props.data.length
            }]
        };

        var _msg = formatMessage({ id: "grdNoRecords" });
        const NoRecordsFound = <div aria-label={_msg} aria-live="polite">{_msg}</div>;

        const { SearchBar } = Search;
        const tableTitle = this.props.title ? this.props.title:'';
        $('#tblAdditionalDetails').attr("aria-label", tableTitle);
        const contentTable = ({ paginationProps, paginationTableProps }) => (
            <div>
                <ToolkitProvider
                    keyField="requestid"
                    columns={this.props.columns}
                    data={this.props.data}
                    search
                >
                    {
                        toolkitprops => (
                            <div id="additionalDetailsTable">
                                <BootstrapTable
                                   id="tblAdditionalDetails"
                                    hover
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    condensed
                                    noDataIndication={NoRecordsFound}
                                />
                            </div>
                        )
                    }
                </ToolkitProvider>                
            </div>
        );


        return (
            <div className="col-sm-24 col-md-24 col-lg-24">
                <PaginationProvider pagination={paginationFactory(options)}>
                    {contentTable}
                </PaginationProvider>
            </div>
        );

    }
}
export default injectIntl(GridControlWithoutSerachandPage);
