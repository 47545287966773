import { put, select, takeLatest } from "redux-saga/effects";
import {
    makeSelectAccessToken,
    makeSelectAuthType
} from "../../../../common/app/selectors";
import { newMTRequestCreate } from "../../../../common/internals/api/requests";
import { sagaFetchHandler } from "../../../../common/internals/api/sagaFetchHandler";
import { searchCompleted, searchError, resetToDefault } from "./actions";
import { MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER } from './constants';
import { makeSelectLeadEnrollmentNumberCreate } from './selectors';


export function* getLeadEnrollmentDetails() {
   
    const leadEnrollmentNumber = yield select(makeSelectLeadEnrollmentNumberCreate());
    var newMTRequest = newMTRequestCreate();
    newMTRequest.url = `api/agreement/${encodeURIComponent(leadEnrollmentNumber.trim())}?api-version=1.0`;
    yield* setAuthTokens(newMTRequest);
    yield* sagaFetchHandler(
        newMTRequest,
        searchCompleted,
        searchError,
        null,
        { "leadEnrollmentNumber": leadEnrollmentNumber }
    )
    yield put(resetToDefault());
}


function* setAuthTokens(request) {
    const accessToken = yield select(makeSelectAccessToken());
    const authType = yield select(makeSelectAuthType());
    request.options.headers["Authorization"] = "Bearer " + accessToken;
    request.options.headers["x-ms-AuthType"] = authType;
}

export default function* createRequestSaga() {
    yield takeLatest(MULTI_TENANT_SEARCH_WITH_LEAD_ENROLLMENT_NUMBER, getLeadEnrollmentDetails);
}
