import {
    FBC_CHANGE_ENROLLMENT_NUMBER,
    FBC_CHANGE_SYSTEM_PO_NUMBER,
    FBC_SEARCH_WITH_POAA,
    FBC_CLEAR_ENROLLMENT_NUMBER,
    FBC_CLEAR_SYSTEM_PO_NUMBER,
    FBC_SEARCH_WITH_POAA_SUCCESS,
    FBC_SEARCH_WITH_POAA_ERROR,
    FBC_CHANGE_COSMIC_CASENUMBER,
    FBC_CHANGE_BILLING_DOCUMENT_NUMBER
} from "./constants";

export function search() {
    return {
        type: FBC_SEARCH_WITH_POAA
    };
}

export function clearEnrollmentNumber() {
    return {
        type: FBC_CLEAR_ENROLLMENT_NUMBER
    };
}

export function clearSystemPONumber() {
    return {
        type: FBC_CLEAR_SYSTEM_PO_NUMBER
    };
}

export function searchCompleted(invoices, searchObject) {
    return {
        type: FBC_SEARCH_WITH_POAA_SUCCESS,
        invoices,
        searchObject
    };
}

export function searchError(error) {
    return {
        type: FBC_SEARCH_WITH_POAA_ERROR,
        error
    };
}

export function changeEnrollmentNumber(enrollmentNumber) {
    console.log("enrollmentNumber:::" + enrollmentNumber);
    return {
        type: FBC_CHANGE_ENROLLMENT_NUMBER,
        enrollmentNumber
    };
}

export function changeSystemPONumber(systemPONumber) {
    return {
        type: FBC_CHANGE_SYSTEM_PO_NUMBER,
        systemPONumber
    };
}

export function changeManualCosmicCaseNumber(value) {
    return {
        type: FBC_CHANGE_COSMIC_CASENUMBER,
        value
    };
}

export function changeBillingDocumentNbr(billingDocumentNbr) {
    return {
        type: FBC_CHANGE_BILLING_DOCUMENT_NUMBER,
        billingDocumentNbr
    }
}

