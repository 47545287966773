import { defineMessages } from 'react-intl';

export const scope = 'mtApproverDetails';

export default defineMessages({
    selectOtherRejectionReason: {
        id: `${scope}.selectOtherRejectionReason`,
        defaultMessage: 'Please type the reason for rejecting this credit (Please be aware that this reason will be shared with the credit request submitter, which may be a partner).'
    },
    selectRejectionReason: {
        id: `${scope}.selectRejectionReason`,
        defaultMessage: 'Please select a rejection reason in order to reject this credit.'
    },
    dvSubmitErrorMessage: {
        id: `${scope}.dvSubmitErrorMessage`,
        defaultMessage: 'This credit cannot be approved as a rejection reason has been selected. Please remove the rejection reason to approve this credit.',
    },
    selectTxtApproverComments: {
        id: `${scope}.selectTxtApproverComments`,
        defaultMessage: 'Please type the reason for Approving this credit (Please be aware that this reason will be shared with the credit request submitter, which may be a partner).'
    },
});
