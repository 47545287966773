import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { isLRDCountryCode } from '../../../../common/internals/utils/HandlingFeeHelper';

const selectRoot = state => state;
const selectReturnFormDomain = state => state.returnForm || initialState;

const makeSelectReturnForm = () =>
    createSelector(
        selectReturnFormDomain,
        substate => substate,
    );

const makeSelectReturnStatus = () =>
    createSelector(selectReturnFormDomain, substate => substate.requestStatusCode);

const makeSelectReturnFormError = () =>
    createSelector(selectReturnFormDomain, substate => substate.error);

const makeSelectReturnFormLoading = () =>
    createSelector(selectReturnFormDomain, substate => substate.loading);

const makeSelectReturnFormSuccess = () =>
    createSelector(selectReturnFormDomain, substate => substate.successResponse);

const makeSelectReturnFormDeleteSuccessResponse = () =>
    createSelector(selectReturnFormDomain, substate => substate.deleteSuccessResponse);

const makeSelectReturnFormDeleteError = () =>
    createSelector(selectReturnFormDomain, substate => substate.deleteError);

const makeSelectReturnFormSaveSuccessResponse = () =>
    createSelector(selectReturnFormDomain, substate => substate.saveSuccessResponse);

const makeSelectReturnFormSaveError = () =>
    createSelector(selectReturnFormDomain, substate => substate.saveError);

const makeSelectReturnFormIsActionOpen = () =>
    createSelector(selectReturnFormDomain, substate => substate.isActionsOpen);

const makeSelectShowRebillConfirmation = () =>
    createSelector(selectReturnFormDomain, substate => substate.showRebillConfirmation);

const makeSelectShowMCSKUConfirmation = () =>
    createSelector(selectReturnFormDomain, substate => substate.showMCSKUConfirmation);

const makeSelectReturnFormEReceiptStatus = () =>
    createSelector(selectReturnFormDomain, substate => substate.eReceiptStatus);

const makeSelectIsLRDCountryCode = () =>
    createSelector(
        selectRoot,
        globalState => {
            const billToCountryCode = null;
            if (globalState.fetchReturnRequest && globalState.fetchReturnRequest.invoice)
                billToCountryCode = globalState.fetchReturnRequest.invoice.billToCountryCode;
            else if (globalState.createRequest && globalState.createRequest.invoice)
                billToCountryCode = globalState.createRequest.invoice.billToCountryCode;

            if (isLRDCountryCode(billToCountryCode))
                return billToCountryCode;

            return null;
        });

const makeSelectIsReturnFormReadOnly = () =>
    createSelector(
        selectRoot,
        globalState => {
            if (globalState.fetchReturnRequest && globalState.fetchReturnRequest.invoice)
                return globalState.fetchReturnRequest.invoice.isReadOnly
                    || globalState.fetchReturnRequest.invoice.isUserReadOnly;

            if (globalState.createRequest && globalState.createRequest.invoice)
                return globalState.createRequest.invoice.isReadOnly
                    || globalState.createRequest.invoice.isUserReadOnly;

            return null;
        },
    );
const makeSelectLineItemDetails = () =>
    createSelector(
        selectRoot,
        globalState => {
            const {
                returnDetails: {
                    isFullCredit: isFullCredit,
                },
                lineItemDetails: {
                    lineItemDetails: lineItemDetails
                }
            } = globalState;
            lineItemDetails.forEach(function (v) { delete v.isValid });
            const purchaseOrderLineItemDetails = isFullCredit ? lineItemDetails :lineItemDetails.filter(item => item.isAdjusted)
            return purchaseOrderLineItemDetails;
        });
const makeSelectReturnFormData = () =>
    createSelector(
        selectRoot,
        globalState => {
            const {
                rebillpoTable: {
                    rebillInvoicepo: rebillPOList
                },
                lineItemDetails: {
                    lineItemDetails: lineItemDetails
                },
                returnDetails: {
                    handlingFeePercentage: handlingFee,
                    isFullCredit: isFullCredit,
                    returnReason: returnReasonCode,
                    netCreditAmount: invoiceTotalAmountUSD,
                    netCreditAmountLocal: invoiceTotalAmount,
                    operationsCenterCode: operationsCenterCode

                },
                empowermentsDetails: {
                    empowermentIdCSV: empowermentIds,
                    notes: notes
                },
                returnForm: {
                    requestStatusCode: requestStatusCode
                },
                fileUploadModal: modalData
            } = globalState;

            lineItemDetails.forEach(function (v) { delete v.isValid });
            const returnRequestPayload = {
                "openOrder": null,
                "vlOrder": {
                    isFullCredit,
                    returnReasonCode,
                    "netAmountUSD": invoiceTotalAmountUSD,
                    "netAmount": invoiceTotalAmount,
                    "purchaseOrderLineItemCollection": isFullCredit ?
                        [] : lineItemDetails.filter(item => item.isAdjusted),
                    "rebillPOList": isFullCredit ? rebillPOList : [],
                    "attachment": modalData ? modalData.uploadedFiles : [],
                    empowermentIds,
                    notes,
                    handlingFee,
                    requestStatusCode,
                    "alternateROC":  operationsCenterCode,
                }
            };
            return returnRequestPayload;
        }
    );


const makeSelectInvoice = () =>
    createSelector(
        selectRoot,
        globalState => {
            if (globalState.fetchReturnRequest && globalState.fetchReturnRequest.invoice)
                return globalState.fetchReturnRequest.invoice;

            if (globalState.createRequest && globalState.createRequest.invoice)
                return globalState.createRequest.invoice;

            return null;
        },
    );




export default makeSelectReturnForm;
export {
    selectReturnFormDomain,
    makeSelectReturnFormError,
    makeSelectReturnFormLoading,
    makeSelectReturnFormSuccess,
    makeSelectIsReturnFormReadOnly,
    makeSelectReturnFormIsActionOpen,
    makeSelectReturnFormData,
    makeSelectReturnStatus,
    makeSelectIsLRDCountryCode,
    makeSelectReturnFormDeleteError,
    makeSelectReturnFormSaveError,
    makeSelectReturnFormDeleteSuccessResponse,
    makeSelectReturnFormSaveSuccessResponse,
    makeSelectShowRebillConfirmation,
    makeSelectReturnFormEReceiptStatus,
    makeSelectInvoice,
    makeSelectShowMCSKUConfirmation,
    makeSelectLineItemDetails
};