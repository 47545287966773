import React from 'react';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import  DynamicErrorMessage  from './DynamicErrorMessage'; 

function CloseButton({ onClick }) {
    if (onClick) {
        return (
            <FormattedMessage {...messages.closeErrorMessage}>
                {(closeMessage) =>
                    <button type="button" className="close" data-dismiss="alert" aria-label={closeMessage}>
                        <span aria-hidden="true"><i className="win-icon win-icon-Cancel"></i>asdadf</span>
                    </button>
                }
            </FormattedMessage>
        );
    } else {
        return (
            <FormattedMessage {...messages.closeErrorMessage}>
                {(closeMessage) =>
                    <button type="button" className="close" data-dismiss="alert" aria-label={closeMessage}>
                        <span aria-hidden="true"><i className="win-icon win-icon-Cancel" /></span>
                    </button>
                }
            </FormattedMessage>
        );
    }
}


export function ErrorMessage({ messageBody, messageTitle, onCloseClick, defaultMessage }) {
    const allMessages = messageBody;
    return (
        <div className="alert-band">
            <div className="alert with-icon alert-danger alert-dismissible fade in" role="alert" aria-live="assertive" aria-hidden="false">
                <CloseButton onClick={onCloseClick} />
                <span className="alert-icon"><i className="win-icon win-icon-ErrorBadge" /></span>
                <div className="alert-title">
                    {messageTitle ?
                        <b><FormattedMessage {...messageTitle} /></b> :<b>
                            'Error'</b>

                    }
                </div>
                <div className="row">
                    <div className="col-xs-24">
                            {
                                allMessages && Object.keys(allMessages).length > 0 ?
                                    Object.keys(allMessages).map(error => {
                                        const messages = allMessages[error];
                                        return writeError(messages, error);
                                    })
                                    :
                                    writeError(Array(1).fill(defaultMessage))

                                }
                    </div>
                </div>
            </div>
        </div>
    );
}

function writeError(messages,error="errors") {
    return (
        <div>
            {error != "errors" ?
                <li aria-live="assertive" aria-label={error}><b>{error}</b></li> :
                ""
            }
            {messages
                && Array.isArray(messages)
                && messages.map(
                    message => (
                        message.dynamic ? <DynamicErrorMessage message={message} /> :
                            <ul>
                            <li>
                            {
                                (message && message.id.split("errorMessages.").length > 1 && !isNaN(message.id.split("errorMessages.")[1])) ?
                                    (
                                        <FormattedMessage {...message}>
                                            {(m) =>
                                                <div aria-live="assertive" aria-label={message.id.split("errorMessages.")[1] +":" + m}> {message.id.split("errorMessages.")[1]} : {m}</div>
                                            }
                                        </FormattedMessage>
                                    ) :
                                    <FormattedMessage {...message}>
                                        {(m) =>
                                            <div aria-live="assertive" aria-label={m}> {m}</div>
                                        }
                                    </FormattedMessage>
                                    
                            }
                                </li>
                                </ul>
                    )
                )}
        </div>
    );
}
export default ErrorMessage;
