import produce from 'immer';
import { CHANGE_CANCEL_REASON, ADD_OTHER_CANCEL_REASON, SET_OTHER_CANCEL_REASON, INVOKE_CANCEL_REASON_REQUEST_SUCCESS, INVOKE_CANCEL_REASON_REQUEST_FAIL, INVOKE_CANCEL_REASON_APIREQUEST, SHOW_CANCEL_CONFIRMATION, CLOSE_CANCEL_CONFIRMATION } from './constants'

export const initialState = {};

const cancellationrequestreducer = (state = initialState, action) =>
    produce(state, (draft) => {       
        switch (action.type) {

            case CHANGE_CANCEL_REASON:
                draft.cancelReasonCode = action.value;                
                break;
            case ADD_OTHER_CANCEL_REASON:
                draft.cancelReasonDescripiton = action.value;               
                break;
            case SET_OTHER_CANCEL_REASON:
                draft.isOtherReasonSelected = action.value;               
                break;
            case INVOKE_CANCEL_REASON_REQUEST_SUCCESS:
                draft.cancelSuccess = action.value;
                draft.loading = false;
                break;
            case INVOKE_CANCEL_REASON_APIREQUEST:  
                draft.showCancelConfirmation = false;
                draft.loading = true;
                break;
            case INVOKE_CANCEL_REASON_REQUEST_FAIL:
                draft.cancelFail = action.value;
                draft.loading = false
                break;
            case SHOW_CANCEL_CONFIRMATION:
                draft.showCancelConfirmation = true;
                break;
            case CLOSE_CANCEL_CONFIRMATION:
                draft.showCancelConfirmation = false;
                break;
                
        }
    });

export default cancellationrequestreducer;