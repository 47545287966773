import { Approver_Comments, Approver_Callout, CHANGE_REJECTION_REASON, ADD_OTHER_REJECTION_REASON, SET_OTHER_REJECTION_REASON} from './constants';


export function changeApproverComments(value) {
  return {
      type: Approver_Comments,
      value
  };
}

export function changeApproverCallout(value) {
  return {
      type: Approver_Callout,
      value
  };
}

export function changeRejectionReason(value) {
    return {
        type: CHANGE_REJECTION_REASON,
        value
    };
}

export function addOtherRejectionReason(value) {
    return {
        type: ADD_OTHER_REJECTION_REASON,
        value
    };
}

export function setOtherRejectionReason(value) {
    return {
        type: SET_OTHER_REJECTION_REASON,
        value
    };
}




