import { createSelector } from "reselect";

const selectRoot = state => state;

const makeSelectMTSubmitInfoData = () => createSelector(
    selectRoot,
    globalState => {
        var formData = null;
        
        if(globalState.multiTenantRequest) {
            formData = globalState.multiTenantRequest;
        }

        return formData;
    }
)

export {makeSelectMTSubmitInfoData};