import {FileSignatures} from './constants'

export default class FileSignatureValidator {
    fileSignaturesMap = new Map(FileSignatures);

    IsAValidFile = (fileInfo) => {
        let isAValidFile = false;

        try
        {
            if (this.fileSignaturesMap.has(fileInfo.fileExtension.toLowerCase()) === false) {                
                return isAValidFile;
            }

            var signature = this.fileSignaturesMap.get(fileInfo.fileExtension.toLowerCase());
            var signatureLength = signature.length;

            var arrayBuffer = fileInfo.fileContent;
            var arrayBufferLength = fileInfo.fileLengthInBytes;

            if (arrayBufferLength > signatureLength) {
                let byteCounter = 0;
                var view = new DataView(arrayBuffer);
                signature.forEach((signatureByte) => {
                    isAValidFile = (signatureByte === view.getUint8(byteCounter));
                    byteCounter = ++byteCounter;
                });
            }
        }
        catch (err) {
        }        

        return isAValidFile;
    }
}