import produce from 'immer';
import { GET_FULL_ORDER_SUCCESS, ADD_REALLOCATE, GET_FULL_ORDER_ERROR, PART_NUMBER_LOADING_SUCCESS } from './constants'
export const initialState = {
    orderDetails: {}, isLoading: false };


const ReallocationDetailsTable = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case GET_FULL_ORDER_SUCCESS:
                let externalIdentifier = action.searchObject.externalIdentifier;
                draft.orderDetails[externalIdentifier] = action.purchaseorder;
                draft.billToCountryCode = action.purchaseorder.billToCountryCode;
                draft.error = null;
                draft.isLoading = false;
                break;
            case ADD_REALLOCATE:
                draft.error = null;
                draft.isLoading = true;
                break;
            case GET_FULL_ORDER_ERROR:
                draft.isLoading = false;
                draft.error = action.error;
                break;
            case PART_NUMBER_LOADING_SUCCESS:
                draft.isLoading = false;
                break;
        }
    });

export default ReallocationDetailsTable;