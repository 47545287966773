import { transformResponse } from './transformResponse';
export function transformReturnResponse(orderDetailsResponse) {

    let orderDetails = window.vlcredits.FeatureName ==="FBC" && orderDetailsResponse ? orderDetailsResponse.duplicatePurchaseOrderList ? orderDetailsResponse.duplicatePurchaseOrderList : transformResponse("GetReturn", orderDetailsResponse.vlfbOrder)[0] : transformResponse("GetReturn", orderDetailsResponse.vlOrder)[0];

    if (window.vlcredits.FeatureName === "MT" && orderDetailsResponse) {
        if (orderDetailsResponse.vlmtOrder) {
            orderDetails = transformResponse("GetMTReturn", orderDetailsResponse.vlmtOrder)[0];
        }
        else {
            orderDetails = transformResponse("GetReturn", orderDetailsResponse)[0];
        }
    }

    if (orderDetails.rebillInvoicePO) {
        orderDetails.rebillInvoicePO = transformResponse("RebillPO", orderDetails.rebillInvoicePO);
    }

    if (orderDetails.lineItemDetails) {
        orderDetails.lineItemDetails = transformResponse("lineItemDetails", orderDetails.lineItemDetails);
        orderDetails.lineItemDetails.forEach(function (item) {
            item.USDCurrencyValue = orderDetails.USDCurrencyValue
        });
    }

    if (orderDetails.multipleInvoiceList) {
        var invoiceDetailsResult = [];

        if (
            orderDetails.multipleInvoiceList &&
            orderDetails.multipleInvoiceList.length > 0
        ) {
            orderDetails.multipleInvoiceList.map(data =>
                invoiceDetailsResult.push({
                    invoiceNumber: data.invoiceNumber,
                    invoiceDate: data.invoiceDate,
                    invoiceAmount: data.invoiceTotalAmount,
                    invoiceAmountUSD: data.invoiceTotalAmountUSD,
                    currency: orderDetails.currency,
                    restockFee: data.restockFee,
                    isSelected: data.isSelected,
                    localInvoiceNumber : data.notaFiscal
                })
            );
        }
        orderDetails.invoicesDetails = invoiceDetailsResult;
        console.log("invoicesDetails " + orderDetails.invoicesDetails);
    }

    if (orderDetails.tickets) {
        orderDetails.cosmicCaseNumber =
            orderDetails.tickets && orderDetails.tickets.length > 0 ?
                orderDetails.tickets.map((val) => { return val.ticketId; }).join(',') : "";
    }

    if (orderDetails.workflowList) {
        var approverListResult = [];
        orderDetails.workflowList && orderDetails.workflowList.forEach(approverworkflowList => {
            approverworkflowList.approverList &&
                approverworkflowList.approverList.forEach(approverList => {
                    approverListResult.push
                        ({
                            workflow: approverworkflowList.workflow,
                            approverLevel: approverList.approverLevel,
                            approvedBy: approverList.approverAlias,
                            approveDate: approverList.approveDate,
                            status: approverList.status,
                            validUntill: approverworkflowList.validUntil,
                            approverComments: approverList.comments
                        });
                });
        });

        orderDetails.approverList
            = transformResponse("ApproverList", approverListResult);

    }

    return orderDetails;
}

export function transformGetOrdersResponse(getOrdersResponse) {

    var purchaseOrdersList = [];

    if (getOrdersResponse) {
        getOrdersResponse.map(data =>
            purchaseOrdersList.push({
                purchaseOrderNumber: data.purchaseOrderNumber,
                purchaseOrderID: data.purchaseOrderID,
                createdDate: data.createdDate,
                purchaseOrderTypeCode: data.purchaseOrderTypeCode,
                externalIdentifier: data.externalIdentifier,
                systemPONumber: data.systemPONumber
            })
        );
    }
    return purchaseOrdersList;
}