import { createSelector } from "reselect";
import { initialState } from "./reducer";
import { isNotaFiscalNumberCountryCode } from '../../../../common/internals/utils/HandlingFeeHelper';

const selectCreateMultiInvoiceRequestDomain = state =>
  state.createMultiInvoiceRequest || initialState;

const makeSelectCreateMultiInvoiceRequest = () =>
  createSelector(selectCreateMultiInvoiceRequestDomain, substate => substate);

const makeSelectSystemPONumberCreate = () =>
  createSelector(
    selectCreateMultiInvoiceRequestDomain,
    substate => substate.systemPONumber
  );

const makeSelectEnrollmentNumberCreate = () =>
  createSelector(
    selectCreateMultiInvoiceRequestDomain,
    substate => substate.enrollmentNumber
    );

const makeSelectCreateMultiInvoicerequestLoading = () =>
  createSelector(
    selectCreateMultiInvoiceRequestDomain,
    substate => substate.loading
  );

const makeSelectCreateMultiInvoiceRequestError = () =>
  createSelector(
    selectCreateMultiInvoiceRequestDomain,
    substate => substate.error
  );

const makeSelectInvoicesCreate = () =>
  createSelector(
    selectCreateMultiInvoiceRequestDomain,
    substate => substate.invoices
  );

const makeSelectInvoicesFetch = () =>
  createSelector(
    selectCreateMultiInvoiceRequestDomain,
    substate => substate.invoices
    );

const makeSelectCosmicCaseNumber = () =>
    createSelector(selectCreateMultiInvoiceRequestDomain,
        substate => substate.invoices && substate.invoices.purchaseOrderId ?
            (substate.invoices.cosmicCaseNumber ? substate.invoices.cosmicCaseNumber : ' ') : null);

const makeSelectCreateRequestIsLRD = () =>
    createSelector(selectCreateMultiInvoiceRequestDomain,
        substate => substate.invoices ?
            isNotaFiscalNumberCountryCode(substate.invoices.billToCountryCode)
            : null);

const makeSelectInvoiceNumberCreate = () =>
    createSelector(selectCreateMultiInvoiceRequestDomain,substate => substate.invoices ? substate.invoices.invoiceNumber : null);

export default makeSelectCreateMultiInvoiceRequest;

export {
  makeSelectCreateMultiInvoiceRequest,
  makeSelectSystemPONumberCreate,
  makeSelectEnrollmentNumberCreate,
  makeSelectCreateMultiInvoicerequestLoading,
  makeSelectCreateMultiInvoiceRequestError,
  makeSelectInvoicesCreate,
    makeSelectInvoicesFetch,
    makeSelectCosmicCaseNumber,
    makeSelectCreateRequestIsLRD,
    makeSelectInvoiceNumberCreate
};
