import { defineMessages } from 'react-intl';

export const scope = 'reallocationBody';

export default defineMessages({
    selectPartNumber: {
        id: `${scope}.selectPartNumber`,
        defaultMessage: 'Select the Part Number',
    },
    selectCoveragePeriod: {
        id: `${scope}.selectCoveragePeriod`,
        defaultMessage: 'Select the Coverage Period',
    },
    productDescription: {
        id: `${scope}.productDescription`,
        defaultMessage: 'Product Description',
    },
    coverageMessage: {
        id: `${scope}.coverageMessage`,
        defaultMessage: 'Please note selection of a coverage will reallocate the licenses for that period and all subsequent coverages',
    },
   
});
