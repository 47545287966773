import produce from 'immer';
import { SUBMIT_MT_ApprovalRequest_FAIL, SUBMIT_MT_ApprovalRequest_SUCCESS }
    from '../../containers/multiTenantRequest/constants';

export const initialState = { error: false };

const mtApprovalErrorsReducer = (state = initialState, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case SUBMIT_MT_ApprovalRequest_FAIL:
                draft.error = action.error;
                break;
            case SUBMIT_MT_ApprovalRequest_SUCCESS:
                draft.error = false;
                break;
        }
    });

export default mtApprovalErrorsReducer;
