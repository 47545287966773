import { connect } from "react-redux";
import { compose } from "redux";
import { initAppinsights } from "./actions";
import { useInjectSaga } from "../../internals/utils/injectSaga";
import { useInjectReducer } from "../../internals/utils/injectReducer";
import reducer from "./reducer";
import saga from "./saga";

const AppInsights = (props) => {
  useInjectReducer({ key: "appinsight", reducer });
  useInjectSaga({ key: "appinsight", saga });

  return props.children;
};

export function mapDispatchToProps(dispatch) {
  return {
    initAppinsights: (key) => dispatch(initAppinsights(key)),
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(AppInsights);
