export default class FileUploadReader {
    GetFileExtension = (filePath) => {
        // pop the last array element which will be the file extension.
        return filePath.split('.').pop();
    }

    ReadFile = (evt, onFileRead, onFileReadError) => {
        try {
            let files = evt.target.files,
                value = evt.target.value;

            var file = files[0];

            var fileExtension = this.GetFileExtension(value);
            //if (file.type == '' || file.type == null) {
            //    file.type = 'application/vnd.ms-outlook';
            //}
            // FileReader - Chrome 7, Edge, FF 3.6, IE 10, Opera 11, Safari 6
            // https://www.htmlgoodies.com/beyond/javascript/read-text-files-using-the-javascript-filereader.html
            var reader = new FileReader();

            reader.onload = function (evt) {
                // https://www.w3.org/TR/FileAPI/#dom-filereader-readystate
                if (evt.target.readyState == FileReader.DONE) {   
                    var arrayBuffer = evt.target.result;                    
                    var fileInfo = {
                        "fileName": file.name,
                        "fileContent": arrayBuffer,
                        "fileLengthInBytes": file.size,
                        "fileExtension": fileExtension,
                        "contentType": file.type // MIME type
                    };

                    
                    if (onFileRead != null) {
                        onFileRead(fileInfo);
                    }

                    if (fileExtension == "msg") {
                        fileInfo.contentType = 'application/vnd.ms-outlook';
                    }
                }
            }

            reader.onerror = function (event) {
                reader.abort();
                if (onFileReadError != null) {
                    onFileReadError((reader.error) ? reader.error.code + " -> " + reader.error.message : "");
                }
            };


            // ArrayBuffer - Chrome 7, Edge 12, FF 4, IE 10, Opera 11.6, Safari 5.1
            reader.readAsArrayBuffer(file);
        }
        catch (err) {
            if (onFileReadError != null) {
                onFileReadError(err);
            }
        }
    }
}