//#region  import section

import React, { useContext } from "react";
import messages from "./messages";
import { injectIntl } from "react-intl";
import LocalisedLabel from "../../../../common/components/controls/LocalisedLabel";
import collapsableSection from "../../../../common/components/collapsableSection";
import GridControl from "../../../../common/components/grid/GridControlWithoutSP";
import '../../../../common/app/accessibility-style.css';
//#endregion

//#region function component

const AdditionalDetails = ({
    props,
    approvalListProps,
    readOnlyMTData
  
}) => {
    
    const userRole = window && window.userRole;
    //const { formatMessage } = props.intl;
    let approvaList = [];
    if (approvalListProps && approvalListProps.length > 0) {
        approvalListProps.map((data) =>
            approvaList.push({
                //for partner logins - do not show approvedby email . Display as BPOAgent for the approved/rejected status. empty for pending status.
                approvedBy: (userRole === 'VPU' && (data.status === "Pending")) ? "" : 
                    (userRole === 'VPU' && (data.status != "Pending")) ? "BPO - Agent" : data.approvedBy,
                approveDate: data.approveDate ? data.approveDate.substring(0, 10) : "",
                status: data.status,
                approverComments: data.approverComments,
            })
        );
        approvaList.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
    }
   

    const mtViewAdditionalDeatils = [
        {
            dataField: "approvedBy",
            text: "Reviewed By" ,
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        },
        {
            dataField: "approveDate",
            text: "Reviewed Date" ,
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        },
        {
            dataField: "status",
            text: "Review Status" ,
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        },
        {
            dataField: "approverComments",
            text: "Reviewer Comments" ,
            title: (cell, row, rowIndex, colIndex) => `${cell}`,
            headerTitle: true,
        },
    ];

    return (
        <div className="row">
            <div className="col-lg-24 ">
                <div className="form-group">
                    <div className="row spacer-xs-bottom">
                        <GridControl
                            key={"gridcontrolfordatadisplayAdditionalDeatils"}
                            columns={mtViewAdditionalDeatils}
                            data={approvaList}
                            //intl={props.intl}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

//#endregion

export default collapsableSection(
    messages.additionalDetailsHeader,
    true
)(injectIntl(AdditionalDetails));
