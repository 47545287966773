import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectMultiTenantCreateRequestDomain = state => state.multiTenantCreateRequest || initialState;
const makeSelectMultiTenantCreateRequest = () => createSelector(selectMultiTenantCreateRequestDomain, substate => substate);

const makeSelectLeadEnrollmentNumberCreate = () => createSelector(selectMultiTenantCreateRequestDomain, substate => substate.leadEnrollmentNumber);

const makeSelectCosmicCaseNumber = () => createSelector(selectMultiTenantCreateRequestDomain, substate => substate.cosmicCaseNumber);

const makeSelectTrackingId = () => createSelector(selectMultiTenantCreateRequestDomain, substate => substate.trackingId);

const makeUserProfileRole = () => createSelector(selectMultiTenantCreateRequestDomain, substate => substate.role ?
    (substate.role ? substate.role : ' ') : '');
const makeSelectTenantAgreements = () => createSelector(selectMultiTenantCreateRequestDomain, substate => substate.tenantAgreements);


const makeSelectMultiTenantCreateRequestLoading = () => createSelector(selectMultiTenantCreateRequestDomain, substate => substate.loading);

const makeSelectIsEnrollmentDetailsLoadedFromCreateRequest = () => createSelector(selectMultiTenantCreateRequestDomain, substate => substate.isEnrollmentDetailsLoaded);

const makeSelectMultiTenantCreateRequestError = () =>
    createSelector(
        selectMultiTenantCreateRequestDomain,
        substate => substate.error
    );

const makeSelectIsReadOnly = () => createSelector(selectMultiTenantCreateRequestDomain, substate => substate.isReadOnly);

export default makeSelectMultiTenantCreateRequest;

export {
    makeSelectMultiTenantCreateRequest,
    makeSelectCosmicCaseNumber,
    makeSelectTrackingId,
    makeSelectLeadEnrollmentNumberCreate,
    makeSelectMultiTenantCreateRequestLoading,
    makeSelectMultiTenantCreateRequestError,
    makeSelectIsEnrollmentDetailsLoadedFromCreateRequest,
    makeUserProfileRole,
    makeSelectIsReadOnly,
    makeSelectTenantAgreements
    
};
