import {
    INIT_APPINSIGHTS, SET_APPINSIGHTS
} from './constants';

export function initAppinsights(instrumentation_key) {
    return {
        type: INIT_APPINSIGHTS,
        instrumentation_key
    };
}
export function setAppInsights(appInsights) {
    return {
        type: SET_APPINSIGHTS, appInsights
    };
}