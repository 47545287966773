import { createSelector } from "reselect";
import { initialState } from "./reducer";
import { isNotaFiscalNumberCountryCode } from '../../../../common/internals/utils/HandlingFeeHelper';
const selectRoot = state => state;
const selectCancelFutureBillingRequestDomain = state => state.CancelFutureBillingCreateRequest || initialState;
const selectFBCReturnDetailstDomain = state => state.cfbreturnDetails || initialState;

const makeSelectCancelFutureBillingRequest = () => createSelector(selectCancelFutureBillingRequestDomain, substate => substate);


const makeSelectCosmicCaseNumber = () =>
    createSelector(selectCancelFutureBillingRequestDomain,
        substate => substate.invoices ? substate.invoices.cosmicCaseNumber : null);

const makeSelectIsLRDInvoice = () =>
    createSelector(selectRoot, globalState => {
        if (globalState.CancelFutureBillingFetchRequest && globalState.CancelFutureBillingFetchRequest.invoices)
            return isNotaFiscalNumberCountryCode(globalState.CancelFutureBillingFetchRequest.invoices.billToCountryCode);

        if (globalState.CancelFutureBillingCreateRequest && globalState.CancelFutureBillingCreateRequest.invoices)
            return isNotaFiscalNumberCountryCode(globalState.CancelFutureBillingCreateRequest.invoices.billToCountryCode);

        return null;
    });

const makeSelectIsFBCReturnFormReadOnly = () =>
    createSelector(selectRoot, globalState => {
        if (globalState.CancelFutureBillingFetchRequest && globalState.CancelFutureBillingFetchRequest.invoices)
            return globalState.CancelFutureBillingFetchRequest.invoices.isReadOnly
                || globalState.CancelFutureBillingFetchRequest.invoices.isUserReadOnly;

        if (globalState.CancelFutureBillingCreateRequest && globalState.CancelFutureBillingCreateRequest.invoices)
            return globalState.CancelFutureBillingCreateRequest.invoices.isReadOnly
                || globalState.CancelFutureBillingCreateRequest.invoices.isUserReadOnly;

        return null;
    });

    const makeSelectPoFetch = () =>
    createSelector(selectRoot, globalState => {
        if (globalState && globalState.CancelFutureBillingRequest && globalState.CancelFutureBillingRequest.podetails)
            return globalState.CancelFutureBillingRequest.podetails;

            return null;
    });

    const makeSelectPoError = () =>
    createSelector(selectRoot, globalState => {
        if (globalState && globalState.CancelFutureBillingRequest && globalState.CancelFutureBillingRequest.error)
            return globalState.CancelFutureBillingRequest.error;

            return null;
    });

    const makeSelectPoIsLoading = () =>
    createSelector(selectRoot, globalState => {
        if (globalState && globalState.CancelFutureBillingRequest && globalState.CancelFutureBillingRequest.loading)
            return globalState.CancelFutureBillingRequest.loading;

            return null;
    });

export default makeSelectCancelFutureBillingRequest;
export { selectCancelFutureBillingRequestDomain, makeSelectIsFBCReturnFormReadOnly, makeSelectIsLRDInvoice, makeSelectCosmicCaseNumber,makeSelectPoFetch,makeSelectPoError,makeSelectPoIsLoading };
